var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-modal",
          {
            attrs: {
              id: "TerminateInstance",
              centered: "",
              "content-class": "terminateInstance",
              title: "Terminate Instance"
            },
            on: { hide: _vm.closeInstance },
            scopedSlots: _vm._u([
              {
                key: "modal-footer",
                fn: function() {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-primary btn-custom btn-user-cred",
                        attrs: { size: "sm" },
                        on: { click: _vm.terminateInstance }
                      },
                      [_vm._v(" Confirm ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _c("p", [
              _vm._v(
                "Please confirm you want to terminate the demo environment."
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }