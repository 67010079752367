<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data () {
    return {
      gradient: null
    }
  },
  props: ['chartLabels', 'chartData', 'options'],
  methods: {
    getGradient (rgbcolorArray) {
      const colorArray = []
      rgbcolorArray.forEach(element => {
        const color = element.split(/(?:\(|\))+/)[1]
        this.gradient = this.$refs.canvas
          .getContext('2d')
          .createLinearGradient(0, 0, 0, 450)
        this.gradient.addColorStop(0, `rgba(${color}, 0.85)`)
        this.gradient.addColorStop(0.5, `rgba(${color}, 0.55)`)
        this.gradient.addColorStop(1, `rgba(${color}, 0.25)`)
        colorArray.push(this.gradient)
      })

      return colorArray
    },
    getDataSet (data) {
      return data.map((value, index) => {
        const gradColor = this.getGradient(value.backgroundColor)
        const obj = {
          label: value.label,
          borderColor: gradColor,
          pointBackgroundColor: gradColor,
          borderWidth: 2,
          pointBorderColor: gradColor,
          backgroundColor: gradColor,
          data: value.data
        }
        return obj
      })
    }
  },
  mounted () {
    this.renderChart({
      labels: this.chartLabels,
      datasets: this.getDataSet(this.chartData)
    }, this.options)
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart({
          labels: this.chartLabels,
          datasets: this.getDataSet(newOption)
        }, this.options)
      },
      deep: true
    }
  }
}
</script>
