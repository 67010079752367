<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data () {
    return {
      gradient: null
    }
  },
  props: ['chartLabels', 'chartData', 'options'],
  methods: {},
  mounted () {
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: this.chartData
      },
      this.options
    )
  }
}
</script>
