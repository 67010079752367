<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  mounted () {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })

    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label',
        callbacks: {
          title: function (tooltipItems, data) {
            return 'Cost In USD'
          },
          label: function (tooltipItem, data) {
            return (
              data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
              ': ' +
              formatter.format(tooltipItem.yLabel)
            )
          }
        }
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 15
            }
          }
        ],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              if (parseInt(value) >= 1000) {
                return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              } else {
                return '$' + value
              }
            }
          }
        }]
      }
    })
  }
}
</script>
