<template>
  <div class="row m-0 p-0">
    <!-- <div class="row chart-row parent-dashboard">
      <div class="col-md-7" style="padding: 0px">
        <div
          class="col-md-12 col-sm-12"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div
                style="
                  font-size: 1.35rem;
                  margin-left: 20px !important;
                  text-transform: uppercase;
                "
              >
                <h3 class="graph-name">Engagement<small></small></h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative; max-height: 450px"
              >
                <VideoEngagementAreaChart
                  :chart-labels="videoEngagementChartLabel"
                  :chart-data="videoEngagementChartData"
                  :options="createVideoEngagementChartOption()"
                  :styles="{ height: '420px !important', position: 'relative' }"
                />
                <loading
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div
                style="
                  font-size: 1.35rem;
                  margin-left: 20px !important;
                  text-transform: uppercase;
                "
              >
                <h3 class="graph-name">watch time<small></small></h3>
              </div>
            </div>
            <div class="row chart-row" style="margin: 2px !important">
              <div class="col-md-1 col-sm-1">
                <i
                  class="material-icons"
                  style="font-size: 25px; margin-top: 14px"
                  >smart_display</i
                >
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="col-md-2 col-sm-4 tile_stats_count">
                  <span
                    class="count_top"
                    style="color: #ff681d; font-size: 22px"
                    >2 min 29 sec</span
                  >
                  <div
                    class="count"
                    style="
                      color: #948c8c;
                      text-transform: uppercase;
                      font-size: 12px;
                    "
                  >
                    avg view duration
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="col-md-2 col-sm-4 tile_stats_count">
                  <span
                    class="count_top"
                    style="color: #ff681d; font-size: 22px"
                    >29%</span
                  >
                  <div
                    class="count"
                    style="
                      color: #948c8c;
                      text-transform: uppercase;
                      font-size: 12px;
                    "
                  >
                    avg view percentage
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-3">
                <div class="col-md-2 col-sm-4 tile_stats_count">
                  <span
                    class="count_top"
                    style="color: #ff681d; font-size: 22px"
                    >30</span
                  >
                  <div
                    class="count"
                    style="
                      color: #948c8c;
                      text-transform: uppercase;
                      font-size: 12px;
                    "
                  >
                    avg view daily
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5" style="padding: 0px">
        <div
          class="col-md-12 col-sm-12"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div
                style="
                  font-size: 1.35rem;
                  margin-left: 20px !important;
                  text-transform: uppercase;
                "
              >
                <h3 class="graph-name">top videos by view<small></small></h3>
              </div>
            </div>
            <div
              class="row"
              style="
                margin: 0px !important;
                padding-bottom: 10px;
                padding: 10px 0px;
                background-color: #c3bcbc1a;
              "
            >
              <div class="col-md-8 col-sm-8">
                <video
                  controls
                  controlsList="nodownload"
                  oncontextmenu="return false;"
                  style="width: 100% !important; height: auto !important"
                >
                  <source
                    src="https://test-steeldemo-video-output.s3.eu-west-1.amazonaws.com/SaaS-and-Security.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="col-md-4 col-sm-4">
                <div>
                  <span
                    class="count_top"
                    style="color: #333333; font-size: 22px; min-height: 86px"
                    >SaaS and Security</span
                  >
                  <div
                    class="count"
                    style="
                      color: #948c8c;
                      text-transform: uppercase;
                      font-size: 12px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <i
                      class="material-icons"
                      style="
                        font-size: 25px;
                        margin-top: 4px;
                        margin-right: 6px;
                      "
                      >visibility</i
                    >
                    <span style="margin-top: 7px">80</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              style="
                margin: 0px !important;
                padding-bottom: 10px;
                padding: 10px 0px;
                background-color: #c3bcbc1a;
              "
            >
              <div class="col-md-8 col-sm-8">
                <video
                  controls
                  controlsList="nodownload"
                  oncontextmenu="return false;"
                  style="width: 100% !important; height: auto !important"
                >
                  <source
                    src="https://test-steeldemo-video-output.s3.eu-west-1.amazonaws.com/Traffic_conditioning.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="col-md-4 col-sm-4">
                <div>
                  <span
                    class="count_top"
                    style="color: #333333; font-size: 22px; min-height: 86px"
                    >Traffic conditioning</span
                  >
                  <div
                    class="count"
                    style="
                      color: #948c8c;
                      text-transform: uppercase;
                      font-size: 12px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <i
                      class="material-icons"
                      style="
                        font-size: 25px;
                        margin-top: 4px;
                        margin-right: 6px;
                      "
                      >visibility</i
                    >
                    <span style="margin-top: 7px">100</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              style="
                margin: 0px !important;
                padding-bottom: 10px;
                padding: 10px 0px;
                background-color: #c3bcbc1a;
              "
            >
              <div class="col-md-8 col-sm-8">
                <video
                  controls
                  controlsList="nodownload"
                  oncontextmenu="return false;"
                  style="width: 100% !important; height: auto !important"
                >
                  <source
                    src="https://test-steeldemo-video-output.s3.eu-west-1.amazonaws.com/MS-Stream.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="col-md-4 col-sm-4">
                <div>
                  <span
                    class="count_top"
                    style="color: #333333; font-size: 22px; min-height: 86px"
                    >MS Stream</span
                  >
                  <div
                    class="count"
                    style="
                      color: #948c8c;
                      text-transform: uppercase;
                      font-size: 12px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <i
                      class="material-icons"
                      style="
                        font-size: 25px;
                        margin-top: 4px;
                        margin-right: 6px;
                      "
                      >visibility</i
                    >
                    <span style="margin-top: 7px">80</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row chart-row parent-dashboard">
      <div class="col-md-5" style="padding: 0px">
        <div
          class="col-md-12 col-sm-12"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div
                class="col-md-8"
                style="font-size: 1.35rem; padding-right: 0px"
              >
                <h3 class="graph-name">Viewers Engagement</h3>
              </div>
              <div class="col-md-4" style="padding: 0px">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right"
                >
                  <b-dropdown
                    id="dropdown-right"
                    right
                    variant="outline-danger"
                    split-variant="outline-danger"
                    offset="25"
                    class="m-2 cost-dropdown"
                    v-model="viewersEngageDropDown.selectedOption"
                    :text="viewersEngageDropDown.selectedOption"
                  >
                    <b-dropdown-item
                      v-for="option in viewersEngageDropDown.options"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative; max-height: 350px"
              >
                <h5
                  class="mt-3"
                  style="font-size: 18px !important; color: #333"
                >
                  SaaS and Security
                </h5>
                <div
                  class="count"
                  style="
                    color: #948c8c;
                    text-transform: uppercase;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                  "
                  @click="clickViewerVideo"
                >
                  <b-progress
                    :max="100"
                    class="mb-3"
                    style="margin-top: -12px; margin-right: 15px"
                  >
                    <b-progress-bar
                      :value="84.4"
                      :label="`${((84.4 / 100) * 100).toFixed(2)}%`"
                      style="cursor: pointer"
                    ></b-progress-bar>
                  </b-progress>
                  <i
                    class="material-icons"
                    style="
                      font-size: 25px;
                      margin-top: -16px;
                      margin-right: 4px;
                    "
                    >visibility</i
                  >
                  <span style="margin-top: -12px">4</span>
                </div>
                <h5
                  class="mt-3"
                  style="font-size: 18px !important; color: #333"
                >
                  Traffic conditioning
                </h5>
                <div
                  class="count"
                  style="
                    color: #948c8c;
                    text-transform: uppercase;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                  "
                  @click="clickViewerVideo"
                >
                  <b-progress
                    :max="100"
                    class="mb-3"
                    style="margin-top: -12px; margin-right: 15px"
                  >
                    <b-progress-bar
                      :value="67.2"
                      :label="`${((67.2 / 100) * 100).toFixed(2)}%`"
                      style="cursor: pointer"
                    ></b-progress-bar>
                  </b-progress>
                  <i
                    class="material-icons"
                    style="
                      font-size: 25px;
                      margin-top: -16px;
                      margin-right: 4px;
                    "
                    >visibility</i
                  >
                  <span style="margin-top: -12px">3</span>
                </div>
                <h5
                  class="mt-3"
                  style="font-size: 18px !important; color: #333"
                >
                  MS Stream
                </h5>
                <div
                  class="count"
                  style="
                    color: #948c8c;
                    text-transform: uppercase;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                  "
                  @click="clickViewerVideo"
                >
                  <b-progress
                    :max="100"
                    class="mb-3"
                    style="margin-top: -12px; margin-right: 15px"
                  >
                    <b-progress-bar
                      :value="50"
                      :label="`${((50 / 100) * 100).toFixed(2)}%`"
                      style="cursor: pointer"
                    ></b-progress-bar>
                  </b-progress>
                  <i
                    class="material-icons"
                    style="
                      font-size: 25px;
                      margin-top: -16px;
                      margin-right: 4px;
                    "
                    >visibility</i
                  >
                  <span style="margin-top: -12px">2</span>
                </div>
                <h5
                  class="mt-3"
                  style="font-size: 18px !important; color: #333"
                >
                  SaaS and Security 2
                </h5>
                <div
                  class="count"
                  style="
                    color: #948c8c;
                    text-transform: uppercase;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                  "
                  @click="clickViewerVideo"
                >
                  <b-progress
                    :max="100"
                    class="mb-3"
                    style="margin-top: -12px; margin-right: 15px"
                  >
                    <b-progress-bar
                      :value="30.8"
                      :label="`${((30.8 / 100) * 100).toFixed(2)}%`"
                      style="cursor: pointer"
                    ></b-progress-bar>
                  </b-progress>
                  <i
                    class="material-icons"
                    style="
                      font-size: 25px;
                      margin-top: -16px;
                      margin-right: 4px;
                    "
                    >visibility</i
                  >
                  <span style="margin-top: -12px">1</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7" style="padding: 0px; display: flex">
        <div
          class="col-md-12 col-sm-12"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div
                style="
                  font-size: 1.35rem;
                  margin-left: 20px !important;
                  text-transform: uppercase;
                "
              >
                <h3 class="graph-name">Viewers Acitivity<small></small></h3>
              </div>
              <div class="col-md-12 col-sm-12">
                <div
                  id="chart_plot_01"
                  class="demo-placeholder"
                  style="padding: 0px; position: relative; max-height: 350px"
                >
                  <ViewerEngagementBarChart
                    :chart-labels="viewerEngagementChartLabel"
                    :chart-data="viewerEngagementChartData"
                    :options="createviewerEngagementChartOption()"
                    :styles="{
                      height: '320px !important',
                      position: 'relative',
                    }"
                  />
                  <loading
                    :is-full-page="fullPage"
                    :loader="loader"
                    :color="color"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="padding: 0px">
        <div
          class="col-md-12 col-sm-12"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div
                style="
                  font-size: 1.35rem;
                  margin-left: 20px !important;
                  text-transform: uppercase;
                "
              >
                <h3 class="graph-name">Viewers Events<small></small></h3>
              </div>
              <div class="col-md-12 col-sm-12">
                <div
                  id="chart_plot_01"
                  class="demo-placeholder"
                  style="padding: 0px; position: relative; max-height: 350px"
                >
                  <ViewerEngagementAreaChart
                    :chart-labels="viewerEngagementEventChartLabel"
                    :chart-data="viewerEngagementEventChartData"
                    :options="createviewerEngagementEventChartOption()"
                    :styles="{
                      height: '320px !important',
                      position: 'relative',
                    }"
                  />
                  <loading
                    :is-full-page="fullPage"
                    :loader="loader"
                    :color="color"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VideoEngagementAreaChart from '@/charts/VideoEngagementAreaChart.vue'
import ViewerEngagementBarChart from '@/charts/ViewerEngagementBarChart.vue'
import ViewerEngagementAreaChart from '@/charts/ViewerEngagementAreaChart.vue'
import Loading from 'vue-loading-overlay'

export default {
  name: 'VideoAnalytics',
  data () {
    return {
      fullPage: false,
      loader: 'bars',
      color: '#ff681d',
      // videoEngagementChartLabel: [
      //   'Jan',
      //   'Feb',
      //   'Mar',
      //   'Apr',
      //   'May',
      //   'Jun',
      //   'July',
      //   'Aug'
      // ],
      // videoEngagementChartData: [10, 20, 40, 30, 10, 50, 20, 60],
      viewerEngagementChartLabel: [
        'Aug 21',
        'Aug 21',
        'Aug 22',
        'Aug 22',
        'Aug 23',
        'Aug 23',
        'Aug 23'
      ],
      viewerEngagementEventChartLabel: [
        'Aug 19',
        'Aug 20',
        'Aug 21',
        'Aug 22',
        'Aug 23'
      ],
      viewerEngagementChartData: [
        {
          data: [10, 30, 20, 25, 50, 60, 80],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(14, 98, 232, 0.2)',
            'rgba(2, 179, 31, 0.2)',
            'rgba(255, 104, 29, 0.2)',
            'rgba(0, 231, 255, 0.2)',
            'rgba(255, 206, 86, 0.2)'
          ],
          borderColor: [
            'rgba(255, 159, 64, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(14, 98, 232, 1)',
            'rgba(2, 179, 31, 1)',
            'rgba(255, 104, 29, 1)',
            'rgba(0, 231, 255, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }
      ],

      viewerEngagementEventChartData: [
        [1, 1, 2, 3, 1, 0],
        [2, 3, 4, 1, 5, 5],
        [0, 0, 0, 1, 0, 1],
        [0, 0, 0, 0, 0, 1]
      ],
      viewersEngageDropDown: {
        selectedOption: 'Priyanka Ghosh',
        options: [
          {
            value: 'Priyanka Ghosh',
            text: 'Priyanka Ghosh'
          }
        ]
      }
    }
  },
  components: {
    Loading,
    // VideoEngagementAreaChart,
    ViewerEngagementBarChart,
    ViewerEngagementAreaChart
  },
  methods: {
    // createVideoEngagementChartOption () {
    //   return {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     tooltips: {
    //       mode: 'label',
    //       callbacks: {
    //         label: function (tooltipItem, data) {
    //           return (
    //             data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
    //             ': ' +
    //             tooltipItem.yLabel
    //           )
    //         }
    //       }
    //     },
    //     title: {
    //       display: true,
    //       text: 'Total: 240 views'
    //     },
    //     legend: {
    //       display: false
    //     }
    //   }
    // },
    createviewerEngagementChartOption () {
      // console.log(this.$store.videoLogs)
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 80,
                callback: function (value) {
                  return ((value / 80) * 100).toFixed(0) + '%'
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Percentage'
              }
            }
          ]
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                'Video Watched: ' +
                ((tooltipItem.yLabel / 80) * 100).toFixed(0) +
                '%'
              )
            }
          }
        },
        title: {
          display: true,
          text: 'Viewers acitivity on the video'
        }
      }
    },
    createviewerEngagementEventChartOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                ': ' +
                tooltipItem.yLabel
              )
            }
          }
        }
      }
    },
    clickViewerVideo () {
      this.viewerEngagementEventChartData = [
        [1, 0, 1, 1, 1, 0],
        [0, 1, 2, 3, 2, 2],
        [0, 0, 0, 1, 0, 1],
        [0, 1, 0, 0, 0, 1]
      ]
      this.viewerEngagementChartData.data = [5, 10, 20, 20, 40, 50, 70]
    }
  },
  created () {
  }
}
</script>
