<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartLabels', 'chartData'],

  data () {
    return {
      gradient: null
    }
  },

  mounted () {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
    let sum = 0
    this.chartData.map((data) => {
      sum += parseInt(data)
      return sum
    })

    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(14, 98, 232, 0.5)')
    this.gradient.addColorStop(0.5, 'rgba(14, 98, 232, 0.25)')
    this.gradient.addColorStop(1, 'rgba(14, 98, 232, 0)')

    this.renderChart({
      labels: this.chartLabels,
      datasets: [
        {
          label:
              'Total (' +
          formatter.format(sum) +
          ')',
          borderColor: this.gradient,
          pointBackgroundColor: this.gradient,
          borderWidth: 1,
          pointBorderColor: 'white',
          backgroundColor: this.gradient,
          data: this.chartData
        }
      ]
    }, {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label',
        callbacks: {
          title: function (tooltipItems, data) {
            return tooltipItems[0].label
          },
          label: function (tooltipItem, data) {
            return 'Cost: ' + formatter.format(tooltipItem.yLabel)
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              if (parseInt(value) >= 1000) {
                return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              } else {
                return '$' + value
              }
            }
          }
        }]
      }
    })
  },
  watch: {
    chartData () {
      this.$data._chart.update()
    },
    chartLabels () {
      this.$data._chart.update()
    }
  }
}
</script>
