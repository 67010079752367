var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "DemoPage",
      staticClass: "Demo",
      staticStyle: { "overflow-x": "hidden" }
    },
    [
      _c(
        "div",
        { staticClass: "scenarioDetails" },
        [
          _c("div", { staticClass: "field_copy_link" }, [
            _vm.showScriptClass != "selected"
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons x-close",
                    staticStyle: { "font-size": "25px" },
                    attrs: { title: "Close Demo" },
                    on: {
                      click: function($event) {
                        return _vm.closeScenario()
                      }
                    }
                  },
                  [_vm._v("close")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.copyDemoUrl(
                      _vm.scenario.demoType,
                      _vm.scenario.lastPublishedDate,
                      _vm.scenario.scenarioId
                    )
                  }
                }
              },
              [_vm._v(" Copy Sharable Link ")]
            ),
            _vm.scenario.demoScript && _vm.deployMode === "demo"
              ? _c(
                  "button",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-left": "10px" },
                    on: {
                      click: function($event) {
                        return _vm.showScript(_vm.scenario.demoScript)
                      }
                    }
                  },
                  [_vm._v(" Demo Script ")]
                )
              : _vm._e()
          ]),
          _c(
            "transition",
            { attrs: { name: "fade" }, on: { enter: _vm.copyDemoUrlLink } },
            [
              _vm.isCopySuccessful
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "10px",
                        "margin-top": "-15px",
                        "margin-left": "3px",
                        color: "green",
                        "font-weight": "500",
                        "margin-bottom": "0px"
                      }
                    },
                    [_vm._v(" copied! ")]
                  )
                : _vm._e()
            ]
          ),
          _c("h4", [_vm._v(_vm._s(_vm.scenario.name))]),
          _c(
            "p",
            { staticStyle: { "font-size": "12px", color: "#808080d1" } },
            [
              _vm._v(
                " Last Updated: " +
                  _vm._s(_vm.getDate(_vm.scenario.lastPublishedDate)) +
                  " "
              )
            ]
          ),
          _c("div", [
            _c(
              "span",
              {
                class:
                  "badge badge-dark badge-custom-details " +
                  _vm.scenario.featuredClass
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.getScenarioMapId(
                      _vm.scenario.demoType,
                      _vm.scenario.lastPublishedDate,
                      _vm.scenario.scenarioId
                    )
                  )
                )
              ]
            ),
            (_vm.scenario.demoType !== "Lab" &&
              _vm.scenario.status === "User Created") ||
            _vm.scenario.status === "Topology Created" ||
            _vm.scenario.status === "User Session Created" ||
            _vm.scenario.status === "Org User Created" ||
            _vm.scenario.status === "Instance State UP"
              ? _c(
                  "span",
                  {
                    staticClass: "badge badge-dark badge-custom-details",
                    staticStyle: {
                      "font-size": "14px !important",
                      "background-color": "green !important",
                      "margin-left": "5px !important"
                    }
                  },
                  [_vm._v("Running")]
                )
              : _vm._e(),
            _vm.scenario.inProcess
              ? _c(
                  "span",
                  {
                    staticClass: "badge badge-dark badge-custom-details",
                    staticStyle: {
                      "font-size": "14px !important",
                      "background-color": "#ff681d !important",
                      "margin-left": "5px !important",
                      width: "auto !important"
                    }
                  },
                  [_vm._v(_vm._s(_vm.scenario.status))]
                )
              : _vm._e()
          ]),
          !_vm.scenario.inProcess
            ? _c("div", { staticClass: "demo-link" }, [
                _vm.scenario["ip_address"]
                  ? _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            return _vm.openAlert(_vm.scenario["ip_address"])
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-bolt",
                          staticStyle: { color: "#fbb022", cursor: "pointer" },
                          attrs: { title: "IP Address" }
                        }),
                        _vm._v(" IP Address ")
                      ]
                    )
                  : _vm._e(),
                _vm.scenario["wvd_url"]
                  ? _c(
                      "a",
                      {
                        staticClass: "btn",
                        staticStyle: {
                          "font-size": "12px !important",
                          "text-decoration": "none",
                          "margin-right": "11px !important",
                          "margin-left": "-4px !important",
                          "margin-top": "10px !important",
                          "padding-top": "2px",
                          width: "157px"
                        },
                        attrs: {
                          href: _vm.scenario["wvd_url"],
                          target: "_blank"
                        }
                      },
                      [_vm._m(0)]
                    )
                  : _vm._e(),
                (_vm.scenario.status === "User Created" ||
                  _vm.scenario.status === "Org User Created") &&
                _vm.scenario.user &&
                _vm.scenario.password
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-7",
                            modifiers: { "modal-7": true }
                          }
                        ],
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            return _vm.openUserCred(
                              _vm.scenario["user"],
                              _vm.scenario["password"],
                              _vm.scenario["subnet"] || ""
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-user-secret",
                          staticStyle: { color: "#fbb022", cursor: "pointer" },
                          attrs: { title: "Demo details" }
                        }),
                        _vm._v(" Demo Details ")
                      ]
                    )
                  : _vm._e(),
                _vm.scenario["demoId"]
                  ? _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            return _vm.terminate_instance(
                              _vm.scenario["demoId"]
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-stop-circle",
                          staticStyle: { color: "red", cursor: "pointer" },
                          attrs: { title: "Terminate demo" }
                        }),
                        _vm._v(" Terminate Demo ")
                      ]
                    )
                  : _vm._e(),
                _vm.scenario.snapshot &&
                _vm.scenario.status === "Topology Created"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            return _vm.showSnapshot(_vm.scenario["demoId"])
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-camera",
                          staticStyle: { color: "#575757", cursor: "pointer" },
                          attrs: { title: "Snapshot" }
                        }),
                        _vm._v(" Snapshot ")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { padding: "20px 0px", "max-width": "880px" } },
            [
              _vm.scenario.video
                ? _c(
                    "div",
                    { staticClass: "video-container" },
                    [
                      _c("youtube", {
                        attrs: {
                          "video-id": _vm.getId(_vm.scenario.video),
                          "player-vars": _vm.playerVars
                        }
                      })
                    ],
                    1
                  )
                : _c("b-img", {
                    attrs: { src: _vm.scenario.picture, fluid: "", alt: "" }
                  })
            ],
            1
          ),
          _c("div", { staticClass: "description" }, [
            _c("h5", { staticStyle: { "margin-bottom": "-10px" } }, [
              _vm._v("Description:")
            ]),
            _c("p", [_vm._v(_vm._s(_vm.scenario.description))])
          ]),
          !_vm.scenario.link && _vm.scenario.status.length === 0
            ? _c("div", { staticClass: "deployParameters" }, [
                _vm.feedback
                  ? _c("p", { staticClass: "feedback" }, [
                      _vm._v(_vm._s(_vm.feedback))
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { padding: "6px 0px" } },
                  [
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-1",
                              label: "Demo duration (hours):",
                              "label-for": "input-1",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("b-form-spinbutton", {
                              attrs: {
                                id: "sb-inline",
                                min: "1",
                                max: "8",
                                inline: ""
                              },
                              model: {
                                value: _vm.duration,
                                callback: function($$v) {
                                  _vm.duration = $$v
                                },
                                expression: "duration"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-2",
                              label: "Date & Time:",
                              "label-for": "input-2",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("date-picker", {
                              staticStyle: { "margin-top": "-6px" },
                              attrs: {
                                type: "datetime",
                                "value-type": "format",
                                format: "YYYY-MM-DD HH:mm"
                              },
                              model: {
                                value: _vm.dateToPick,
                                callback: function($$v) {
                                  _vm.dateToPick = $$v
                                },
                                expression: "dateToPick"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-3",
                              label: "Demo Purpose:",
                              "label-for": "input-3",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("b-form-radio-group", {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                options: _vm.demoPurposeOptions,
                                name: "radio-inline"
                              },
                              model: {
                                value: _vm.selectedDemoPurpose,
                                callback: function($$v) {
                                  _vm.selectedDemoPurpose = $$v
                                },
                                expression: "selectedDemoPurpose"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-4",
                              label: "Customer Name:",
                              "label-for": "input-4",
                              "label-cols-sm": "2",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "4"
                            }
                          },
                          [
                            _c("b-form-input", {
                              staticStyle: { "margin-top": "-5px" },
                              attrs: {
                                id: "input-4",
                                placeholder: "Please write Customer Name",
                                plaintext:
                                  _vm.selectedDemoPurpose == "customerDemos"
                                    ? false
                                    : true
                              },
                              model: {
                                value: _vm.customerName,
                                callback: function($$v) {
                                  _vm.customerName = $$v
                                },
                                expression: "customerName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-5",
                              label: "SFDC Link (Riverbed Internal):",
                              "label-for": "input-5",
                              "label-cols-sm": "2",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "4"
                            }
                          },
                          [
                            _c("b-form-input", {
                              staticStyle: { "margin-top": "-5px" },
                              attrs: {
                                id: "input-5",
                                placeholder: "Please mention SFDC Link",
                                plaintext:
                                  _vm.selectedDemoPurpose == "customerDemos"
                                    ? false
                                    : true
                              },
                              model: {
                                value: _vm.customerLink,
                                callback: function($$v) {
                                  _vm.customerLink = $$v
                                },
                                expression: "customerLink"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          !_vm.scenario.link && _vm.scenario.status.length === 0
            ? _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.deployScenario()
                    }
                  }
                },
                [_vm._m(1)]
              )
            : _vm._e(),
          _vm.scenario.link
            ? _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.redirectToDemo(
                        _vm.scenario.link,
                        _vm.scenario.user,
                        _vm.scenario.password
                      )
                    }
                  }
                },
                [_vm._m(2)]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { class: "right-slide " + _vm.showScriptClass }, [
        _c("div", { staticClass: "right-slide-header" }, [
          _vm._m(3),
          _c("div", { staticClass: "right-slide-header-icons" }, [
            _c(
              "i",
              {
                staticClass: "material-icons",
                staticStyle: {
                  "font-size": "20px",
                  "margin-top": "3px",
                  position: "absolute",
                  right: "30px",
                  cursor: "pointer",
                  color: "black"
                },
                attrs: { title: "Open in New Tab" },
                on: {
                  click: function($event) {
                    return _vm.openScriptInTab(_vm.scenario.demoScript)
                  }
                }
              },
              [_vm._v("open_in_new")]
            ),
            _c(
              "i",
              {
                staticClass: "material-icons",
                staticStyle: {
                  "font-size": "25px",
                  cursor: "pointer",
                  color: "black"
                },
                attrs: { title: "Close Instructions" },
                on: { click: _vm.onCloseInstructions }
              },
              [_vm._v("close")]
            )
          ])
        ]),
        _c("iframe", {
          attrs: {
            id: "demo-script-iframe",
            src: _vm.scenario.demoScript,
            width: "100%",
            height: "95%%",
            frameborder: "0",
            scrolling: "auto"
          }
        })
      ]),
      _vm.showUserCredModal
        ? _c(
            "div",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-7",
                    centered: "",
                    "hide-header": "",
                    "content-class": "modal-content2"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "modal-footer",
                        fn: function() {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "btn btn-primary btn-custom btn-user-cred",
                                attrs: { size: "sm" },
                                on: { click: _vm.closeUserCredModal }
                              },
                              [_vm._v(" Close ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    435782646
                  )
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", { staticStyle: { float: "right" } }, [
                          _vm._v("User Name: ")
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-6",
                          staticStyle: { "max-width": "142px !important" }
                        },
                        [_vm._v(" " + _vm._s(_vm.userName) + " ")]
                      ),
                      _c("input", {
                        attrs: { type: "hidden", id: "copy-code-username" },
                        domProps: { value: _vm.userName }
                      }),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          staticStyle: {
                            cursor: "pointer",
                            "margin-left": "6px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.copyUsername()
                            }
                          }
                        },
                        [_vm._v("content_copy")]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: { name: "fade" },
                          on: { enter: _vm.enterUserName }
                        },
                        [
                          _vm.copyUserName
                            ? _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "0.8vw",
                                    "margin-top": "3px",
                                    "margin-left": "3px",
                                    color: "green",
                                    "font-weight": "500"
                                  }
                                },
                                [_vm._v(" copied! ")]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("strong", { staticStyle: { float: "right" } }, [
                        _vm._v("Password:")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticStyle: { display: "inline-flex" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-6",
                            staticStyle: {
                              "max-width": "113px !important",
                              "margin-top": "3px"
                            }
                          },
                          [_vm._v(" ***************** ")]
                        ),
                        _c("input", {
                          attrs: { type: "hidden", id: "copy-code" },
                          domProps: { value: _vm.password }
                        }),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: {
                              cursor: "pointer",
                              "margin-left": "35px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.togglePassword()
                              }
                            }
                          },
                          [_vm._v("content_copy")]
                        ),
                        _c(
                          "transition",
                          {
                            attrs: { name: "fade" },
                            on: { enter: _vm.enterPassword }
                          },
                          [
                            _vm.copyPassword
                              ? _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-size": "0.8vw",
                                      "margin-top": "3px",
                                      "margin-left": "3px",
                                      color: "green",
                                      "font-weight": "500"
                                    }
                                  },
                                  [_vm._v(" copied! ")]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.subnet
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", { staticStyle: { float: "right" } }, [
                            _vm._v("Subnet:")
                          ])
                        ]),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-flex" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                staticStyle: {
                                  "max-width": "113px !important",
                                  "margin-top": "3px"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.subnet) + " ")]
                            ),
                            _c("input", {
                              attrs: { type: "hidden", id: "copy-code-subnet" },
                              domProps: { value: _vm.subnet }
                            }),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons",
                                staticStyle: {
                                  cursor: "pointer",
                                  "margin-left": "35px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleSubnet()
                                  }
                                }
                              },
                              [_vm._v("content_copy")]
                            ),
                            _c(
                              "transition",
                              {
                                attrs: { name: "fade" },
                                on: { enter: _vm.enterSubnet }
                              },
                              [
                                _vm.copySubnet
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-size": "0.8vw",
                                          "margin-top": "3px",
                                          "margin-left": "3px",
                                          color: "green",
                                          "font-weight": "500"
                                        }
                                      },
                                      [_vm._v(" copied! ")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "modal-share-video",
            "dialog-class": "modal-share-video",
            scrollable: ""
          },
          on: {
            show: _vm.resetShareVideoModal,
            hidden: _vm.resetShareVideoModal
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "flex-direction": "row" }
                    },
                    [
                      _c("p", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v("Sharable Video Link")
                      ]),
                      _c(
                        "small",
                        {
                          staticStyle: {
                            padding: "6px",
                            "font-size": "14px",
                            color: "rgb(150, 144, 144)",
                            "padding-left": "15px"
                          }
                        },
                        [
                          _vm._v(
                            " Sharable video link will be send to the Customer "
                          )
                        ]
                      )
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _vm.isFirstView
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-user-cred",
                          staticStyle: {
                            "margin-right": "10px !important",
                            width: "11% !important"
                          },
                          on: { click: _vm.nextClick }
                        },
                        [_vm._v(" NEXT ")]
                      )
                    : _vm._e(),
                  !_vm.previewMode && !_vm.isFirstView
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-video-pre",
                          on: { click: _vm.backClick }
                        },
                        [_vm._v(" PREVIOUS ")]
                      )
                    : _vm._e(),
                  _vm.previewMode && !_vm.isFirstView
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-video-pre",
                          on: { click: _vm.cancelPreview }
                        },
                        [_vm._v(" BACK ")]
                      )
                    : _vm._e(),
                  !_vm.previewMode && !_vm.isFirstView
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-video-preview",
                          on: { click: _vm.previewClick }
                        },
                        [_vm._v(" PREVIEW ")]
                      )
                    : _vm._e(),
                  !_vm.previewMode && !_vm.isFirstView
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-user-cred",
                          staticStyle: {
                            width: "23% !important",
                            "margin-right": "10px !important"
                          },
                          on: { click: _vm.copyShareVideoModal }
                        },
                        [_vm._v(" SEND VIDEO LINK ")]
                      )
                    : _vm._e(),
                  _vm.previewMode && !_vm.isFirstView
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-user-cred",
                          staticStyle: {
                            width: "23% !important",
                            "margin-left": "auto !important",
                            "margin-right": "10px !important"
                          },
                          on: { click: _vm.copyShareVideoModal }
                        },
                        [_vm._v(" SEND VIDEO LINK ")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.previewMode
            ? _c("div", [
                this.isFirstView
                  ? _c(
                      "div",
                      { staticClass: "pop-up-container" },
                      [
                        _c(
                          "b-form",
                          {
                            staticClass: "pop-up-view",
                            on: {
                              submit: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.nextClick.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-12",
                                staticStyle: { "margin-left": "-15px" }
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-cname",
                                      label: "Customer Company Name:",
                                      "label-for": "input-cname",
                                      "label-cols-sm": "3",
                                      "label-cols-lg": "4",
                                      "content-cols-sm": "",
                                      "content-cols-lg": "8"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true }"
                                        }
                                      ],
                                      attrs: {
                                        id: "input-cname",
                                        name: "input-cname",
                                        placeholder:
                                          "Enter customer company name",
                                        "aria-describedby":
                                          "input-cname-live-feedback",
                                        "data-vv-as": "customer company name",
                                        state: _vm.validateState("input-cname")
                                      },
                                      model: {
                                        value: _vm.videoShareForm.companyName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.videoShareForm,
                                            "companyName",
                                            $$v
                                          )
                                        },
                                        expression: "videoShareForm.companyName"
                                      }
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          id: "input-cname-live-feedback"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.veeErrors.first("input-cname")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.videoShareForm.videoCustomerInfoArray,
                              function(info, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "row" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-10" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              id: "input-group-" + index,
                                              label: "Recipient's Name:",
                                              "label-for": "input-" + index,
                                              "label-cols-sm": "3",
                                              "label-cols-lg": "4",
                                              "content-cols-sm": "",
                                              "content-cols-lg": "7"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: { required: true },
                                                  expression:
                                                    "{ required: true }"
                                                }
                                              ],
                                              staticStyle: {
                                                "margin-top": "-5px",
                                                "margin-left": "34px !important"
                                              },
                                              attrs: {
                                                id: "input-" + index,
                                                name: "input-" + index,
                                                placeholder:
                                                  "Enter Recipient's Name",
                                                "aria-describedby":
                                                  "input-" +
                                                  index +
                                                  "-live-feedback",
                                                "data-vv-as":
                                                  "recipient's name",
                                                state: _vm.validateState(
                                                  "input-" + index
                                                )
                                              },
                                              model: {
                                                value: info.videoCustomerName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    info,
                                                    "videoCustomerName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "info.videoCustomerName"
                                              }
                                            }),
                                            _c(
                                              "b-form-invalid-feedback",
                                              {
                                                staticStyle: {
                                                  "margin-left":
                                                    "34px !important"
                                                },
                                                attrs: {
                                                  id:
                                                    "input-" +
                                                    index +
                                                    "-live-feedback"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.veeErrors.first(
                                                      "input-" + index
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              id:
                                                "input-group-" +
                                                _vm.videoShareForm
                                                  .videoCustomerInfoArray
                                                  .length +
                                                index,
                                              label:
                                                "Recipient's Email Address:",
                                              "label-for":
                                                "input-" +
                                                _vm.videoShareForm
                                                  .videoCustomerInfoArray
                                                  .length +
                                                index,
                                              "label-cols-sm": "3",
                                              "label-cols-lg": "4",
                                              "content-cols-sm": "",
                                              "content-cols-lg": "7"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    required: true,
                                                    email: "email"
                                                  },
                                                  expression:
                                                    "{ required: true, email: 'email' }"
                                                }
                                              ],
                                              staticStyle: {
                                                "margin-top": "-5px",
                                                "margin-left": "34px !important"
                                              },
                                              attrs: {
                                                id:
                                                  "input-" +
                                                  _vm.videoShareForm
                                                    .videoCustomerInfoArray
                                                    .length +
                                                  index,
                                                name:
                                                  "input-" +
                                                  _vm.videoShareForm
                                                    .videoCustomerInfoArray
                                                    .length +
                                                  index,
                                                placeholder:
                                                  "Enter recipient's email address",
                                                "aria-describedby":
                                                  "input-" +
                                                  _vm.videoShareForm
                                                    .videoCustomerInfoArray
                                                    .length +
                                                  index +
                                                  "-live-feedback",
                                                "data-vv-as":
                                                  "recipient's email address",
                                                state: _vm.validateState(
                                                  "input-" +
                                                    _vm.videoShareForm
                                                      .videoCustomerInfoArray
                                                      .length +
                                                    index
                                                )
                                              },
                                              model: {
                                                value: info.videoCustomerEmail,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    info,
                                                    "videoCustomerEmail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "info.videoCustomerEmail"
                                              }
                                            }),
                                            _c(
                                              "b-form-invalid-feedback",
                                              {
                                                staticStyle: {
                                                  "margin-left":
                                                    "34px !important"
                                                },
                                                attrs: {
                                                  id:
                                                    "input-" +
                                                    _vm.videoShareForm
                                                      .videoCustomerInfoArray
                                                      .length +
                                                    index +
                                                    "-live-feedback"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.veeErrors.first(
                                                      "input-" +
                                                        _vm.videoShareForm
                                                          .videoCustomerInfoArray
                                                          .length +
                                                        index
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-md-2" }, [
                                      _vm.videoShareForm.videoCustomerInfoArray
                                        .length -
                                        1 ==
                                        index && index <= 1
                                        ? _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              staticStyle: {
                                                "font-size": "25px",
                                                "margin-top": "45px",
                                                cursor: "pointer"
                                              },
                                              attrs: { title: "add" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVideoCustomerInfoClickAdd()
                                                }
                                              }
                                            },
                                            [_vm._v("add")]
                                          )
                                        : index != 0 ||
                                          (_vm.videoShareForm
                                            .videoCustomerInfoArray.length ==
                                            2 &&
                                            index == 0)
                                        ? _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              staticStyle: {
                                                "font-size": "25px",
                                                "margin-top": "45px",
                                                cursor: "pointer"
                                              },
                                              attrs: { title: "delete" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVideoCustomerInfoClickDelete(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("delete")]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-md-12",
                                staticStyle: { "margin-left": "-15px" }
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-ccemail",
                                      label: "CC Email Address:",
                                      "label-for": "input-ccemail",
                                      "label-cols-sm": "3",
                                      "label-cols-lg": "4",
                                      "content-cols-sm": "",
                                      "content-cols-lg": "8"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "email",
                                          expression: "'email'"
                                        }
                                      ],
                                      attrs: {
                                        id: "input-ccemail",
                                        name: "input-ccemail",
                                        placeholder: "Enter CC email ID",
                                        "aria-describedby":
                                          "input-ccemail-live-feedback",
                                        "data-vv-as": "CC Email Id",
                                        state: _vm.validateState(
                                          "input-ccemail"
                                        )
                                      },
                                      model: {
                                        value: _vm.videoShareForm.ccEmail,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.videoShareForm,
                                            "ccEmail",
                                            $$v
                                          )
                                        },
                                        expression: "videoShareForm.ccEmail"
                                      }
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          id: "input-ccemail-live-feedback"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.veeErrors.first("input-ccemail")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-ebody",
                                      label: "Email Body:",
                                      "label-for": "input-ebody",
                                      "label-cols-sm": "3",
                                      "label-cols-lg": "4",
                                      "content-cols-sm": "",
                                      "content-cols-lg": "8"
                                    }
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true, min: 10 },
                                          expression:
                                            "{ required: true, min: 10 }"
                                        }
                                      ],
                                      attrs: {
                                        id: "input-ebody",
                                        name: "input-ebody",
                                        rows: "7",
                                        "max-rows": "16",
                                        state: _vm.validateState("input-ebody"),
                                        "aria-describedby":
                                          "input-ebody-live-feedback",
                                        "data-vv-as": "Email Body"
                                      },
                                      model: {
                                        value:
                                          _vm.videoShareForm
                                            .videoCustomerEmailBody,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.videoShareForm,
                                            "videoCustomerEmailBody",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "videoShareForm.videoCustomerEmailBody"
                                      }
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          id: "input-ebody-live-feedback"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.veeErrors.first("input-ebody")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "pop-up-container" },
                      [
                        _c(
                          "b-form",
                          {
                            staticClass: "pop-up-view",
                            on: {
                              submit: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.copyShareVideoModal.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { margin: "0px", width: "98%" }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "rgb(51, 51, 51)",
                                      "font-size": "15px",
                                      "font-weight": "600"
                                    }
                                  },
                                  [
                                    _vm._v(" Links for video Page: "),
                                    _c(
                                      "i",
                                      {
                                        staticClass: "material-icons tool-tip",
                                        staticStyle: {
                                          "font-size": "18px",
                                          color: "gray",
                                          cursor: "pointer"
                                        },
                                        attrs: { id: "tool-tip-target" }
                                      },
                                      [_vm._v("tips_and_updates")]
                                    ),
                                    _c("b-tooltip", {
                                      attrs: {
                                        target: "tool-tip-target",
                                        placement: "rightbottom",
                                        variant: "info",
                                        title:
                                          "Share additional links to product documents or data sheets."
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.videoShareForm
                                    .videoCustomerExtraLinkArray,
                                  function(info, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "row",
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                          width: "98%",
                                          margin: "0px"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-11",
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                size: "sm",
                                                placeholder: "Link Name"
                                              },
                                              model: {
                                                value: info.name,
                                                callback: function($$v) {
                                                  _vm.$set(info, "name", $$v)
                                                },
                                                expression: "info.name"
                                              }
                                            }),
                                            _c("b-form-input", {
                                              staticStyle: {
                                                "margin-left": "20px"
                                              },
                                              attrs: {
                                                size: "sm",
                                                placeholder: "Link URL"
                                              },
                                              model: {
                                                value: info.url,
                                                callback: function($$v) {
                                                  _vm.$set(info, "url", $$v)
                                                },
                                                expression: "info.url"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col-md-1" }, [
                                          _vm.videoShareForm
                                            .videoCustomerExtraLinkArray
                                            .length -
                                            1 ==
                                            index && index <= 1
                                            ? _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                    "margin-top": "6px",
                                                    cursor: "pointer"
                                                  },
                                                  attrs: { title: "add" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onVideoCustomerExtraLinkClickAdd()
                                                    }
                                                  }
                                                },
                                                [_vm._v("add")]
                                              )
                                            : index != 0 ||
                                              (_vm.videoShareForm
                                                .videoCustomerExtraLinkArray
                                                .length == 2 &&
                                                index == 0)
                                            ? _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                    "margin-top": "4px",
                                                    cursor: "pointer"
                                                  },
                                                  attrs: { title: "delete" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onVideoCustomerExtraLinkClickDelete(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("delete")]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "rgb(51, 51, 51)",
                                      "font-size": "15px",
                                      "font-weight": "600",
                                      "padding-top": "15px"
                                    }
                                  },
                                  [_vm._v(" Email Signature with Preview: ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { width: "98%", margin: "0px" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-md-6",
                                        staticStyle: {
                                          "border-right": "1px solid #e4e2e2"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            id: "input-sname",
                                            name: "input-sname",
                                            size: "sm",
                                            placeholder: "Name",
                                            state: _vm.validateState(
                                              "input-sname"
                                            ),
                                            "aria-describedby":
                                              "input-sname-live-feedback",
                                            "data-vv-as": "name"
                                          },
                                          model: {
                                            value:
                                              _vm.videoShareForm.signature.name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.videoShareForm.signature,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoShareForm.signature.name"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-sname-live-feedback"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "input-sname"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            size: "sm",
                                            placeholder: "Role",
                                            id: "input-srole",
                                            name: "input-srole",
                                            state: _vm.validateState(
                                              "input-srole"
                                            ),
                                            "aria-describedby":
                                              "input-srole-live-feedback",
                                            "data-vv-as": "role"
                                          },
                                          model: {
                                            value:
                                              _vm.videoShareForm.signature
                                                .title,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.videoShareForm.signature,
                                                "title",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoShareForm.signature.title"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-srole-live-feedback"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "input-srole"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            size: "sm",
                                            placeholder: "Company",
                                            id: "input-scompany",
                                            name: "input-scompany",
                                            state: _vm.validateState(
                                              "input-scompany"
                                            ),
                                            "aria-describedby":
                                              "input-scompany-live-feedback",
                                            "data-vv-as": "company"
                                          },
                                          model: {
                                            value:
                                              _vm.videoShareForm.signature
                                                .company,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.videoShareForm.signature,
                                                "company",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoShareForm.signature.company"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-scompany-live-feedback"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "input-scompany"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            size: "sm",
                                            placeholder: "Phone",
                                            id: "input-sphone",
                                            name: "input-sphone",
                                            state: _vm.validateState(
                                              "input-sphone"
                                            ),
                                            "aria-describedby":
                                              "input-sphone-live-feedback",
                                            "data-vv-as": "phone"
                                          },
                                          model: {
                                            value:
                                              _vm.videoShareForm.signature
                                                .phone,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.videoShareForm.signature,
                                                "phone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoShareForm.signature.phone"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-sphone-live-feedback"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "input-sphone"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                required: true,
                                                email: "email"
                                              },
                                              expression:
                                                "{ required: true, email: 'email' }"
                                            }
                                          ],
                                          attrs: {
                                            size: "sm",
                                            placeholder: "Email ID",
                                            id: "input-semail",
                                            name: "input-semail",
                                            state: _vm.validateState(
                                              "input-semail"
                                            ),
                                            "aria-describedby":
                                              "input-semail-live-feedback",
                                            "data-vv-as": "email id"
                                          },
                                          model: {
                                            value:
                                              _vm.videoShareForm.signature
                                                .email,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.videoShareForm.signature,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoShareForm.signature.email"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-semail-live-feedback"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "input-semail"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }"
                                            }
                                          ],
                                          attrs: {
                                            size: "sm",
                                            placeholder: "Website",
                                            id: "input-swebsite",
                                            name: "input-swebsite",
                                            state: _vm.validateState(
                                              "input-swebsite"
                                            ),
                                            "aria-describedby":
                                              "input-swebsite-live-feedback",
                                            "data-vv-as": "website"
                                          },
                                          model: {
                                            value:
                                              _vm.videoShareForm.signature
                                                .website,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.videoShareForm.signature,
                                                "website",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoShareForm.signature.website"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "input-swebsite-live-feedback"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "input-swebsite"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-md-6" }, [
                                      _c(
                                        "div",
                                        { staticClass: "criador__source" },
                                        [
                                          _c("div", { ref: "sourceWrapper" }, [
                                            _c(
                                              "table",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "Helvetica, Arial, sans-serif",
                                                  "font-size": "14px",
                                                  "font-weight": "normal",
                                                  color: "#37474f",
                                                  "text-align": "left",
                                                  "line-height": "20px"
                                                },
                                                attrs: {
                                                  id: "source",
                                                  cellpadding: "0",
                                                  cellspacing: "0"
                                                }
                                              },
                                              [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px",
                                                        "text-align": "left"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            color: "#ff681d",
                                                            "text-transform":
                                                              "capitalize"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.videoShareForm
                                                                .signature.name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.videoShareForm
                                                              .signature.title
                                                          )
                                                        )
                                                      ]),
                                                      _vm.videoShareForm
                                                        .signature.company
                                                        ? _c("span", [
                                                            _vm._v(" | ")
                                                          ])
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.videoShareForm
                                                              .signature.company
                                                          )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm.videoShareForm
                                                        .signature.phone
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "p: " +
                                                                _vm._s(
                                                                  _vm
                                                                    .videoShareForm
                                                                    .signature
                                                                    .phone
                                                                )
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _c("br"),
                                                      _vm.videoShareForm
                                                        .signature.email
                                                        ? _c("span", [
                                                            _vm._v("e: "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#ff681d"
                                                                },
                                                                attrs: {
                                                                  tabindex:
                                                                    "-1",
                                                                  href:
                                                                    _vm
                                                                      .videoShareForm
                                                                      .signature
                                                                      .email
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .videoShareForm
                                                                      .signature
                                                                      .email
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm.videoShareForm
                                                        .signature.website
                                                        ? _c("span", [
                                                            _vm._v(" | w: "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#ff681d"
                                                                },
                                                                attrs: {
                                                                  tabindex:
                                                                    "-1",
                                                                  href:
                                                                    _vm
                                                                      .videoShareForm
                                                                      .signature
                                                                      .website
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.videoShareForm.signature.website.split(
                                                                      "//"
                                                                    )[1]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ],
                              2
                            )
                          ]
                        )
                      ],
                      1
                    )
              ])
            : _c("div", [
                _c(
                  "table",
                  {
                    staticStyle: {
                      "font-family": "Helvetica, Arial, sans-serif",
                      "font-size": "14px",
                      "font-weight": "normal",
                      color: "#37474f",
                      "text-align": "left",
                      "line-height": "20px"
                    },
                    attrs: { id: "source", cellpadding: "0", cellspacing: "0" }
                  },
                  _vm._l(_vm.videoShareForm.videoCustomerInfoArray, function(
                    info,
                    index
                  ) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "#ff681d",
                                  "text-transform": "capitalize"
                                }
                              },
                              [_vm._v(" Email Recipient " + _vm._s(index + 1))]
                            ),
                            _c("br"),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticStyle: { "text-transform": "capitalize" }
                              },
                              [
                                _vm._v(
                                  "Hello " +
                                    _vm._s(info.videoCustomerName) +
                                    ","
                                )
                              ]
                            ),
                            _c("br"),
                            _c("br"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.previewEmailBody)
                              }
                            }),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "#ff681d",
                                  "text-transform": "capitalize"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.videoShareForm.signature.name)
                                )
                              ]
                            ),
                            _c("br"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.videoShareForm.signature.title))
                            ]),
                            _vm.videoShareForm.signature.company
                              ? _c("span", [_vm._v(" | ")])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.videoShareForm.signature.company)
                              )
                            ]),
                            _c("br"),
                            _vm.videoShareForm.signature.phone
                              ? _c("span", [
                                  _vm._v(
                                    "p: " +
                                      _vm._s(_vm.videoShareForm.signature.phone)
                                  )
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm.videoShareForm.signature.email
                              ? _c("span", [
                                  _vm._v("e: "),
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#ff681d" },
                                      attrs: {
                                        tabindex: "-1",
                                        href: _vm.videoShareForm.signature.email
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.videoShareForm.signature.email
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.videoShareForm.signature.website
                              ? _c("span", [
                                  _vm._v(" | w: "),
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#ff681d" },
                                      attrs: {
                                        tabindex: "-1",
                                        href:
                                          _vm.videoShareForm.signature.website
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.videoShareForm.signature.website.split(
                                            "//"
                                          )[1]
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
        ]
      ),
      _vm.terminateInstance
        ? _c("TerminateInstance", { attrs: { instance: _vm.instance } })
        : _vm._e(),
      _vm.ipAddress ? _c("InstanceIP") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "flex-direction": "row" } },
      [
        _c("i", {
          staticClass: "fas fa-desktop",
          staticStyle: {
            color: "#fbb022",
            cursor: "pointer",
            display: "block",
            "margin-top": "2px"
          },
          attrs: { title: "Connect Demo" }
        }),
        _c(
          "div",
          { staticStyle: { "margin-top": "8px", "margin-left": "6px" } },
          [_vm._v("Connect Demo")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field_deploy" }, [
      _c("button", { staticClass: "btn" }, [_vm._v("Deploy")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field_deploy" }, [
      _c("button", { staticClass: "btn" }, [_vm._v("Connect")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-slide-header-title" }, [
      _c("span", { staticClass: "primary-title" }, [_vm._v("Instructions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }