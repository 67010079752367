var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "updates" }, [
    _c("div", { staticClass: "updates card" }, [
      _c(
        "div",
        { staticClass: "details" },
        [
          _c(
            "i",
            {
              staticClass: "material-icons close",
              on: {
                click: function($event) {
                  return _vm.closeUpdate()
                }
              }
            },
            [_vm._v("clear")]
          ),
          _c("div", { staticClass: "title" }, [
            _vm._v("SteelDemo Recent Updates")
          ]),
          _vm._l(_vm.data, function(update) {
            return _c("div", { key: update["date"], staticClass: "content" }, [
              _c("div", { staticClass: "date" }, [
                _vm._v(_vm._s(_vm.showDate(update["date"])))
              ]),
              _c(
                "div",
                { staticClass: "features" },
                [
                  _c("p", [_vm._v("New Features")]),
                  _vm._l(update["New Features"], function(feature) {
                    return _c("ul", { key: feature }, [
                      _c("li", [_vm._v(_vm._s(feature))])
                    ])
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "changes" },
                [
                  _c("p", [_vm._v("Changes")]),
                  _vm._l(update["Changes"], function(change) {
                    return _c("ul", { key: change }, [
                      _c("li", [_vm._v(_vm._s(change))])
                    ])
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "fixes" },
                [
                  _c("p", [_vm._v("Fixes")]),
                  _vm._l(update["Fixes"], function(fix) {
                    return _c("ul", { key: fix }, [
                      _c("li", [_vm._v(_vm._s(fix))])
                    ])
                  })
                ],
                2
              )
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }