var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "div",
        { staticClass: "summary", style: { opacity: _vm.opacityDashboard } },
        _vm._l(_vm.info_data, function(info) {
          return _c(
            "div",
            {
              key: info.id,
              staticClass: "dashboard_box",
              on: {
                click: function($event) {
                  return _vm.UPDATE_FOCUS(info.name)
                }
              }
            },
            [
              _c("div", { class: info.name }, [
                _c("i", { staticClass: "material-icons icon" }, [
                  _vm._v(_vm._s(info.icon))
                ])
              ]),
              _c("div", { staticClass: "info" }, [
                _c("p", { staticClass: "item" }, [_vm._v(_vm._s(info.label))]),
                _c("p", { staticClass: "value" }, [_vm._v(_vm._s(info.value))])
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "table", style: { opacity: _vm.opacityDashboard } },
        [
          _vm.focus === "planned"
            ? _c("Tables", {
                attrs: {
                  headers: _vm.headerFields_planned,
                  data: _vm.planned_list,
                  type: _vm.focus,
                  scenarios: _vm.scenarioNames
                }
              })
            : _vm._e(),
          _vm.focus === "running"
            ? _c("Tables", {
                attrs: {
                  headers: _vm.headerFields_running,
                  data: _vm.running_list,
                  type: _vm.focus,
                  scenarios: _vm.scenarioNames
                }
              })
            : _vm._e(),
          _vm.focus === "demos" || _vm.focus === "cost"
            ? _c("Tables", {
                attrs: {
                  headers: _vm.headerFields_demos,
                  data: _vm.demos_list,
                  type: _vm.focus,
                  scenarios: _vm.scenarioNames
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.terminateInstance
        ? _c("TerminateInstance", { attrs: { instance: _vm.instance } })
        : _vm._e(),
      _vm.ipAddress ? _c("InstanceIP") : _vm._e(),
      _vm.steeldemo_updates
        ? _c("Updates", { attrs: { data: _vm.updates_list } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }