<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['chartLabels', 'chartData', 'options'],
  mounted () {
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: this.chartData
      }, this.options
    )
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart({
          labels: this.chartLabels,
          datasets: newOption
        }, this.options)
      },
      deep: true
    }
  }
}
</script>
