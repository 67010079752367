<template>
  <div class="startSnapshot">
    <div class="startSnapshot">
      <b-modal
        centered
        content-class="startSnapshot"
        title="Start Instance"
        v-model="showStartSnapshot"
        @hide="closeStartSnapshot()"
      >
        <div class="deployParameters">
          <p class="feedback" v-if="feedback">{{ feedback }}</p>
          <div class="duration_and_mode">
            <div class="field duration">
              <label for="title">Demo duration (hours):</label>
              <input type="text" name="duration" v-model="duration" />
              <button class="decrement" v-on:click="changeDuration(-1)">
                -
              </button>
              <button class="increment" v-on:click="changeDuration(1)">
                +
              </button>
            </div>
          </div>
          <div class="deployDate">
            <label for="date">Date & Time:</label>
            <toggle-button
              @change="deployDate = $event.value"
              :labels="{ checked: 'Planned', unchecked: 'ASAP' }"
              :color="{ checked: '#FBB022', unchecked: '#FF6900' }"
              :width="70"
            />
            <date-pick
              v-if="deployDate"
              v-model="dateToPick"
              :pickTime="true"
              :format="'YYYY-MM-DD HH:mm'"
            ></date-pick>
          </div>
        </div>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            size="sm"
            v-on:click="deploySnapshot()"
          >
            Deploy
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
import moment from 'moment'
import { mapMutations, mapGetters, mapState } from 'vuex'

export default {
  name: 'startSnapshot',
  components: {
    DatePick
  },
  data () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      feedback: null,
      duration: 2, // number of hours to run the environment
      deployDate: false, // by default we deploy it ASAP (vs planned)
      dateToPick: moment().format('YYYY-MM-DD HH:mm'),
      input: {
        snapshotId: '',
        scenarioId: 'scenario030',
        deployMode: 'demo',
        duration: '',
        date: null,
        username: '',
        emailAddress: '',
        category: 'SD-WAN'
      }
    }
  },
  props: {
    snapshot: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'snapshotName',
      'snapshotCategory',
      'running_demos',
      'planned_demos',
      'otherMail',
      'mailUserName'
    ]),
    ...mapState(['startSnapshot', 'demoPurposeRecord']),
    showStartSnapshot: {
      get: function () {
        return this.startSnapshot
      },
      set: function (newValue) {
        return this.startSnapshot
      }
    }
  },
  methods: {
    closeStartSnapshot () {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_START_SNAPSHOT(false)
      const demoPurposeRecord = {
        demo_purpose: '',
        demo_purpose_customer_name: '',
        demo_purpose_customer_url: ''
      }
      this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
    },
    deploySnapshot () {
      this.input.username = this.mailUserName
      this.input.snapshotId = this.snapshot
      this.input.duration = this.duration * 3600 // convert the duration in s
      // need to add the oterMail email address for backward compatibility for former Auth0 users
      this.input.emailAddress = this.$msal.data.user.userName
      this.input = {
        ...this.input,
        ...this.demoPurposeRecord
      }
      // if the demo has been scheduled, we load the time
      if (this.deployDate) {
        this.input.date = moment(this.dateToPick).unix() // send Epoch Time
        if (this.input.date < moment().unix()) {
          this.feedback = 'The demo cannot be deployed in the past'
          return
        }
        if (this.input.date < moment().unix() + 3600) {
          this.feedback = 'The demo must be planned at least 1 hour ahead'
          return
        }
        // if there is already a demo in the same category planned at the same deploy, we can't deploy
        if (
          this.checkPlannedDemos(
            this.snapshotCategory,
            this.input.date,
            this.input.duration
          )
        ) {
          this.feedback =
            'You cannot planned two demos of the same category running at the same time'
          return
        }
      } else {
        this.input.date = null
        // if there is already a demo in the same category, we can't deploy
        if (this.checkRunningDemos(this.snapshotCategory)) {
          this.feedback = 'You cannot have two demos at the same time'
          return
        }
      }
      this.input.category = this.snapshotCategory
      axios({
        method: 'PUT',
        url: this.backendURL + 'snapshot',
        data: this.input,
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        // crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.response = result.data
        },
        (error) => {
          console.error(error)
        }
      )
      this.closeStartSnapshot()
    },
    ...mapMutations([
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_SNAPSHOT_NAME',
      'UPDATE_START_SNAPSHOT',
      'SELECT_DEMO_PURPOSE'
    ]),
    changeDuration (increment) {
      if (this.duration === 1) {
        if (increment > 0) this.duration = this.duration + increment
      } else {
        if (this.duration === 8 && increment > 0) {
          this.feedback = 'Maximum duration is 8 hours'
        } else {
          this.duration = this.duration + increment
          this.feedback = null
        }
      }
    },
    // check whether a demo is already running
    checkRunningDemos (category) {
      if (this.$store.running_demos === null) return false
      if (this.$store.running_demos.length > 0) return true
      else return false
    },
    // check whether a demo is already planned at the same time
    checkPlannedDemos (category, startDate, duration) {
      const categoryList = this.$store.planned_demos.filter(function (demos) {
        return demos.category === category
      })
      const endDate = startDate + duration * 3600
      if (categoryList === null) return false
      categoryList.forEach(function (demo) {
        const start = demo.starting_timestamp
        const end = start + demo.duration * 3600
        if (
          (startDate < start && end < endDate) ||
          (startDate > start && end < endDate) ||
          (startDate > start && end > endDate) ||
          (startDate < start && end > endDate)
        ) {
          return true
        }
      })
      return false
    }
  }
}
</script>

<style>
.deployParameters .duration_and_mode {
  display: inline-flex;
  text-align: center;
  width: 100%;
}

.startSnapshot .deployParameters label {
  font-size: 14px;
  color: grey;
}

.startSnapshot .deployParameters input {
  font-size: 14px;
  color: grey;
  font-weight: bold;
}

.startSnapshot .deployParameters .duration {
  width: 150%;
  padding-top: 0;
}

.startSnapshot .deployParameters .duration input {
  width: 1em;
  margin-right: 0;
}

.startSnapshot .deployParameters .feedback {
  color: red;
  font-weight: bold;
  text-align: center;
}

.startSnapshot .deployParameters .increment {
  background-color: #5b7f95;
  color: white;
}

.startSnapshot .deployParameters .decrement {
  background-color: #6bb5e4;
  color: white;
}

.startSnapshot .deployParameters .deployMode {
  padding-top: 12px;
  width: 80%;
  display: inline-flex;
}

.startSnapshot .deployParameters .deployDate {
  text-align: center;
}

.startSnapshot .deployParameters .v-switch-label {
  font-size: 10px;
}

/* .startSnapshot .snap .deploy button {
  margin-top: 10px;
  margin-left: 25%;
  width: 50%;
  background-color: #ff6900;
} */

/* .startSnapshot .close {
  position: absolute;
  right: 5px;
  top: 16px;
  color: #aaa;
  font-size: 1.4em;
  cursor: pointer;
} */
</style>
