<template>
  <div class="Demo" ref="DemoPage" style="overflow-x: hidden">
    <div class="scenarioDetails">
      <div class="field_copy_link">
        <i
          v-if="showScriptClass != 'selected'"
          class="material-icons x-close"
          style="font-size: 25px"
          v-on:click="closeScenario()"
          title="Close Demo"
          >close</i
        >
        <button
          class="btn"
          v-on:click="
            copyDemoUrl(
              scenario.demoType,
              scenario.lastPublishedDate,
              scenario.scenarioId
            )
          "
        >
          Copy Sharable Link
        </button>
        <button
          v-if="scenario.demoScript && deployMode === 'demo'"
          v-on:click="showScript(scenario.demoScript)"
          class="btn"
          style="margin-left: 10px"
        >
          Demo Script
        </button>
        <!-- <button
          v-if="scenario.demoScript && deployMode === 'demo'"
          class="btn video_button"
          style="margin-left: 10px"
          v-b-modal.modal-share-video
        >
          Share Demo Video
        </button> -->
      </div>
      <transition name="fade" v-on:enter="copyDemoUrlLink">
        <p
          v-if="isCopySuccessful"
          style="
            font-size: 10px;
            margin-top: -15px;
            margin-left: 3px;
            color: green;
            font-weight: 500;
            margin-bottom: 0px;
          "
        >
          copied!
        </p>
      </transition>
      <h4>{{ scenario.name }}</h4>
      <p style="font-size: 12px; color: #808080d1">
        Last Updated: {{ getDate(scenario.lastPublishedDate) }}
      </p>
      <div>
        <span
          :class="
            'badge badge-dark badge-custom-details ' + scenario.featuredClass
          "
          >{{
            getScenarioMapId(
              scenario.demoType,
              scenario.lastPublishedDate,
              scenario.scenarioId
            )
          }}</span
        ><span
          class="badge badge-dark badge-custom-details"
          v-if="
            (scenario.demoType !== 'Lab' &&
              scenario.status === 'User Created') ||
            scenario.status === 'Topology Created' ||
            scenario.status === 'User Session Created' ||
            scenario.status === 'Org User Created' ||
            scenario.status === 'Instance State UP'
          "
          style="
            font-size: 14px !important;
            background-color: green !important;
            margin-left: 5px !important;
          "
          >Running</span
        >
        <span
          class="badge badge-dark badge-custom-details"
          v-if="scenario.inProcess"
          style="
            font-size: 14px !important;
            background-color: #ff681d !important;
            margin-left: 5px !important;
            width: auto !important;
          "
          >{{ scenario.status }}</span
        >
      </div>
      <div v-if="!scenario.inProcess" class="demo-link">
        <button
          class="btn"
          v-on:click="openAlert(scenario['ip_address'])"
          v-if="scenario['ip_address']"
        >
          <i
            class="fas fa-bolt"
            style="color: #fbb022; cursor: pointer"
            title="IP Address"
          ></i>
          IP Address
        </button>
        <a
          class="btn"
          :href="scenario['wvd_url']"
          v-if="scenario['wvd_url']"
          target="_blank"
          style="
            font-size: 12px !important;
            text-decoration: none;
            margin-right: 11px !important;
            margin-left: -4px !important;
            margin-top: 10px !important;
            padding-top: 2px;
            width: 157px;
          "
        >
          <div style="display: flex; flex-direction: row">
            <i
              class="fas fa-desktop"
              style="
                color: #fbb022;
                cursor: pointer;
                display: block;
                margin-top: 2px;
              "
              title="Connect Demo"
            ></i>
            <div style="margin-top: 8px; margin-left: 6px">Connect Demo</div>
          </div>
        </a>
        <button
          class="btn"
          v-on:click="
            openUserCred(
              scenario['user'],
              scenario['password'],
              scenario['subnet'] || ''
            )
          "
          v-if="
            (scenario.status === 'User Created' ||
              scenario.status === 'Org User Created') &&
            scenario.user &&
            scenario.password
          "
          v-b-modal.modal-7
        >
          <i
            class="fas fa-user-secret"
            style="color: #fbb022; cursor: pointer"
            title="Demo details"
          ></i>
          Demo Details
        </button>
        <button
          class="btn"
          v-on:click="terminate_instance(scenario['demoId'])"
          v-if="scenario['demoId']"
        >
          <i
            class="fas fa-stop-circle"
            style="color: red; cursor: pointer"
            title="Terminate demo"
          ></i>
          Terminate Demo
        </button>
        <button
          class="btn"
          v-on:click="showSnapshot(scenario['demoId'])"
          v-if="scenario.snapshot && scenario.status === 'Topology Created'"
        >
          <i
            class="fas fa-camera"
            style="color: #575757; cursor: pointer"
            title="Snapshot"
          ></i>
          Snapshot
        </button>
      </div>
      <div style="padding: 20px 0px; max-width: 880px">
        <div class="video-container" v-if="scenario.video">
          <youtube
            :video-id="getId(scenario.video)"
            :player-vars="playerVars"
          ></youtube>
        </div>
        <b-img v-else :src="scenario.picture" fluid alt=""></b-img>
      </div>
      <div class="description">
        <h5 style="margin-bottom: -10px">Description:</h5>
        <p>{{ scenario.description }}</p>
      </div>
      <div
        class="deployParameters"
        v-if="!scenario.link && scenario.status.length === 0"
      >
        <p class="feedback" v-if="feedback">{{ feedback }}</p>
        <div style="padding: 6px 0px">
          <b-form>
            <b-form-group
              id="input-group-1"
              label="Demo duration (hours):"
              label-for="input-1"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
            >
              <b-form-spinbutton
                id="sb-inline"
                v-model="duration"
                min="1"
                max="8"
                inline
              ></b-form-spinbutton>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Date & Time:"
              label-for="input-2"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
            >
              <date-picker
                v-model="dateToPick"
                type="datetime"
                value-type="format"
                format="YYYY-MM-DD HH:mm"
                style="margin-top: -6px"
              ></date-picker>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label="Demo Purpose:"
              label-for="input-3"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="8"
            >
              <b-form-radio-group
                v-model="selectedDemoPurpose"
                :options="demoPurposeOptions"
                name="radio-inline"
                style="margin-top: 5px"
              ></b-form-radio-group>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              label="Customer Name:"
              label-for="input-4"
              label-cols-sm="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="4"
            >
              <b-form-input
                id="input-4"
                v-model="customerName"
                placeholder="Please write Customer Name"
                :plaintext="
                  selectedDemoPurpose == 'customerDemos' ? false : true
                "
                style="margin-top: -5px"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              label="SFDC Link (Riverbed Internal):"
              label-for="input-5"
              label-cols-sm="2"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="4"
            >
              <b-form-input
                id="input-5"
                v-model="customerLink"
                placeholder="Please mention SFDC Link"
                :plaintext="
                  selectedDemoPurpose == 'customerDemos' ? false : true
                "
                style="margin-top: -5px"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </div>
      </div>
      <form
        @submit.prevent="deployScenario()"
        v-if="!scenario.link && scenario.status.length === 0"
      >
        <div class="field_deploy">
          <button class="btn">Deploy</button>
        </div>
      </form>
      <form
        v-if="scenario.link"
        @submit.prevent="
          redirectToDemo(scenario.link, scenario.user, scenario.password)
        "
      >
        <div class="field_deploy">
          <button class="btn">Connect</button>
        </div>
      </form>
    </div>
    <div :class="'right-slide ' + showScriptClass">
      <div class="right-slide-header">
        <div class="right-slide-header-title">
          <span class="primary-title">Instructions</span>
        </div>
        <div class="right-slide-header-icons">
          <i
            class="material-icons"
            style="
              font-size: 20px;
              margin-top: 3px;
              position: absolute;
              right: 30px;
              cursor: pointer;
              color: black;
            "
            @click="openScriptInTab(scenario.demoScript)"
            title="Open in New Tab"
            >open_in_new</i
          >
          <i
            class="material-icons"
            style="font-size: 25px; cursor: pointer; color: black"
            title="Close Instructions"
            @click="onCloseInstructions"
            >close</i
          >
        </div>
      </div>
      <iframe
        id="demo-script-iframe"
        :src="scenario.demoScript"
        width="100%"
        height="95%%"
        frameborder="0"
        scrolling="auto"
      ></iframe>
    </div>
    <div v-if="showUserCredModal">
      <b-modal id="modal-7" centered hide-header content-class="modal-content2">
        <div class="row">
          <div class="col-4">
            <strong style="float: right">User Name: </strong>
          </div>
          <div class="col-6" style="max-width: 142px !important">
            {{ userName }}
          </div>
          <input type="hidden" id="copy-code-username" :value="userName" />
          <i
            class="material-icons"
            @click="copyUsername()"
            style="cursor: pointer; margin-left: 6px"
            >content_copy</i
          >
          <transition name="fade" v-on:enter="enterUserName">
            <p
              v-if="copyUserName"
              style="
                font-size: 0.8vw;
                margin-top: 3px;
                margin-left: 3px;
                color: green;
                font-weight: 500;
              "
            >
              copied!
            </p>
          </transition>
        </div>
        <div class="row">
          <div class="col-4">
            <strong style="float: right">Password:</strong>
          </div>
          <div style="display: inline-flex">
            <div
              class="col-6"
              style="max-width: 113px !important; margin-top: 3px"
            >
              *****************
            </div>
            <input type="hidden" id="copy-code" :value="password" />
            <i
              class="material-icons"
              @click="togglePassword()"
              style="cursor: pointer; margin-left: 35px"
              >content_copy</i
            >
            <transition name="fade" v-on:enter="enterPassword">
              <p
                v-if="copyPassword"
                style="
                  font-size: 0.8vw;
                  margin-top: 3px;
                  margin-left: 3px;
                  color: green;
                  font-weight: 500;
                "
              >
                copied!
              </p>
            </transition>
          </div>
        </div>
        <div class="row" v-if="subnet">
          <div class="col-4">
            <strong style="float: right">Subnet:</strong>
          </div>
          <div style="display: inline-flex">
            <div
              class="col-6"
              style="max-width: 113px !important; margin-top: 3px"
            >
              {{ subnet }}
            </div>
            <input type="hidden" id="copy-code-subnet" :value="subnet" />
            <i
              class="material-icons"
              @click="toggleSubnet()"
              style="cursor: pointer; margin-left: 35px"
              >content_copy</i
            >
            <transition name="fade" v-on:enter="enterSubnet">
              <p
                v-if="copySubnet"
                style="
                  font-size: 0.8vw;
                  margin-top: 3px;
                  margin-left: 3px;
                  color: green;
                  font-weight: 500;
                "
              >
                copied!
              </p>
            </transition>
          </div>
        </div>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            size="sm"
            v-on:click="closeUserCredModal"
          >
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
    <b-modal
      id="modal-share-video"
      dialog-class="modal-share-video"
      ref="modal"
      @show="resetShareVideoModal"
      @hidden="resetShareVideoModal"
      scrollable
    >
      <template #modal-title>
        <div style="display: flex; flex-direction: row">
          <p style="font-size: 20px">Sharable Video Link</p>
          <small
            style="
              padding: 6px;
              font-size: 14px;
              color: rgb(150, 144, 144);
              padding-left: 15px;
            "
          >
            Sharable video link will be send to the Customer
          </small>
        </div>
      </template>
      <div v-if="!previewMode">
        <div class="pop-up-container" v-if="this.isFirstView">
          <b-form class="pop-up-view" @submit.stop.prevent="nextClick">
            <div class="col-md-12" style="margin-left: -15px">
              <b-form-group
                id="input-group-cname"
                label="Customer Company Name:"
                label-for="input-cname"
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
              >
                <b-form-input
                  id="input-cname"
                  name="input-cname"
                  placeholder="Enter customer company name"
                  v-model="videoShareForm.companyName"
                  v-validate="{ required: true }"
                  aria-describedby="input-cname-live-feedback"
                  data-vv-as="customer company name"
                  :state="validateState('input-cname')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-cname-live-feedback">{{
                  veeErrors.first("input-cname")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div
              class="row"
              v-for="(info, index) in videoShareForm.videoCustomerInfoArray"
              :key="index"
            >
              <div class="col-md-10">
                <b-form-group
                  :id="'input-group-' + index"
                  label="Recipient's Name:"
                  :label-for="'input-' + index"
                  label-cols-sm="3"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    :id="'input-' + index"
                    :name="'input-' + index"
                    v-model="info.videoCustomerName"
                    v-validate="{ required: true }"
                    placeholder="Enter Recipient's Name"
                    style="margin-top: -5px; margin-left: 34px !important"
                    :aria-describedby="'input-' + index + '-live-feedback'"
                    data-vv-as="recipient's name"
                    :state="validateState('input-' + index)"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    style="margin-left: 34px !important"
                    :id="'input-' + index + '-live-feedback'"
                    >{{
                      veeErrors.first("input-" + index)
                    }}</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group
                  :id="
                    'input-group-' +
                    videoShareForm.videoCustomerInfoArray.length +
                    index
                  "
                  label="Recipient's Email Address:"
                  :label-for="
                    'input-' +
                    videoShareForm.videoCustomerInfoArray.length +
                    index
                  "
                  label-cols-sm="3"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    :id="
                      'input-' +
                      videoShareForm.videoCustomerInfoArray.length +
                      index
                    "
                    :name="
                      'input-' +
                      videoShareForm.videoCustomerInfoArray.length +
                      index
                    "
                    v-model="info.videoCustomerEmail"
                    v-validate="{ required: true, email: 'email' }"
                    placeholder="Enter recipient's email address"
                    style="margin-top: -5px; margin-left: 34px !important"
                    :aria-describedby="
                      'input-' +
                      videoShareForm.videoCustomerInfoArray.length +
                      index +
                      '-live-feedback'
                    "
                    data-vv-as="recipient's email address"
                    :state="
                      validateState(
                        'input-' +
                          videoShareForm.videoCustomerInfoArray.length +
                          index
                      )
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback
                    style="margin-left: 34px !important"
                    :id="
                      'input-' +
                      videoShareForm.videoCustomerInfoArray.length +
                      index +
                      '-live-feedback'
                    "
                    >{{
                      veeErrors.first(
                        "input-" +
                          videoShareForm.videoCustomerInfoArray.length +
                          index
                      )
                    }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <div class="col-md-2">
                <i
                  v-if="
                    videoShareForm.videoCustomerInfoArray.length - 1 == index &&
                    index <= 1
                  "
                  class="material-icons"
                  style="font-size: 25px; margin-top: 45px; cursor: pointer"
                  @click="onVideoCustomerInfoClickAdd()"
                  title="add"
                  >add</i
                >
                <i
                  v-else-if="
                    index != 0 ||
                    (videoShareForm.videoCustomerInfoArray.length == 2 &&
                      index == 0)
                  "
                  class="material-icons"
                  style="font-size: 25px; margin-top: 45px; cursor: pointer"
                  @click="onVideoCustomerInfoClickDelete(index)"
                  title="delete"
                  >delete</i
                >
              </div>
            </div>
            <div class="col-md-12" style="margin-left: -15px">
              <b-form-group
                id="input-group-ccemail"
                label="CC Email Address:"
                label-for="input-ccemail"
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
              >
                <b-form-input
                  id="input-ccemail"
                  name="input-ccemail"
                  placeholder="Enter CC email ID"
                  v-model="videoShareForm.ccEmail"
                  v-validate="'email'"
                  aria-describedby="input-ccemail-live-feedback"
                  data-vv-as="CC Email Id"
                  :state="validateState('input-ccemail')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-ccemail-live-feedback">{{
                  veeErrors.first("input-ccemail")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-ebody"
                label="Email Body:"
                label-for="input-ebody"
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
              >
                <b-form-textarea
                  id="input-ebody"
                  name="input-ebody"
                  v-model="videoShareForm.videoCustomerEmailBody"
                  rows="7"
                  max-rows="16"
                  v-validate="{ required: true, min: 10 }"
                  :state="validateState('input-ebody')"
                  aria-describedby="input-ebody-live-feedback"
                  data-vv-as="Email Body"
                ></b-form-textarea>
                <b-form-invalid-feedback id="input-ebody-live-feedback">{{
                  veeErrors.first("input-ebody")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-form>
        </div>
        <div class="pop-up-container" v-else>
          <b-form
            class="pop-up-view"
            @submit.stop.prevent="copyShareVideoModal"
          >
            <div class="row" style="margin: 0px; width: 98%">
              <p
                style="
                  color: rgb(51, 51, 51);
                  font-size: 15px;
                  font-weight: 600;
                "
              >
                Links for video Page:
                <i
                  class="material-icons tool-tip"
                  id="tool-tip-target"
                  style="font-size: 18px; color: gray; cursor: pointer"
                  >tips_and_updates</i
                >
                <b-tooltip
                  target="tool-tip-target"
                  placement="rightbottom"
                  variant="info"
                  title="Share additional links to product documents or data sheets."
                ></b-tooltip>
              </p>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 98%;
                  margin: 0px;
                "
                class="row"
                v-for="(
                  info, index
                ) in videoShareForm.videoCustomerExtraLinkArray"
                :key="index"
              >
                <div
                  class="col-md-11"
                  style="display: flex; flex-direction: row"
                >
                  <b-form-input
                    size="sm"
                    v-model="info.name"
                    placeholder="Link Name"
                  ></b-form-input>
                  <b-form-input
                    size="sm"
                    v-model="info.url"
                    placeholder="Link URL"
                    style="margin-left: 20px"
                  ></b-form-input>
                </div>
                <div class="col-md-1">
                  <i
                    v-if="
                      videoShareForm.videoCustomerExtraLinkArray.length - 1 ==
                        index && index <= 1
                    "
                    class="material-icons"
                    style="font-size: 25px; margin-top: 6px; cursor: pointer"
                    @click="onVideoCustomerExtraLinkClickAdd()"
                    title="add"
                    >add</i
                  >
                  <i
                    v-else-if="
                      index != 0 ||
                      (videoShareForm.videoCustomerExtraLinkArray.length == 2 &&
                        index == 0)
                    "
                    class="material-icons"
                    style="font-size: 25px; margin-top: 4px; cursor: pointer"
                    @click="onVideoCustomerExtraLinkClickDelete(index)"
                    title="delete"
                    >delete</i
                  >
                </div>
              </div>
              <p
                style="
                  color: rgb(51, 51, 51);
                  font-size: 15px;
                  font-weight: 600;
                  padding-top: 15px;
                "
              >
                Email Signature with Preview:
              </p>
              <div class="row" style="width: 98%; margin: 0px">
                <div class="col-md-6" style="border-right: 1px solid #e4e2e2">
                  <b-form-input
                    id="input-sname"
                    name="input-sname"
                    size="sm"
                    v-model="videoShareForm.signature.name"
                    placeholder="Name"
                    style="text-transform: capitalize"
                    v-validate="{ required: true }"
                    :state="validateState('input-sname')"
                    aria-describedby="input-sname-live-feedback"
                    data-vv-as="name"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-sname-live-feedback">{{
                    veeErrors.first("input-sname")
                  }}</b-form-invalid-feedback>
                  <b-form-input
                    size="sm"
                    v-model="videoShareForm.signature.title"
                    placeholder="Role"
                    id="input-srole"
                    name="input-srole"
                    style="text-transform: capitalize"
                    v-validate="{ required: true }"
                    :state="validateState('input-srole')"
                    aria-describedby="input-srole-live-feedback"
                    data-vv-as="role"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-srole-live-feedback">{{
                    veeErrors.first("input-srole")
                  }}</b-form-invalid-feedback>
                  <b-form-input
                    size="sm"
                    v-model="videoShareForm.signature.company"
                    placeholder="Company"
                    id="input-scompany"
                    name="input-scompany"
                    style="text-transform: capitalize"
                    v-validate="{ required: true }"
                    :state="validateState('input-scompany')"
                    aria-describedby="input-scompany-live-feedback"
                    data-vv-as="company"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-scompany-live-feedback">{{
                    veeErrors.first("input-scompany")
                  }}</b-form-invalid-feedback>
                  <b-form-input
                    size="sm"
                    v-model="videoShareForm.signature.phone"
                    placeholder="Phone"
                    id="input-sphone"
                    name="input-sphone"
                    style="text-transform: capitalize"
                    v-validate="{ required: true }"
                    :state="validateState('input-sphone')"
                    aria-describedby="input-sphone-live-feedback"
                    data-vv-as="phone"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-sphone-live-feedback">{{
                    veeErrors.first("input-sphone")
                  }}</b-form-invalid-feedback>
                  <b-form-input
                    size="sm"
                    v-model="videoShareForm.signature.email"
                    placeholder="Email ID"
                    id="input-semail"
                    name="input-semail"
                    v-validate="{ required: true, email: 'email' }"
                    :state="validateState('input-semail')"
                    aria-describedby="input-semail-live-feedback"
                    data-vv-as="email id"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-semail-live-feedback">{{
                    veeErrors.first("input-semail")
                  }}</b-form-invalid-feedback>
                  <b-form-input
                    size="sm"
                    v-model="videoShareForm.signature.website"
                    placeholder="Website"
                    id="input-swebsite"
                    name="input-swebsite"
                    v-validate="{ required: true }"
                    :state="validateState('input-swebsite')"
                    aria-describedby="input-swebsite-live-feedback"
                    data-vv-as="website"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-swebsite-live-feedback">{{
                    veeErrors.first("input-swebsite")
                  }}</b-form-invalid-feedback>
                </div>
                <div class="col-md-6">
                  <div class="criador__source">
                    <div ref="sourceWrapper">
                      <table
                        id="source"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          font-family: Helvetica, Arial, sans-serif;
                          font-size: 14px;
                          font-weight: normal;
                          color: #37474f;
                          text-align: left;
                          line-height: 20px;
                        "
                      >
                        <tr>
                          <td style="padding-left: 10px; text-align: left">
                            <span
                              style="
                                font-weight: bold;
                                color: #ff681d;
                                text-transform: capitalize;
                              "
                              >{{ videoShareForm.signature.name }}</span
                            >
                            <br />
                            <span>{{ videoShareForm.signature.title }}</span
                            ><span v-if="videoShareForm.signature.company">
                              | </span
                            ><span>{{ videoShareForm.signature.company }}</span>
                            <br />
                            <span v-if="videoShareForm.signature.phone"
                              >p: {{ videoShareForm.signature.phone }}</span
                            >
                            <br />
                            <span v-if="videoShareForm.signature.email"
                              >e:
                              <a
                                tabindex="-1"
                                :href="videoShareForm.signature.email"
                                style="color: #ff681d"
                                >{{ videoShareForm.signature.email }}</a
                              ></span
                            >
                            <span v-if="videoShareForm.signature.website">
                              | w:
                              <a
                                tabindex="-1"
                                :href="videoShareForm.signature.website"
                                style="color: #ff681d"
                                >{{
                                  videoShareForm.signature.website.split(
                                    "//"
                                  )[1]
                                }}</a
                              ></span
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
      <div v-else>
        <table
          id="source"
          cellpadding="0"
          cellspacing="0"
          style="
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: #37474f;
            text-align: left;
            line-height: 20px;
          "
        >
          <div
            class="row"
            v-for="(info, index) in videoShareForm.videoCustomerInfoArray"
            :key="index"
          >
            <tr>
              <td style="padding-left: 10px; text-align: left">
                <span
                  style="
                    font-weight: bold;
                    color: #ff681d;
                    text-transform: capitalize;
                  "
                  > Email Recipient {{index+1}}</span
                >
                <br />
                <br />
                <span style="text-transform: capitalize"
                  >Hello {{ info.videoCustomerName }},</span
                >
                <br />
                <br />
                <span v-html="previewEmailBody"></span>
                <br />
                <span
                  style="
                    font-weight: bold;
                    color: #ff681d;
                    text-transform: capitalize;
                  "
                  >{{ videoShareForm.signature.name }}</span
                >
                <br />
                <span>{{ videoShareForm.signature.title }}</span
                ><span v-if="videoShareForm.signature.company"> | </span
                ><span>{{ videoShareForm.signature.company }}</span>
                <br />
                <span v-if="videoShareForm.signature.phone"
                  >p: {{ videoShareForm.signature.phone }}</span
                >
                <br />
                <span v-if="videoShareForm.signature.email"
                  >e:
                  <a
                    tabindex="-1"
                    :href="videoShareForm.signature.email"
                    style="color: #ff681d"
                    >{{ videoShareForm.signature.email }}</a
                  ></span
                >
                <span v-if="videoShareForm.signature.website">
                  | w:
                  <a
                    tabindex="-1"
                    :href="videoShareForm.signature.website"
                    style="color: #ff681d"
                    >{{ videoShareForm.signature.website.split("//")[1] }}</a
                  ></span
                >
              </td>
            </tr>
          </div>
        </table>
      </div>
      <template #modal-footer content-class="modal-footer1">
        <b-button
          class="btn btn-primary btn-custom btn-user-cred"
          v-on:click="nextClick"
          v-if="isFirstView"
          style="margin-right: 10px !important; width: 11% !important"
        >
          NEXT
        </b-button>
        <b-button
          class="btn btn-primary btn-custom btn-video-pre"
          v-on:click="backClick"
          v-if="!previewMode && !isFirstView"
        >
          PREVIOUS
        </b-button>
        <b-button
          class="btn btn-primary btn-custom btn-video-pre"
          v-on:click="cancelPreview"
          v-if="previewMode && !isFirstView"
        >
          BACK
        </b-button>
        <b-button
          class="btn btn-primary btn-custom btn-video-preview"
          v-on:click="previewClick"
          v-if="!previewMode && !isFirstView"
        >
          PREVIEW
        </b-button>
        <b-button
          v-if="!previewMode && !isFirstView"
          class="btn btn-primary btn-custom btn-user-cred"
          v-on:click="copyShareVideoModal"
          style="width: 23% !important; margin-right: 10px !important"
        >
          SEND VIDEO LINK
        </b-button>
        <b-button
          class="btn btn-primary btn-custom btn-user-cred"
          v-on:click="copyShareVideoModal"
          v-if="previewMode && !isFirstView"
          style="
            width: 23% !important;
            margin-left: auto !important;
            margin-right: 10px !important;
          "
        >
          SEND VIDEO LINK
        </b-button>
      </template>
    </b-modal>
    <TerminateInstance :instance="instance" v-if="terminateInstance" />
    <InstanceIP v-if="ipAddress" />
  </div>
</template>
<script>
import { scenarioStore } from '@/store/scenarioStore.js'
import TerminateInstance from '@/components/TerminateInstance'
import InstanceIP from '@/components/InstanceIP'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import { mapMutations, mapGetters, mapState } from 'vuex'

export default {
  name: 'Demo',
  components: {
    DatePicker,
    TerminateInstance,
    InstanceIP
  },
  data () {
    return {
      scenario: scenarioStore.scenario,
      selected: true,
      backendURL: process.env.VUE_APP_BACKEND_URL,
      feedback: null,
      duration: 2, // number of hours to run the environment
      dateToPick: moment().format('YYYY-MM-DD HH:mm'),
      deployMode: 'demo',
      slide: 0,
      sliding: null,
      input: {
        scenarioId: 0,
        category: 'SD-WAN',
        date: null,
        mode: null,
        username: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        demo_purpose: '',
        demo_purpose_customer_name: '',
        demo_purpose_customer_url: '',
        org: process.env.VUE_APP_DIRECTOR_ORG,
        role: process.env.VUE_APP_DIRECTOR_ROLE
      },
      playerVars: {
        autoplay: 0
      },
      selectedDemoPurpose: '',
      demoPurposeOptions: [
        { text: 'Customer Demos ', value: 'customerDemos' },
        { text: 'Practice & Preparation', value: 'practice' }
      ],
      showScriptClass: '',
      demoScriptHtml: '',
      isCopySuccessful: false,
      customerName: '',
      customerLink: '',
      running_list: [],
      showUserCredModal: false,
      copyUserName: false,
      copyPassword: false,
      copySubnet: false,
      subnet: '',
      isFirstView: true,
      previewEmailBody: '',
      videoShareForm: {
        companyName: '',
        ccEmail: '',
        videoCustomerEmailBody:
          'I wanted to share a brief demonstration video with you. I think this video will highlight how riverbed solves…. And what we can do for you.\n\nPlease feel free to contact me directly if you would like to schedule a one-on-one demo that’s tailor made for your organization.\n\nPlease click here to watch the video.\n\nThank you,',
        videoCustomerInfoArray: [
          {
            videoCustomerName: '',
            videoCustomerEmail: ''
          }
        ],
        videoCustomerExtraLinkArray: [
          {
            name: '',
            url: ''
          }
        ],
        signature: {
          name: '',
          title: '',
          company: 'Riverbed Technology, Inc.',
          phone: '',
          email: '',
          website: 'https://www.riverbed.com'
        }
      },
      previewMode: false
    }
  },
  computed: {
    ...mapGetters([
      'category',
      'running_demos',
      'planned_demos',
      'otherMail',
      'mailUserName',
      'snapshotNbr'
    ]),
    ...mapState([
      'firestoreDB',
      'terminateInstance',
      'instance',
      'ipAddress',
      'demoPurposeRecord'
    ]),
    player () {
      return this.$refs.youtube.player
    }
  },
  methods: {
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    nextClick () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }
        this.isFirstView = false
      })
    },
    backClick () {
      this.isFirstView = true
    },
    previewMailBody () {
      this.previewEmailBody = this.videoShareForm.videoCustomerEmailBody.includes(
        'Please click here to watch the video.'
      )
        ? this.videoShareForm.videoCustomerEmailBody.replace(
          'Please click here to watch the video.',
          '<a href="%s"><img alt="Please click here to watch the video." src="https://test-steeldemo-video-output.s3.eu-west-1.amazonaws.com/video.png" width="350" height="150"></a>'
        )
        : this.videoShareForm.videoCustomerEmailBody.replace(
          'Thank you,',
          '<a href="%s"><img alt="Please click here to watch the video." src="https://test-steeldemo-video-output.s3.eu-west-1.amazonaws.com/video.png" width="350" height="150"></a>\n\nThank you,'
        )
      this.previewEmailBody = this.previewEmailBody.replace(
        /\n/g,
        '<br/>'
      )
    },
    previewClick () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }
        this.previewMailBody()
        this.previewMode = true
      })
    },
    cancelPreview () {
      this.previewMode = false
    },
    ...mapMutations([
      'SELECT_CATEGORY',
      'UPDATE_IP_ADDRESS',
      'SELECT_INSTANCE',
      'UPDATE_TERMINATE_INSTANCE',
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_CREATE_SNAPSHOT',
      'SELECT_DEMO_PURPOSE'
    ]),
    terminate_instance (instanceId) {
      this.SELECT_INSTANCE(instanceId)
      this.UPDATE_TERMINATE_INSTANCE(true)
    },
    onVideoCustomerInfoClickAdd () {
      this.videoShareForm.videoCustomerInfoArray.push({
        videoCustomerName: '',
        videoCustomerEmail: ''
      })
    },
    onVideoCustomerInfoClickDelete (index) {
      this.videoShareForm.videoCustomerInfoArray.splice(index, 1)
    },
    onVideoCustomerExtraLinkClickAdd () {
      this.videoShareForm.videoCustomerExtraLinkArray.push({
        name: '',
        url: ''
      })
    },
    onVideoCustomerExtraLinkClickDelete (index) {
      this.videoShareForm.videoCustomerExtraLinkArray.splice(index, 1)
    },
    resetShareVideoModal () {
      this.isFirstView = true
      this.previewMode = false
      this.videoShareForm = {
        companyName: '',
        ccEmail: '',
        videoCustomerEmailBody:
          'I wanted to share a brief demonstration video with you. I think this video will highlight how riverbed solves…. And what we can do for you.\n\nPlease feel free to contact me directly if you would like to schedule a one-on-one demo that’s tailor made for your organization.\n\nPlease click here to watch the video.\n\nThank you,',
        videoCustomerInfoArray: [
          {
            videoCustomerName: '',
            videoCustomerEmail: ''
          }
        ],
        videoCustomerExtraLinkArray: [
          {
            name: '',
            url: ''
          }
        ],
        signature: {
          name: '',
          title: '',
          company: 'Riverbed Technology, Inc.',
          phone: '',
          email: '',
          website: 'https://www.riverbed.com'
        }
      }
      if (this.$store.signature) { this.videoShareForm.signature = this.$store.signature }
    },
    copyShareVideoModal (event) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }
        this.$bvModal.hide('modal-share-video')
        this.previewMailBody()
        const reqbody = {
          steeldemoID: this.$msal.data.user.userName,
          companyName: this.videoShareForm.companyName,
          ccEmail: this.videoShareForm.ccEmail,
          customerInfo: this.videoShareForm.videoCustomerInfoArray.map(
            (info) => {
              info.videoCustomerName = info.videoCustomerName
                .toLowerCase()
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                )
              return info
            }
          ),
          emailbody: this.previewEmailBody,
          scenarioId: 'scenarioId',
          videoName: 'MS-Stream',
          signature: {
            name: this.videoShareForm.signature.name
              .toLowerCase()
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ),
            title: this.videoShareForm.signature.title,
            company: this.videoShareForm.signature.company,
            phone: this.videoShareForm.signature.phone,
            email: this.videoShareForm.signature.email,
            website: this.videoShareForm.signature.website
          },
          links: this.videoShareForm.videoCustomerExtraLinkArray
        }
        this.$nextTick(() => {
          axios({
            method: 'POST',
            url: this.backendURL + 'video/sharable-link',
            data: { ...reqbody },
            headers: {
              'Content-Type': 'application/json',
              Authorization: sessionStorage.getItem('msal.idtoken')
            },
            responseType: 'json'
          }).then(
            (result) => {
              this.$bvModal.hide('modal-share-video')
              this.$bvToast.toast(
                'Shareable video link is sent to the customer',
                {
                  title: 'Send Status',
                  variant: 'info',
                  solid: true
                }
              )
              axios({
                method: 'GET',
                url: this.backendURL + 'video/logs',
                params: {
                  id: this.$msal.data.user.userName
                },
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: sessionStorage.getItem('msal.idtoken')
                },
                responseType: 'json'
              }).then(
                (result) => {
                  this.$store.videoLogs = JSON.parse(result.data.body)
                })
            },
            (error) => {
              console.error(error)
              this.$bvModal.hide('modal-share-video')
              this.$bvToast.toast('Failed to send, please try again', {
                title: 'Send Status',
                variant: 'danger',
                solid: true
              })
            }
          )
        })
      })
    },
    showSnapshot (instanceId) {
      if (this.snapshotNbr > 2) {
        alert('SteelDemo does not allow more than 3 snapshots per user')
      } else {
        this.SELECT_INSTANCE(instanceId)
        this.$store.running_demos.map((obj) => {
          if (obj.demoId === instanceId) {
            const demoPurposeRecord = {
              demo_purpose: obj.demo_purpose || '',
              demo_purpose_customer_name: obj.demo_purpose_customer_name || '',
              demo_purpose_customer_url: obj.demo_purpose_customer_url || ''
            }
            this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
          }
          return this.UPDATE_CREATE_SNAPSHOT(true)
        })
      }
    },
    openAlert (ip) {
      this.UPDATE_IP_ADDRESS(ip)
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    openUserCred (user, password, subnet) {
      this.showUserCredModal = true
      this.userName = user
      this.password = password
      this.subnet = subnet
    },
    closeUserCredModal () {
      this.showUserCredModal = false
      this.userName = ''
      this.password = ''
      this.subnet = ''
    },
    togglePassword: function () {
      const codeToCopy = document.querySelector('#copy-code')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copyPassword = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    toggleSubnet: function () {
      const codeToCopy = document.querySelector('#copy-code-subnet')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copySubnet = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    copyUsername: function () {
      const codeToCopy = document.querySelector('#copy-code-username')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copyUserName = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    enterUserName: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copyUserName = false
      }, 3000) // hide the message after 3 seconds
    },
    enterPassword: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copyPassword = false
      }, 3000) // hide the message after 3 seconds
    },
    enterSubnet: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copySubnet = false
      }, 3000) // hide the message after 3 seconds
    },
    updateStatus () {
      const refDemos = this.firestoreDB.collection('demos')
      const queryDemos = refDemos.where('username', '==', this.mailUserName)
      queryDemos.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.running_list.push(change.doc.data())
          }
          if (change.type === 'modified') {
            const index = this.running_list.findIndex(
              (e) => e.demoId === change.doc.data().demoId
            )
            if (index === -1) {
              this.running_list.push(change.doc.data())
            } else {
              this.running_list[index] = change.doc.data()
            }
          }
          if (change.type === 'removed') {
            this.running_list.pop(change.doc.data())
            if (this.scenario.scenarioId === change.doc.data().scenarioId) {
              this.scenario.inProcess = true
              this.scenario.status = ''
            }
          }
        })
        this.$store.running_demos = this.running_list
        this.$store.running_demos.map((record) => {
          if (!record.snapshotId) {
            if (this.scenario.scenarioId === record.scenarioId) {
              const scenario = this.scenario
              if (
                (scenario.demoType !== 'Lab' &&
                  record.status === 'User Created') ||
                record.status === 'Topology Created' ||
                record.status === 'User Session Created' ||
                record.status === 'Org User Created' ||
                record.status === 'Instance State UP'
              ) {
                scenario.status = record.status
                scenario.url = record.url
                scenario.ip_address = record.ip_address
                scenario.wvd_url = record.wvd_url
                scenario.demoId = record.demoId
                scenario.script = record.script
                scenario.duration = record.duration
                if (
                  (record.login && record.password) ||
                  (record.login !== 'N/A' && record.password !== 'N/A')
                ) {
                  scenario.user = record.login
                  scenario.password = record.password
                }
                if (
                  record.subnet &&
                  record.subnet !== 'N/A' &&
                  record.subnet !== null
                ) {
                  scenario.subnet = record.subnet
                }
                scenario.inProcess = false
              } else {
                scenario.inProcess = true
                scenario.status = record.status
              }
              this.scenario = scenario
            }
          }
          return true
        })
      })
    },
    copyDemoUrlLink: function (el, done) {
      const that = this
      setTimeout(function () {
        that.isCopySuccessful = false
      }, 3000) // hide the message after 3 seconds
    },
    copyDemoUrl (type, year, id) {
      const input = document.createElement('input')
      document.body.appendChild(input)
      let demoType = ''
      if (type === 'Lab') demoType = 'S'
      else demoType = 'D'
      const yearNumber = year ? year.slice(year.length - 2) : ''
      input.value =
        window.location.origin +
        '/demos/' +
        demoType +
        yearNumber +
        id.slice(id.length - 3)
      input.focus()
      input.select()
      this.isCopySuccessful = document.execCommand('copy')
      input.remove()
      if (!this.isCopySuccessful) {
        console.error('Failed to copy text.')
      }
      this.$nextTick(() => {
        window.scrollTo(0, 0)
      })
    },
    getDate (date) {
      const dt = moment(date, 'DD-MM-YYYY')
      return dt.format('LL')
    },
    getScenarioMapId (type, year, id) {
      let demoType = ''
      if (type === 'Lab') demoType = 'ID: S'
      else demoType = 'ID: D'
      const yearNumber = year ? year.slice(year.length - 2) : ''
      return demoType + yearNumber + id.slice(id.length - 3)
    },
    playVideo () {
      this.player.playVideo()
    },
    getId (videoUrl) {
      return this.$youtube.getIdFromUrl(videoUrl)
    },
    // collects all parameters and call the deploy URL
    deployScenario () {
      this.input.scenarioId = this.scenario.scenarioId
      this.input.duration = this.duration * 3600 // convert the duration in s
      this.input.demo_purpose = this.selectedDemoPurpose
      this.input.demo_purpose_customer_name = this.customerName
      this.input.demo_purpose_customer_url = this.customerLink
      // if the demo has been scheduled, we load the time
      if (this.dateToPick !== moment().format('YYYY-MM-DD HH:mm')) {
        this.input.date = moment(this.dateToPick).unix() // send Epoch Time
        if (this.input.date < moment().unix()) {
          this.feedback = 'The demo cannot be deployed in the past'
          return
        }
        if (this.input.date < moment().unix() + 3600) {
          this.feedback = 'The demo must be planned at least 1 hour ahead'
          return
        }
        // if there is already a demo in the same category planned at the same deploy, we can't deploy
        if (
          this.checkPlannedDemos(
            this.category,
            this.input.date,
            this.input.duration
          )
        ) {
          this.feedback =
            'You cannot planned two demos of the same category running at the same time'
          return
        }
      } else {
        this.input.date = null
        // if there is already a demo in the same category, we can't deploy
        if (this.checkRunningDemos(this.category)) {
          this.feedback = 'You cannot have two demos at the same time'
          return
        }

        if (this.input.demo_purpose === '') {
          this.feedback =
            'Demo purpose is a required value.  Please make a selection before deploying the demo.'
          return
        }
        if (
          this.input.demo_purpose === 'customerDemos' &&
          this.input.demo_purpose_customer_name === ''
        ) {
          this.feedback = 'Please mention the customer name'
          return
        }
      }
      this.input.username = this.mailUserName
      this.input.firstName = this.mailUserName
      this.input.lastName = this.mailUserName
      this.input.emailAddress = this.$msal.data.user.userName
      this.input.mode = this.deployMode
      this.input.category = this.category
      console.log(this.input)
      axios({
        method: 'POST',
        url: this.backendURL + 'topology/create',
        data: this.input,
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        // crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.response = result.data
          this.$bvToast.toast('Please wait while deploying the demo', {
            title: 'Deploy Status',
            variant: 'info',
            solid: true
          })
          this.scenario.status = 'Deploying'
        },
        (error) => {
          console.error(error)
          this.$bvToast.toast(
            'Error while deploying the demo, Please report an incident in support',
            {
              title: 'Deploy Status',
              variant: 'danger',
              solid: true
            }
          )
        }
      )
      // reset category so main menu disappears
      this.SELECT_CATEGORY('')

      // close scenario detail frame
      window.scrollTo(0, 0)
    },
    redirectToDemo (link, user, password) {
      window.open(link, '_blank')
    },
    showScript (script) {
      if (this.showScriptClass === '' || this.showScriptClass === 'dismiss') {
        this.showScriptClass = 'selected'
        document
          .getElementById('demo-script-iframe')
          .contentWindow.location.reload(true)
      } else {
        this.showScriptClass = 'dismiss'
      }
    },
    openScriptInTab (script) {
      window.open(script, '_blank')
    },
    onCloseInstructions () {
      this.showScriptClass = 'dismiss'
    },
    closeScenario () {
      scenarioStore.reset()
      this.scenario = ''
      this.showScriptClass = ''
      this.scenarioId = ''
      this.feedback = ''
      this.selected = true
      this.selectedDemoPurpose = ''
      this.showScriptClass = ''
      this.demoScriptHtml = ''
      this.isCopySuccessful = false
      this.customerName = ''
      this.customerLink = ''
    },
    // check whether a demo is already running for a particular category
    checkRunningDemos (category) {
      if (this.$store.running_demos === null) return false
      if (this.$store.running_demos.length > 0) return true
      else return false
    },
    // check whether a demo is already planned at the same time
    checkPlannedDemos (category, startDate, duration) {
      const categoryList = this.$store.planned_demos
        ? this.$store.planned_demos.filter(function (demos) {
          return demos.category === category
        })
        : null
      const endDate = startDate + duration * 3600
      if (categoryList === null) return false
      categoryList.forEach(function (demo) {
        const start = demo.starting_timestamp
        const end = start + demo.duration * 3600
        if (
          (startDate < start && end < endDate) ||
          (startDate > start && end < endDate) ||
          (startDate > start && end > endDate) ||
          (startDate < start && end > endDate)
        ) {
          return true
        }
      })
      return false
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
    if (this.$store.signature) { this.videoShareForm.signature = this.$store.signature }
    this.updateStatus()
  }
}
</script>
<style>
.btn-video-pre {
  width: 15% !important;
  margin-left: 10px !important;
  background-color: #fff !important;
  color: #ff681d !important;
  border-color: #ff681d !important;
  margin-top: 10px !important;
}
.btn-video-pre:hover {
  background-color: #ff681d !important;
  color: white !important;
}
.btn-video-preview {
  background-color: #5fb4e4 !important;
  color: white !important;
  border-color: #5fb4e4 !important;
  margin-top: 10px !important;
  margin-right: 25px !important;
  width: 13% !important;
  margin-left: auto !important;
}
.btn-video-preview:hover {
  background-color: white !important;
  color: #5fb4e4 !important;
}

.tool-tip:hover {
  color: #ff681d !important;
}
.pop-up {
  position: absolute;
  width: 650px;
  height: 550px;
  border: 1px solid black;
  top: calc((100% - 550px) / 2);
  left: calc((100% - 650px) / 2);
}

.breadcrumb {
  height: 60px;
}

.pop-up-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 60px);
  align-items: center;
}

.pop-up-view {
  width: 100%;
}

.video-pop-svg-left {
  width: 5%;
  cursor: pointer;
  margin-right: 15px;
  transform: scaleX(-1);
  margin-left: -22px;
}

.video-pop-svg-right {
  width: 5%;
  cursor: pointer;
  margin-left: 38px;
}
.demo-link {
  padding-top: 15px;
}
.demo-link > button {
  margin-right: 15px !important;
  font-size: 12px !important;
  margin-top: 10px;
}
.demo-link > button > i {
  font-size: 16px !important;
}
.toast {
  display: block !important;
  padding: 0px !important;
  color: black !important;
  margin: 0px !important;
  top: 0px !important;
}
.right-slide {
  display: none;
  z-index: 999;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  position: fixed;
  width: 40%;
  top: 0px;
  right: 0;
  /* -webkit-animation: slide 1s forwards;
  animation: slide 1s forwards; */
  text-align: center;
  background: white;
  height: 100vh;
  border: 0 solid #aaafb8;
  box-shadow: 0px 4px 8px #00000080;
}
.right-slide-header {
  position: relative;
  height: 35px;
  width: 100%;
  padding: 8px 13px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: rgba(245, 248, 248, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.right-slide-header-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 20px;
  width: 250px;
  line-height: 20px;
  vertical-align: middle;
  padding: 0 !important;
  font-size: 16px;
  text-align: left;
  margin-left: 7px !important;
  text-transform: uppercase;
  font-weight: 500;
  color: black;
}
.right-slide-header-icons {
  right: 15px;
  position: absolute;
  top: 6px;
}
li.mx-time-item {
  display: list-item !important;
}
.selected {
  display: block;
  animation: slide-in 1s forwards;
  -webkit-animation: slide-in 1s forwards;
}

.dismiss {
  display: block;
  animation: slide-out 1s forwards;
  -webkit-animation: slide-out 1s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
.scenarioDetails {
  background: rgba(0, 50, 50, 0.4);
  background: white;
  animation: 0.5s grow;
  animation-fill-mode: forwards;
  padding: 20px;
  margin: 20px 15px !important;
}

@keyframes grow {
  0% {
    transform: scale(0);
    background: #ff681d;
  }
  100% {
    transform: scale(1);
    background: white;
    height: auto;
    margin: 45px;
  }
  /* 10% {
    background: #ff681d;
  }
  30% {
    background: #ff681d5e;
  }
  70% {
    background: #ff681d2b;
  }
  100% {
    height: 100vh;
    margin: 45px;
    width: auto;
    background: white;
  } */
}

.x-close {
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 42px;
}

.card {
  background: #fffff9;
}

.col-4-custom {
  content: "";
  flex: 0 0 auto;
  animation: 0.5s fadeIn;
  animation-fill-mode: both;
  visibility: hidden;
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.field_deploy .btn {
  margin-top: 10px;
  background-color: #ff681d;
  color: white;
}

.field_deploy .btn:hover {
  color: #ff681d;
  background-color: #fff !important;
  border-color: #ff681d !important;
}

.field_copy_link {
  padding: 0px 0px 15px 0px;
}

.field_copy_link .btn {
  color: #ff681d;
  background-color: #fff;
  border-color: #ff681d;
  font-size: 12px;
  height: auto;
}

.field_copy_link .btn:hover {
  background-color: #ff681d !important;
  color: white !important;
}
.field_copy_link .video_button {
  background-color: #ff681d !important;
  color: white !important;
}

.field_copy_link .video_button:hover {
  color: #ff681d !important;
  background-color: #fff !important;
}
.scenarioDetails .details {
  background-color: white;
  width: 700px;
  height: auto;
  overflow: visible;
  border-radius: 10px;
  position: absolute;
  color: #ffffff;
  z-index: 1000;
  padding: 20px;
  top: 20px;
}

.scenarioDetails .details .title {
  font-size: 20px;
  color: rgb(156, 155, 155);
}

.scenarioDetails .details img {
  width: 400px;
  height: auto;
  margin-bottom: 0%;
}

.scenarioDetails .details h3 {
  font-size: 14px;
  color: grey;
  margin: 0;
}

.scenarioDetails .details .description {
  font-size: 14px;
  color: grey;
  margin: 0;
}

.scenarioDetails .details .description p {
  padding-top: 5px;
  font-size: 12px;
  color: slategrey;
}
.scenarioDetails .script i {
  color: #5b7f95;
  font-size: 40px;
  cursor: pointer;
}

.scenarioDetails .deployParameters .duration_and_mode {
  display: inline-flex;
  width: 100%;
}

.scenarioDetails .deployParameters label {
  font-size: 14px;
  color: grey;
}

.scenarioDetails .deployParameters input {
  font-size: 14px;
  color: grey;
  font-weight: bold;
}

.scenarioDetails .deployParameters .duration {
  width: 150%;
  padding-top: 0;
}

.scenarioDetails .deployParameters .duration input {
  width: 1em;
  margin-right: 0;
}

.scenarioDetails .deployParameters .feedback {
  color: red;
  font-weight: bold;
}

.feedback {
  color: red;
  font-weight: bold;
}

.scenarioDetails .deployParameters .increment {
  background-color: #5b7f95;
  color: white;
}

.scenarioDetails .deployParameters .decrement {
  background-color: #6bb5e4;
  color: white;
}

.scenarioDetails .deployParameters .deployMode {
  padding-top: 12px;
  width: 80%;
  display: inline-flex;
}

.scenarioDetails .deployParameters .v-switch-label {
  font-size: 10px;
}

.scenarioDetails .deploy button {
  margin-top: 10px;
  background-color: #ff6900;
}

.scenarioDetails .credentials {
  font-size: 12px;
  font-weight: bold;
  color: #5b7f95;
}

.scenarioDetails .credentials input {
  font-size: 12px;
  width: 18em;
  font-weight: bold;
  color: #5b7f95;
  background-color: 0;
  outline: none;
  margin-bottom: 0;
}

.scenarioDetails .credentials i {
  font-size: 14px;
  cursor: pointer;
}

.scenarioDetails .close {
  position: absolute;
  right: 5px;
  top: 16px;
  color: #aaa;
  font-size: 1.4em;
  cursor: pointer;
}

.modal {
  background: transparent !important;
  box-shadow: none !important;
  max-height: 100%;
}

.modal-dialog {
  max-width: 700px !important;
}

.modal-share-video {
  max-width: 840px !important;
}

.modal-content1 {
  position: fixed !important;
  display: -ms-flexbox;
  display: block !important;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 700px !important;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  margin-top: 15%;
}

.badge-custom-details {
  margin-top: -12px;
  width: 65px !important;
  color: #fff !important;
  background-color: #5fb4e4 !important;
  margin-left: 1px !important;
  font-size: 0.8vw !important;
  font-size: 10px !important;
  height: 22px !important;
  font-weight: 400 !important;
  min-width: 4.5rem !important;
  display: block;
  padding: 2px 0px 0px 6px;
  float: left !important;
}
.featured-details {
  background-color: #ff681d !important;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.form-control.focus {
  font-size: 1rem !important;
  padding: 0px !important;
  margin: 0px !important;
  border-color: rgb(0 0 0 / 63%) !important;
  box-shadow: 1px 0px 0px 0rem rgb(0 0 0 / 17%) !important;
}
.demo-link > a {
  margin-right: 20px !important;
  font-size: 12px !important;
  margin-top: 10px;
}
</style>
