var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "snapshotCreation" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "content-class": "snapshotCreation",
            title: "Snapshot Creation"
          },
          on: {
            hide: function($event) {
              return _vm.closeSnapshot()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _vm.snapshotNbr < 3
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-user-cred",
                          attrs: { size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.snapshotCreation()
                            }
                          }
                        },
                        [_vm._v(" Create ")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showSnapshot,
            callback: function($$v) {
              _vm.showSnapshot = $$v
            },
            expression: "showSnapshot"
          }
        },
        [
          _c("p", [
            _vm._v(
              " Make sure all your nodes are shutdown on GNS3 before creating a snapshot "
            )
          ]),
          _vm.snapshotNbr < 3
            ? _c("div", { staticClass: "snapText" }, [
                _c("div", { staticClass: "name" }, [
                  _c("p", [_vm._v("Name:")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.snapshotName,
                        expression: "snapshotName"
                      }
                    ],
                    staticClass: "w3-input w3-border-0",
                    attrs: {
                      type: "text",
                      placeholder: "Name of the snapshot"
                    },
                    domProps: { value: _vm.snapshotName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.snapshotName = $event.target.value
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "description" }, [
                  _c("p", [_vm._v("Description:")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.snapshotDescription,
                        expression: "snapshotDescription"
                      }
                    ],
                    attrs: {
                      placeholder: "add a description to your snapshot"
                    },
                    domProps: { value: _vm.snapshotDescription },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.snapshotDescription = $event.target.value
                      }
                    }
                  })
                ]),
                _vm.feedback
                  ? _c("p", { staticClass: "feedback" }, [
                      _vm._v(_vm._s(_vm.feedback))
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }