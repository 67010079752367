var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row m-0 p-0" },
    [
      _c(
        "div",
        {
          staticClass: "side-nav-layout",
          staticStyle: { "margin-top": "10px" }
        },
        [
          _c("div", { staticClass: "side-nav" }, [
            _c(
              "div",
              {
                class: _vm.navItemDashboard,
                on: { click: _vm.showDashboardClick }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "font-size": "25px" }
                  },
                  [_vm._v("dashboard")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "custom-dashboard",
                    staticStyle: { "margin-top": "-2px" }
                  },
                  [_vm._v("Dashboard")]
                )
              ]
            ),
            _vm.managerDashboardViewer
              ? _c(
                  "div",
                  {
                    class: _vm.navItemManagerDashboard,
                    on: { click: _vm.showManagerDashboardClick }
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { "font-size": "25px" }
                      },
                      [_vm._v("groups")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "custom-dashboard",
                        staticStyle: { "margin-top": "-2px" }
                      },
                      [_vm._v("Mngr Dashboard")]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: _vm.navItemScenarios,
                on: { click: _vm.showScenariosClick }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "font-size": "25px" }
                  },
                  [_vm._v("emoji_objects")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "custom-dashboard",
                    staticStyle: { "margin-top": "-3px" }
                  },
                  [_vm._v("Solution Demos")]
                )
              ]
            ),
            _c(
              "div",
              {
                class: _vm.navItemFeature,
                on: { click: _vm.showFeatureClick }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    staticStyle: { "font-size": "25px" }
                  },
                  [_vm._v("featured_video")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "custom-dashboard",
                    staticStyle: { "margin-top": "-3px" }
                  },
                  [_vm._v("Feature Demos")]
                )
              ]
            ),
            !this.$msal.data.user.userName.startsWith("demo-")
              ? _c(
                  "div",
                  {
                    class: _vm.navItemSandboxLabs,
                    on: { click: _vm.showSandboxLabsClick }
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: {
                          "font-size": "35px",
                          "margin-left": "-4px"
                        }
                      },
                      [_vm._v("biotech")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "custom-dashboard",
                        staticStyle: {
                          "margin-top": "2px",
                          "margin-left": "5px !important"
                        }
                      },
                      [_vm._v("Sandbox Labs")]
                    )
                  ]
                )
              : _vm._e(),
            !this.$msal.data.user.userName.startsWith("demo-")
              ? _c(
                  "div",
                  {
                    class: _vm.navItemSnapshot,
                    on: { click: _vm.showSnapshotClick }
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { "font-size": "25px" }
                      },
                      [_vm._v("photo_camera")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "custom-dashboard",
                        staticStyle: { "margin-top": "-2px" }
                      },
                      [
                        _vm._v("Snapshots "),
                        _c("span", { staticClass: "badge badge-info" }, [
                          _vm._v(_vm._s(_vm.snapshot_list.length))
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "content-layout",
          staticStyle: { "border-left": "1px solid #e3e3e3" }
        },
        [
          _c("keep-alive", [_c(_vm.activeTab, { tag: "component" })], 1),
          _vm.showSnapshot
            ? _c(
                "div",
                { staticClass: "table" },
                [
                  _c("loading", {
                    attrs: {
                      active: _vm.isLoading,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  }),
                  _c("SnapshotList", { attrs: { data: _vm.snapshot_list } })
                ],
                1
              )
            : _vm._e(),
          _vm.startSnapshot
            ? _c("StartSnapshot", { attrs: { snapshot: _vm.snapshotId } })
            : _vm._e(),
          _vm.deleteSnapshot
            ? _c("DeleteSnapshot", { attrs: { snapshot: _vm.snapshotId } })
            : _vm._e(),
          _vm.createSnapshot
            ? _c("CreateSnapshot", {
                attrs: {
                  instance: _vm.instance,
                  demoPurposeRecord: _vm.demoPurposeRecord
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("Chatbot"),
      _c(
        "div",
        [
          _c(
            "b-modal",
            {
              ref: "modalUserInfo",
              attrs: {
                id: "modalUserInfo",
                scrollable: "",
                "cancel-disabled": true,
                "no-close-on-backdrop": true,
                "no-close-on-esc": true,
                "no-enforce-focus": true,
                "hide-header-close": true
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-header",
                  fn: function() {
                    return [
                      _c("div", [
                        _c("h3", { staticStyle: { color: "#ff681d" } }, [
                          _vm._v("Welcome to Steeldemo")
                        ]),
                        _c("p", { staticStyle: { "font-size": "17px" } }, [
                          _vm._v(
                            " Tell us more about youself for a better experience "
                          )
                        ])
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "modal-footer",
                  fn: function() {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-primary btn-custom btn-user-cred",
                          staticStyle: {
                            "margin-right": "41% !important",
                            width: "17% !important"
                          },
                          attrs: { size: "sm" },
                          on: { click: _vm.onUserInfoSubmit }
                        },
                        [_vm._v(" SUBMIT ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.modalShow,
                callback: function($$v) {
                  _vm.modalShow = $$v
                },
                expression: "modalShow"
              }
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onUserInfoSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-1",
                        label: "Full Name:",
                        "label-for": "input-1",
                        "label-cols-sm": "3",
                        "label-cols-lg": "4",
                        "content-cols-sm": "",
                        "content-cols-lg": "8"
                      }
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true, min: 3 },
                            expression: "{ required: true, min: 3 }"
                          }
                        ],
                        attrs: {
                          id: "input-1",
                          name: "input-1",
                          placeholder: "Please enter full name",
                          "aria-describedby": "input-1-live-feedback",
                          "data-vv-as": "Name",
                          state: _vm.validateState("input-1")
                        },
                        model: {
                          value: _vm.userForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "name", $$v)
                          },
                          expression: "userForm.name"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-1-live-feedback" } },
                        [_vm._v(_vm._s(_vm.veeErrors.first("input-1")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-2",
                        label: "Organisation:",
                        "label-for": "input-2",
                        "label-cols-sm": "3",
                        "label-cols-lg": "4",
                        "content-cols-sm": "",
                        "content-cols-lg": "8"
                      }
                    },
                    [
                      _c("b-form-radio-group", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }"
                          }
                        ],
                        staticClass: "user-employee-option",
                        attrs: {
                          id: "input-2",
                          name: "input-2",
                          options: _vm.userOrganisationOptions,
                          state: _vm.validateState("input-2"),
                          "aria-describedby": "input-2-live-feedback",
                          "data-vv-as": "Organisation",
                          size: "lg"
                        },
                        model: {
                          value: _vm.userForm.userOrganisationOptions,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.userForm,
                              "userOrganisationOptions",
                              $$v
                            )
                          },
                          expression: "userForm.userOrganisationOptions"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-2-live-feedback" } },
                        [_vm._v(_vm._s(_vm.veeErrors.first("input-2")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-3",
                        label: "Role:",
                        "label-for": "input-3",
                        "label-cols-sm": "3",
                        "label-cols-lg": "4",
                        "content-cols-sm": "",
                        "content-cols-lg": "8"
                      }
                    },
                    [
                      _c("b-form-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }"
                          }
                        ],
                        attrs: {
                          id: "input-3",
                          name: "input-3",
                          options: _vm.userRoleDropDown,
                          state: _vm.validateState("input-3"),
                          "aria-describedby": "input-3-live-feedback",
                          disabled:
                            _vm.userForm.userOrganisationOptions == "riverbed"
                              ? false
                              : true,
                          "data-vv-as": "Role"
                        },
                        model: {
                          value: _vm.userForm.userRoleDropDown,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "userRoleDropDown", $$v)
                          },
                          expression: "userForm.userRoleDropDown"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-3-live-feedback" } },
                        [_vm._v(_vm._s(_vm.veeErrors.first("input-3")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-4",
                        label: "Manager Name:",
                        "label-for": "input-4",
                        "label-cols-sm": "3",
                        "label-cols-lg": "4",
                        "content-cols-sm": "",
                        "content-cols-lg": "8"
                      }
                    },
                    [
                      _c("b-form-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }"
                          }
                        ],
                        attrs: {
                          id: "input-4",
                          options: _vm.userManagerDropDown,
                          name: "input-4",
                          state: _vm.validateState("input-4"),
                          "aria-describedby": "input-4-live-feedback",
                          "data-vv-as": "Manager Name",
                          disabled:
                            _vm.userForm.userOrganisationOptions == "riverbed"
                              ? false
                              : true
                        },
                        model: {
                          value: _vm.userForm.userManagerDropDown,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "userManagerDropDown", $$v)
                          },
                          expression: "userForm.userManagerDropDown"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-4-live-feedback" } },
                        [_vm._v(_vm._s(_vm.veeErrors.first("input-4")))]
                      )
                    ],
                    1
                  ),
                  _vm.userForm.userManagerDropDown == "Other"
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-5",
                            "label-for": "input-5",
                            "label-cols-sm": "3",
                            "label-cols-lg": "4",
                            "content-cols-sm": "",
                            "content-cols-lg": "8"
                          }
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true, min: 3 },
                                expression: "{ required: true, min: 3 }"
                              }
                            ],
                            attrs: {
                              id: "input-5",
                              name: "input-5",
                              placeholder: "Please enter manager name",
                              "aria-describedby": "input-5-live-feedback",
                              "data-vv-as": "Manager Name",
                              state: _vm.validateState("input-5")
                            },
                            model: {
                              value: _vm.userForm.otherManagerName,
                              callback: function($$v) {
                                _vm.$set(_vm.userForm, "otherManagerName", $$v)
                              },
                              expression: "userForm.otherManagerName"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-5-live-feedback" } },
                            [_vm._v(_vm._s(_vm.veeErrors.first("input-5")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-6",
                        label: "Geo Location:",
                        "label-for": "input-6",
                        "label-cols-sm": "3",
                        "label-cols-lg": "4",
                        "content-cols-sm": "",
                        "content-cols-lg": "8"
                      }
                    },
                    [
                      _c("b-form-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }"
                          }
                        ],
                        attrs: {
                          id: "input-6",
                          options: _vm.userGeoDropDown,
                          name: "input-6",
                          state: _vm.validateState("input-6"),
                          "aria-describedby": "input-6-live-feedback",
                          "data-vv-as": "Geo Location"
                        },
                        model: {
                          value: _vm.userForm.userGeoDropDown,
                          callback: function($$v) {
                            _vm.$set(_vm.userForm, "userGeoDropDown", $$v)
                          },
                          expression: "userForm.userGeoDropDown"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-6-live-feedback" } },
                        [_vm._v(_vm._s(_vm.veeErrors.first("input-6")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }