<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartLabels', 'chartData'],

  data () {
    return {
      gradient: null
    }
  },

  mounted () {
    let sum = 0
    this.chartData.map((data) => {
      sum += parseInt(data)
      return sum
    })

    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(255, 104, 29, 0.5)')
    this.gradient.addColorStop(0.5, 'rgba(255, 104, 29, 0.25)')
    this.gradient.addColorStop(1, 'rgba(255, 104, 29, 0)')

    this.renderChart({
      labels: this.chartLabels,
      datasets: [
        {
          label:
              'Total (' +
          sum +
          ')',
          borderColor: this.gradient,
          pointBackgroundColor: this.gradient,
          borderWidth: 1,
          pointBorderColor: 'white',
          backgroundColor: this.gradient,
          data: this.chartData
        }
      ]
    }, {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label',
        callbacks: {
          title: function (tooltipItems, data) {
            return tooltipItems[0].label
          },
          label: function (tooltipItem, data) {
            return 'Total: ' + tooltipItem.yLabel
          }
        }
      }
    })
  },
  watch: {
    chartData () {
      this.$data._chart.update()
    },
    chartLabels () {
      this.$data._chart.update()
    }
  }
}
</script>
