<template>
  <div class="deleteSnapshot">
    <div class="snap card">
      <div class="snap-content">
        <i class="material-icons close" v-on:click="closeSnapshot()">clear</i>
        <div class="title">Snapshot Deletion</div>
        <div class="snapText" style="text-align: center">
          <div class="name">
            <p>Please confirm you want to delete '{{ snapshotName }}'.</p>
            <form @submit.prevent="deleteSnapshot()">
              <div class="field deploy">
                <button class="btn">Confirm Deletion</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'deleteSnapshot',
  data () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      input: {
        snapshotId: ''
      }
    }
  },
  props: {
    snapshot: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['snapshotName'])
  },
  methods: {
    deleteSnapshot () {
      this.input.snapshotId = this.snapshot

      axios({
        method: 'DELETE',
        url: this.backendURL + 'snapshot',
        data: this.input,
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        // crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.response = result.data
        },
        (error) => {
          console.error(error)
        }
      )
      this.closeSnapshot()
    },
    ...mapMutations([
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_SNAPSHOT_NAME',
      'UPDATE_DELETE_SNAPSHOT'
    ]),
    closeSnapshot () {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_DELETE_SNAPSHOT(false)
    }
  }
}
</script>

<style>
.deleteSnapshot {
  /* display: inline-table; */
  margin-left: 30%;
  text-align: left;
  margin-top: -15%;
}

.deleteSnapshot .snap {
  background-color: white;
  width: 350px;
  height: auto;
  overflow: visible;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
  padding: 20px;
  font-family: "Source Sans Pro", sans-serif;
  color: slategrey;
}

.deleteSnapshot .snap .title {
  font-size: 20px;
  color: rgb(156, 155, 155);
  text-align: center;
  font: bold;
}

.deleteSnapshot .snap .deploy button {
  margin-top: 10px;
  /* margin-left: 25%; */
  /* width:50%; */
  background-color: #ff6900;
}

.deleteSnapshot .close {
  position: absolute;
  right: 5px;
  top: 16px;
  color: #aaa;
  font-size: 1.4em;
  cursor: pointer;
}
</style>
