var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row m-0 p-0" }, [
    _c(
      "div",
      {
        staticClass: "row parent-dashboard",
        staticStyle: { "box-shadow": "none" }
      },
      [
        _c(
          "div",
          {
            staticClass: "row chart-row",
            staticStyle: { "margin-top": "5px", "margin-bottom": "5px" }
          },
          [
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _vm.memberOfDashboardViewers
                ? _c(
                    "div",
                    { staticStyle: { float: "left" } },
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "m-2 manager-dropdown",
                          attrs: {
                            variant: "outline-danger",
                            "split-variant": "outline-danger",
                            offset: "25",
                            text: _vm.managerListDropDown.selectedOption
                          },
                          model: {
                            value: _vm.managerListDropDown.selectedOption,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.managerListDropDown,
                                "selectedOption",
                                $$v
                              )
                            },
                            expression: "managerListDropDown.selectedOption"
                          }
                        },
                        _vm._l(_vm.managerListDropDown.options, function(
                          option
                        ) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: option.value,
                              attrs: { value: option.value },
                              on: {
                                click: function($event) {
                                  return _vm.changeMangerDropValue(option.value)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "pull-right",
                  staticStyle: { background: "#fff", float: "right" },
                  attrs: { id: "reportrange" }
                },
                [
                  _c("date-range-picker", {
                    attrs: {
                      startDate: _vm.startDate,
                      endDate: _vm.endDate,
                      "locale-data": _vm.locale,
                      ranges: _vm.ranges,
                      maxDate: _vm.maxEndDate,
                      opens: "left"
                    },
                    on: { update: _vm.showDashboard },
                    scopedSlots: _vm._u([
                      {
                        key: "input",
                        fn: function(picker) {
                          return _c("div", {}, [
                            _c("i", {
                              staticClass:
                                "glyphicon glyphicon-calendar fa fa-calendar"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("dateFormat")(picker.startDate)) +
                                " - " +
                                _vm._s(_vm._f("dateFormat")(picker.endDate)) +
                                " "
                            )
                          ])
                        }
                      }
                    ]),
                    model: {
                      value: _vm.dateRange,
                      callback: function($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange"
                    }
                  }),
                  _c("b", { staticClass: "caret" })
                ],
                1
              ),
              _c(
                "small",
                {
                  staticStyle: {
                    color: "grey",
                    "font-size": "0.9rem",
                    "font-weight": "700",
                    float: "right",
                    "margin-right": "20px",
                    "margin-top": "8px"
                  }
                },
                [_vm._v("Please select date range for the dashboard")]
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "row chart-row parent-dashboard" },
          [
            _c(
              "b-container",
              { staticClass: "bv-example-row" },
              [
                _c(
                  "b-row",
                  { staticClass: "justify-content-md-center" },
                  [
                    _c("b-col", {
                      staticStyle: {
                        "background-color": "#ff681d",
                        "margin-top": "9px",
                        "margin-bottom": "7px"
                      },
                      attrs: { col: "", lg: "4" }
                    }),
                    _c(
                      "b-col",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: { cols: "12", md: "auto" }
                      },
                      [_vm._v("Individual Activity")]
                    ),
                    _c("b-col", {
                      staticStyle: {
                        "background-color": "#ff681d",
                        "margin-top": "9px",
                        "margin-bottom": "7px"
                      },
                      attrs: { col: "", lg: "4" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-70",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: {
                            padding: "20px",
                            position: "relative",
                            "max-height": "445px"
                          },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _vm.reporteesName.length > 0
                            ? _c("ReporteeBarChart", {
                                attrs: {
                                  "chart-labels": _vm.reporteesName,
                                  "chart-data": _vm.reporteesRecord,
                                  options: _vm.createReporteesBarOption(),
                                  styles: {
                                    height: "415px !important",
                                    position: "relative"
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("loading", {
                            attrs: {
                              active:
                                _vm.reporteesRecord.length > 0 ? false : true,
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-30",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _c("div", { staticClass: "row x_title" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "1.35rem",
                            "margin-left": "20px !important"
                          }
                        },
                        [
                          _c("h3", { staticClass: "graph-name" }, [
                            _vm._v(" Reportee Demo Activity "),
                            _c("small", [
                              _vm._v(
                                " (" + _vm._s(_vm.reporteeNameDetails) + ")"
                              )
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: {
                            padding: "0px",
                            position: "relative",
                            "max-height": "445px"
                          },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _vm.reporteePieDataSet.length > 0
                            ? _c("ReporteePieChart", {
                                attrs: {
                                  "chart-labels": Object.keys(
                                    _vm.reporteePieData
                                  ),
                                  "chart-data": _vm.reporteePieDataSet,
                                  options: _vm.createReporteePieOption(),
                                  styles: {
                                    height: "415px !important",
                                    position: "relative"
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("loading", {
                            attrs: {
                              active:
                                _vm.reporteePieDataSet.length > 0
                                  ? false
                                  : true,
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-100",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _c("div", { staticClass: "row x_title" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-md-12",
                          staticStyle: {
                            "font-size": "1.35rem",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "graph-name",
                              staticStyle: { "text-transform": "uppercase" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.reporteeNameDetails) + " "
                              )
                            ]
                          ),
                          _c("small")
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12 col-sm-12",
                        staticStyle: { "min-height": "445px" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "demo-placeholder",
                            staticStyle: {
                              padding: "0px",
                              position: "relative"
                            },
                            attrs: { id: "chart_plot_01" }
                          },
                          [
                            _c("b-table", {
                              attrs: {
                                striped: "",
                                hover: "",
                                responsive: "",
                                "sticky-header": "400px",
                                "no-border-collapse": true,
                                items: _vm.reporteeDetails
                              }
                            }),
                            _c("loading", {
                              attrs: {
                                active:
                                  _vm.reporteesRecord.length > 0 ? false : true,
                                "is-full-page": _vm.fullPage,
                                loader: _vm.loader,
                                color: _vm.color
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row chart-row parent-dashboard" },
          [
            _c(
              "b-container",
              { staticClass: "bv-example-row" },
              [
                _c(
                  "b-row",
                  { staticClass: "justify-content-md-center" },
                  [
                    _c("b-col", {
                      staticStyle: {
                        "background-color": "#ff681d",
                        "margin-top": "9px",
                        "margin-bottom": "7px"
                      },
                      attrs: { col: "", lg: "4" }
                    }),
                    _c(
                      "b-col",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: { cols: "12", md: "auto" }
                      },
                      [_vm._v("Team Activity")]
                    ),
                    _c("b-col", {
                      staticStyle: {
                        "background-color": "#ff681d",
                        "margin-top": "9px",
                        "margin-bottom": "7px"
                      },
                      attrs: { col: "", lg: "4" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-70",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _vm._m(1),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: { padding: "0px", position: "relative" },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _vm.lastSixMonth.length > 0
                            ? _c("UserActivityAreaChart", {
                                attrs: {
                                  "chart-labels": _vm.lastSixMonth,
                                  "chart-data": [
                                    _vm.lastSixMonthRecord,
                                    _vm.lastSixMonthRecordByUser,
                                    _vm.lastSixMonthRecordByPurposePractice,
                                    _vm.lastSixMonthRecordByPurposeCustomer
                                  ],
                                  options: _vm.createUserActivityChartOption(),
                                  styles: {
                                    height: "410px !important",
                                    position: "relative"
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("loading", {
                            attrs: {
                              active:
                                _vm.lastSixMonth.length > 0 ? false : true,
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-30",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _vm._m(2),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: { padding: "0px", position: "relative" },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _vm.pillars["SD-WAN"] > 0
                            ? _c("PillarsPieChart", {
                                attrs: {
                                  "chart-labels": Object.keys(_vm.pillars),
                                  "chart-data": Object.values(_vm.pillars),
                                  options: _vm.createPillarsOption(),
                                  styles: {
                                    height: "410px !important",
                                    position: "relative"
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("loading", {
                            attrs: {
                              active: _vm.pillars["SD-WAN"] > 0 ? false : true,
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-30",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _vm._m(3),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: { padding: "0px", position: "relative" },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _vm.scenarioListDataset.data.length > 0
                            ? _c("ScenarioPieChat", {
                                attrs: {
                                  "chart-labels": _vm.scenarioList,
                                  "chart-data": _vm.scenarioListDataset,
                                  options: _vm.createScenarioPieOption(),
                                  styles: {
                                    height: "410px !important",
                                    position: "relative"
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("loading", {
                            attrs: {
                              active:
                                _vm.scenarioList.length > 0 ? false : true,
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col-custom-70",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x_panel",
                    staticStyle: { "box-shadow": "none" }
                  },
                  [
                    _vm._m(4),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: { padding: "0px", position: "relative" },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _vm.lastSixMonth_for_demo.length > 0
                            ? _c("DemoBarChart", {
                                attrs: {
                                  "chart-labels": _vm.lastSixMonth_for_demo,
                                  "chart-data": _vm.lastSixMonthScenarios,
                                  options: _vm.createDemoBarOption(),
                                  styles: {
                                    height: "410px !important",
                                    position: "relative"
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("loading", {
                            attrs: {
                              active:
                                _vm.lastSixMonth.length > 0 ? false : true,
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row chart-row parent-dashboard" },
          [
            _c(
              "b-container",
              { staticClass: "bv-example-row" },
              [
                _c(
                  "b-row",
                  { staticClass: "justify-content-md-center" },
                  [
                    _c("b-col", {
                      staticStyle: {
                        "background-color": "#ff681d",
                        "margin-top": "9px",
                        "margin-bottom": "7px"
                      },
                      attrs: { col: "", lg: "4" }
                    }),
                    _c(
                      "b-col",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: { cols: "12", md: "auto" }
                      },
                      [_vm._v("Geo Activity")]
                    ),
                    _c("b-col", {
                      staticStyle: {
                        "background-color": "#ff681d",
                        "margin-top": "9px",
                        "margin-bottom": "7px"
                      },
                      attrs: { col: "", lg: "4" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c("div", { staticClass: "x_panel" }, [
                _c("div", { staticClass: "row x_title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-8",
                      staticStyle: {
                        "font-size": "1.35rem",
                        "padding-right": "0px"
                      }
                    },
                    [
                      _c("h3", { staticClass: "graph-name" }, [
                        _vm._v(" GEO Demos Activity"),
                        _c(
                          "small",
                          {
                            staticStyle: {
                              padding: "5px",
                              "font-size": "13px",
                              color: "#969090"
                            }
                          },
                          [
                            _vm._v(
                              "Records from July 16, 21 (Total " +
                                _vm._s(_vm.countryDataTotal) +
                                ")"
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-4",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pull-right",
                          staticStyle: { background: "#fff", float: "right" },
                          attrs: { id: "reportrange" }
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "m-2 cost-dropdown",
                              attrs: {
                                id: "dropdown-right",
                                right: "",
                                variant: "outline-danger",
                                "split-variant": "outline-danger",
                                offset: "25",
                                text: _vm.reporteeGeoDropDown.selectedOption
                              },
                              model: {
                                value: _vm.reporteeGeoDropDown.selectedOption,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.reporteeGeoDropDown,
                                    "selectedOption",
                                    $$v
                                  )
                                },
                                expression: "reporteeGeoDropDown.selectedOption"
                              }
                            },
                            _vm._l(_vm.reporteeGeoDropDown.options, function(
                              option
                            ) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: option.value,
                                  attrs: { value: option.value },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeReporteeGeoData(
                                        option.value
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "demo-placeholder",
                      staticStyle: { padding: "0px", position: "relative" },
                      attrs: { id: "chart_plot_01" }
                    },
                    [
                      _c("MapChart", {
                        attrs: {
                          countryData: _vm.countryData,
                          highColor: "rgba(232, 78, 2, .75)",
                          lowColor: "rgba(249, 210, 191, .75)",
                          countryStrokeColor: "#909090",
                          defaultCountryFillColor: "#dadada",
                          legendFontColorHeader: "rgba(238, 113, 55, .75)",
                          legendBorderCss: "1px solid #fff",
                          legendBoxShadowCss: "0px 0px 10px #fff",
                          showEmptyValue: false,
                          currencyAdd: false,
                          LangUser: "en"
                        }
                      }),
                      _c("loading", {
                        attrs: {
                          active:
                            Object.keys(_vm.countryData).length > 0
                              ? false
                              : true,
                          "is-full-page": _vm.fullPage,
                          loader: _vm.loader,
                          color: _vm.color
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        { staticClass: "col-md-10", staticStyle: { "font-size": "1.35rem" } },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" Reportee Activity"),
            _c(
              "small",
              {
                staticStyle: {
                  padding: "5px",
                  "font-size": "13px",
                  color: "rgb(150, 144, 144)"
                }
              },
              [_vm._v("Select a user for detailed records")]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "col-md-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        { staticClass: "col-md-12", staticStyle: { "font-size": "1.35rem" } },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" Reportees Activity "),
            _c(
              "small",
              {
                staticStyle: {
                  padding: "5px",
                  "font-size": "13px",
                  color: "rgb(150, 144, 144)"
                }
              },
              [_vm._v("(Direct Reports Only)")]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "col-md-8" }, [
        _c(
          "div",
          {
            staticClass: "pull-right",
            staticStyle: { background: "#fff", float: "right" },
            attrs: { id: "reportrange" }
          },
          [_c("b", { staticClass: "caret" })]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" Activity By Pillar "),
            _c(
              "small",
              {
                staticStyle: {
                  padding: "5px",
                  "font-size": "13px",
                  color: "rgb(150, 144, 144)"
                }
              },
              [_vm._v("(Direct Reports Only)")]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" Demo Activity "),
            _c(
              "small",
              {
                staticStyle: {
                  padding: "5px",
                  "font-size": "13px",
                  color: "rgb(150, 144, 144)"
                }
              },
              [_vm._v("(Direct Reports Only)")]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        { staticClass: "col-md-10", staticStyle: { "font-size": "1.35rem" } },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" Demo’s By Month "),
            _c(
              "small",
              {
                staticStyle: {
                  padding: "5px",
                  "font-size": "13px",
                  color: "rgb(150, 144, 144)"
                }
              },
              [_vm._v("(Direct Reports Only)")]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "col-md-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }