var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "InstanceIP",
            centered: "",
            "content-class": "instance-ip",
            title: "Topology Access"
          },
          on: { hide: _vm.closeInstance },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-primary btn-custom btn-user-cred",
                      attrs: { size: "sm" },
                      on: { click: _vm.closeInstance }
                    },
                    [_vm._v(" Close ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "name" }, [
            _c("p", [
              _vm._v(
                "Access the topology from the browser in your WVD session:"
              ),
              _c("br")
            ]),
            _c("li", [
              _vm._v("Topology URL: http://" + _vm._s(_vm.ipAddress) + ":3080")
            ]),
            _c("p", [_vm._v("Devices web console:"), _c("br")]),
            _c("li", [
              _vm._v(
                " SteelConnect-EX Director: https://" +
                  _vm._s(_vm.ipAddress) +
                  ":5443 (forward to 192.168.122.174:443) "
              )
            ]),
            _c("br"),
            _c("li", [
              _vm._v(" SteelConnect-EX Analytics:"),
              _c("br"),
              _vm._v(
                "https://" +
                  _vm._s(_vm.ipAddress) +
                  ":5445 (forward to 192.168.122.175:8443)"
              ),
              _c("br"),
              _c("strong", [_vm._v("OR")]),
              _c("br"),
              _vm._v(
                " http://" +
                  _vm._s(_vm.ipAddress) +
                  ":5444 (forward to 192.168.122.175:8080) "
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }