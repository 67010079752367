<template>
  <div>
    <keep-alive>
    <ScenarioList v-if="scenario.scenarioId == null"/>
    </keep-alive>
    <Demo  v-if="scenario.scenarioId !== null" />
  </div>
</template>

<script>
import { scenarioStore } from '@/store/scenarioStore.js'
import ScenarioList from '@/components/ScenarioList'
import Demo from '@/components/Demo'
import { mapState } from 'vuex'

export default {
  name: 'Scenarios',
  computed: {
    ...mapState(['category'])
  },
  data () {
    return {
      scenario: scenarioStore.scenario
    }
  },
  components: {
    ScenarioList,
    Demo
  },
  methods: {}
}
</script>
<style>
.Scenarios {
  text-align: center;
}
</style>
