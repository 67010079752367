var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "deleteSnapshot" }, [
    _c("div", { staticClass: "snap card" }, [
      _c("div", { staticClass: "snap-content" }, [
        _c(
          "i",
          {
            staticClass: "material-icons close",
            on: {
              click: function($event) {
                return _vm.closeSnapshot()
              }
            }
          },
          [_vm._v("clear")]
        ),
        _c("div", { staticClass: "title" }, [_vm._v("Snapshot Deletion")]),
        _c(
          "div",
          { staticClass: "snapText", staticStyle: { "text-align": "center" } },
          [
            _c("div", { staticClass: "name" }, [
              _c("p", [
                _vm._v(
                  "Please confirm you want to delete '" +
                    _vm._s(_vm.snapshotName) +
                    "'."
                )
              ]),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.deleteSnapshot()
                    }
                  }
                },
                [_vm._m(0)]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field deploy" }, [
      _c("button", { staticClass: "btn" }, [_vm._v("Confirm Deletion")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }