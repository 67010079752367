<template>
  <div class="row m-0 p-0">
    <div class="side-nav-layout" style="margin-top: 10px">
      <div class="side-nav">
        <div :class="navItemDashboard" @click="showDashboardClick">
          <i class="material-icons" style="font-size: 25px">dashboard</i>
          <span class="custom-dashboard" style="margin-top: -2px"
            >Dashboard</span
          >
        </div>
        <div
          :class="navItemManagerDashboard"
          @click="showManagerDashboardClick"
          v-if="managerDashboardViewer"
        >
          <i class="material-icons" style="font-size: 25px">groups</i>
          <span class="custom-dashboard" style="margin-top: -2px"
            >Mngr Dashboard</span
          >
        </div>
        <!-- <div :class="navItemVideoAnalyties" @click="showVideoAnalytiesClick" v-if="videoAnalyticsDashboardViewer">
          <i class="material-icons" style="font-size: 25px">analytics</i>
          <span class="custom-dashboard" style="margin-top: -2px"
            >Video Analytics</span
          >
        </div> -->
        <div :class="navItemScenarios" @click="showScenariosClick">
          <i class="material-icons" style="font-size: 25px">emoji_objects</i>
          <span class="custom-dashboard" style="margin-top: -3px"
            >Solution Demos</span
          >
        </div>
        <div :class="navItemFeature" @click="showFeatureClick">
          <i class="material-icons" style="font-size: 25px">featured_video</i>
          <span class="custom-dashboard" style="margin-top: -3px"
            >Feature Demos</span
          >
        </div>
        <div
          :class="navItemSandboxLabs"
          @click="showSandboxLabsClick"
          v-if="!this.$msal.data.user.userName.startsWith('demo-')"
        >
          <i class="material-icons" style="font-size: 35px; margin-left: -4px"
            >biotech</i
          >
          <span
            class="custom-dashboard"
            style="margin-top: 2px; margin-left: 5px !important"
            >Sandbox Labs</span
          >
        </div>
        <!-- <div
          :class="navItemROI"
          @click="showROIClick"
          v-if="!this.$msal.data.user.userName.startsWith('demo-')"
        >
          <i class="material-icons" style="font-size: 25px">calculate</i>
          <span class="custom-dashboard" style="margin-top: -2px"
            >ROI Calculation</span
          >
        </div> -->
        <div
          :class="navItemSnapshot"
          @click="showSnapshotClick"
          v-if="!this.$msal.data.user.userName.startsWith('demo-')"
        >
          <i class="material-icons" style="font-size: 25px">photo_camera</i>
          <span class="custom-dashboard" style="margin-top: -2px"
            >Snapshots
            <span class="badge badge-info">{{
              snapshot_list.length
            }}</span></span
          >
        </div>
      </div>
    </div>
    <div class="content-layout" style="border-left: 1px solid #e3e3e3">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
      <div class="table" v-if="showSnapshot">
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :loader="loader"
          :color="color"
        />
        <SnapshotList :data="snapshot_list" />
      </div>
      <StartSnapshot :snapshot="snapshotId" v-if="startSnapshot" />
      <DeleteSnapshot :snapshot="snapshotId" v-if="deleteSnapshot" />
      <CreateSnapshot
        :instance="instance"
        :demoPurposeRecord="demoPurposeRecord"
        v-if="createSnapshot"
      />
    </div>
    <Chatbot />
    <div>
      <b-modal
        ref="modalUserInfo"
        id="modalUserInfo"
        scrollable
        :cancel-disabled="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :no-enforce-focus="true"
        :hide-header-close="true"
        v-model="modalShow"
      >
        <template #modal-header>
          <div>
            <h3 style="color: #ff681d">Welcome to Steeldemo</h3>
            <p style="font-size: 17px">
              Tell us more about youself for a better experience
            </p>
          </div>
        </template>
        <b-form @submit.stop.prevent="onUserInfoSubmit">
          <b-form-group
            id="input-group-1"
            label="Full Name:"
            label-for="input-1"
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-input
              id="input-1"
              name="input-1"
              placeholder="Please enter full name"
              v-model="userForm.name"
              v-validate="{ required: true, min: 3 }"
              aria-describedby="input-1-live-feedback"
              data-vv-as="Name"
              :state="validateState('input-1')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              veeErrors.first("input-1")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Organisation:"
            label-for="input-2"
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-radio-group
              id="input-2"
              name="input-2"
              class="user-employee-option"
              v-model="userForm.userOrganisationOptions"
              :options="userOrganisationOptions"
              v-validate="{ required: true }"
              :state="validateState('input-2')"
              aria-describedby="input-2-live-feedback"
              data-vv-as="Organisation"
              size="lg"
            ></b-form-radio-group>
            <b-form-invalid-feedback id="input-2-live-feedback">{{
              veeErrors.first("input-2")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Role:"
            label-for="input-3"
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-3"
              name="input-3"
              v-model="userForm.userRoleDropDown"
              :options="userRoleDropDown"
              v-validate="{ required: true }"
              :state="validateState('input-3')"
              aria-describedby="input-3-live-feedback"
              :disabled="
                userForm.userOrganisationOptions == 'riverbed' ? false : true
              "
              data-vv-as="Role"
            ></b-form-select>
            <b-form-invalid-feedback id="input-3-live-feedback">{{
              veeErrors.first("input-3")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            label="Manager Name:"
            label-for="input-4"
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-4"
              v-model="userForm.userManagerDropDown"
              :options="userManagerDropDown"
              name="input-4"
              v-validate="{ required: true }"
              :state="validateState('input-4')"
              aria-describedby="input-4-live-feedback"
              data-vv-as="Manager Name"
              :disabled="
                userForm.userOrganisationOptions == 'riverbed' ? false : true
              "
            ></b-form-select>
            <b-form-invalid-feedback id="input-4-live-feedback">{{
              veeErrors.first("input-4")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="userForm.userManagerDropDown == 'Other'"
            id="input-group-5"
            label-for="input-5"
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-input
              id="input-5"
              name="input-5"
              placeholder="Please enter manager name"
              v-model="userForm.otherManagerName"
              v-validate="{ required: true, min: 3 }"
              aria-describedby="input-5-live-feedback"
              data-vv-as="Manager Name"
              :state="validateState('input-5')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-5-live-feedback">{{
              veeErrors.first("input-5")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-6"
            label="Geo Location:"
            label-for="input-6"
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-6"
              v-model="userForm.userGeoDropDown"
              :options="userGeoDropDown"
              name="input-6"
              v-validate="{ required: true }"
              :state="validateState('input-6')"
              aria-describedby="input-6-live-feedback"
              data-vv-as="Geo Location"
            ></b-form-select>
            <b-form-invalid-feedback id="input-6-live-feedback">{{
              veeErrors.first("input-6")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-form>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            style="margin-right: 41% !important; width: 17% !important"
            size="sm"
            @click="onUserInfoSubmit"
          >
            SUBMIT
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard'
import Scenarios from '@/components/Scenarios'
import SandboxLabs from '@/components/SandboxLabs'
import FeatureDemo from '@/components/FeatureDemo'
import { mapGetters, mapMutations, mapState } from 'vuex'
import Chatbot from '@/components/Chatbot'
import SnapshotList from '@/components/SnapshotList'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import StartSnapshot from '@/components/StartSnapshot'
import DeleteSnapshot from '@/components/DeleteSnapshot'
import CreateSnapshot from '@/components/CreateSnapshot'
import ManagementDashboard from '@/components/ManagementDashboard'
import ManagerDashboard from '@/components/ManagerDashboard'
import VideoAnalytics from '@/components/VideoAnalytics'
import ROICalculation from '@/components/ROICalculation'
import axios from 'axios'
import { scenarioStore } from '@/store/scenarioStore.js'

export default {
  name: 'contentArea',
  data () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      memberOfDashboardViewersGroup: false,
      managerDashboardViewer: false,
      navItemDashboard: 'nav-item-dashboad',
      navItemScenarios: 'focus',
      navItemSnapshot: 'nav-item-dashboad',
      navItemSandboxLabs: 'nav-item-dashboad',
      navItemManagerDashboard: 'nav-item-dashboad',
      navItemVideoAnalyties: 'nav-item-dashboad',
      navItemROI: 'nav-item-dashboad',
      navItemFeature: 'nav-item-dashboad',
      showSnapshot: false,
      menuCount: 4,
      iconClass: [
        'fas fa-chart-area sub-menu-icon',
        'fa fa-user-clock sub-menu-icon',
        'fa fa-tachometer-alt sub-menu-icon',
        'fas fa-dice-d20 sub-menu-icon'
      ],
      menuList: ['Visibility', 'Acceleration', 'WANOp', 'SD-WAN'],
      snapshot_list: [],
      isLoading: false,
      fullPage: false,
      color: '#ff681d',
      loader: 'bars',
      activeTab: 'Scenarios',
      modalShow: false,
      userRoleDropDown: [
        { text: 'Select One', value: null },
        'Sales & SE',
        'PS',
        'Marketing',
        'Support',
        'Partner',
        'Other'
      ],
      userManagerDropDown: [
        { text: 'Select One', value: null }
      ],
      userGeoDropDown: [
        { text: 'Select One', value: null },
        'EMEA',
        'APAC',
        'AMER'
      ],
      userOrganisationOptions: [
        { text: 'Riverbed Technology', value: 'riverbed' },
        { text: 'Aternity', value: 'aternity' }
      ],
      userForm: {
        name: '',
        userRoleDropDown: null,
        userOrganisationOptions: '',
        userManagerDropDown: null,
        userGeoDropDown: null,
        otherManagerName: ''
      },
      videoAnalyticsDashboardViewer: false,
      planned_list: []
    }
  },
  components: {
    Dashboard,
    Scenarios,
    Loading,
    StartSnapshot,
    DeleteSnapshot,
    SandboxLabs,
    SnapshotList,
    Chatbot,
    ManagementDashboard,
    CreateSnapshot,
    ManagerDashboard,
    VideoAnalytics,
    ROICalculation,
    FeatureDemo
  },
  methods: {
    ...mapMutations([
      'UPDATE_SNAPSHOTNBR',
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_SNAPSHOT_NAME',
      'UPDATE_START_SNAPSHOT',
      'UPDATE_DELETE_SNAPSHOT'
    ]),
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    onUserInfoSubmit (event) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }
        const reqbody = {
          id: this.$msal.data.user.userName,
          organisation: this.userForm.userOrganisationOptions,
          fullName: this.userForm.name,
          role: this.userForm.userRoleDropDown,
          manager: this.userForm.userManagerDropDown,
          geo: this.userForm.userGeoDropDown,
          otherManagerName: this.userForm.otherManagerName
        }
        this.$bvModal.hide('modalUserInfo')
        axios({
          method: 'POST',
          url: this.backendURL + 'dynamodb/steeldemousers',
          data: { ...reqbody },
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          responseType: 'json'
        }).then(
          (result) => {
            this.$bvModal.hide('modalUserInfo')
          }).catch(err => {
          this.$bvModal.hide('modalUserInfo')
          console.log(err)
        })
      })
    },
    showDashboardClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemDashboard = 'focus'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.activeTab = this.memberOfDashboardViewersGroup
        ? 'ManagementDashboard'
        : 'Dashboard'
      scenarioStore.reset()
    },
    showVideoAnalytiesClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'focus'
      this.activeTab = 'VideoAnalytics'
      scenarioStore.reset()
    },
    showFeatureClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemFeature = 'focus'
      this.activeTab = 'FeatureDemo'
      scenarioStore.reset()
    },
    showManagerDashboardClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemManagerDashboard = 'focus'
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.activeTab = 'ManagerDashboard'
      scenarioStore.reset()
    },
    showScenariosClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'focus'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.activeTab = 'Scenarios'
      scenarioStore.reset()
    },
    showSandboxLabsClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'focus'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.activeTab = 'SandboxLabs'
      scenarioStore.reset()
    },
    showSnapshotClick () {
      this.closeStartSnapshot()
      this.getSnapshotData()
      this.showSnapshot = true
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemROI = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.navItemSnapshot = 'focus'
      this.activeTab = ''
      scenarioStore.reset()
    },
    showROIClick () {
      this.closeStartSnapshot()
      this.showSnapshot = false
      this.navItemDashboard = 'nav-item-dashboad'
      this.navItemScenarios = 'nav-item-dashboad'
      this.navItemSnapshot = 'nav-item-dashboad'
      this.navItemManagerDashboard = 'nav-item-dashboad'
      this.navItemSandboxLabs = 'nav-item-dashboad'
      this.navItemVideoAnalyties = 'nav-item-dashboad'
      this.navItemFeature = 'nav-item-dashboad'
      this.navItemROI = 'focus'
      this.activeTab = 'ROICalculation'
      scenarioStore.reset()
    },
    async checkDashboardAccess () {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('msal.idtoken')
      }
      const reqBody = { username: this.mailUserName }
      const requestOne = axios.post(
        this.backendURL + 'azure/dashboardviewersaccess',
        { ...reqBody },
        { headers }
      )
      const config = {
        headers,
        params: {
          id: this.$msal.data.user.userName
        }
      }
      const requestTwo = axios.get(
        this.backendURL + 'dynamodb/steeldemousers',
        config
      )

      const requestThree = axios.get(
        this.backendURL + 'video/logs',
        config
      )

      const requestFour = axios.get(
        this.backendURL + 'azure/listofmanagerdashboard',
        { headers }
      )

      await axios
        .all([requestOne, requestTwo, requestThree, requestFour])
        .then(
          axios.spread((...responses) => {
            const responseOne = responses[0]
            const responseTwo = responses[1]

            const resbodyOne = responseOne.data.body
            this.memberOfDashboardViewersGroup =
              resbodyOne.MemberOfDashboardViewers
            this.$store.memberOfDashboardViewers = resbodyOne.MemberOfDashboardViewers

            const resbodyTwo = responseTwo.data.body
            if (resbodyTwo.reportees && resbodyTwo.reportees.length > 0) {
              this.managerDashboardViewer = true
              this.$store.reportees = resbodyTwo.reportees
            }
            if (resbodyTwo.signature) {
              this.$store.signature = resbodyTwo.signature
            }
            if (!this.$msal.data.user.userName.startsWith('demo-')) {
              this.modalShow = !resbodyTwo.organisation
            }

            const resbodyThree = JSON.parse(responses[2].data.body)
            if (resbodyThree.length > 0) {
              this.videoAnalyticsDashboardViewer = true
              this.$store.videoLogs = resbodyThree
            }
            const resbodyFour = responses[3].data.body
            this.userManagerDropDown = this.userManagerDropDown.concat(resbodyFour)
            this.userManagerDropDown = this.userManagerDropDown.concat('Other')

            if (this.memberOfDashboardViewersGroup) {
              this.managerDashboardViewer = true
              axios({
                method: 'GET',
                url: this.backendURL + 'dynamodb/steeldemousers',
                params: {
                  admin: true
                },
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: sessionStorage.getItem('msal.idtoken')
                },
                responseType: 'json'
              }).then(
                (result) => {
                  const localList = {}
                  result.data.body.Items.forEach(obj => {
                    localList[obj.fullName] = obj.reportees
                  })
                  this.$store.managerList = localList
                }).catch(err => {
                console.log(err)
              })
            }
          })
        )
        .catch((errors) => {})
    },
    getSnapshotData () {
      try {
        const ref = this.firestoreDB.collection('snapshots')

        const query = ref.where('username', '==', this.mailUserName)
        this.isLoading = true
        query.onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const instance = change.doc.data()
            if (change.type === 'added') {
              const indexSnapshot = this.snapshot_list.findIndex(
                (e) => e.snapshotId === instance.snapshotId
              )
              if (indexSnapshot === -1) {
                this.snapshot_list.push(instance)
              }
            }
            if (change.type === 'removed') {
              const indexSnapshot = this.snapshot_list.findIndex(
                (e) => e.snapshotId === instance.snapshotId
              )
              if (indexSnapshot > -1) {
                this.snapshot_list.splice(indexSnapshot, 1)
              }
            }
          })
          this.UPDATE_SNAPSHOTNBR(this.snapshot_list.length)
          this.isLoading = false
        })
      } catch (error) {
        // console.log('error in dashboard Instances ' + error)
      }
    },
    closeStartSnapshot () {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_START_SNAPSHOT(false)
      this.UPDATE_DELETE_SNAPSHOT(false)
    },
    checkforPlannedDemo () {
      const ref = this.firestoreDB.collection('instances')
      const query = ref.where('username', '==', this.mailUserName)
      query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const instance = change.doc.data()
          if (change.type === 'added') {
            if (instance.status === 'planned') {
              this.planned_list.push(instance)
            }
          }
          if (change.type === 'modified') {
            const index = this.planned_list.findIndex(
              (e) => e.demoId === instance.demoId
            )
            if (index === -1) {
              this.planned_list.push(instance)
            }
          }
          if (change.type === 'removed') {
            this.planned_list.pop(change.doc.data())
          }
        })
        this.$store.planned_demos = this.planned_list
      })
    }
  },
  computed: {
    ...mapGetters(['GET_USER', 'mailUserName']),
    ...mapState([
      'firestoreDB',
      'snapshotId',
      'deleteSnapshot',
      'startSnapshot',
      'instance',
      'demoPurposeRecord',
      'createSnapshot'
    ])
  },
  mounted () {
    // this.$refs.modalUserInfo.show()
    this.$root.$on('scenario_nav', () => {
      this.showScenariosClick()
    })
    this.$root.$on('lab_nav', () => {
      this.showSandboxLabsClick()
    })
    this.$root.$on('snapshot_nav', () => {
      this.showSnapshotClick()
    })
    this.$store.watch(
      (state, getters) => getters.authenticated,
      (newValue, oldValue) => {
        if (newValue !== null) {
          this.closeStartSnapshot()
          this.getSnapshotData()
        }
      }
    )
  },
  async created () {
    await this.checkDashboardAccess()
    this.checkforPlannedDemo()
  }
}
</script>
<style>
.user-employee-option label.custom-control-label {
    font-size: 16px;
    color: #333;
}
.content-layout {
  width: calc(100% - 220px);
}
.side-nav-layout {
  width: 220px;
}
.col-2 {
  flex: 0 0 16.666667% !important;
  max-width: 12.666667% !important;
}
.side-nav {
  background-color: #ffffff;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100vh;
  color: #545b5b;
  transition: left 0.4s ease !important;
}

.logo-text {
  font-size: 22px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  letter-spacing: 2px;
  border-bottom: #343d46 1px solid;
  cursor: pointer;
}

.nav-header {
  font-size: 17px;
  padding-left: 40px;
  color: #7e868f;
  padding-top: 25px;
  letter-spacing: 2px;
  padding-bottom: 15px;
}

.custom-dashboard {
  box-shadow: none;
  border-color: white;
  text-transform: capitalize;
  font-size: 20px;
  overflow-anchor: none;
  margin-left: 9px;
}
.nav-item-dashboad {
  padding-left: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  letter-spacing: 0.4px;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: row;
}

.focus {
  padding-left: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  letter-spacing: 0.4px;
  cursor: pointer;
  background-color: #ff681d;
  color: white;
  display: flex;
  flex-direction: row;
}

.focus .material-icons {
  color: white;
}

.nav-item-dashboad:hover {
  border-radius: 8px;
  color: black;
}

.nav-item-dashboad .badge-info {
  color: #fff;
  background-color: #ff6900;
  min-width: auto !important;
  margin-top: 4px;
}

.focus .badge-info {
  color: black;
  background-color: #f8f9fa;
  min-width: auto !important;
  margin-top: 4px;
}

.nav-icon,
svg {
  margin-top: -6px;
  margin-right: 10px;
}

.sub-menu {
  color: #000;
  font-size: 20px;
  padding-top: 5px;
}

.sub-menu-icon {
  color: #ff6900;
  padding-right: 10px;
  width: 20%;
  font-size: 20px;
}
</style>
