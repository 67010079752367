var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "startSnapshot" }, [
    _c(
      "div",
      { staticClass: "startSnapshot" },
      [
        _c(
          "b-modal",
          {
            attrs: {
              centered: "",
              "content-class": "startSnapshot",
              title: "Start Instance"
            },
            on: {
              hide: function($event) {
                return _vm.closeStartSnapshot()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "modal-footer",
                fn: function() {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-primary btn-custom btn-user-cred",
                        attrs: { size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.deploySnapshot()
                          }
                        }
                      },
                      [_vm._v(" Deploy ")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.showStartSnapshot,
              callback: function($$v) {
                _vm.showStartSnapshot = $$v
              },
              expression: "showStartSnapshot"
            }
          },
          [
            _c("div", { staticClass: "deployParameters" }, [
              _vm.feedback
                ? _c("p", { staticClass: "feedback" }, [
                    _vm._v(_vm._s(_vm.feedback))
                  ])
                : _vm._e(),
              _c("div", { staticClass: "duration_and_mode" }, [
                _c("div", { staticClass: "field duration" }, [
                  _c("label", { attrs: { for: "title" } }, [
                    _vm._v("Demo duration (hours):")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.duration,
                        expression: "duration"
                      }
                    ],
                    attrs: { type: "text", name: "duration" },
                    domProps: { value: _vm.duration },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.duration = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "decrement",
                      on: {
                        click: function($event) {
                          return _vm.changeDuration(-1)
                        }
                      }
                    },
                    [_vm._v(" - ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "increment",
                      on: {
                        click: function($event) {
                          return _vm.changeDuration(1)
                        }
                      }
                    },
                    [_vm._v(" + ")]
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "deployDate" },
                [
                  _c("label", { attrs: { for: "date" } }, [
                    _vm._v("Date & Time:")
                  ]),
                  _c("toggle-button", {
                    attrs: {
                      labels: { checked: "Planned", unchecked: "ASAP" },
                      color: { checked: "#FBB022", unchecked: "#FF6900" },
                      width: 70
                    },
                    on: {
                      change: function($event) {
                        _vm.deployDate = $event.value
                      }
                    }
                  }),
                  _vm.deployDate
                    ? _c("date-pick", {
                        attrs: { pickTime: true, format: "YYYY-MM-DD HH:mm" },
                        model: {
                          value: _vm.dateToPick,
                          callback: function($$v) {
                            _vm.dateToPick = $$v
                          },
                          expression: "dateToPick"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }