export const scenarioStore = {
  scenario: {
    scenarioId: null,
    name: '',
    picture: '',
    description: '',
    link: '',
    user: '',
    password: '',
    trainingScript: '',
    demoScript: '',
    video: '',
    demoType: '',
    lastPublishedDate: '',
    featuredClass: '',
    inProcess: true,
    status: '',
    url: '',
    ip_address: '',
    wvd_url: '',
    demoId: '',
    duration: '',
    snapshot: ''
  },
  set (scenarioId, name, picture, description, link, user, password, trainingScript, demoScript, video, demoType, lastPublishedDate, snapshot, featuredClass) {
    this.scenario.scenarioId = scenarioId
    this.scenario.name = name
    this.scenario.picture = picture
    this.scenario.description = description
    this.scenario.link = link
    this.scenario.user = user
    this.scenario.password = password
    this.scenario.trainingScript = trainingScript
    this.scenario.demoScript = demoScript
    this.scenario.video = video
    this.scenario.demoType = demoType
    this.scenario.lastPublishedDate = lastPublishedDate
    this.scenario.snapshot = snapshot
    this.scenario.featuredClass = featuredClass
  },
  reset () {
    this.scenario.scenarioId = null
    this.scenario.name = ''
    this.scenario.picture = ''
    this.scenario.description = ''
    this.scenario.link = ''
    this.scenario.user = ''
    this.scenario.password = ''
    this.scenario.trainingScript = ''
    this.scenario.demoScript = ''
    this.scenario.video = ''
    this.scenario.demoType = ''
    this.scenario.lastPublishedDate = ''
    this.scenario.featuredClass = ''
    this.scenario.inProcess = true
    this.scenario.status = ''
    this.scenario.url = ''
    this.scenario.ip_address = ''
    this.scenario.wvd_url = ''
    this.scenario.demoId = ''
    this.scenario.duration = ''
    this.scenario.snapshot = ''
  }
}
