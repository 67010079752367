var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Table" }, [
    _c("table", [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.headers, function(header) {
            return _c("th", { key: header }, [_vm._v(_vm._s(header))])
          }),
          0
        )
      ]),
      _vm.type === "planned"
        ? _c(
            "tbody",
            _vm._l(_vm.data, function(row) {
              return _c("tr", { key: row.demoId }, [
                _c("td", [_vm._v(" " + _vm._s(row["category"]) + " ")]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showScenarioName(row.scenarioId)))
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showDate(row["starting_timestamp"])))
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showDuration(row["duration"])) + " hours")
                ]),
                _c("td", [_vm._v(_vm._s(row["status"]))]),
                _c("td", [
                  _c("i", {
                    staticClass: "fas fa-ban",
                    staticStyle: { color: "red", cursor: "pointer" },
                    attrs: { title: "Cancel demo" },
                    on: {
                      click: function($event) {
                        return _vm.cancelInstance(
                          row["scenarioId"],
                          row["demoId"]
                        )
                      }
                    }
                  })
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.type === "running"
        ? _c(
            "tbody",
            _vm._l(_vm.data, function(row, index) {
              return _c(
                "tr",
                { key: row.demoId },
                [
                  _vm._t(
                    "default",
                    function() {
                      return [
                        _c("td", [_vm._v(" " + _vm._s(row["category"]) + " ")]),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              color: "blue",
                              "text-decoration": "underline"
                            },
                            on: {
                              click: function($event) {
                                return _vm.tabChange(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.showScenarioName(row.scenarioId)) +
                                " "
                            )
                          ]
                        ),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.showDate(row["starting_timestamp"]))
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.showDuration(row["duration"])) + " hours"
                          )
                        ]),
                        _c("td", [_vm._v(_vm._s(row["status"]))]),
                        _c("td", [
                          row["login"] && row["login"] !== "N/A"
                            ? _c(
                                "div",
                                { staticStyle: { display: "inline-flex" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-6",
                                      staticStyle: {
                                        "max-width": "135px !important"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(row["login"]) + " ")]
                                  ),
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyUsername(row, index)
                                        }
                                      }
                                    },
                                    [_vm._v("content_copy")]
                                  ),
                                  _c(
                                    "transition",
                                    {
                                      attrs: { name: "fade" },
                                      on: { enter: _vm.enterUserName }
                                    },
                                    [
                                      _vm.copyUserName
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8vw",
                                                "margin-top": "3px",
                                                "margin-left": "3px",
                                                color: "green",
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v(" copied! ")]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      id: "copy-code-username"
                                    },
                                    domProps: { value: row["login"] }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticStyle: { display: "inline-flex" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-6",
                                      staticStyle: {
                                        "max-width": "135px !important"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(row["login"]) + " ")]
                                  )
                                ]
                              )
                        ]),
                        _c("td", [
                          row["password"] && row["password"] !== "N/A"
                            ? _c(
                                "div",
                                { staticStyle: { display: "inline-flex" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-6",
                                      staticStyle: {
                                        "max-width": "135px !important",
                                        "margin-top": "3px"
                                      }
                                    },
                                    [_vm._v(" **************** ")]
                                  ),
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.togglePassword(row, index)
                                        }
                                      }
                                    },
                                    [_vm._v("content_copy")]
                                  ),
                                  _c(
                                    "transition",
                                    {
                                      attrs: { name: "fade" },
                                      on: { enter: _vm.enterPassword }
                                    },
                                    [
                                      _vm.copyPassword
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8vw",
                                                "margin-top": "3px",
                                                "margin-left": "3px",
                                                color: "green",
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v(" copied! ")]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c("input", {
                                    attrs: { type: "hidden", id: "copy-code" },
                                    domProps: { value: row["password"] }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticStyle: { display: "inline-flex" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-6",
                                      staticStyle: {
                                        "max-width": "135px !important"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row["password"]) + " "
                                      )
                                    ]
                                  )
                                ]
                              )
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.showSubnet(row["subnet"])))
                        ])
                      ]
                    },
                    { row: row }
                  )
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm.type === "demos" || _vm.type === "cost"
        ? _c(
            "tbody",
            _vm._l(_vm.data, function(row) {
              return _c("tr", { key: row.demoId }, [
                _c("td", [_vm._v(_vm._s(row["category"]))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showScenarioName(row.scenarioId)))
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showDate(row["starting_timestamp"])))
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showDuration(row["duration"])) + " hours")
                ]),
                _c("td", [_vm._v("$ " + _vm._s(row["cost"]))])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.type === "snapshot"
        ? _c(
            "tbody",
            _vm._l(_vm.data, function(row) {
              return _c("tr", { key: row.snapshotId }, [
                _c("td", [_vm._v(_vm._s(row["category"]))]),
                _c("td", [_vm._v(_vm._s(row["name"]))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.showDate(row["creation_timestamp"])))
                ]),
                _c("td", [_vm._v(_vm._s(row["description"]))]),
                _c("td", { staticClass: "links" }, [
                  _c("i", {
                    staticClass: "fas fa-play-circle",
                    staticStyle: { color: "#6bb5e4", cursor: "pointer" },
                    attrs: { title: "Start snapshot" },
                    on: {
                      click: function($event) {
                        return _vm.startSnapshot(
                          row["snapshotId"],
                          row["name"],
                          row["category"]
                        )
                      }
                    }
                  }),
                  _c("i", {
                    staticClass: "fas fa-trash-alt",
                    staticStyle: { color: "#575757", cursor: "pointer" },
                    attrs: { title: "Delete snapshot" },
                    on: {
                      click: function($event) {
                        return _vm.deleteSnapshot(
                          row["snapshotId"],
                          row["name"]
                        )
                      }
                    }
                  })
                ])
              ])
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }