var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-nav-bar" }, [
    _vm._m(0),
    _c(
      "nav",
      {
        staticClass: "navbar navbar-expand-lg navbar-light bg-light nav-custom"
      },
      [
        _c("img", {
          staticClass: "nav-brand",
          attrs: { src: require("@/assets/logo_riverbed_orange.svg") }
        }),
        _c("p", { staticClass: "custom-nav-link" }, [
          _vm._v("SteelDemo Manger")
        ]),
        _vm._m(1),
        _vm._m(2),
        _c("ul", [
          _c("li", [
            _c(
              "div",
              { staticClass: "notication-action" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      size: "lg",
                      variant: "link",
                      "toggle-class": "text-decoration-none notification-bell",
                      "no-caret": "",
                      right: "",
                      "menu-class": "notication-action-dropdown"
                    },
                    on: {
                      show: function($event) {
                        return _vm.notificationClicked()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _vm.clicked_notifications ||
                            _vm.updates_list.length <= 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "material-icons",
                                    staticStyle: { color: "gray" },
                                    attrs: { title: "Notifications" }
                                  },
                                  [_vm._v(" notifications ")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "material-icons",
                                    attrs: { title: "Notifications" }
                                  },
                                  [_vm._v(" notifications_active ")]
                                )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    !_vm.updates_list.length
                      ? _c(
                          "div",
                          [_c("b-dropdown-text", [_vm._v("No Notifications")])],
                          1
                        )
                      : _c(
                          "div",
                          _vm._l(_vm.updates_list, function(list, index) {
                            return _c(
                              "div",
                              { key: index },
                              _vm._l(list, function(value, name) {
                                return _c(
                                  "div",
                                  { key: name },
                                  [
                                    name !== "date" && name !== "uuid"
                                      ? _c(
                                          "b-card",
                                          {
                                            staticClass:
                                              "notication-card notication-card-new",
                                            attrs: {
                                              "body-class":
                                                "notication-card-body"
                                            }
                                          },
                                          [
                                            _c(
                                              "b-card-title",
                                              {
                                                staticStyle: {
                                                  "font-size": "18px",
                                                  "font-weight": "400"
                                                }
                                              },
                                              [_vm._v(_vm._s(name))]
                                            ),
                                            _c(
                                              "b-card-sub-title",
                                              {
                                                staticClass: "mb-2",
                                                staticStyle: {
                                                  "font-size": "10px",
                                                  color: "#6c757dad !important",
                                                  "margin-top": "-7px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDate(list["date"])
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-list-group",
                                              { attrs: { flush: "" } },
                                              _vm._l(value, function(
                                                item,
                                                index_item
                                              ) {
                                                return _c(
                                                  "b-list-group-item",
                                                  {
                                                    key: index_item,
                                                    staticStyle: {
                                                      padding: "2px 2px 2px 0px"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item))]
                                                )
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          }),
                          0
                        )
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "logout-action" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://community.riverbed.com/resource/1608347446000/RiverBedCommunity/images/user-login-icon.svg"
                }
              }),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleLogout()
                    }
                  }
                },
                [_vm._v("Logout")]
              )
            ])
          ])
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "navbar navbar-expand-lg navbar-light bg-blue",
        style: _vm.divscrolltop
      },
      [
        _c(
          "p",
          {
            staticStyle: {
              display: "inline-block",
              "overflow-wrap": "break-word",
              margin: "0px"
            }
          },
          [
            _vm._v(
              "Attention: Our Weekly General Maintenance Window is scheduled every Friday between 11:00 pm PDT to 7am PDT. During this time the system may be unstable."
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-nav" }, [
      _c("div", { staticClass: "container-custom" }, [
        _c("ul", { staticClass: "top-link" }, [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "https://www.riverbed.com/", target: "_blank" }
              },
              [_vm._v("Riverbed.com")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://www.riverbed.com/document-repository.html#!filter-page=1",
                  target: "_blank"
                }
              },
              [_vm._v("Resources")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://www.riverbed.com/about/events.html#!filter-page=1",
                  target: "_blank"
                }
              },
              [_vm._v("Events")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://education.riverbed.com/",
                  target: "_blank"
                }
              },
              [_vm._v("Education")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://community.riverbed.com/",
                  target: "_blank"
                }
              },
              [_vm._v("Community")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.riverbed.com/about/contact-us.html",
                  target: "_blank"
                }
              },
              [_vm._v("Contact Us")]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav-item-custom clearfix" }, [
      _c("li", { staticClass: "right-nav-items" }, [
        _c(
          "a",
          {
            staticClass: "api-nav menu-icon",
            attrs: {
              href: "https://support.riverbed.com/apis/index.html",
              target: "_blank"
            }
          },
          [_c("span", { staticStyle: { left: "8px" } }, [_vm._v("API")])]
        ),
        _c(
          "a",
          {
            staticClass: "github-nav menu-icon",
            attrs: { href: "https://github.com/riverbed", target: "_blank" }
          },
          [_c("span", [_vm._v("Github")])]
        ),
        _c(
          "a",
          {
            staticClass: "gns-nav menu-icon",
            attrs: {
              href:
                "https://gns3.com/marketplace/appliances/riverbed-steelhead",
              target: "_blank"
            }
          },
          [_c("span", { staticStyle: { left: "4px" } }, [_vm._v("GNS3")])]
        ),
        _c(
          "a",
          {
            staticClass: "oracle-nav menu-icon",
            attrs: {
              href:
                "https://cloudmarketplace.oracle.com/marketplace/en_US/listing/53564858",
              target: "_blank"
            }
          },
          [_c("span", [_vm._v("Oracle")])]
        ),
        _c(
          "a",
          {
            staticClass: "aws-nav menu-icon",
            attrs: {
              href:
                "https://aws.amazon.com/marketplace/seller-profile?id=33ecb59b-d3be-4f77-a03e-1e51c0f9a4ad&ref=dtl_B06Y8QN6YM",
              target: "_blank"
            }
          },
          [_c("span", { staticStyle: { left: "5px" } }, [_vm._v("AWS")])]
        ),
        _c(
          "a",
          {
            staticClass: "azure-nav menu-icon",
            attrs: {
              href:
                "https://azuremarketplace.microsoft.com/en-us/marketplace/apps?search=riverbed",
              target: "_blank"
            }
          },
          [_c("span", [_vm._v("Azure")])]
        ),
        _c(
          "a",
          {
            staticClass: "podcast-nav menu-icon",
            attrs: {
              href: "https://networkcollective.com/category/riverbed/",
              target: "_blank"
            }
          },
          [_c("span", { staticStyle: { left: "-4px" } }, [_vm._v("PODCAST")])]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "btn-wrap" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "primary-btn-orange free-trial",
            attrs: {
              href: "https://www.riverbed.com/in/trialdownloads.html",
              target: "_blank",
              role: "button"
            }
          },
          [_vm._v("free trials")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }