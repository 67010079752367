<template>
  <div class="callback">
    <div class="validEmail" v-if="displayError">
      <img class="logo" src="@/assets/logo-public.svg" />
      <div class="appName">SteelDemo</div>
      <div class="message">{{ errorDescription }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import { msalMixin } from 'vue-msal'

export default {
  name: 'callback',
  mixins: [msalMixin],
  data () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      displayError: false,
      error: '',
      errorDescription: ''
    }
  },
  created () {
    const currentUrl = window.location.href
    const query = this.readErrorMessage(currentUrl)
    // get Firebase JWT token to be able to read the db
    let input = {}
    if (query === null) {
      if (
        this.msal.isAuthenticated &&
        typeof this.msal.user.userName !== 'undefined'
      ) {
        // we load the user item in our store. We use JSON.parse to convert the string into an object
        const email = this.msal.user.userName
        const mailUserName = email.substring(0, email.lastIndexOf('@'))
        input = { emailAddress: email }
        this.UPDATE_MAIL_USERNAME(mailUserName)
        this.initFirebase(input)
      } else {
        this.$msal.signIn()
      }
    } else {
      this.error = query.error
      this.errorDescription = query.error_description
      this.displayError = true
    }
  },

  computed: {
    ...mapGetters(['GET_USER'])
  },
  methods: {
    readErrorMessage (url) {
      const urlToken = url.split('#')
      const query = {}
      if (urlToken.length > 1) {
        let pair
        const search = urlToken[1].split('&')
        let i = search.length
        while (i--) {
          pair = search[i].split('=')
          query[pair[0]] = decodeURIComponent(pair[1])
        }
      }
      // if there is no error, we return null
      if (!query.error) {
        return null
      }
      return query
    },
    ...mapMutations([
      'UPDATE_USER',
      'UPDATE_OTHER_MAIL',
      'UPDATE_MAIL_USERNAME',
      'UPDATE_LAST_LOGIN',
      'INITIALIZE_FIRESTORE'
    ]),
    initFirebase: async function (input) {
      const firebaseTokenUrl = this.backendURL + 'auth/firebasetoken'
      await axios
        .post(firebaseTokenUrl, input, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          }
        })
        .then((response) => {
          const token = JSON.parse(response.data.body).customToken
          this.INITIALIZE_FIRESTORE(token)
        })
        .catch((error) => {
          console.error('problem INITIALIZE_FIRESTORE ' + error)
          setTimeout(() => {
            this.initFirebase({ emailAddress: this.msal.user.userName })
          }, 1000)
        })
    }
  }
}
</script>

<style>
.callback {
  text-align: center;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.validEmail {
  margin-top: 20%;
  background-color: white;
  width: 350px;
  height: auto;
  overflow: visible;
  border-radius: 10px;
  position: relative;
  z-index: 50;
  padding: 20px;
  display: inline-table;
  justify-content: center;
  border-radius: 10px;
  border: 0.5px solid;
  border-color: lightgrey;
  box-shadow: 5px 10px 8px #888888;
}

.validEmail .appName {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 200%;
  color: #a9a9a9;
}

.callback .spinner {
  width: 100%;
  height: 100%;
  z-index: 60;
  text-align: center;
  margin-left: 45%;
  margin-top: 20%;
}
</style>
