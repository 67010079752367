<template>
  <div>
    <!-- <div class="instance card">
            <div class="instance-content">
                <i class="material-icons close" v-on:click="closeInstance()">clear</i>
                <div class="title">Topology Access</div>
                <div class="snapText">
                    <div class="name">
                        <p>Access the topology from the browser in your WVD session:<br></p>
                            <li>Topology URL: http://{{ ipAddress }}:3080</li>
                        <p>Devices web console:<br></p>
                            <li>SteelConnect-EX Director: https://{{ ipAddress }}:5443 (forward to 192.168.122.174:443)</li><br>
                            <li>SteelConnect-EX Analytics:<br>https://{{ ipAddress }}:5445 (forward to 192.168.122.175:8443)<br><strong>OR</strong><br>
                                http://{{ ipAddress }}:5444 (forward to 192.168.122.175:8080)</li>
                    </div>
                    <form @submit.prevent="closeInstance()">
                        <div class="deploy">
                            <button class="btn-instance-custom">Close</button>
                        </div>
                    </form>
                </div>
           </div>
        </div> -->

    <b-modal
      id="InstanceIP"
      centered
      content-class="instance-ip"
      title="Topology Access"
      @hide="closeInstance"
    >
      <div class="name">
        <p>Access the topology from the browser in your WVD session:<br /></p>
        <li>Topology URL: http://{{ ipAddress }}:3080</li>
        <p>Devices web console:<br /></p>
        <li>
          SteelConnect-EX Director: https://{{ ipAddress }}:5443 (forward to
          192.168.122.174:443)
        </li>
        <br />
        <li>
          SteelConnect-EX Analytics:<br />https://{{ ipAddress }}:5445 (forward
          to 192.168.122.175:8443)<br /><strong>OR</strong><br />
          http://{{ ipAddress }}:5444 (forward to 192.168.122.175:8080)
        </li>
      </div>
      <template #modal-footer content-class="modal-footer1">
        <b-button
          class="btn btn-primary btn-custom btn-user-cred"
          size="sm"
          v-on:click="closeInstance"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'instanceIP',
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['ipAddress'])
  },
  methods: {
    ...mapMutations(['UPDATE_IP_ADDRESS']),
    closeInstance () {
      this.UPDATE_IP_ADDRESS('')
    }
  },
  created () {
    this.$bvModal.show('InstanceIP')
  }
}
</script>

<style>
.instance-ip{
    font-family: 'Source Sans Pro', sans-serif;
    color: slategrey;
    word-wrap: break-word;
    text-align: center;
}
</style>
