var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row m-0 p-0" }, [
        _c(
          "div",
          {
            staticClass: "content-layout-area",
            staticStyle: {
              "padding-top": "20px !important",
              "padding-right": "20px !important",
              "border-right": "1px solid #e3e3e3",
              "border-left": "1px solid #e3e3e3"
            }
          },
          [
            _c("loading", {
              attrs: {
                active: _vm.isLoading,
                "is-full-page": _vm.fullPage,
                loader: _vm.loader,
                color: _vm.color
              }
            }),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "padding-left": "20px" } },
              [
                !_vm.scenarios.length && !_vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          color: "#ff681d",
                          "font-size": "30px",
                          padding: "20%"
                        }
                      },
                      [_vm._v(" No product, please choose another scenarios ")]
                    )
                  : _vm._e(),
                _vm._l(_vm.scenarios, function(scenario) {
                  return _c(
                    "div",
                    {
                      key: scenario.id,
                      staticClass: "col-custom",
                      staticStyle: { "padding-bottom": "20px" }
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "col-4-custom " +
                            scenario.featuredClass +
                            scenario.staging
                        },
                        [
                          _c("div", { staticClass: "icon" }, [
                            !_vm.checkNewScenario(scenario.createAt)
                              ? _c("i", { staticClass: "material-icons" }, [
                                  _vm._v(" " + _vm._s(scenario.icon))
                                ])
                              : _vm._e()
                          ]),
                          _vm.checkNewScenario(scenario.createAt)
                            ? _c(
                                "div",
                                { staticClass: "ribbon ribbon-top-right" },
                                [_c("span", [_vm._v("new")])]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "badge badge-dark badge-custom" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getScenarioMapId(
                                    "ID: D",
                                    scenario.lastPublishedDate,
                                    scenario.id
                                  )
                                )
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-1",
                                  modifiers: { "modal-1": true }
                                }
                              ],
                              staticClass: "content-wrap-custom",
                              on: {
                                click: function($event) {
                                  return _vm.showScenario(scenario.id)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(_vm._s(scenario.category))
                              ]),
                              _c("h5", { staticClass: "custom-class-h5" }, [
                                _vm._v(_vm._s(scenario.name))
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "custom-class-p",
                                  staticStyle: {
                                    "font-size": "12px",
                                    "font-weight": "700",
                                    "margin-bottom": "0.5rem",
                                    bottom: "85px",
                                    position: "absolute",
                                    "padding-right": "20px",
                                    "padding-bottom": "10px"
                                  }
                                },
                                [
                                  scenario.facet_products
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scenario.facet_products_status
                                            )
                                        )
                                      ])
                                    : _vm._e(),
                                  scenario.facet_products_status &&
                                  scenario.facet_products_status
                                    ? _c("span", [_vm._v(", ")])
                                    : _vm._e(),
                                  scenario.facet_products_status
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v(_vm._s(scenario.demoType))]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "status-custom justify-content-center"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "links scenario-list-links" },
                                [
                                  scenario.inProcess
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "scenario-status-label"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scenario.status) + " "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "auto",
                                            "margin-right": "20px",
                                            "text-align": "center",
                                            "margin-top": "-3px"
                                          }
                                        },
                                        [
                                          scenario["ip_address"]
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-bolt fas-custom",
                                                staticStyle: {
                                                  color: "#fbb022",
                                                  cursor: "pointer"
                                                },
                                                attrs: { title: "IP Address" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openAlert(
                                                      scenario["ip_address"]
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          scenario["wvd_url"]
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: scenario["wvd_url"],
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-desktop fas-custom",
                                                    staticStyle: {
                                                      color: "#fbb022",
                                                      cursor: "pointer"
                                                    },
                                                    attrs: {
                                                      title: "Connect Demo"
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          (scenario.status === "User Created" ||
                                            scenario.status ===
                                              "Org User Created") &&
                                          scenario.user &&
                                          scenario.password
                                            ? _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-2",
                                                    modifiers: {
                                                      "modal-2": true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "fas fa-user-secret fas-custom",
                                                staticStyle: {
                                                  color: "#fbb022",
                                                  cursor: "pointer"
                                                },
                                                attrs: {
                                                  title: "Demo details"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openUserCred(
                                                      scenario["user"],
                                                      scenario["password"],
                                                      scenario["subnet"] || ""
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          scenario["demoId"]
                                            ? _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.TerminateInstance",
                                                    modifiers: {
                                                      TerminateInstance: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "fas fa-stop-circle fas-custom",
                                                staticStyle: {
                                                  color: "red",
                                                  cursor: "pointer"
                                                },
                                                attrs: {
                                                  title: "Terminate demo"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.terminate_instance(
                                                      scenario["demoId"]
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          scenario["script"]
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-file-alt fas-custom",
                                                staticStyle: {
                                                  color: "#6bb5e4",
                                                  cursor: "pointer"
                                                },
                                                attrs: { title: "Script" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openLink(
                                                      scenario["script"]
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          scenario.snapshot &&
                                          scenario.status === "Topology Created"
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-camera fas-custom",
                                                staticStyle: {
                                                  color: "#575757",
                                                  cursor: "pointer"
                                                },
                                                attrs: { title: "Snapshot" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showSnapshot(
                                                      scenario["demoId"]
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                ]
                              ),
                              scenario.status === "User Created" ||
                              scenario.status === "Topology Created" ||
                              scenario.status === "User Session Created" ||
                              scenario.status === "Org User Created" ||
                              scenario.status === "Instance State UP"
                                ? _c(
                                    "p",
                                    { staticClass: "scenario-status-running" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scenario.status) + " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ],
          1
        ),
        _c("div", { staticClass: "right-filters-side" }, [
          _c(
            "div",
            {
              staticClass: "accordion",
              attrs: { role: "tablist", fixed: "top" }
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-1 custom-right-side-nav",
                  attrs: { "no-body": "", fixed: "top" }
                },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1 filter-heading",
                      attrs: {
                        "header-tag": "header",
                        role: "tab",
                        fixed: "top"
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true }
                            }
                          ],
                          staticClass: "card-filter",
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("FILTER BY PILLARS")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: "",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        _vm._l(_vm.menuCount, function(n) {
                          return _c(
                            "span",
                            {
                              key: n.id,
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: _vm.menuListClass[n - 1],
                                  on: {
                                    click: function($event) {
                                      return _vm.filterScenarios(
                                        _vm.menuList[n - 1]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    ref: "icon",
                                    refInFor: true,
                                    class: _vm.iconClass[n - 1],
                                    attrs: { title: _vm.menuList[n - 1] }
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.menuList[n - 1]))
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass: "mb-1 custom-right-side-nav",
                  attrs: { "no-body": "", fixed: "top" }
                },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1 filter-heading",
                      attrs: {
                        "header-tag": "header",
                        role: "tab",
                        fixed: "top"
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true }
                            }
                          ],
                          staticClass: "card-filter",
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("FILTER BY PRODUCTS")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        _vm._l(_vm.facetProducts.length, function(n) {
                          return _c(
                            "span",
                            {
                              key: n.id,
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "filter-option custom-responsive-filter" +
                                    _vm.facetProductsClass[n - 1],
                                  on: {
                                    click: function($event) {
                                      return _vm.filterProducts(
                                        _vm.facetProducts[n - 1]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.facetProducts[n - 1]))
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass: "mb-1 custom-right-side-nav",
                  attrs: { fixed: "top", "no-body": "" }
                },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1 filter-heading",
                      attrs: {
                        "header-tag": "header",
                        role: "tab",
                        fixed: "top"
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticClass: "card-filter",
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("FILTER BY VERTICALS")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        _vm._l(_vm.facetVerticals.length, function(n) {
                          return _c(
                            "span",
                            {
                              key: n.id,
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "filter-option custom-responsive-filter" +
                                    _vm.facetVerticalsClass[n - 1],
                                  on: {
                                    click: function($event) {
                                      return _vm.filterVerticals(
                                        _vm.facetVerticals[n - 1]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.facetVerticals[n - 1]))
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass: "mb-1 custom-right-side-nav",
                  attrs: { fixed: "top", "no-body": "" }
                },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1 filter-heading",
                      attrs: {
                        "header-tag": "header",
                        role: "tab",
                        fixed: "top"
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-4",
                              modifiers: { "accordion-4": true }
                            }
                          ],
                          staticClass: "card-filter",
                          attrs: { block: "", variant: "info" }
                        },
                        [_vm._v("FILTER BY OTHERS")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-4",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        _vm._l(_vm.facetOthers.length, function(n) {
                          return _c(
                            "span",
                            {
                              key: n.id,
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "filter-option custom-responsive-filter" +
                                    _vm.facetOthersClass[n - 1],
                                  on: {
                                    click: function($event) {
                                      return _vm.filterOthers(
                                        _vm.facetOthers[n - 1]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.facetOthers[n - 1]))
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm.terminateInstance
        ? _c("TerminateInstance", { attrs: { instance: _vm.instance } })
        : _vm._e(),
      _vm.ipAddress ? _c("InstanceIP") : _vm._e(),
      _vm.showUserCredModal
        ? _c(
            "div",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-2",
                    centered: "",
                    "hide-header": "",
                    "content-class": "modal-content2"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "modal-footer",
                        fn: function() {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "btn btn-primary btn-custom btn-user-cred",
                                attrs: { size: "sm" },
                                on: { click: _vm.closeUserCredModal }
                              },
                              [_vm._v(" Close ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    435782646
                  )
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", { staticStyle: { float: "right" } }, [
                          _vm._v("User Name: ")
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-6",
                          staticStyle: { "max-width": "142px !important" }
                        },
                        [_vm._v(" " + _vm._s(_vm.userName) + " ")]
                      ),
                      _c("input", {
                        attrs: { type: "hidden", id: "copy-code-username" },
                        domProps: { value: _vm.userName }
                      }),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          staticStyle: {
                            cursor: "pointer",
                            "margin-left": "6px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.copyUsername()
                            }
                          }
                        },
                        [_vm._v("content_copy")]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: { name: "fade" },
                          on: { enter: _vm.enterUserName }
                        },
                        [
                          _vm.copyUserName
                            ? _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "0.8vw",
                                    "margin-top": "3px",
                                    "margin-left": "3px",
                                    color: "green",
                                    "font-weight": "500"
                                  }
                                },
                                [_vm._v(" copied! ")]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("strong", { staticStyle: { float: "right" } }, [
                        _vm._v("Password:")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticStyle: { display: "inline-flex" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-6",
                            staticStyle: {
                              "max-width": "113px !important",
                              "margin-top": "3px"
                            }
                          },
                          [_vm._v(" ***************** ")]
                        ),
                        _c("input", {
                          attrs: { type: "hidden", id: "copy-code" },
                          domProps: { value: _vm.password }
                        }),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: {
                              cursor: "pointer",
                              "margin-left": "35px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.togglePassword()
                              }
                            }
                          },
                          [_vm._v("content_copy")]
                        ),
                        _c(
                          "transition",
                          {
                            attrs: { name: "fade" },
                            on: { enter: _vm.enterPassword }
                          },
                          [
                            _vm.copyPassword
                              ? _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-size": "0.8vw",
                                      "margin-top": "3px",
                                      "margin-left": "3px",
                                      color: "green",
                                      "font-weight": "500"
                                    }
                                  },
                                  [_vm._v(" copied! ")]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.subnet
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", { staticStyle: { float: "right" } }, [
                            _vm._v("Subnet:")
                          ])
                        ]),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-flex" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                staticStyle: {
                                  "max-width": "113px !important",
                                  "margin-top": "3px"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.subnet) + " ")]
                            ),
                            _c("input", {
                              attrs: { type: "hidden", id: "copy-code-subnet" },
                              domProps: { value: _vm.subnet }
                            }),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons",
                                staticStyle: {
                                  cursor: "pointer",
                                  "margin-left": "35px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleSubnet()
                                  }
                                }
                              },
                              [_vm._v("content_copy")]
                            ),
                            _c(
                              "transition",
                              {
                                attrs: { name: "fade" },
                                on: { enter: _vm.enterSubnet }
                              },
                              [
                                _vm.copySubnet
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-size": "0.8vw",
                                          "margin-top": "3px",
                                          "margin-left": "3px",
                                          color: "green",
                                          "font-weight": "500"
                                        }
                                      },
                                      [_vm._v(" copied! ")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }