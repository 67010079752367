<template>
  <div class="snapshotCreation">
    <b-modal
      centered
      content-class="snapshotCreation"
      title="Snapshot Creation"
      v-model="showSnapshot"
      @hide="closeSnapshot()"
    >
      <p>
        Make sure all your nodes are shutdown on GNS3 before creating a snapshot
      </p>
      <div class="snapText" v-if="snapshotNbr < 3">
        <div class="name">
          <p>Name:</p>
          <input
            class="w3-input w3-border-0"
            type="text"
            v-model="snapshotName"
            placeholder="Name of the snapshot"
          />
        </div>
        <div class="description">
          <p>Description:</p>
          <textarea
            v-model="snapshotDescription"
            placeholder="add a description to your snapshot"
          ></textarea>
        </div>
        <p class="feedback" v-if="feedback">{{ feedback }}</p>
      </div>
      <template #modal-footer content-class="modal-footer1">
        <b-button
          class="btn btn-primary btn-custom btn-user-cred"
          size="sm"
          v-on:click="snapshotCreation()"
          v-if="snapshotNbr < 3"
        >
          Create
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapGetters, mapState } from 'vuex'

export default {
  name: 'snapshotCreation',
  data () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      feedback: null,
      snapshotName: '',
      snapshotDescription: '',
      input: {
        instanceId: '',
        name: '',
        description: '',
        username: '',
        emailAddress: '',
        demo_purpose: '',
        demo_purpose_customer_name: '',
        demo_purpose_customer_url: ''
      }
    }
  },
  computed: {
    ...mapGetters(['snapshotNbr', 'mailUserName']),
    ...mapState([
      'instance',
      'demoPurposeRecord',
      'createSnapshot'
    ]),
    showSnapshot: {
      get: function () {
        return this.createSnapshot
      },
      set: function (newValue) {
        return this.createSnapshot
      }
    }
  },
  methods: {
    ...mapMutations(['SELECT_INSTANCE', 'UPDATE_CREATE_SNAPSHOT', 'SELECT_DEMO_PURPOSE']),
    alphanumeric (inputtxt) {
      const letterNumber = /^[0-9a-zA-Z]+$/
      if (inputtxt.match(letterNumber)) {
        return true
      } else {
        return false
      }
    },
    snapshotCreation () {
      this.input.username = this.mailUserName
      this.input.emailAddress = this.$msal.data.user.userName
      this.input.instanceId = this.instance
      this.input.name = this.snapshotName
      this.input.description = this.snapshotDescription
      this.input = {
        ...this.input,
        ...this.demoPurposeRecord
      }
      if (this.snapshotName === '') {
        this.feedback = 'Please save the snapshot with a name'
        return
      }
      if (!this.alphanumeric(this.snapshotName)) {
        this.feedback = 'Please use only Alphanumeric characters'
        return
      }
      axios({
        method: 'POST',
        url: this.backendURL + 'snapshot',
        data: this.input,
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.response = result.data
        }
      )
      this.closeSnapshot()
    },
    closeSnapshot () {
      this.UPDATE_CREATE_SNAPSHOT(false)
      this.SELECT_INSTANCE('')
      const demoPurposeRecord = {
        demo_purpose: '',
        demo_purpose_customer_name: '',
        demo_purpose_customer_url: ''
      }
      this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
    }
  },
  mounted () {
    if (this.snapshotNbr > 2) {
      this.feedback =
        'You have exceeded your limit of 3 snapshots. Please delete an older one to proceed.'
    }
  }
}
</script>
<style>
.snapshotCreation .snapText .name {
  display: inline-flex;
}
.snapshotCreation .snapText .name input {
  margin-top: -11px;
  margin-left: 15px;
  border: 0;
  font-size: 14.5px;
}

.snapshotCreation .snapText .description textarea {
  border: 0;
  width: 90%;
}

.snapshotCreation .snapText .feedback {
  color: red;
  text-align: center;
  font: bold;
}
</style>
