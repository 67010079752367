<template>
  <div>
    <keep-alive>
      <FeatureDemoList v-if="scenario.scenarioId == null" />
    </keep-alive>
    <Demo v-if="scenario.scenarioId !== null" />
  </div>
</template>

<script>
import { scenarioStore } from '@/store/scenarioStore.js'
import FeatureDemoList from '@/components/FeatureDemoList'
import Demo from '@/components/Demo'
import { mapState } from 'vuex'

export default {
  name: 'FeatureDemo',
  computed: {
    ...mapState(['category'])
  },
  data () {
    return {
      scenario: scenarioStore.scenario
    }
  },
  components: {
    FeatureDemoList,
    Demo
  },
  methods: {}
}
</script>
<style>
.SandboxLabs {
  text-align: center;
}
</style>
