var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          staticStyle: {
            height: "auto",
            "flex-direction": "column",
            "padding-top": "10px"
          },
          attrs: {
            "content-class": "mt-3 roi-tab-contain",
            "nav-class": "roi-tabs",
            fill: ""
          }
        },
        [
          _c(
            "b-tab",
            { key: "1", attrs: { title: "SaaS WFA-WFH", active: "" } },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-3 scenario-text" },
                    [
                      _c("b-col", [
                        _c("strong", [_vm._v("Scenario: ")]),
                        _vm._v(
                          " SaaS usage WFH/WFA leveraging Riverbed SaaS Accelerator and Client Accelerator, monthly costs"
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3", attrs: { "align-h": "start" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "color-highlight-orange",
                          attrs: { cols: "auto", md: "auto" }
                        },
                        [_vm._v("Riverbed SaaS / Client Accelerator")]
                      ),
                      _c("div", { staticClass: "w-100" }),
                      _c(
                        "b-col",
                        {
                          staticStyle: {
                            color: "gray",
                            "font-size": "13px",
                            "margin-bottom": "10px"
                          },
                          attrs: { cols: "auto", md: "auto" }
                        },
                        [_vm._v("v2.4, May 5th, 2021")]
                      ),
                      _c("div", { staticClass: "w-100" }),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "font-weight": "600" },
                          attrs: { cols: "auto", md: "auto" }
                        },
                        [_vm._v("Note: Please enter data in marked cells")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "contain-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4", md: "4" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline"
                                  }
                                },
                                [_vm._v("Unoptimized")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticStyle: { "padding-left": "12px" },
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("Number of (concurrent) users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.concurrentUser,
                                      callback: function($$v) {
                                        _vm.concurrentUser = $$v
                                      },
                                      expression: "concurrentUser"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("b-col", { attrs: { cols: "3", md: "3" } }, [
                                _vm._v("SaaS/WFA/WFH")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("O365 users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.o356Users,
                                      callback: function($$v) {
                                        _vm.o356Users = $$v
                                      },
                                      expression: "o356Users"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.o356Users *
                                          _vm.appUnitsPerUser["o365"]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("MS Teams & Stream users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.MSStreamUsers,
                                      callback: function($$v) {
                                        _vm.MSStreamUsers = $$v
                                      },
                                      expression: "MSStreamUsers"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.MSStreamUsers *
                                          _vm.appUnitsPerUser[
                                            "teamAndStreamVideo"
                                          ]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("MS Dynamics users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.MSDynamicUsers,
                                      callback: function($$v) {
                                        _vm.MSDynamicUsers = $$v
                                      },
                                      expression: "MSDynamicUsers"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.MSDynamicUsers *
                                          _vm.appUnitsPerUser["MSDynamics"]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("SalesForce users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.salesForceUsers,
                                      callback: function($$v) {
                                        _vm.salesForceUsers = $$v
                                      },
                                      expression: "salesForceUsers"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.salesForceUsers *
                                          _vm.appUnitsPerUser["salesForce"]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("ServiceNow users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.serviceNowUser,
                                      callback: function($$v) {
                                        _vm.serviceNowUser = $$v
                                      },
                                      expression: "serviceNowUser"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.serviceNowUser *
                                          _vm.appUnitsPerUser["serviceNow"]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("Veeva users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.veevaUsers,
                                      callback: function($$v) {
                                        _vm.veevaUsers = $$v
                                      },
                                      expression: "veevaUsers"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.veevaUsers *
                                          _vm.appUnitsPerUser["veeva"]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "padding-left",
                                  attrs: { cols: "6", md: "6" }
                                },
                                [_vm._v("Box users")]
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.boxUsers,
                                      callback: function($$v) {
                                        _vm.boxUsers = $$v
                                      },
                                      expression: "boxUsers"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.boxUsers *
                                          _vm.appUnitsPerUser["box"]
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                                _vm._v("Combined number of SaaS users")
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  staticStyle: { "padding-right": "0px" },
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        parseInt(_vm.o356Users) +
                                          parseInt(_vm.MSStreamUsers) +
                                          parseInt(_vm.MSDynamicUsers) +
                                          parseInt(_vm.salesForceUsers) +
                                          parseInt(_vm.serviceNowUser) +
                                          parseInt(_vm.veevaUsers) +
                                          parseInt(_vm.boxUsers)
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.conevertNumberToComaSepa(
                                        _vm.getCombineNumberOfSaasUserPerUnit()
                                      )
                                    ) + " AppUnits"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                                _vm._v("Number of WFA/WFH users")
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.WFAUsers,
                                      callback: function($$v) {
                                        _vm.WFAUsers = $$v
                                      },
                                      expression: "WFAUsers"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(Math.ceil(_vm.WFAUsers / 4000)) +
                                      " CA vController"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                                _vm._v("Weekly work time")
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.weeklyWorkTime,
                                      callback: function($$v) {
                                        _vm.weeklyWorkTime = $$v
                                      },
                                      expression: "weeklyWorkTime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("hours")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                                _vm._v("SaaS/WFA/WFH utilization per hour")
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    model: {
                                      value: _vm.SaasUtilizationPerHour,
                                      callback: function($$v) {
                                        _vm.SaasUtilizationPerHour = $$v
                                      },
                                      expression: "SaasUtilizationPerHour"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("Minute")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                                _vm._v("Average salary per user")
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "3", md: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "user" },
                                    on: { change: _vm.averageSalaryHandler },
                                    model: {
                                      value: _vm.averageSalaryPerUser,
                                      callback: function($$v) {
                                        _vm.averageSalaryPerUser = $$v
                                      },
                                      expression: "averageSalaryPerUser"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("US-$")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4", md: "4" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline"
                                  },
                                  attrs: { cols: "7", md: "7" }
                                },
                                [
                                  _vm._v(
                                    "Optimized with Riverbed SaaS/Client Accelerator"
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "5", md: "5" }
                                },
                                [_vm._v("monthly")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("Optimization rate")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    attrs: { placeholder: "rate" },
                                    on: { update: _vm.optimizationRateHandler },
                                    model: {
                                      value: _vm.optimizationRate,
                                      callback: function($$v) {
                                        _vm.optimizationRate = $$v
                                      },
                                      expression: "optimizationRate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "2", md: "2" }
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "cal-input",
                                    staticStyle: {
                                      "padding-right": "0px !important"
                                    },
                                    on: {
                                      update: _vm.currencyPercentageHandler
                                    },
                                    model: {
                                      value: _vm.currencyPercentage,
                                      callback: function($$v) {
                                        _vm.currencyPercentage = $$v
                                      },
                                      expression: "currencyPercentage"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS Accelerator for O365 users")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "o356Users"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [
                                  _vm._v(
                                    "Saas Accelerator for MS Teams & Streams"
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "MSStreamUsers"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [
                                  _vm._v(
                                    "SaaS Accelerator for MS Dynamcis users"
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "MSDynamicUsers"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [
                                  _vm._v(
                                    "SaaS Acccelerator for SalesForce users"
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "salesForceUsers"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [
                                  _vm._v(
                                    "SaaS Accelerator for ServiceNow users"
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "serviceNowUser"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS Accelerator for Veea users")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "veevaUsers"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS Accelerator for Box users")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.getSaasAccCostMonthlyO365(
                                            _vm.combineNumberOfSaasUserPerUnit,
                                            "boxUsers"
                                          )
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS Accelerator combined")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getRound(
                                          _vm.saasAcceleratorCombined()
                                        )
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("Client Accelerator users")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.clientAcceleratorUsers()
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS + Client Accelerator")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.saasAcceleratorCombined() +
                                          _vm.clientAcceleratorUsers()
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS Accelerator combined per user")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getRound(
                                        _vm.saasAcceleratorCombined() /
                                          _vm.concurrentUser
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "color-highlight-orange",
                                  attrs: { cols: "7", md: "7" }
                                },
                                [_vm._v("SaaS + Client Accelerator per user")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getRound(
                                        (_vm.saasAcceleratorCombined() +
                                          _vm.clientAcceleratorUsers()) /
                                          _vm.concurrentUser
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { border: "0px", "padding-left": "7px" },
                          attrs: { cols: "4", md: "4" }
                        },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline"
                                  },
                                  attrs: { cols: "8", md: "8" }
                                },
                                [_vm._v("Difference Unoptimized vs. Optimized")]
                              ),
                              _c("b-col", { attrs: { cols: "4", md: "4" } }, [
                                _vm._v("monthly")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }, [
                                _vm._v("User work time saved with optimization")
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "end-text-align",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("1,064.0")]
                              ),
                              _c("b-col", { attrs: { cols: "1", md: "1" } }, [
                                _vm._v("hours")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }, [
                                _vm._v("Salary cost savings")
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "end-text-align color-highlight-green",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("-21,000.00")]
                              ),
                              _c("b-col", { attrs: { cols: "1", md: "1" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }, [
                                _vm._v(
                                  "SaaS Accelerator cost difference incl. saved salary"
                                )
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "end-text-align color-highlight-green",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("-16,762.00")]
                              ),
                              _c("b-col", { attrs: { cols: "1", md: "1" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-border-bottom",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }),
                              _c("b-col", {
                                staticClass: "end-text-align",
                                attrs: { cols: "3", md: "3" }
                              }),
                              _c("b-col", { attrs: { cols: "1", md: "1" } })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "class-bold",
                              attrs: { "align-h": "start" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { cols: "8", md: "8" }
                                },
                                [
                                  _vm._v(
                                    "SaaS + Client Accelerator cost difference incl. saved salary"
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "end-text-align color-highlight-green",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("-13,030.00")]
                              ),
                              _c("b-col", { attrs: { cols: "1", md: "1" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }, [
                                _vm._v(
                                  "SaaS Accelerator cost difference per user"
                                )
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "end-text-align color-highlight-green",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("-13.97")]
                              ),
                              _c("b-col", { attrs: { cols: "1", md: "1" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { attrs: { "align-h": "start" } },
                            [
                              _c("b-col", { attrs: { cols: "8", md: "8" } }, [
                                _vm._v(
                                  "SaaS + Client Accelerator cost difference per user"
                                )
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "end-text-align color-highlight-green",
                                  attrs: { cols: "3", md: "3" }
                                },
                                [_vm._v("-10.86")]
                              ),
                              _c("b-col", { attrs: { cols: "1", md: "1" } }, [
                                _vm._v("US-$")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-tab", { key: "2", attrs: { title: "Data egress", lazy: "" } }, [
            _c("p", [_vm._v("Data egress")])
          ]),
          _c(
            "b-tab",
            { key: "3", attrs: { title: "Internet cloud usage", lazy: "" } },
            [_c("p", [_vm._v("Internet cloud usage")])]
          ),
          _c(
            "b-tab",
            { key: "4", attrs: { title: "Hybrid Cloud", lazy: "" } },
            [_c("p", [_vm._v("Hybrid Cloud")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }