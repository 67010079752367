<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['userRolesChartData'],
  mounted () {
    this.renderChart(
      {
        labels: this.userRolesChartData.labels,
        datasets: [
          {
            backgroundColor: this.userRolesChartData.backgroundColor,
            data: this.userRolesChartData.data
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        title: {
          display: true,
          text: 'Total: ' + this.userRolesChartData.total + ' Users'
        }
      }
    )
  }
}
</script>
