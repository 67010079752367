<template>
  <div>
    <div>
      <b-modal
        id="TerminateInstance"
        centered
        content-class="terminateInstance"
        title="Terminate Instance"
        @hide="closeInstance"
      >
        <p>Please confirm you want to terminate the demo environment.</p>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            size="sm"
            v-on:click="terminateInstance"
          >
            Confirm
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
  name: 'terminateInstance',
  data () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      input: {
        instanceId: 0
      }
    }
  },
  props: {
    instance: {
      type: String,
      required: true
    }
  },
  methods: {
    closeInstance () {
      this.SELECT_INSTANCE('')
      this.UPDATE_TERMINATE_INSTANCE(false)
    },
    terminateInstance () {
      this.input.instanceId = this.instance

      axios({
        method: 'DELETE',
        url: this.backendURL + 'topology/delete',
        data: this.input,
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        // crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.response = result.data
        },
        (error) => {
          console.error(error)
        }
      )
      this.closeInstance()
    },
    ...mapMutations(['SELECT_INSTANCE', 'UPDATE_TERMINATE_INSTANCE'])
  },
  created () {
    this.$bvModal.show('TerminateInstance')
  }
}
</script>
