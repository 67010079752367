var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-modal",
          {
            attrs: {
              id: "modal-preview-image",
              centered: "",
              "content-class": "report-incident",
              title: "Incident Report",
              "hide-footer": "",
              "hide-header": ""
            }
          },
          [_c("img", { ref: "previewImage" })]
        )
      ],
      1
    ),
    _c("div", { staticClass: "chatbot-div" }, [
      _c("div", { staticClass: "chatbot-header" }, [
        _c(
          "span",
          {
            staticClass: "material-icons",
            staticStyle: { "margin-left": "20px", "margin-top": "7px" },
            attrs: { title: "SteelDemo Support" }
          },
          [_vm._v(" support_agent ")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "margin-top": "9px",
              "margin-left": "9px",
              color: "#ff681d",
              "font-weight": "500",
              cursor: "pointer"
            },
            on: { click: _vm.toggleChatbot }
          },
          [_vm._v("SteelDemo Support")]
        ),
        !_vm.showChatbot && _vm.newMessage
          ? _c(
              "span",
              {
                staticClass: "badge badge-info",
                staticStyle: {
                  color: "white",
                  "background-color": "forestgreen",
                  "margin-top": "10px"
                }
              },
              [_vm._v("New ")]
            )
          : _vm._e(),
        _vm.showChatbot
          ? _c(
              "i",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.modal-4",
                    modifiers: { "modal-4": true }
                  }
                ],
                staticClass: "material-icons incident-class",
                style:
                  _vm.reportSession === 1
                    ? " cursor: not-allowed;"
                    : "cursor: pointer;",
                attrs: {
                  title: "Report an Incident",
                  disabled: _vm.reportSession === 1
                }
              },
              [_vm._v(" report_problem ")]
            )
          : _vm._e(),
        _vm.showChatbot
          ? _c(
              "i",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.modal-5",
                    modifiers: { "modal-5": true }
                  }
                ],
                staticClass: "material-icons",
                staticStyle: {
                  "line-height": "normal",
                  "margin-left": "4%",
                  "margin-top": "7px",
                  cursor: "pointer",
                  color: "#4646d2"
                },
                attrs: { title: "Feature Request" }
              },
              [_vm._v(" engineering ")]
            )
          : _vm._e(),
        _vm.showChatbot
          ? _c(
              "i",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.modal-6",
                    modifiers: { "modal-6": true }
                  }
                ],
                staticClass: "material-icons",
                staticStyle: {
                  "line-height": "normal",
                  "margin-left": "4%",
                  "margin-top": "7px",
                  color: "green"
                },
                style:
                  _vm.reportSession === 0
                    ? " cursor: not-allowed;"
                    : "cursor: pointer;",
                attrs: {
                  title: "close incident",
                  disabled: _vm.reportSession === 0
                }
              },
              [_vm._v(" done ")]
            )
          : _vm._e(),
        _vm.showChatbot
          ? _c(
              "span",
              {
                staticClass: "material-icons chatbot-toggle",
                attrs: { title: "minimize" },
                on: { click: _vm.toggleChatbot }
              },
              [_vm._v(" keyboard_arrow_down ")]
            )
          : _c(
              "span",
              {
                staticClass: "material-icons chatbot-toggle",
                staticStyle: { "margin-left": "auto", "margin-right": "10px" },
                attrs: { title: "maximize" },
                on: { click: _vm.toggleChatbotMax }
              },
              [_vm._v(" keyboard_arrow_up ")]
            )
      ]),
      _vm.showChatbot
        ? _c("section", { staticClass: "chat-box" }, [
            _c(
              "div",
              { ref: "chatbox", staticClass: "chat-box-list-container" },
              [
                _c(
                  "ul",
                  { staticClass: "chat-box-list" },
                  _vm._l(_vm.messages, function(message, idx) {
                    return _c(
                      "li",
                      {
                        key: idx,
                        staticClass: "message",
                        class: message.author
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "para-custom" },
                          [
                            _vm._l(message.images, function(image, key) {
                              return _c(
                                "span",
                                { key: key, staticClass: "img-wrap" },
                                [
                                  _c("img", {
                                    staticClass: "preview card-image",
                                    staticStyle: { width: "200px" },
                                    attrs: { src: image }
                                  })
                                ]
                              )
                            }),
                            message.summary
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "span-custom",
                                    domProps: {
                                      innerHTML: _vm._s(message.summary)
                                    }
                                  },
                                  [_vm._v(_vm._s(message.summary))]
                                )
                              : _vm._e(),
                            message.showOption
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "btn-group",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "Basic example"
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-outline-warning option-button",
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal.modal-4",
                                                modifiers: { "modal-4": true }
                                              }
                                            ],
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row"
                                            }
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                staticStyle: {
                                                  "line-height": "normal"
                                                },
                                                attrs: {
                                                  title: "Report an Incident"
                                                }
                                              },
                                              [_vm._v(" report_problem ")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "padding-left":
                                                    "6px !important",
                                                  "margin-top": "4px !important"
                                                }
                                              },
                                              [_vm._v("Report an Incident")]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-outline-warning option-button",
                                        staticStyle: {
                                          "margin-left": "8px",
                                          "margin-right": "-7px"
                                        },
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal.modal-5",
                                                modifiers: { "modal-5": true }
                                              }
                                            ],
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row"
                                            }
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                staticStyle: {
                                                  "line-height": "normal"
                                                },
                                                attrs: {
                                                  title: "Feature Request"
                                                }
                                              },
                                              [_vm._v(" engineering ")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "padding-left":
                                                    "6px !important",
                                                  "margin-top": "4px !important"
                                                }
                                              },
                                              [_vm._v("Feature Request")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _c("div", { staticStyle: { display: "grid" } }, [
              _vm.reportSession === 1
                ? _c(
                    "div",
                    {
                      staticClass: "float-right",
                      staticStyle: { opacity: "0.6", "font-size": "12px" }
                    },
                    [
                      _vm._v(
                        " Note: Please close current incident to create new * "
                      )
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "chat-inputs" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message"
                    }
                  ],
                  staticClass: "input-custom",
                  staticStyle: { "padding-top": "10px" },
                  attrs: {
                    placeholder: "Reply...",
                    disabled: _vm.reportSession === 0
                  },
                  domProps: { value: _vm.message },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.sendMessage.apply(null, arguments)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.message = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  attrs: {
                    type: "file",
                    id: "fileUpload",
                    multiple: "",
                    hidden: ""
                  },
                  on: { change: _vm.previewChatImage }
                }),
                _c(
                  "span",
                  {
                    staticClass: "material-icons button-custom",
                    attrs: { title: "upload image" },
                    on: {
                      click: function($event) {
                        return _vm.chooseFiles()
                      }
                    }
                  },
                  [_vm._v("image")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "material-icons button-custom",
                    attrs: { title: "send" },
                    on: { click: _vm.sendMessage }
                  },
                  [_vm._v(" send ")]
                )
              ]),
              _c(
                "div",
                { staticClass: "card-row" },
                _vm._l(_vm.chatImages, function(image, key) {
                  return _c("div", { key: key, staticClass: "img-wrap" }, [
                    _c(
                      "span",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.removeChatImage(image, key)
                          }
                        }
                      },
                      [_vm._v("×")]
                    ),
                    _c("img", {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-preview-image",
                          modifiers: { "modal-preview-image": true }
                        }
                      ],
                      ref: "chatImages",
                      refInFor: true,
                      staticClass: "preview card-image",
                      on: {
                        click: function($event) {
                          return _vm.previewChatEnlargeImage(image, key)
                        }
                      }
                    })
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      [
        _vm.showIncidentReport
          ? _c(
              "b-modal",
              {
                attrs: {
                  id: "modal-4",
                  "modal-class": "modal-custom",
                  centered: "",
                  "content-class": "report-incident ",
                  title: "Incident Report",
                  "hide-footer": "",
                  "hide-header-close": "",
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                  "no-enforce-focus": "",
                  "no-stacking": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modal-title",
                      fn: function() {
                        return [
                          _c(
                            "h5",
                            {
                              staticStyle: {
                                "margin-top": "-20px",
                                color: "#ff681d !important"
                              }
                            },
                            [_vm._v(" INCIDENT REPORT ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1467419841
                )
              },
              [
                _vm.showIncidentReport
                  ? _c(
                      "b-form",
                      {
                        on: {
                          submit: _vm.onSubmitIncidentReport,
                          reset: _vm.onResetIncidentReport
                        }
                      },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-1",
                              label: "Incident Type:",
                              "label-for": "input-1",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "input-1",
                                options: _vm.incidentType,
                                required: ""
                              },
                              model: {
                                value: _vm.form.incidentType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "incidentType", $$v)
                                },
                                expression: "form.incidentType"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-2",
                              label: "Incident Summary:",
                              "label-for": "input-2",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "input-2",
                                placeholder: "Please write incident summary",
                                required: ""
                              },
                              model: {
                                value: _vm.form.summary,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "summary", $$v)
                                },
                                expression: "form.summary"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-3",
                              label: "Urgency of your request:",
                              "label-for": "input-3",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "input-3",
                                options: _vm.urgency,
                                required: ""
                              },
                              model: {
                                value: _vm.form.urgency,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "urgency", $$v)
                                },
                                expression: "form.urgency"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-4",
                              label: "Anything else we should know (optional):",
                              "label-for": "input-4",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "input-4",
                                placeholder: "Write something...",
                                rows: "3",
                                "max-rows": "6"
                              },
                              model: {
                                value: _vm.form.additionalInfo,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "additionalInfo", $$v)
                                },
                                expression: "form.additionalInfo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "input-group-5",
                              label: "Attach Images:",
                              "label-for": "input-5",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "",
                              "content-cols-lg": "8"
                            }
                          },
                          [
                            _c("input", {
                              attrs: { type: "file", multiple: "" },
                              on: { change: _vm.previewImage }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "card-row" },
                          _vm._l(_vm.images, function(image, key) {
                            return _c(
                              "div",
                              { key: key, staticClass: "img-wrap" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "close",
                                    on: {
                                      click: function($event) {
                                        return _vm.removeImage(image, key)
                                      }
                                    }
                                  },
                                  [_vm._v("×")]
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-preview-image",
                                      modifiers: { "modal-preview-image": true }
                                    }
                                  ],
                                  ref: "image",
                                  refInFor: true,
                                  staticClass: "preview card-image",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewEnlargeImage(image, key)
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "submit-report-custom",
                                attrs: {
                                  type: "submit",
                                  variant: "outline-success"
                                }
                              },
                              [_vm._v("Submit")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "submit-report-custom",
                                attrs: {
                                  type: "reset",
                                  variant: "outline-dark"
                                }
                              },
                              [_vm._v("Reset")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "submit-report-custom",
                                attrs: { variant: "outline-danger" },
                                on: { click: _vm.onCloseIncidentReport }
                              },
                              [_vm._v("Close")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-modal",
          {
            ref: "modal-5",
            attrs: {
              id: "modal-5",
              centered: "",
              "content-class": "report-incident",
              title: "Feature Request",
              "hide-footer": "",
              "hide-header-close": "",
              "no-close-on-backdrop": "",
              "no-close-on-esc": "",
              "no-enforce-focus": "",
              "no-stacking": ""
            },
            scopedSlots: _vm._u([
              {
                key: "modal-title",
                fn: function() {
                  return [
                    _c(
                      "h5",
                      {
                        staticStyle: {
                          "margin-top": "-20px",
                          color: "#ff681d !important"
                        }
                      },
                      [_vm._v(" FEATURE REQUEST ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _vm.showFeatureRequest
              ? _c(
                  "b-form",
                  {
                    on: {
                      submit: _vm.onSubmitFeatureRequest,
                      reset: _vm.onResetFeatureRequest
                    }
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-1",
                          label: "Request category:",
                          "label-for": "input-1",
                          "label-cols-sm": "4",
                          "label-cols-lg": "3",
                          "content-cols-sm": "",
                          "content-cols-lg": "8"
                        }
                      },
                      [
                        _c("b-form-select", {
                          attrs: {
                            id: "input-1",
                            options: _vm.requestcategory,
                            required: ""
                          },
                          model: {
                            value: _vm.form2.requestcategory,
                            callback: function($$v) {
                              _vm.$set(_vm.form2, "requestcategory", $$v)
                            },
                            expression: "form2.requestcategory"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-2",
                          label: "Summary of your request:",
                          "label-for": "input-2",
                          "label-cols-sm": "4",
                          "label-cols-lg": "3",
                          "content-cols-sm": "",
                          "content-cols-lg": "8"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-2",
                            placeholder: "Please write your request summary",
                            required: ""
                          },
                          model: {
                            value: _vm.form2.requestSummary,
                            callback: function($$v) {
                              _vm.$set(_vm.form2, "requestSummary", $$v)
                            },
                            expression: "form2.requestSummary"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-3",
                          label: "Urgency of your request:",
                          "label-for": "input-3",
                          "label-cols-sm": "4",
                          "label-cols-lg": "3",
                          "content-cols-sm": "",
                          "content-cols-lg": "8"
                        }
                      },
                      [
                        _c("b-form-select", {
                          attrs: {
                            id: "input-3",
                            options: _vm.urgencyRequest,
                            required: ""
                          },
                          model: {
                            value: _vm.form2.urgencyRequest,
                            callback: function($$v) {
                              _vm.$set(_vm.form2, "urgencyRequest", $$v)
                            },
                            expression: "form2.urgencyRequest"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-4",
                          label: "Anything else we should know (optional):",
                          "label-for": "input-4",
                          "label-cols-sm": "4",
                          "label-cols-lg": "3",
                          "content-cols-sm": "",
                          "content-cols-lg": "8"
                        }
                      },
                      [
                        _c("b-form-textarea", {
                          attrs: {
                            id: "input-4",
                            placeholder: "Write something...",
                            rows: "3",
                            "max-rows": "6"
                          },
                          model: {
                            value: _vm.form2.additionalInfoRequest,
                            callback: function($$v) {
                              _vm.$set(_vm.form2, "additionalInfoRequest", $$v)
                            },
                            expression: "form2.additionalInfoRequest"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { float: "right" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "submit-report-custom",
                            attrs: {
                              type: "submit",
                              variant: "outline-success"
                            }
                          },
                          [_vm._v("Submit")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "submit-report-custom",
                            attrs: { type: "reset", variant: "outline-dark" }
                          },
                          [_vm._v("Reset")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "submit-report-custom",
                            attrs: { variant: "outline-danger" },
                            on: { click: _vm.onCloseFeatureRequest }
                          },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.showCloseIncident
          ? _c(
              "b-modal",
              {
                attrs: { id: "modal-6", centered: "", title: "Close Incident" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modal-footer",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "btn btn-primary btn-custom btn-user-cred",
                              attrs: { size: "sm" },
                              on: { click: _vm.closeIncident }
                            },
                            [_vm._v(" Confirm ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3260769294
                )
              },
              [
                _c("p", [
                  _vm._v("Please confirm you want to close the incident.")
                ])
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }