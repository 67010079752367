<template>
  <div>
    <b-tabs
      content-class="mt-3 roi-tab-contain"
      style="height: auto; flex-direction: column; padding-top: 10px"
      nav-class="roi-tabs"
      fill
    >
      <b-tab title="SaaS WFA-WFH" active key="1">
        <b-container fluid>
          <b-row class="mb-3 scenario-text">
            <b-col
              ><strong>Scenario: </strong> SaaS usage WFH/WFA leveraging
              Riverbed SaaS Accelerator and Client Accelerator, monthly
              costs</b-col
            >
          </b-row>
          <b-row align-h="start" class="mb-3">
            <b-col cols="auto" md="auto" class="color-highlight-orange"
              >Riverbed SaaS / Client Accelerator</b-col
            >
            <div class="w-100"></div>
            <b-col
              cols="auto"
              md="auto"
              style="color: gray; font-size: 13px; margin-bottom: 10px"
              >v2.4, May 5th, 2021</b-col
            >
            <div class="w-100"></div>
            <b-col cols="auto" md="auto" style="font-weight: 600"
              >Note: Please enter data in marked cells</b-col
            >
          </b-row>
          <b-row class="contain-row">
            <b-col cols="4" md="4"
              ><b-row align-h="start" class="class-bold class-border-bottom"
                ><b-col style="text-decoration: underline"
                  >Unoptimized</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" style="padding-left: 12px"
                  >Number of (concurrent) users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="concurrentUser"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3">SaaS/WFA/WFH</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left">O365 users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="o356Users"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      o356Users * appUnitsPerUser["o365"]
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left"
                  >MS Teams & Stream users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="MSStreamUsers"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      MSStreamUsers * appUnitsPerUser["teamAndStreamVideo"]
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left"
                  >MS Dynamics users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="MSDynamicUsers"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      MSDynamicUsers * appUnitsPerUser["MSDynamics"]
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left"
                  >SalesForce users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="salesForceUsers"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      salesForceUsers * appUnitsPerUser["salesForce"]
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left"
                  >ServiceNow users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="serviceNowUser"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      serviceNowUser * appUnitsPerUser["serviceNow"]
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left">Veeva users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="veevaUsers"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      veevaUsers * appUnitsPerUser["veeva"]
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6" class="padding-left">Box users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="boxUsers"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(boxUsers * appUnitsPerUser["box"])
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6">Combined number of SaaS users</b-col
                ><b-col
                  cols="3"
                  md="3"
                  class="end-text-align"
                  style="padding-right: 0px"
                  >{{
                    conevertNumberToComaSepa(
                      parseInt(o356Users) +
                        parseInt(MSStreamUsers) +
                        parseInt(MSDynamicUsers) +
                        parseInt(salesForceUsers) +
                        parseInt(serviceNowUser) +
                        parseInt(veevaUsers) +
                        parseInt(boxUsers)
                    )
                  }}</b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{
                    conevertNumberToComaSepa(
                      getCombineNumberOfSaasUserPerUnit()
                    )
                  }}
                  AppUnits</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6">Number of WFA/WFH users</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="WFAUsers"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >{{ Math.ceil(WFAUsers / 4000) }} CA vController</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6">Weekly work time</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="weeklyWorkTime"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >hours</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6">SaaS/WFA/WFH utilization per hour</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="SaasUtilizationPerHour"
                    placeholder="user"
                    class="cal-input"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >Minute</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="6" md="6">Average salary per user</b-col
                ><b-col cols="3" md="3"
                  ><b-form-input
                    v-model="averageSalaryPerUser"
                    placeholder="user"
                    class="cal-input"
                    @change="averageSalaryHandler"
                  ></b-form-input></b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  >US-$</b-col
                ></b-row
              >
            </b-col>
            <b-col cols="4" md="4"
              ><b-row align-h="start" class="class-bold class-border-bottom"
                ><b-col cols="7" md="7" style="text-decoration: underline"
                  >Optimized with Riverbed SaaS/Client Accelerator</b-col
                >
                <b-col cols="5" md="5" class="end-text-align"
                  >monthly</b-col
                ></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >Optimization rate</b-col
                ><b-col cols="3" md="3" class="end-text-align"
                  ><b-form-input
                    v-model="optimizationRate"
                    placeholder="rate"
                    class="cal-input"
                    @update="optimizationRateHandler"
                  ></b-form-input></b-col
                ><b-col cols="2" md="2" class="end-text-align"
                  ><b-form-input
                    v-model="currencyPercentage"
                    class="cal-input"
                    style="padding-right: 0px !important"
                    @update="currencyPercentageHandler"
                  >
                  </b-form-input
                ></b-col>
              </b-row>
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator for O365 users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "o356Users"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >Saas Accelerator for MS Teams & Streams</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "MSStreamUsers"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator for MS Dynamcis users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "MSDynamicUsers"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Acccelerator for SalesForce users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "salesForceUsers"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator for ServiceNow users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "serviceNowUser"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator for Veea users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "veevaUsers"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start" class="class-border-bottom"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator for Box users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(
                    getSaasAccCostMonthlyO365(
                      combineNumberOfSaasUserPerUnit,
                      "boxUsers"
                    )
                  ))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start" class="class-bold"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator combined</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(getRound(saasAcceleratorCombined()))
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start" class="class-border-bottom"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >Client Accelerator users</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(clientAcceleratorUsers())
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start" class="class-bold"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS + Client Accelerator</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  formatCurrency(saasAcceleratorCombined() + clientAcceleratorUsers())
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS Accelerator combined per user</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  getRound(saasAcceleratorCombined() / concurrentUser)
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="7" md="7" class="color-highlight-orange"
                  >SaaS + Client Accelerator per user</b-col
                ><b-col cols="3" md="3" class="end-text-align">{{
                  getRound(
                    (saasAcceleratorCombined() + clientAcceleratorUsers()) /
                      concurrentUser
                  )
                }}</b-col
                ><b-col cols="2" md="2">US-$</b-col></b-row
              >
            </b-col>
            <b-col cols="4" md="4" style="border: 0px; padding-left: 7px"
              ><b-row align-h="start" class="class-bold class-border-bottom"
                ><b-col cols="8" md="8" style="text-decoration: underline"
                  >Difference Unoptimized vs. Optimized</b-col
                >
                <b-col cols="4" md="4">monthly</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="8" md="8"
                  >User work time saved with optimization</b-col
                ><b-col cols="3" md="3" class="end-text-align">1,064.0</b-col
                ><b-col cols="1" md="1">hours</b-col>
              </b-row>
              <b-row align-h="start"
                ><b-col cols="8" md="8">Salary cost savings</b-col
                ><b-col
                  cols="3"
                  md="3"
                  class="end-text-align color-highlight-green"
                  >-21,000.00</b-col
                ><b-col cols="1" md="1">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start" class="class-border-bottom"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start" class="class-bold"
                ><b-col cols="8" md="8"
                  >SaaS Accelerator cost difference incl. saved salary</b-col
                ><b-col
                  cols="3"
                  md="3"
                  class="end-text-align color-highlight-green"
                  >-16,762.00</b-col
                ><b-col cols="1" md="1">US-$</b-col></b-row
              >
              <b-row align-h="start" class="class-border-bottom"
                ><b-col cols="8" md="8"></b-col
                ><b-col cols="3" md="3" class="end-text-align"></b-col
                ><b-col cols="1" md="1"></b-col
              ></b-row>
              <b-row align-h="start" class="class-bold"
                ><b-col cols="8" md="8" style="font-size: 14px"
                  >SaaS + Client Accelerator cost difference incl. saved
                  salary</b-col
                ><b-col
                  cols="3"
                  md="3"
                  class="end-text-align color-highlight-green"
                  >-13,030.00</b-col
                ><b-col cols="1" md="1">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="8" md="8"
                  >SaaS Accelerator cost difference per user</b-col
                ><b-col
                  cols="3"
                  md="3"
                  class="end-text-align color-highlight-green"
                  >-13.97</b-col
                ><b-col cols="1" md="1">US-$</b-col></b-row
              >
              <b-row align-h="start"
                ><b-col cols="8" md="8"
                  >SaaS + Client Accelerator cost difference per user</b-col
                ><b-col
                  cols="3"
                  md="3"
                  class="end-text-align color-highlight-green"
                  >-10.86</b-col
                ><b-col cols="1" md="1">US-$</b-col></b-row
              >
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab title="Data egress" key="2" lazy><p>Data egress</p></b-tab>
      <b-tab title="Internet cloud usage" key="3" lazy
        ><p>Internet cloud usage</p></b-tab
      >
      <b-tab title="Hybrid Cloud" key="4" lazy><p>Hybrid Cloud</p></b-tab>
    </b-tabs>
  </div>
</template>
<script>
export default {
  name: 'ROICalculation',
  data () {
    return {
      concurrentUser: 1200,
      o356Users: 1100,
      MSStreamUsers: 0,
      MSDynamicUsers: 0,
      salesForceUsers: 500,
      serviceNowUser: 20,
      veevaUsers: 0,
      boxUsers: 10,
      WFAUsers: 1200,
      weeklyWorkTime: 38,
      SaasUtilizationPerHour: 0.5,
      averageSalaryPerUser: '3000.00',
      optimizationRate: '70%',
      optimizationRateDecimal: 0.7,
      currencyPercentage: '',
      currencyPercentageDecimal: '',
      appUnitsPerUser: {
        o365: 10,
        teamAndStreamVideo: 10,
        MSDynamics: 20,
        salesForce: 10,
        serviceNow: 10,
        veeva: 20,
        box: 5
      },
      combineNumberOfSaasUserPerUnit: 0,
      saasAccCostMonthlySum: {}
    }
  },
  methods: {
    formatCurrency: function (amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(amount)
    },
    getRound: function (number) {
      return Math.round(parseFloat(number) * 100) / 100
    },
    optimizationRateHandler: function (val) {
      this.optimizationRate =
        this.optimizationRate.toString().replace(/%/g, '') + '%'
      this.optimizationRateDecimal =
        this.optimizationRate.toString().replace(/%/g, '') / 100
    },
    currencyPercentageHandler: function (val) {
      this.currencyPercentage =
        this.currencyPercentage.toString().replace(/%/g, '') + '%'
      this.currencyPercentageDecimal =
        this.currencyPercentage.toString().replace(/%/g, '') / 100
    },
    averageSalaryHandler: function (val) {
      this.averageSalaryPerUser = this.formatCurrency(this.averageSalaryPerUser)
    },
    saasAcceleratorCombined: function () {
      return Object.values(this.saasAccCostMonthlySum).reduce((a, b) => a + b)
    },
    clientAcceleratorUsers: function () {
      return (
        this.getClientAccCost(this.WFAUsers) *
        this.WFAUsers *
        (1 - this.currencyPercentageDecimal)
      )
    },
    conevertNumberToComaSepa (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getCombineNumberOfSaasUserPerUnit () {
      this.combineNumberOfSaasUserPerUnit =
        parseInt(this.o356Users * this.appUnitsPerUser.o365) +
        parseInt(this.MSStreamUsers * this.appUnitsPerUser.teamAndStreamVideo) +
        parseInt(this.MSDynamicUsers * this.appUnitsPerUser.MSDynamics) +
        parseInt(this.salesForceUsers * this.appUnitsPerUser.salesForce) +
        parseInt(this.serviceNowUser * this.appUnitsPerUser.serviceNow) +
        parseInt(this.veevaUsers * this.appUnitsPerUser.veeva) +
        parseInt(this.boxUsers * this.appUnitsPerUser.box)
      return this.combineNumberOfSaasUserPerUnit
    },
    getSaasAccCostMonthlyO365 (unit, name) {
      if (unit >= 0 && unit <= 5999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.45 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
      if (unit >= 6000 && unit <= 9999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.3 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
      if (unit >= 10000 && unit <= 49999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.26 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
      if (unit >= 50000 && unit <= 99999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.25 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
      if (unit >= 100000 && unit <= 249999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.24 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
      if (unit >= 250000 && unit <= 599999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.23 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
      if (unit >= 600000 && unit <= 999999) {
        this.saasAccCostMonthlySum[name] =
          this.appUnitsPerUser.o365 *
          0.22 *
          this[name] *
          (1 - this.currencyPercentageDecimal)
        return this.saasAccCostMonthlySum[name]
      }
    },
    getClientAccCost (num) {
      if (num >= 0 && num <= 499) return 3.48
      if (num >= 500 && num <= 999) return 3.26
      if (num >= 1000 && num <= 1999) return 3.11
      if (num >= 2000 && num <= 3999) return 2.49
      if (num >= 4000 && num <= 9999) return 2.37
      if (num >= 10000 && num <= 29999) return 2.33
      if (num >= 30000 && num <= 49999) return 2.25
      if (num >= 50000 && num <= 99999) return 2.04
      if (num >= 100000 && num <= 999999) return 1.83
    }
  }
}
</script>
<style>
.roi-tabs .nav-link.active,
.roi-tabs .nav-item.show .nav-link {
  color: #ff681d;
}
.roi-tabs .nav-link {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}
.color-highlight-orange {
  background-color: #f29c39;
  border-radius: 7px;
}

.color-highlight-green {
  background-color: #4fac5b;
  border-radius: 7px;
}

.roi-tab-contain {
  padding: 10px;
}
.end-text-align {
  /* padding-right: 0px !important; */
  text-align: end;
}

.padding-left {
  padding-left: 30px;
}

.contain-row {
  min-width: 2000px;
  text-transform: capitalize;
}
.cal-input {
  margin: 0px !important;
  border: 1px solid #333 !important;
  padding: 0px 7px !important;
  height: fit-content !important;
  text-align: end;
}
.contain-row .col-md-4 .justify-content-start,
.contain-row .col-4 .justify-content-start {
  justify-content: flex-start !important;
  min-height: 30px;
  margin-bottom: 5px;
}

.contain-row .col-md-4,
.contain-row .col-4 {
  border-right: 1px solid black;
}

.class-border-bottom {
  border-bottom: 1px solid #333;
}

.class-bold {
  font-weight: bold;
}
/* .contain-row .col,
.contain-row .col-auto { */
/* padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2); */
/* word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word; */
/* } */
.scenario-text .col {
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
}
</style>
