<template>
  <div>
    <!-- Preview Section -->
    <div>
      <b-modal
        id="modal-preview-image"
        centered
        content-class="report-incident"
        title="Incident Report"
        hide-footer
        hide-header
      >
        <img ref="previewImage" />
      </b-modal>
    </div>

    <div class="chatbot-div">
      <div class="chatbot-header">
        <span
          class="material-icons"
          style="margin-left: 20px; margin-top: 7px"
          title="SteelDemo Support"
        >
          support_agent
        </span>
        <span
          style="
            margin-top: 9px;
            margin-left: 9px;
            color: #ff681d;
            font-weight: 500;
            cursor: pointer;
          "
          @click="toggleChatbot"
          >SteelDemo Support</span
        >
        <span
          class="badge badge-info"
          style="color: white; background-color: forestgreen; margin-top: 10px"
          v-if="!showChatbot && newMessage"
          >New
        </span>
        <i
          class="material-icons incident-class"
          title="Report an Incident"
          v-bind:style="
            reportSession === 1 ? ' cursor: not-allowed;' : 'cursor: pointer;'
          "
          v-b-modal.modal-4
          :disabled="reportSession === 1"
          v-if="showChatbot"
        >
          report_problem
        </i>
        <i
          class="material-icons"
          title="Feature Request"
          style="
            line-height: normal;
            margin-left: 4%;
            margin-top: 7px;
            cursor: pointer;
            color: #4646d2;
          "
          v-b-modal.modal-5
          v-if="showChatbot"
        >
          engineering
        </i>
        <i
          class="material-icons"
          title="close incident"
          style="
            line-height: normal;
            margin-left: 4%;
            margin-top: 7px;
            color: green;
          "
          v-bind:style="
            reportSession === 0 ? ' cursor: not-allowed;' : 'cursor: pointer;'
          "
          :disabled="reportSession === 0"
          v-b-modal.modal-6
          v-if="showChatbot"
        >
          done </i
        ><span
          v-if="showChatbot"
          class="material-icons chatbot-toggle"
          title="minimize"
          @click="toggleChatbot"
        >
          keyboard_arrow_down
        </span>
        <span
          v-else
          class="material-icons chatbot-toggle"
          title="maximize"
          @click="toggleChatbotMax"
          style="margin-left: auto; margin-right: 10px"
        >
          keyboard_arrow_up
        </span>
      </div>
      <section class="chat-box" v-if="showChatbot">
        <div class="chat-box-list-container" ref="chatbox">
          <ul class="chat-box-list">
            <li
              class="message"
              v-for="(message, idx) in messages"
              :key="idx"
              :class="message.author"
            >
              <p class="para-custom">
                <span
                  v-for="(image, key) in message.images"
                  :key="key"
                  class="img-wrap"
                >
                  <img
                    class="preview card-image"
                    style="width: 200px"
                    :src="image"
                  />
                </span>
                <span
                  class="span-custom"
                  v-if="message.summary"
                  v-html="message.summary"
                  >{{ message.summary }}</span
                >
                <span
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                  v-if="message.showOption"
                >
                  <button
                    type="button"
                    class="btn btn-outline-warning option-button"
                  >
                    <div
                      style="display: flex; flex-direction: row"
                      v-b-modal.modal-4
                    >
                      <i
                        class="material-icons"
                        title="Report an Incident"
                        style="line-height: normal"
                      >
                        report_problem </i
                      ><span
                        style="
                          padding-left: 6px !important;
                          margin-top: 4px !important;
                        "
                        >Report an Incident</span
                      >
                    </div>
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-warning option-button"
                    style="margin-left: 8px; margin-right: -7px"
                  >
                    <div
                      style="display: flex; flex-direction: row"
                      v-b-modal.modal-5
                    >
                      <i
                        class="material-icons"
                        title="Feature Request"
                        style="line-height: normal"
                      >
                        engineering
                      </i>
                      <span
                        style="
                          padding-left: 6px !important;
                          margin-top: 4px !important;
                        "
                        >Feature Request</span
                      >
                    </div>
                  </button>
                </span>
              </p>
            </li>
          </ul>
        </div>
        <div style="display: grid">
          <div
            class="float-right"
            style="opacity: 0.6; font-size: 12px"
            v-if="reportSession === 1"
          >
            Note: Please close current incident to create new *
          </div>

          <div class="chat-inputs">
            <textarea
              style="padding-top: 10px"
              class="input-custom"
              placeholder="Reply..."
              v-model="message"
              @keyup.enter="sendMessage"
              :disabled="reportSession === 0"
            >
            </textarea>

            <input
              type="file"
              id="fileUpload"
              multiple
              @change="previewChatImage"
              hidden
            />
            <span
              class="material-icons button-custom"
              title="upload image"
              @click="chooseFiles()"
              >image</span
            >
            <span
              class="material-icons button-custom"
              title="send"
              @click="sendMessage"
            >
              send
            </span>
          </div>
          <div class="card-row">
            <div v-for="(image, key) in chatImages" :key="key" class="img-wrap">
              <span class="close" @click="removeChatImage(image, key)"
                >&times;</span
              >
              <img
                class="preview card-image"
                :ref="'chatImages'"
                v-b-modal.modal-preview-image
                @click="previewChatEnlargeImage(image, key)"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <b-modal
        id="modal-4"
        modal-class="modal-custom"
        centered
        content-class="report-incident "
        title="Incident Report"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        no-stacking
        v-if="showIncidentReport"
      >
        <template #modal-title>
          <h5 style="margin-top: -20px; color: #ff681d !important">
            INCIDENT REPORT
          </h5>
        </template>
        <b-form
          @submit="onSubmitIncidentReport"
          @reset="onResetIncidentReport"
          v-if="showIncidentReport"
        >
          <b-form-group
            id="input-group-1"
            label="Incident Type:"
            label-for="input-1"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-1"
              v-model="form.incidentType"
              :options="incidentType"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Incident Summary:"
            label-for="input-2"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-input
              id="input-2"
              v-model="form.summary"
              placeholder="Please write incident summary"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Urgency of your request:"
            label-for="input-3"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-3"
              v-model="form.urgency"
              :options="urgency"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            label="Anything else we should know (optional):"
            label-for="input-4"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-textarea
              id="input-4"
              v-model="form.additionalInfo"
              placeholder="Write something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            id="input-group-5"
            label="Attach Images:"
            label-for="input-5"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <input type="file" multiple @change="previewImage" />
          </b-form-group>
          <div class="card-row">
            <div v-for="(image, key) in images" :key="key" class="img-wrap">
              <span class="close" @click="removeImage(image, key)"
                >&times;</span
              >
              <img
                class="preview card-image"
                :ref="'image'"
                v-b-modal.modal-preview-image
                @click="previewEnlargeImage(image, key)"
              />
            </div>
          </div>
          <div style="float: right">
            <b-button
              type="submit"
              variant="outline-success"
              class="submit-report-custom"
              >Submit</b-button
            >
            <b-button
              type="reset"
              variant="outline-dark"
              class="submit-report-custom"
              >Reset</b-button
            >
            <b-button
              variant="outline-danger"
              class="submit-report-custom"
              @click="onCloseIncidentReport"
              >Close</b-button
            >
          </div>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-5"
        ref="modal-5"
        centered
        content-class="report-incident"
        title="Feature Request"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        no-stacking
      >
        <template #modal-title>
          <h5 style="margin-top: -20px; color: #ff681d !important">
            FEATURE REQUEST
          </h5>
        </template>
        <b-form
          @submit="onSubmitFeatureRequest"
          @reset="onResetFeatureRequest"
          v-if="showFeatureRequest"
        >
          <b-form-group
            id="input-group-1"
            label="Request category:"
            label-for="input-1"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-1"
              v-model="form2.requestcategory"
              :options="requestcategory"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Summary of your request:"
            label-for="input-2"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-input
              id="input-2"
              v-model="form2.requestSummary"
              placeholder="Please write your request summary"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Urgency of your request:"
            label-for="input-3"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-select
              id="input-3"
              v-model="form2.urgencyRequest"
              :options="urgencyRequest"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            label="Anything else we should know (optional):"
            label-for="input-4"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
          >
            <b-form-textarea
              id="input-4"
              v-model="form2.additionalInfoRequest"
              placeholder="Write something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <div style="float: right">
            <b-button
              type="submit"
              variant="outline-success"
              class="submit-report-custom"
              >Submit</b-button
            >
            <b-button
              type="reset"
              variant="outline-dark"
              class="submit-report-custom"
              >Reset</b-button
            >
            <b-button
              variant="outline-danger"
              class="submit-report-custom"
              @click="onCloseFeatureRequest"
              >Close</b-button
            >
          </div>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-6"
        centered
        title="Close Incident"
        v-if="showCloseIncident"
      >
        <!-- <template #modal-title class="custom-title-details">
          <h5 style="margin-top: -20px">Terminate Instance</h5>
        </template> -->
        <p>Please confirm you want to close the incident.</p>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            size="sm"
            v-on:click="closeIncident"
          >
            Confirm
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'chatbot',
  data () {
    return {
      images: [],
      chatImages: [],
      chatlogImages: [],
      currentThreadId: '',
      sessionImages: [],
      chatbotClient: process.env.VUE_APP_CHATBOT_CLIENT,
      webSocketUrl: process.env.VUE_APP_WEB_SOCKET,
      preSignedUrl: process.env.VUE_APP_PRESIGNED_URL,
      newMessage: false,
      reportSession: 0,
      form: {
        summary: '',
        urgency: '< 12 hours',
        incidentType: null,
        additionalInfo: ''
      },
      form2: {
        requestcategory: null,
        requestSummary: '',
        urgencyRequest: 'Nice to have',
        additionalInfoRequest: ''
      },
      incidentType: [
        { text: 'Pick an incident', value: null },
        'Lab guide',
        'Demo Provisioning',
        'Connectivity',
        'Slowness',
        'Others'
      ],
      requestcategory: [
        { text: 'Pick an category', value: null },
        'Demo Scripts',
        'RiverBed Product',
        'Capabilities',
        'Performance',
        'other'
      ],
      urgency: ['< 48 hours', '< 12 hours', '< 1 hours'],
      urgencyRequest: [
        'Nice to have',
        'Timely, but not urgent',
        'Business critical'
      ],
      showIncidentReport: true,
      showFeatureRequest: true,

      showChatbot: false,
      message: '',
      messages: [],
      showCloseIncident: true,
      connection: null,
      serverResponse: false
    }
  },
  methods: {
    chooseFiles () {
      document.getElementById('fileUpload').click()
    },
    previewChatEnlargeImage (image, key) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.$refs.previewImage.src = reader.result
      }
      reader.readAsDataURL(this.chatImages[key])
    },
    previewEnlargeImage (image, key) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.$refs.previewImage.src = reader.result
      }
      reader.readAsDataURL(this.images[key])
    },
    previewImage (e) {
      const selectedFiles = e.target.files
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i])
      }
      for (let i = 0; i < this.images.length; i++) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.$refs.image[i].src = reader.result
        }

        reader.readAsDataURL(this.images[i])
      }
    },
    previewChatImage (e) {
      const selectedFiles = e.target.files
      for (let i = 0; i < selectedFiles.length; i++) {
        this.chatImages.push(selectedFiles[i])
      }
      for (let i = 0; i < this.chatImages.length; i++) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.$refs.chatImages[i].src = reader.result
        }
        reader.readAsDataURL(this.chatImages[i])
      }
    },
    removeImage (image, key) {
      this.images = this.images.filter((val) => val !== image)
      for (let i = 0; i < this.images.length; i++) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.$refs.image[i].src = reader.result
        }

        reader.readAsDataURL(this.images[i])
      }
    },
    removeChatImage (image, key) {
      this.chatImages = this.chatImages.filter((val) => val !== image)
      for (let i = 0; i < this.chatImages.length; i++) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.$refs.chatImages[i].src = reader.result
        }
        reader.readAsDataURL(this.chatImages[i])
      }
    },
    async uploadImages (file, fileName) {
      axios({
        method: 'POST',
        url: this.chatbotClient + 'signature',
        data: { fileName, mime: 'application/json' },
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          const resbody = JSON.parse(result.data.body)
          axios({
            method: 'put',
            url: resbody.uploadUrl,
            data: file,
            headers: { 'Content-Type': file.type },
            crossDomain: true
          }).then((result) => {})
        }
      ).catch(error => {
        console.error(error)
      })
    },
    async onSubmitIncidentReport (event) {
      this.form.summary = this.form.summary.trim()
      if (this.form.summary && this.form.summary.length > 0) {
        event.preventDefault()
        this.showIncidentReport = false
        this.messages.push({
          summary: this.form.summary,
          author: 'client'
        })
        this.messages.push({
          summary: 'Incident has been reported, we will get back to you soon',
          author: 'server'
        })
        this.$bvModal.hide('modal-4')
        Promise.all(
          this.images.map(async (val, index) => {
            const fileName =
            this.$msal.data.user.userName +
            '-' +
            index +
            '.' +
            val.type.split('/')[1]
            this.chatlogImages.push(this.preSignedUrl + fileName)
            await this.uploadImages(val, fileName)
          })
        ).then((val) => {
          axios({
            method: 'POST',
            url: this.chatbotClient + 'events',
            data: {
              requestType: 'Incident Report',
              user: this.$msal.data.user.userName,
              type: this.form.incidentType,
              summary: this.form.summary,
              urgency: this.form.urgency,
              additionalInfo: this.form.additionalInfo,
              images: this.chatlogImages
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: sessionStorage.getItem('msal.idtoken')
            },
            crossDomain: true,
            responseType: 'json'
          }).then(
            (result) => {
              if (this.connection === null || this.connection.readyState !== 1) {
                this.webSocketConnection()
              }
              this.reportSession = 1
              this.showIncidentReport = true
              const body = JSON.parse(result.data.body)
              this.messages = body.chatLogs
              this.messages.push({
                summary:
                'Incident has been reported, we will get back to you soon',
                author: 'server'
              })
              this.serverResponse = false
              setTimeout(() => {
                if (!this.serverResponse && this.reportSession !== 0) {
                  this.messages.push({
                    summary:
                    "We're sorry, our team members are not available at this time.  If your request is urgent please email <a href='mailto:support@steeldemo.cloud' style='display: contents;color: white;font-size: 1rem;font-style: italic;text-decoration: underline;'>support@steeldemo.cloud</a>. Otherwise, please try again at a later time.",
                    author: 'server'
                  })
                }
              }, 300000)

              this.currentThreadId = body.ts
              this.sessionImages = body.images
              this.closeIncidentReport()
              this.resetImageParam()
              this.$nextTick(() => {
                this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
              })
            }
          ).catch(error => {
            console.error(error)
            this.showIncidentReport = true
            this.closeIncidentReport()
            this.resetImageParam()
          })
        })
      }
    },
    closeIncidentReport () {
      this.form.summary = ''
      this.form.incidentType = null
      this.form.urgency = '< 12 hours'
      this.form.additionalInfo = ''
      this.resetImageParam()
    },
    onResetIncidentReport (event) {
      event.preventDefault()
      this.closeIncidentReport()
      this.resetImageParam()
    },
    onCloseIncidentReport (event) {
      this.closeIncidentReport()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-4')
      })
    },
    onSubmitFeatureRequest (event) {
      event.preventDefault()
      if (this.reportSession === 0) {
        this.messages.pop()
      }
      this.messages.push({
        summary: this.form2.requestSummary,
        author: 'client'
      })
      this.messages.push({
        summary:
          'We have recived your feature request, thank you for letting us know',
        author: 'server'
      })
      this.$bvModal.hide('modal-5')
      axios({
        method: 'POST',
        url: this.chatbotClient + 'events',
        data: {
          requestType: 'Feature Request',
          user: this.$msal.data.user.userName,
          type: this.form2.requestcategory,
          summary: this.form2.requestSummary,
          urgency: this.form2.urgencyRequest,
          additionalInfo: this.form2.additionalInfoRequest
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          // this.$refs["modal-5"].hide();

          this.closeFeatureRequest()

          if (this.reportSession === 0) {
            this.messages.push({
              author: 'option',
              showOption: true
            })
          }
          this.$nextTick(() => {
            this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
          })
        }
      ).catch(error => {
        console.error(error)
        this.closeFeatureRequest()
      })
    },
    closeFeatureRequest () {
      this.form2.requestSummary = ''
      this.form2.requestcategory = null
      this.form2.urgencyRequest = 'Nice to have'
      this.form2.additionalInfoRequest = ''
    },
    onResetFeatureRequest (event) {
      event.preventDefault()
      // Reset our form values
      this.form2.requestSummary = ''
      this.form2.requestcategory = null
      this.form2.urgencyRequest = 'Nice to have'
      this.form2.additionalInfoRequest = ''
      // // Trick to reset/clear native browser form validation state
      // this.showFeatureRequest = false;
      // this.$nextTick(() => {
      //   this.showFeatureRequest = true;
      // });
    },
    onCloseFeatureRequest (event) {
      this.closeFeatureRequest()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-5')
      })
    },

    resetImageParam () {
      this.images = []
      this.chatlogImages = []
      this.chatImages = []
    },
    async sendMessage (event) {
      this.message = this.message.trim()
      if (event.shiftKey === true && event.key === 'Enter') {
        return false
      } else if ((this.message && this.message !== '') || this.chatImages.length > 0) {
        const message = this.message
        this.messages.push({
          summary: message,
          author: 'client'
        })
        this.message = ''
        const chatImages = this.chatImages
        this.resetImageParam()
        Promise.all(
          chatImages.map(async (val, index) => {
            const fileNumber = this.sessionImages.length + index
            const fileName =
              this.$msal.data.user.userName +
              '-' +
              fileNumber +
              '.' +
              val.type.split('/')[1]
            this.chatlogImages.push(
              'https://s3-eu-west-1.amazonaws.com/steeldemo.support.images.staging/' +
                fileName
            )
            await this.uploadImages(val, fileName)
          })
        ).then((val) => {
          axios({
            method: 'POST',
            url: this.chatbotClient + 'events',
            data: {
              requestType: 'Incident Report',
              user: this.$msal.data.user.userName,
              summary: message.replace('\n', ' '),
              ts: this.currentThreadId,
              images: this.chatlogImages
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: sessionStorage.getItem('msal.idtoken')
            },
            crossDomain: true,
            responseType: 'json'
          }).then(
            (result) => {
              if (this.connection === null || this.connection.readyState !== 1) {
                this.webSocketConnection()
              }
              const body = JSON.parse(result.data.body)
              this.messages = body.chatLogs
              this.messages.push({
                summary:
                  'Your request has been raised, we will get back to you soon',
                author: 'server'
              })

              this.serverResponse = false
              setTimeout(() => {
                if (!this.serverResponse && this.reportSession !== 0) {
                  this.messages.push({
                    summary:
                      "We're sorry, our team members are not available at this time.  If your request is urgent please email <a href='mailto:support@steeldemo.cloud' style='display: contents;color: white;font-size: 1rem;font-style: italic;text-decoration: underline;'> support@steeldemo.cloud </a>. Otherwise, please try again at a later time.",
                    author: 'server'
                  })
                }
              }, 300000)

              this.currentThreadId = body.ts
              this.sessionImages = body.images
              this.$nextTick(() => {
                this.resetImageParam()
                this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
              })
            }
          ).catch(error => {
            console.error(error)
            this.messages.push({
              summary: 'Something Wrong!!, Please try again',
              author: 'server'
            })
            this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
            this.resetImageParam()
          })
        })

        this.$nextTick(() => {
          this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
        })
      }
    },
    closeIncident () {
      this.showCloseIncident = false
      axios({
        method: 'POST',
        url: this.chatbotClient + 'events',
        data: {
          requestType: 'Incident Report',
          user: this.$msal.data.user.userName,
          ts: this.currentThreadId,
          closeIncident: true,
          images: this.sessionImages
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.messages = []
          this.messages.push({
            summary: 'Your incident has been closed',
            author: 'server'
          })
          this.connection.close()
          this.reportSession = 0
          this.currentThreadId = ''
          this.sessionImages = []
          // this.messages.push({
          //   author: "server",
          //   summary: "Please select one of the option",
          // });
          this.messages.push({
            author: 'option',
            showOption: true
          })
          this.showCloseIncident = true
          this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
          this.resetImageParam()
        }
      ).catch(error => {
        console.error(error)
        this.messages.push({
          summary: 'Something Wrong!!, Please try again',
          author: 'server'
        })
        this.showCloseIncident = true
        this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
        this.resetImageParam()
      })
    },
    toggleChatbot () {
      this.newMessage = false
      this.showChatbot = !this.showChatbot
      this.resetImageParam()
      // if (this.showChatbot) {
      //   this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
      // }
    },
    toggleChatbotMax () {
      this.newMessage = false
      this.showChatbot = !this.showChatbot
      this.resetImageParam()
    },
    webSocketConnection () {
      const wsUri = this.webSocketUrl
      this.connection = new WebSocket(
        wsUri,
        this.$msal.data.user.userName.split('@')[0]
      )
      this.connection.onopen = (evt) => {
        // this.connection.send('{"message":"hello","action":"message"}');
      }
      this.connection.onclose = (evt) => {
        setTimeout(() => {
          if (this.reportSession === 1) this.webSocketConnection()
        }, 5000)
      }
      this.connection.onmessage = (evt) => {
        this.serverResponse = true
        this.messages.push({
          summary: evt.data,
          author: 'server'
        })

        this.$nextTick(() => {
          if (!this.showChatbot) {
            this.newMessage = true
          } else {
            this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
          }
        })
        // this.connection.close();
      }
      this.connection.onerror = (evt) => {}
    }
  },
  created () {
    return axios({
      method: 'GET',
      url: this.chatbotClient + 'events',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('msal.idtoken')
      },
      responseType: 'json'
    }).then((result) => {
      if (result.data) {
        const bodyItems = result.data.body.Items
        const userChatLog = bodyItems.filter(
          (value) => value.userName === this.$msal.data.user.userName
        )

        if (userChatLog.length > 0) {
          this.webSocketConnection()
          userChatLog.map((val) => {
            this.messages = [...val.chatLogs]
            this.currentThreadId = val.ts
            this.sessionImages = val.images
            return true
          })
          this.reportSession = 1
          this.$nextTick(() => {
            if (this.$refs.chatbox) { this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight }
          })
        } else {
          this.reportSession = 0
          this.messages.push({
            author: 'option',
            showOption: true
          })
        }
      } else {
        this.reportSession = 0
        this.messages.push({
          author: 'option',
          showOption: true
        })
      }
    })
  }
}
</script>

<style>
.modal-custom {
  max-height: 100% !important;
}
.img-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
}
.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background-color: #fff;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}
.img-wrap:hover .close {
  opacity: 1;
}
.card-image {
  height: 80px;
  border-radius: 5px;
  margin: 10px;
}

.card-row {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
<style>
.report-incident .modal-header {
  margin-top: -17px !important;
  /* max-height: 60px !important; */
}
.chatbot-div {
  box-shadow: 0 3px 6px rgba(255, 104, 29, 0.8588235294117647) !important;
  border-radius: 6px;
  background-color: var(--color-white);
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  border-top: 4px solid rgb(255, 104, 29) !important;
  width: 410px;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding-bottom: 7px;
}
.chatbot-toggle {
  margin-left: 4%;
  margin-top: 7px;
  cursor: pointer;
}
.chat-box,
.chat-box-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}
.chat-box-list-container {
  overflow-y: scroll;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  overflow-x: hidden;
}

.chat-box-list .span-custom {
  color: black;
  border-radius: 4px;
}
li.message.option {
  margin-right: -20px !important;
}
.chat-box-list .server .span-custom {
  color: white;
}
.chat-box-list .option .span-custom {
  color: white;
}
.chat-box-list .para-custom {
  max-width: 240px;
  display: block;
  height: auto;
  overflow-wrap: anywhere;
  line-height: 1.5 !important;
  padding: 8px !important;
}

.chat-box-list .server .para-custom {
  float: right;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #ff681d !important;
}
.chat-box-list .option .para-custom {
  float: right;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.chat-box-list .client .para-custom {
  float: left;
  background: #ecf0f5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.chat-box {
  border-top: 1px solid rgb(255, 104, 29) !important;
  height: 50vh;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  align-items: space-between;
  justify-content: space-between;
}
.chat-inputs {
  display: flex;
  border-top: 1px solid rgb(255, 104, 29) !important;
}
.chat-inputs .input-custom {
  line-height: 1.4 !important;
  width: 100% !important;
  border: none !important;
  border-bottom-left-radius: 4px !important;
  padding-left: 15px !important;
  height: 60px;
}
textarea:focus {
  outline: none !important;
}
.chat-inputs .button-custom {
  margin-top: 14px;
  margin-right: 10px !important;
  padding-left: 10px;
  cursor: pointer;
}
.chat-box-list .option .para-custom {
  max-width: fit-content !important;
}
.option-button {
  margin-left: -30px;
  color: #ff681d !important;
  border-color: #ff681d !important;
  font-size: 0.8rem !important;
  border-radius: 18px !important;
}

.option-button:hover {
  color: white !important;
  background-color: #ff681d !important;
  border-color: #ff681d !important;
}
.option-button .material-icons:hover,
.option-button:hover .material-icons {
  color: white !important;
}
.submit-report-custom:hover {
  color: #ff681d !important;
}

.incident-class {
  line-height: normal;
  margin-left: 15%;
  margin-top: 7px;
  color: red;
}

@media only screen and (min-width: 2049px) and (max-width: 2560px) {
  .incident-class {
    margin-left: 14%;
  }
}
</style>
