<template>
  <div>
    <div class="row m-0 p-0">
      <div
        class="content-layout-area-sanbox"
        style="
          padding-top: 20px !important;
          padding-right: 20px !important;
          border-left: 1px solid #e3e3e3;
        "
      >
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :loader="loader"
          :color="color"
        />
        <div class="row" style="padding-left: 20px">
          <div
            style="
              text-align: center;
              color: #ff681d;
              font-size: 30px;
              padding: 20%;
            "
          >
            Sandbox Labs are not supported anymore. Please choose another category
          </div>
          <div
            v-if="!scenarios.length && !isLoading"
            style="
              text-align: center;
              color: #ff681d;
              font-size: 30px;
              padding: 20%;
            "
          >
            No product, please choose another scenarios
          </div>
          <!--div
            class="col-custom-sanbox"
            style="padding-bottom: 20px"
            v-for="scenario in scenarios"
            :key="scenario.id"
          >
            <div :class="'col-4-custom ' + scenario.staging">
              <div class="icon">
                <i class="material-icons" v-if="!checkNewScenario(scenario.createAt)"> {{ scenario.icon }}</i>
              </div>
              <div class="ribbon ribbon-top-right" v-if="checkNewScenario(scenario.createAt)"><span>new</span></div>
              <span class="badge badge-dark badge-custom">{{
                getScenarioMapId(
                  "ID: S",
                  scenario.lastPublishedDate,
                  scenario.id
                )
              }}</span>
              <div
                v-b-modal.modal-1
                class="content-wrap-custom"
                @click="showScenario(scenario.id)"
              >
                <span class="title">{{ scenario.category }}</span>
                <h5 class="custom-class-h5">{{ scenario.name }}</h5>
                <p
                  class="custom-class-p"
                  style="
                    font-size: 12px;
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                    bottom: 85px;
                    position: absolute;
                    padding-right: 20px;
                    padding-bottom: 10px;
                  "
                >
                  <span v-if="scenario.facet_products">
                    {{ scenario.facet_products_status }}</span
                  >
                  <span
                    v-if="
                      scenario.facet_products_status &&
                      scenario.facet_products_status
                    "
                    >,
                  </span>
                  <span
                    v-if="scenario.facet_products_status"
                    style="color: red"
                    >{{ scenario.demoType }}</span
                  >
                </p>
              </div>
              <div class="status-custom justify-content-center">
                <div class="links sandbox-list-links">
                  <p v-if="scenario.inProcess" class="scenario-status-label">
                    {{ scenario.status }}
                  </p>
                  <div
                    v-else
                    style="
                      margin-left: auto;
                      margin-right: 20px;
                      text-align: center;
                      margin-top: -3px;
                    "
                  >
                    <i
                      class="fas fa-bolt fas-custom"
                      v-if="scenario['ip_address']"
                      style="color: #fbb022; cursor: pointer"
                      v-on:click="openAlert(scenario['ip_address'])"
                      title="IP Address"
                    ></i>
                    <a :href="scenario['wvd_url']" v-if="scenario['wvd_url']" target="_blank">
                      <i
                        class="fas fa-desktop fas-custom"
                        style="color: #fbb022; cursor: pointer"
                        title="Connect Demo"
                      ></i>
                    </a>
                    <i
                      class="fas fa-user-secret fas-custom"
                      v-if="
                        (scenario.status === 'User Created' ||
                          scenario.status === 'Org User Created') &&
                        scenario.user &&
                        scenario.password
                      "
                      v-b-modal.modal-2
                      v-on:click="
                        openUserCred(scenario['user'], scenario['password'], scenario['subnet'] || '')
                      "
                      style="color: #fbb022; cursor: pointer"
                      title="Demo details"
                    ></i>
                    <i
                      class="fas fa-stop-circle fas-custom"
                      v-if="scenario['demoId']"
                      style="color: red; cursor: pointer"
                      v-on:click="terminate_instance(scenario['demoId'])"
                      title="Terminate demo"
                    ></i>
                    <i
                      class="fas fa-file-alt fas-custom"
                      v-if="scenario['script']"
                      style="color: #6bb5e4; cursor: pointer"
                      v-on:click="openLink(scenario['script'])"
                      title="Script"
                    ></i>
                    <i
                      class="fas fa-camera fas-custom"
                      v-if="
                        scenario.snapshot &&
                        scenario.status === 'Topology Created'
                      "
                      style="color: #575757; cursor: pointer"
                      v-on:click="showSnapshot(scenario['demoId'])"
                      title="Snapshot"
                    ></i>
                  </div>
                </div>
                <p
                  v-if="
                    scenario.status === 'Topology Created' ||
                    scenario.status === 'User Session Created' ||
                    scenario.status === 'Org User Created' ||
                    scenario.status === 'Instance State UP'
                  "
                  class="scenario-status-running"
                >
                  {{ scenario.status }}
                </p>
              </div>
            </div>
          </div-->
        </div>
        <TerminateInstance :instance="instance" v-if="terminateInstance" />
        <InstanceIP v-if="ipAddress" />
      </div>
    </div>
    <div v-if="showUserCredModal">
      <b-modal id="modal-2" centered hide-header content-class="modal-content2">
        <div class="row">
          <div class="col-4">
            <strong style="float: right">User Name: </strong>
          </div>
          <div class="col-6" style="max-width: 142px !important">
            {{ userName }}
          </div>
          <input type="hidden" id="copy-code-username" :value="userName" />
          <i
            class="material-icons"
            @click="copyUsername()"
            style="cursor: pointer; margin-left: 6px"
            >content_copy</i
          >
          <transition name="fade" v-on:enter="enterUserName">
            <p
              v-if="copyUserName"
              style="
                font-size: 0.8vw;
                margin-top: 3px;
                margin-left: 3px;
                color: green;
                font-weight: 500;
              "
            >
              copied!
            </p>
          </transition>
        </div>
        <div class="row">
          <div class="col-4">
            <strong style="float: right">Password:</strong>
          </div>
          <div style="display: inline-flex">
            <div
              class="col-6"
              style="max-width: 113px !important; margin-top: 3px"
            >
              *****************
            </div>
            <input type="hidden" id="copy-code" :value="password" />
            <i
              class="material-icons"
              @click="togglePassword()"
              style="cursor: pointer; margin-left: 35px"
              >content_copy</i
            >
            <transition name="fade" v-on:enter="enterPassword">
              <p
                v-if="copyPassword"
                style="
                  font-size: 0.8vw;
                  margin-top: 3px;
                  margin-left: 3px;
                  color: green;
                  font-weight: 500;
                "
              >
                copied!
              </p>
            </transition>
          </div>
        </div>
        <div class="row" v-if="subnet">
          <div class="col-4">
            <strong style="float: right">Subnet:</strong>
          </div>
          <div style="display: inline-flex">
            <div
              class="col-6"
              style="max-width: 113px !important; margin-top: 3px"
            >
              {{subnet}}
            </div>
            <input type="hidden" id="copy-code-subnet" :value="subnet" />
            <i
              class="material-icons"
              @click="toggleSubnet()"
              style="cursor: pointer; margin-left: 35px"
              >content_copy</i
            >
            <transition name="fade" v-on:enter="enterSubnet">
              <p
                v-if="copySubnet"
                style="
                  font-size: 0.8vw;
                  margin-top: 3px;
                  margin-left: 3px;
                  color: green;
                  font-weight: 500;
                "
              >
                copied!
              </p>
            </transition>
          </div>
        </div>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            size="sm"
            v-on:click="closeUserCredModal"
          >
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
    <div></div>
  </div>
</template>

<script>
import { scenarioStore } from '@/store/scenarioStore.js'
import TerminateInstance from '@/components/TerminateInstance'
import InstanceIP from '@/components/InstanceIP'
import { mapMutations, mapGetters, mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'scenarioList',
  components: {
    TerminateInstance,
    InstanceIP,
    Loading
  },
  data () {
    return {
      copyUserName: false,
      copyPassword: false,
      backendURL: process.env.VUE_APP_BACKEND_URL,
      scenarios: [],
      scenarioId: null,
      showUserCredModal: false,
      menuCount: 5,
      userName: '',
      password: '',
      isLoading: false,
      fullPage: false,
      loader: 'bars',
      color: '#ff681d',
      running_list: [],
      copySubnet: false,
      subnet: ''
    }
  },
  computed: {
    ...mapGetters(['GET_USER', 'mailUserName', 'snapshotNbr']),
    ...mapState([
      'firestoreDB',
      'terminateInstance',
      'instance',
      'ipAddress'
    ])
  },
  methods: {
    getScenarioMapId (type, year, id) {
      const yearNumber = year ? year.slice(year.length - 2) : ''
      return type + yearNumber + id.slice(id.length - 3)
    },
    checkNewScenario (date) {
      if (date) {
        const dateNow = moment()
        const createdAt = moment.unix(date).add(2, 'weeks')
        return createdAt >= dateNow
      } else return false
    },
    openUserCred (user, password, subnet) {
      this.showUserCredModal = true
      this.userName = user
      this.password = password
      this.subnet = subnet
    },
    closeUserCredModal () {
      this.showUserCredModal = false
      this.userName = ''
      this.password = ''
      this.subnet = ''
    },
    ...mapMutations([
      'SELECT_INSTANCE',
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_CREATE_SNAPSHOT',
      'UPDATE_TERMINATE_INSTANCE',
      'UPDATE_IP_ADDRESS',
      'SELECT_CATEGORY',
      'SELECT_DEMO_PURPOSE'
    ]),
    togglePassword: function () {
      const codeToCopy = document.querySelector('#copy-code')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copyPassword = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    toggleSubnet: function () {
      const codeToCopy = document.querySelector('#copy-code-subnet')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copySubnet = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    copyUsername: function () {
      const codeToCopy = document.querySelector('#copy-code-username')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copyUserName = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    enterUserName: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copyUserName = false
      }, 3000) // hide the message after 3 seconds
    },
    enterPassword: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copyPassword = false
      }, 3000) // hide the message after 3 seconds
    },
    enterSubnet: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copySubnet = false
      }, 3000) // hide the message after 3 seconds
    },
    showScenario (scenarioId) {
      // if we click on the same scenario, we close the scenario
      this.isLoading = true
      if (this.scenarioId === scenarioId) {
        scenarioStore.reset()
        this.scenarioId = ''
      } else {
        scenarioStore.reset()
        this.scenarioId = scenarioId
        let i = 0
        while (
          scenarioStore.scenario.scenarioId === null &&
          i < this.scenarios.length
        ) {
          if (this.scenarios[i].id === scenarioId) {
            scenarioStore.set(
              this.scenarios[i].scenarioId,
              this.scenarios[i].name,
              this.scenarios[i].picture,
              this.scenarios[i].description,
              this.scenarios[i].link,
              this.scenarios[i].user,
              this.scenarios[i].password,
              this.scenarios[i].trainingScript,
              this.scenarios[i].demoScript,
              this.scenarios[i].video,
              this.scenarios[i].demoType,
              this.scenarios[i].lastPublishedDate,
              this.scenarios[i].snapshot
            )
            this.SELECT_CATEGORY(this.scenarios[i].category)
          }
          i++
        }
      }
      this.isLoading = false
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    openAlert (ip) {
      this.UPDATE_IP_ADDRESS(ip)
    },
    terminate_instance (instanceId) {
      this.SELECT_INSTANCE(instanceId)
      this.UPDATE_TERMINATE_INSTANCE(true)
    },
    showSnapshot (instanceId) {
      if (this.snapshotNbr > 2) { alert('SteelDemo does not allow more than 3 snapshots per user') } else {
        this.SELECT_INSTANCE(instanceId)
        this.$store.running_demos.map(obj => {
          if (obj.demoId === instanceId) {
            const demoPurposeRecord = {
              demo_purpose: obj.demo_purpose || '',
              demo_purpose_customer_name: obj.demo_purpose_customer_name || '',
              demo_purpose_customer_url: obj.demo_purpose_customer_url || ''
            }
            this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
          }
          return this.UPDATE_CREATE_SNAPSHOT(true)
        })
      }
    },
    updateStatus () {
      const refDemos = this.firestoreDB.collection('demos')
      const queryDemos = refDemos.where('username', '==', this.mailUserName)
      queryDemos.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.running_list.push(change.doc.data())
          }
          if (change.type === 'modified') {
            const index = this.running_list.findIndex(
              (e) => e.demoId === change.doc.data().demoId
            )
            if (index === -1) {
              this.running_list.push(change.doc.data())
            } else {
              this.running_list[index] = change.doc.data()
            }
          }
          if (change.type === 'removed') {
            this.running_list.pop(change.doc.data())
            const findScenariosIndex = this.scenarios.findIndex(
              (e) => e.scenarioId === change.doc.data().scenarioId
            )
            if (findScenariosIndex > -1) {
              this.scenarios[findScenariosIndex].inProcess = true
              this.scenarios[findScenariosIndex].status = ''
            }
          }
        })
        this.$store.running_demos = this.running_list
        this.$store.running_demos.map((record) => {
          if (!record.snapshotId) {
            const findScenariosIndex = this.scenarios.findIndex(
              (e) => e.scenarioId === record.scenarioId
            )
            if (findScenariosIndex > -1) {
              const scenario = this.scenarios[findScenariosIndex]
              if (
                record.status === 'Topology Created' ||
                record.status === 'User Session Created' ||
                record.status === 'Org User Created' ||
                record.status === 'Instance State UP'
              ) {
                scenario.status = record.status
                scenario.url = record.url
                scenario.ip_address = record.ip_address
                scenario.wvd_url = record.wvd_url
                scenario.demoId = record.demoId
                scenario.script = record.script
                scenario.duration = record.duration
                if (
                  (record.login && record.password) ||
                  (record.login !== 'N/A' && record.password !== 'N/A')
                ) {
                  scenario.user = record.login
                  scenario.password = record.password
                }
                if (record.subnet && record.subnet !== 'N/A' && record.subnet !== null) {
                  scenario.subnet = record.subnet
                }
                scenario.inProcess = false
              } else {
                scenario.inProcess = true
                scenario.status = record.status
              }
              this.scenarios[findScenariosIndex] = scenario
              const indexScenarios = this.scenarios.findIndex(
                (e) => e.scenarioId === scenario.scenarioId
              )
              this.scenarios[indexScenarios] = scenario
            }
          }
          return true
        })
        this.scenarios.sort(function (a, b) {
          return b.status ? 1 : -1
        })
      })
    }
  },
  created () {
    this.isLoading = true
    return axios({
      method: 'GET',
      url: this.backendURL + 'dynamodb/scenarios',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('msal.idtoken')
      },
      responseType: 'json'
    }).then(
      (result) => {
        const bodyItems = result.data.body.Items
        const localScenarioVariable = []
        const allProduct = []
        let filterPatner = []

        if (this.$msal.data.user.userName.startsWith('demo-')) {
          filterPatner = bodyItems.filter((obj) => {
            return obj.partnerAccess
          })
        } else {
          filterPatner = [...bodyItems]
        }
        filterPatner.map((record) => {
          if (
            record.description &&
            record.description.facet_others &&
            !record.description.facet_others.includes('deleted') &&
            !record.description.feature_demo
          ) {
            const scenario = record.description
            if (scenario.demo_type && scenario.demo_type === 'Lab') {
              scenario.createAt = record.createAt ? record.createAt : null
              scenario.lastPublishedDate = record.lastPublishedDate
              scenario.staging = ''
              if (!scenario.visible) {
                scenario.staging = ' staging'
              }
              scenario.id = record.description.scenarioId
              if (scenario.facet_pillars) {
                scenario.category = scenario.facet_pillars.join(', ')
              } else {
                if (scenario.category.toLowerCase() === 'visibility') {
                  scenario.category = 'NPM'
                } else if (scenario.category.toLowerCase() === 'acceleration') {
                  scenario.category = 'App Acceleration'
                } else if (scenario.category.toLowerCase() === 'sdwan') {
                  scenario.category = 'SD-WAN'
                }
              }
              if (scenario.facet_products) {
                allProduct.push(...scenario.facet_products)
                scenario.facet_products_status = scenario.facet_products.join(
                  ', '
                )
              }

              scenario.demoScript = scenario.demo_script
              delete scenario.demo_script
              scenario.trainingScript = scenario.training_script
              delete scenario.training_script
              scenario.demoType = scenario.demo_type
              delete scenario.demo_type

              localScenarioVariable.push(scenario)
            }
          }
          return true
        })
        localScenarioVariable.sort(function (a, b) {
          return a.id < b.id ? 1 : b.id < a.id ? -1 : 0
        })
        this.$nextTick(() => {
          this.scenarios = [...localScenarioVariable]
          this.updateStatus()
          this.isLoading = false
        })
      },
      (error) => {
        console.error(error)
        this.isLoading = false
      }
    )
  }
}
</script>
<style>
.content-layout-area-sanbox {
  width: calc(100% - 20px);
}
@media screen and (max-width: 1280px) and (min-width: 920px) {
  .col-custom-sanbox {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media screen and (max-width: 919px) {
  .col-custom-sanbox {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.col-custom-sanbox {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0px 20px;
  display: block;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (min-width: 768px) {
  .col-outer.center-align-items {
    display: flex !important;
    justify-content: center !important;
  }
}

.col-outer {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 46px;
}

.col-4-custom-lab:nth-child(1) {
  border-top: 4px solid #5fb4e4;
}

.col-4-custom-lab:nth-child(2) {
  border-top: 4px solid #5b7d95;
}

.col-4-custom-lab {
  height: 240px;
  box-shadow: 0px 3px 6px #00000033;
  border: 1px solid #f0f5f8;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.col-4-custom-lab:focus {
  outline: none !important;
}

.col-4-custom-lab:nth-child(1) {
  border-top: 4px solid #5fb4e4;
}
.col-4-custom-lab > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.icon {
  position: absolute;
  right: 15px;
  top: 12px;
  min-width: 45px;
  min-height: 45px;
}

.modal-footer1 {
  background-color: white;
  display: block;
}

img {
  border-style: none;
  max-width: 100%;
  padding: 0;
  margin: 0;
  height: auto;
}

.content-wrap-custom {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 60px;
  padding: 0 23px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  height: 90px;
}
.content-wrap-custom:focus {
  outline: none !important;
}

.content-wrap-custom .title {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  color: #5fb4e4;
  text-transform: uppercase;
  margin-top: -45px !important;
}

h5 {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 1.33 !important;
  letter-spacing: 0.21px;
  text-align: left;
  color: #2c3843;
  padding: 8px 0 4px;
  min-height: 50px;
}

.content-wrap-custom .btn-orange-link {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  color: #ff681d;
  padding: 0 40px 0 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  position: relative;
}

.material-icons {
  color: #ff681d;
}

.col-4-custom-lab:nth-child(3) {
  border-top: 4px solid #ff681d;
}

.scenario-status {
  text-transform: uppercase;
  padding: 5px;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 5px;
  color: #fff;
  background-color: red;
}

.scenario-status-label {
  text-transform: uppercase;
  padding: 5px;
  margin-top: 4px;
  border-radius: 5px;
  color: #ff681d;
  font-size: 0.8vw;
  text-align: center;
  font-weight: 700;
}

.scenario-status-running {
  text-transform: uppercase;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: green;
  font-size: 0.8vw;
  text-align: center;
  margin-top: 4px;
  margin-bottom: -2px;
}
.sandbox-list-links {
  margin-top: -17px;
  border-top: 1px solid #e3e3e3 !important;
}

.fas-custom {
  padding-left: 7px;
  font-weight: 900;
  font-size: 1.5vw;
  padding-top: 12px;
}

.modal-content2 {
  width: 500px !important;
  margin-left: 70px !important;
}

#modal-footer > .btn-custom {
  background-color: #ff681d;
  color: white;
  border-color: #ff681d;
}

#modal-footer > .btn-custom:hover {
  background-color: white;
  color: #ff681d;
  border-color: #ff681d;
}
.btn-user-cred {
  margin-top: 10px !important;
  margin-right: 36% !important;
  width: 30% !important;
  background-color: #ff681d !important;
}

.btn-user-cred:hover {
  background-color: #fff !important;
  color: #ff681d !important;
  border-color: #ff681d !important;
}
.staging {
  background-color: #e2dede !important;
  border-top: 4px solid #212529 !important;
}

.badge-custom {
  width: 48px !important;
  color: #fff !important;
  background-color: #5fb4e4 !important;
  margin-left: -3px !important;
  border-radius: 0 !important;
  font-size: 10px !important;
  height: 20px !important;
  margin-top: -2px;
  font-weight: 400 !important;
  min-width: 4.5rem !important;
}

.featured .badge-custom {
  background-color: #ff681d !important;
}

.status-custom {
  padding: 5px;
  padding-top: 8px;
  margin-bottom: -10px;
}
@media screen and (max-width: 1200px) {
  h5 {
    font-size: 18px !important;
  }

  .scenario-status-label {
    margin-top: -3.5px;
    color: #ff6900;
    font-size: 0.9vw;
    text-align: center;
    font-weight: 700;
  }
}
@media screen and (max-width: 1536px) {
  .scenario-status-running {
    font-size: 0.9vw !important;
  }

  .scenario-status-label {
    font-size: 0.9vw !important;
  }
  .content-wrap-custom .title {
    font-size: 10px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #5fb4e4 !important;
    text-transform: uppercase !important;
    margin-top: -45px !important;
  }
  .content-wrap-custom .custom-class-h5 {
    font-size: 18px !important;
  }
  .content-wrap-custom .custom-class-p {
    font-size: 11px !important;
  }
  .card-filter,
  .card-filter:hover {
    overflow-anchor: none !important;
    background-color: #ff681d !important;
    text-align: start !important;
    width: 192px !important;
    padding-left: 13px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-left: -8px !important;
    color: white !important;
  }

  .custom-responsive-filter,
  .custom-responsive-filter .focus {
    padding-top: 7.5px !important;
    padding-left: 10px !important;
    cursor: pointer !important;
    margin-top: 5px !important;
    border-radius: 10px !important;
    padding-bottom: 7.5px !important;
    margin-left: -17px !important;
  }
}
</style>
