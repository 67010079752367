<template>
  <div class="row m-0 p-0">
    <div
      class="row chart-row"
      style="display: inline-block; margin-bottom: 0px"
    >
      <div class="tile_count">
        <div class="col-md-2 col-sm-4 tile_stats_count">
          <span class="count_top"><i class="fa fa-user"></i> Active User</span>
          <div class="count">{{ running_list.length }}</div>
        </div>
        <div class="col-md-2 col-sm-4 tile_stats_count">
          <span class="count_top">Active Demo's</span>
          <div class="count green_color">{{ running_list.length }}</div>
        </div>
        <div class="col-md-2 col-sm-4 tile_stats_count">
          <span class="count_top"> Your's Running Instances</span>
          <div class="count">{{ user_running_list.length }}</div>
        </div>
        <div class="col-md-2 col-sm-4 tile_stats_count">
          <span class="count_top"> Your's Demo to date</span>
          <div class="count">{{ user_instances_list.length }}</div>
        </div>
      </div>
    </div>
    <div class="row parent-dashboard">
      <div class="row chart-row" style="margin-top: 10px; margin-bottom: 10px">
        <div class="col-md-12 col-sm-12">
          <div
            id="reportrange"
            class="pull-right"
            style="background: #fff; float: right"
          >
            <date-range-picker
              v-model="dateRange"
              :startDate="startDate"
              :endDate="endDate"
              :locale-data="locale"
              :ranges="ranges"
              @update="showDashboard"
              opens="left"
            >
              <div slot="input" slot-scope="picker">
                <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                {{ picker.startDate | dateFormat }} -
                {{ picker.endDate | dateFormat }}
              </div>
            </date-range-picker>
            <b class="caret"></b>
          </div>
          <small
            style="
              color: grey;
              font-size: 0.9rem;
              font-weight: 700;
              float: right;
              margin-right: 20px;
              margin-top: 8px;
            "
            >Please select date range for the dashboard</small
          >
        </div>
      </div>
      <div class="row chart-row">
        <div class="col-md-8 col-sm-8">
          <div class="x_panel">
            <div class="row x_title">
              <div class="col-md-4" style="font-size: 1.35rem">
                <h3 class="graph-name">User Activity <small></small></h3>
              </div>
              <div class="col-md-8">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right"
                >
                  <b class="caret"></b>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <UserActivityAreaChart
                  v-if="lastSixMonth.length > 0"
                  :chart-labels="lastSixMonth"
                  :chart-data="[
                    lastSixMonthRecord,
                    lastSixMonthRecordByUser,
                    lastSixMonthRecordByPurposePractice,
                    lastSixMonthRecordByPurposeCustomer,
                  ]"
                  :options="createUserActivityChartOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="lastSixMonth.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-sm-4"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">Popular Demo <small></small></h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <div
                  v-for="(item, index) in scenariosOrderByLenght.slice(0, 5)"
                  :key="index"
                >
                  <h5 class="most-launched-name">{{ item.name }}</h5>
                  <b-progress
                    :value="item.count"
                    :max="scenariosOrderByLenght[0].count + 10"
                    show-value
                    class="mb-3"
                  ></b-progress>
                </div>
                <loading
                  :active="scenariosOrderByLenght.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row">
        <div class="col-md-12 col-sm-12">
          <div class="x_panel">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">
                  Community User Activity<small
                    style="padding: 5px; font-size: 13px; color: #969090"
                  >
                  </small>
                </h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <CommunityActivityAreaChart
                  v-if="communityUsersRecord.data.length > 0"
                  :chart-labels="communityUsersRecord.label"
                  :chart-data="communityUsersRecord.data"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="communityUsersRecord.data.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row">
        <div
          class="col-md-4 col-sm-4"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">Least Launched Demo <small></small></h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <div
                  v-for="(item, index) in scenariosOrderByLenght
                    .slice(scenariosOrderByLenght.length - 5)
                    .reverse()"
                  :key="index"
                >
                  <h5 class="most-launched-name">{{ item.name }}</h5>
                  <b-progress
                    :value="item.count"
                    :max="
                      scenariosOrderByLenght[scenariosOrderByLenght.length - 5]
                        .count + 5
                    "
                    show-value
                    class="mb-3"
                  ></b-progress>
                </div>
                <loading
                  :active="scenariosOrderByLenght.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-8">
          <div class="x_panel">
            <div class="row x_title">
              <div
                class="col-md-5"
                style="font-size: 1.35rem; padding-right: 0px"
              >
                <h3 class="graph-name">
                  Demo
                  <small style="font-size: 70%">SE and Partners only</small>
                </h3>
              </div>
              <div class="col-md-7" style="display: flex; flex-direction: row">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right; margin-left: auto"
                >
                  <date-range-picker
                    v-model="dateRangeByDemoType"
                    :startDate="startDateByDemoType"
                    :endDate="endDateByDemoType"
                    :minDate="minStartDate"
                    :maxDate="maxEndDate"
                    :locale-data="localeByDemoType"
                    @update="showDemoByDemoType"
                    opens="left"
                  >
                    <div slot="input" slot-scope="picker">
                      <i
                        class="glyphicon glyphicon-calendar fa fa-calendar"
                      ></i>
                      {{ picker.startDate | dateFormat }} -
                      {{ picker.endDate | dateFormat }}
                    </div>
                  </date-range-picker>
                  <b class="caret"></b>
                </div>
                <download-excel
                  :data="json_data"
                  :fields="json_fields"
                  type="csv"
                  name="DemoReports.xls"
                >
                  <i
                    class="material-icons"
                    style="
                      font-size: 25px;
                      padding-left: 20px;
                      margin-top: 6px;
                      cursor: pointer;
                    "
                    title="Download Report"
                    >file_download</i
                  >
                </download-excel>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <DemoTypeChart
                  v-if="demo_by_type_label.length > 0"
                  :chart-labels="demo_by_type_label"
                  :chart-data="demo_by_type_data"
                  :options="createDemoTypeChartOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="demo_by_type_label.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row">
        <div class="col-md-8 col-sm-8" style="display: flex">
          <div class="x_panel">
            <div class="row x_title">
              <div class="col-md-4" style="font-size: 1.35rem">
                <h3 class="graph-name">Demo by Role <small></small></h3>
              </div>
              <div class="col-md-8">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right"
                >
                  <date-range-picker
                    v-model="dateRangeByRole"
                    :startDate="startDateByRole"
                    :endDate="endDateByRole"
                    :minDate="minStartDate"
                    :maxDate="maxEndDate"
                    :locale-data="localeByRole"
                    @update="showDemoByRole"
                    opens="left"
                  >
                    <div slot="input" slot-scope="picker">
                      <i
                        class="glyphicon glyphicon-calendar fa fa-calendar"
                      ></i>
                      {{ picker.startDate | dateFormat }} -
                      {{ picker.endDate | dateFormat }}
                    </div>
                  </date-range-picker>
                  <b class="caret"></b>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <DemoByDepartment
                  v-if="demo_by_role_label.length > 0"
                  :chart-data="createRoleChartData()"
                  :options="createRoleChartOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="demo_by_role_label.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4" style="display: flex">
          <div class="x_panel">
            <div class="row x_title">
              <div
                class="col-md-5"
                style="font-size: 1.35rem; padding-right: 0px"
              >
                <h3 class="graph-name">Azure & AWS Cost</h3>
              </div>
              <div class="col-md-7" style="padding: 0px">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right"
                >
                  <b-dropdown
                    id="dropdown-right"
                    right
                    variant="outline-danger"
                    split-variant="outline-danger"
                    offset="25"
                    class="m-2 cost-dropdown"
                    v-model="costQuarterDropDown.selectedOption"
                    :text="costQuarterDropDown.selectedOption"
                  >
                    <b-dropdown-item
                      v-for="option in costQuarterDropDown.options"
                      :key="option.value"
                      :value="option.value"
                      @click="changeCostQuarter(option.value)"
                    >
                      {{ option.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <CostPieChart
                  v-if="costQuarterRecord"
                  :chart-labels="Object.keys(costQuarter)"
                  :chart-data="Object.values(costQuarter)"
                  :options="createCostQuarterOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="!costQuarterRecord"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row">
        <div class="col-md-12 col-sm-12">
          <div class="x_panel">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">
                  GEO Demo Activity<small
                    style="padding: 5px; font-size: 13px; color: #969090"
                  >
                    Records from July 16, 21</small
                  >
                </h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <MapChart
                  :countryData="countryData"
                  highColor="rgba(232, 78, 2, .75)"
                  lowColor="rgba(249, 210, 191, .75)"
                  countryStrokeColor="#909090"
                  defaultCountryFillColor="#dadada"
                  legendFontColorHeader="rgba(238, 113, 55, .75)"
                  legendBorderCss="1px solid #fff"
                  legendBoxShadowCss="0px 0px 10px #fff"
                  :showEmptyValue="false"
                  :currencyAdd="false"
                  LangUser="en"
                />
                <loading
                  :active="scenarioList.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row">
        <div class="col-md-6 col-sm-6">
          <div class="x_panel">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">Demo Activity <small></small></h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <ScenarioPieChat
                  v-if="scenarioListDataset.data.length > 0"
                  :chart-labels="scenarioList"
                  :chart-data="scenarioListDataset"
                  :options="createScenarioPieOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="scenarioList.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="x_panel">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">Activity By Pillar <small></small></h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <PillarsPieChart
                  v-if="pillars['SD-WAN'] > 0"
                  :chart-labels="Object.keys(pillars)"
                  :chart-data="Object.values(pillars)"
                  :options="createPillarsOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="pillars['SD-WAN'] > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row">
        <div class="col-md-4 col-sm-4">
          <div class="x_panel">
            <div class="row x_title">
              <div class="col-md-8" style="font-size: 1.35rem">
                <h3 class="graph-name">Users By Role<small></small></h3>
              </div>
              <div class="col-md-4"></div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <UsersRolePieChart
                  v-if="userRolesChartData.labels.length > 0"
                  :userRolesChartData="userRolesChartData"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="userRolesChartData.labels.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-8 col-sm-8"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel">
            <div class="row x_title">
              <div class="col-md-10" style="font-size: 1.35rem">
                <h3 class="graph-name">Demo By Month<small></small></h3>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <DemoBarChart
                  v-if="lastSixMonth_for_demo.length > 0"
                  :chart-labels="lastSixMonth_for_demo"
                  :chart-data="lastSixMonthScenarios"
                  :options="createDemoBarOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="lastSixMonth.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row chart-row">
      <div class="col-md-6 col-sm-6">
        <div class="x_panel">
          <div class="row x_title">
            <div class="col-md-4" style="font-size: 1.35rem">
              <h3 class="graph-name">Azure Cost <small></small></h3>
            </div>
            <div class="col-md-8">
              <div
                id="reportrange"
                class="pull-right"
                style="background: #fff; float: right"
              >
                <date-range-picker
                  v-model="dateRangeByAzure"
                  :startDate="startDateByAzure"
                  :endDate="endDateByAzure"
                  :locale-data="localeByAzure"
                  @update="showAZPrice"
                  opens="left"
                >
                  <div slot="input" slot-scope="picker">
                    <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                    {{ picker.startDate | dateFormat }} -
                    {{ picker.endDate | dateFormat }}
                  </div>
                </date-range-picker>
                <b class="caret"></b>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div
              id="chart_plot_01"
              class="demo-placeholder"
              style="padding: 0px; position: relative"
            >
              <AzureCostAreaChart
                v-if="azureCostData[0].labels.length > 0"
                :chart-data="createAzureCostChartData()"
                :styles="{ height: '410px !important', position: 'relative' }"
              />
              <loading
                :active="azureCostData[0].labels.length > 0 ? false : true"
                :is-full-page="fullPage"
                :loader="loader"
                :color="color"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="x_panel">
          <div class="row x_title">
            <div class="col-md-4" style="font-size: 1.35rem">
              <h3 class="graph-name">AWS Cost <small></small></h3>
            </div>
            <div class="col-md-8">
              <div
                id="reportrange"
                class="pull-right"
                style="background: #fff; float: right"
              >
                <date-range-picker
                  v-model="dateRangeByAWS"
                  :startDate="startDateByAWS"
                  :endDate="endDateByAWS"
                  :locale-data="localeByAWS"
                  @update="showAWSPrice"
                  opens="left"
                >
                  <div slot="input" slot-scope="picker">
                    <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                    {{ picker.startDate | dateFormat }} -
                    {{ picker.endDate | dateFormat }}
                  </div>
                </date-range-picker>
                <b class="caret"></b>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div
              id="chart_plot_01"
              class="demo-placeholder"
              style="padding: 0px; position: relative"
            >
              <AWSCostBarChart
                v-if="awsCostData.labels.length > 0"
                :chart-labels="awsCostData.labels"
                :chart-data="awsCostData.data"
                :styles="{ height: '410px !important', position: 'relative' }"
              />
              <loading
                :active="awsCostData.labels.length > 0 ? false : true"
                :is-full-page="fullPage"
                :loader="loader"
                :color="color"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import UserActivityAreaChart from '@/charts/UserActivityAreaChart.vue'
import CommunityActivityAreaChart from '@/charts/CommunityActivityAreaChart.vue'
import UsersRolePieChart from '@/charts/UsersRolePieChart.vue'
import ScenarioPieChat from '@/charts/ScenarioPieChat.vue'
import AzureCostAreaChart from '@/charts/AzureCostAreaChart.vue'
import DemoBarChart from '@/charts/DemoBarChart.vue'
import DemoByDepartment from '@/charts/DemoByDepartment.vue'
import AWSCostBarChart from '@/charts/AWSCostBarChart.vue'
import PillarsPieChart from '@/charts/PillarsPieChart.vue'
import CostPieChart from '@/charts/CostPieChart.vue'
import DemoTypeChart from '@/charts/DemoTypeChart.vue'
import MapChart from 'vue-chart-map'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import randomColor from 'randomcolor'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Loading from 'vue-loading-overlay'
import _ from 'lodash'

export default {
  name: 'SteeldemoDashboard',
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      running_list: [],
      user_running_list: [],
      instances_list: [],
      user_instances_list: [],
      chartData: null,
      appendToBody: true,
      monthNames: moment.months(),
      lastSixMonth: [],
      lastSixMonthRecord: [],
      lastSixMonthRecordByUser: [],
      lastSixMonthRecordByPurposePractice: [],
      lastSixMonthRecordByPurposeCustomer: [],
      lastSixMonth_for_demo: [],
      scenarioList: [],
      scenarioListDataset: {
        data: [],
        backgroundColor: []
      },
      lastSixMonthScenarios: [],
      users: [],
      userRolesChartData: {
        labels: [],
        data: [],
        backgroundColor: [],
        total: null
      },
      azureCostData: [
        {
          name: null,
          labels: [],
          data: []
        },
        {
          name: null,
          labels: [],
          data: []
        },
        {
          name: null,
          labels: [],
          data: []
        }
      ],
      awsCostData: { labels: [], data: [] },
      demo_by_role_data: {},
      demo_by_role_label: [],
      demo_by_type_label: [],
      demo_by_type_data: {
        'SE Practice Demos': [],
        'SE Customer Demos': [],
        'Partners Practice Demos': [],
        'Partners Customer Demos': []
      },
      startDateByAzure: null,
      endDateByAzure: null,
      dateRangeByAzure: {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      localeByAzure: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      startDate: null,
      endDate: null,
      dateRange: {
        startDate: moment()
          .subtract(5, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      locale: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      ranges: {
        'This Financial Year': [
          new Date(moment().startOf('year')),
          new Date(moment())
        ],
        'Last Financial Year': [
          new Date(moment().startOf('year').subtract(1, 'year')),
          new Date(
            moment().subtract(1, 'year').month('December').endOf('month')
          )
        ]
      },
      costQuarterDropDown: {
        selectedOption: '',
        options: [
          {
            value: 'This Financial Year',
            text: 'This Financial Year'
          }
        ]
      },
      startDateByRole: null,
      endDateByRole: null,
      dateRangeByRole: {
        startDate: moment()
          .subtract(5, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      dateRangeByDemoType: {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      startDateByDemoType: null,
      endDateByDemoType: null,
      minStartDate: moment()
        .subtract(5, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
      maxEndDate: moment().format('YYYY-MM-DD'),
      localeByDemoType: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      localeByRole: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      startDateByAWS: null,
      endDateByAWS: null,
      dateRangeByAWS: {
        startDate: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      localeByAWS: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      pillars: {
        'App Acceleration': 0,
        NPM: 0,
        'SD-WAN': 0,
        WANop: 0
      },
      costQuarter: {
        AWS: 0,
        AZURE: 0
      },
      costQuarterRecord: false,
      fullPage: false,
      loader: 'bars',
      color: '#ff681d',
      scenariosOrderByLenght: [],
      json_fields: {
        'Demo Date': 'starting_timestamp',
        'User Name': 'username',
        'Email Id': 'id',
        Department: 'department',
        'Demo Purpose': 'demo_purpose',
        'Demo Customer Name': 'demo_purpose_customer_name',
        'SFDC Link': 'demo_purpose_customer_url',
        'Demo Name': 'name'
      },
      json_data: [],
      countryData: {},
      communityUsersRecord: {
        data: [],
        label: []
      }
    }
  },
  computed: {
    ...mapGetters(['mailUserName']),
    ...mapState(['firestoreDB'])
  },
  components: {
    UserActivityAreaChart,
    CommunityActivityAreaChart,
    UsersRolePieChart,
    DemoBarChart,
    ScenarioPieChat,
    AzureCostAreaChart,
    DateRangePicker,
    DemoByDepartment,
    Loading,
    AWSCostBarChart,
    PillarsPieChart,
    DemoTypeChart,
    MapChart,
    CostPieChart
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format('MMMM Do YYYY')
    }
  },
  methods: {
    getSum (data) {
      let sum = 0
      data.map((data) => {
        sum += parseInt(data)
        return sum
      })
      return sum
    },
    getSumFloat (data) {
      let sum = 0
      data.map((data) => {
        sum += parseFloat(data)
        return sum
      })
      return sum
    },
    createAzureCostChartData () {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      return {
        labels: this.azureCostData[0].labels,
        datasets: [
          {
            label:
              this.azureCostData[0].name +
              ' (' +
              formatter.format(this.getSumFloat(this.azureCostData[0].data)) +
              ')',
            borderColor: 'rgba(255, 104, 29)',
            pointBackgroundColor: 'rgba(255, 104, 29)',
            borderWidth: 1,
            pointBorderColor: 'white',
            backgroundColor: 'transparent',
            data: this.azureCostData[0].data
          },
          {
            label:
              this.azureCostData[1].name +
              ' (' +
              formatter.format(this.getSumFloat(this.azureCostData[1].data)) +
              ')',
            borderColor: 'rgba(0, 231, 255)',
            pointBackgroundColor: 'rgba(0, 231, 255)',
            pointBorderColor: 'white',
            borderWidth: 1,
            backgroundColor: 'transparent',
            data: this.azureCostData[1].data
          },
          {
            label:
              this.azureCostData[2].name +
              ' (' +
              formatter.format(this.getSumFloat(this.azureCostData[2].data)) +
              ')',
            borderColor: 'rgba(2, 179, 31)',
            pointBackgroundColor: 'rgba(2, 179, 31)',
            pointBorderColor: 'white',
            borderWidth: 1,
            backgroundColor: 'transparent',
            data: this.azureCostData[2].data
          }
        ]
      }
    },
    createRoleChartData () {
      const datasets = []
      const color = randomColor({
        hue: 'random',
        luminosity: 'random',
        count: this.userRolesChartData.labels.length
      })
      this.userRolesChartData.labels.forEach((key, index) => {
        const obj = {
          label: key + ' (' + this.getSum(this.demo_by_role_data[key]) + ')',
          borderColor: color[index],
          pointBackgroundColor: color[index],
          borderWidth: 2,
          pointBorderColor: 'white',
          backgroundColor: 'transparent',
          data: this.demo_by_role_data[key]
        }
        datasets.push(obj)
      })
      return {
        labels: this.demo_by_role_label,
        datasets: datasets
      }
    },
    createDemoBarOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.yLabel > 0) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                  ': ' +
                  tooltipItem.yLabel
                )
              }
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.5,
              barPercentage: 1
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        },
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    },
    createRoleChartOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function (tooltipItems, data) {
              return 'Demo Launched'
            },
            label: function (tooltipItem, data) {
              if (tooltipItem.yLabel > 0) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                  ': ' +
                  tooltipItem.yLabel
                )
              }
            }
          }
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5
          },
          line: {
            tension: 0
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15
              }
            }
          ]
        }
      }
    },
    createScenarioPieOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 20,
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                data.labels[tooltipItem.index] +
                ': ' +
                (
                  (data.datasets[0].data[tooltipItem.index] * 100) /
                  this.getSum(data.datasets[0].data)
                ).toFixed(2) +
                '%'
              )
            }
          }
        }
      }
    },
    createPillarsOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 10,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                data.labels[tooltipItem.index] +
                ': ' +
                (
                  (data.datasets[0].data[tooltipItem.index] * 100) /
                  this.getSum(data.datasets[0].data)
                ).toFixed(2) +
                '%'
              )
            }
          }
        }
      }
    },
    createCostQuarterOption () {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 10,
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function (tooltipItems, data) {
              return 'Cost In USD'
            },
            label: function (tooltipItem, data) {
              return formatter.format(data.datasets[0].data[tooltipItem.index])
            }
          }
        },
        title: {
          display: true,
          text:
            'Total: ' +
            formatter.format(
              parseFloat(this.costQuarter.AWS) +
                parseFloat(this.costQuarter.AZURE)
            )
        }
      }
    },
    createUserActivityChartOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                ': ' +
                tooltipItem.yLabel
              )
            }
          }
        }
      }
    },
    createDemoTypeChartOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function (tooltipItems, data) {
              return 'Demo Launched'
            },
            label: function (tooltipItem, data) {
              if (tooltipItem.yLabel > 0) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                  ': ' +
                  tooltipItem.yLabel
                )
              }
            }
          }
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5
          },
          line: {
            tension: 0
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15
              }
            }
          ]
        }
      }
    },
    showAZPrice (val) {
      this.mapAzureCost(val.startDate, val.endDate)
    },
    showAWSPrice (val) {
      this.mapAWSCost(val.startDate, val.endDate)
    },
    showDemoByRole (val) {
      this.groupByRole(val.startDate, val.endDate)
    },
    showDemoByDemoType (val) {
      this.groupByDemoType(val.startDate, val.endDate)
    },
    resetRecord () {
      this.scenarioList = []
      this.scenarioListDataset = {
        data: [],
        backgroundColor: []
      }
      this.scenariosOrderByLenght = []
      this.pillars = {
        'App Acceleration': 0,
        NPM: 0,
        'SD-WAN': 0,
        WANop: 0
      }
      this.lastSixMonthScenarios = []
      this.lastSixMonth = []
      this.lastSixMonthRecordByUser = []
      this.lastSixMonthRecordByPurposePractice = []
      this.lastSixMonthRecordByPurposeCustomer = []
      this.lastSixMonthRecord = []
      this.json_data = []
    },
    async showDashboard (val) {
      this.resetRecord()
      this.minStartDate = new Date(val.startDate)
      this.maxEndDate = new Date(val.endDate)
      this.dateRangeByDemoType.startDate = this.minStartDate
      this.dateRangeByDemoType.endDate = this.maxEndDate
      this.dateRangeByRole.startDate = this.minStartDate
      this.dateRangeByRole.endDate = this.maxEndDate
      await this.instance(val)
      this.groupByRole(
        this.dateRangeByRole.startDate,
        this.dateRangeByRole.endDate
      )
      this.groupByDemoType(
        this.dateRangeByDemoType.startDate,
        this.dateRangeByDemoType.endDate
      )
    },
    demos () {
      const refDemos = this.firestoreDB.collection('demos')
      const queryDemos = refDemos
      queryDemos.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.running_list.push(change.doc.data())
          }
          if (change.type === 'modified') {
            const index = this.running_list.findIndex(
              (e) => e.demoId === change.doc.data().demoId
            )
            if (index === -1) {
              this.running_list.push(change.doc.data())
            } else {
              this.running_list[index] = change.doc.data()
            }
          }
          if (change.type === 'removed') {
            this.running_list.pop(change.doc.data())
          }
        })
        this.user_running_list = this.running_list.filter(
          (obj) => obj.username === this.mailUserName
        )
      })
    },
    instance (dateRange) {
      const params = {
        start: moment(dateRange.startDate, 'YYYY-MM-DD').valueOf(),
        end: moment(dateRange.endDate, 'YYYY-MM-DD').valueOf()
      }
      try {
        return axios({
          method: 'GET',
          params: { ...params },
          url: this.backendURL + 'dynamodb/instanceslogs',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          responseType: 'json'
        }).then((result) => {
          const totalScenarios = this.$store.scenarios.concat(
            this.$store.scenariosLab
          )

          this.instances_list = result.data.body.filter(
            (obj) => obj.scenarioId !== 'scenario999'
          )

          this.instances_list = _.map(this.instances_list, (item) => {
            return _.merge(
              item,
              _.find(totalScenarios, { id: item.scenarioId })
            )
          }).filter((obj) => obj.visible)

          this.countryData = _.countBy(this.instances_list, 'country')

          // Users Demos to date
          this.user_instances_list = this.instances_list.filter(
            (obj) => obj.username === this.mailUserName
          )
          // activity by pillars
          this.instances_list.map((obj) => {
            if (obj.category) {
              const categoryList = obj.category.split(',')
              categoryList.map((category) => {
                const trimcategory = category
                  .trim()
                  .toLowerCase()
                  .replace('-', '')
                switch (trimcategory) {
                  case 'all':
                    this.pillars['App Acceleration'] =
                      this.pillars['App Acceleration'] + 1 / 4
                    this.pillars.NPM = this.pillars.NPM + 1 / 4
                    this.pillars['SD-WAN'] = this.pillars['SD-WAN'] + 1 / 4
                    this.pillars.WANop = this.pillars.WANop + 1 / 4
                    break
                  case 'acceleration':
                  case 'app acceleration':
                    this.pillars['App Acceleration'] =
                      this.pillars['App Acceleration'] +
                      1 / categoryList.length
                    break
                  case 'visibility':
                  case 'npm':
                    this.pillars.NPM =
                      this.pillars.NPM + 1 / categoryList.length
                    break
                  case 'sdwan':
                    this.pillars['SD-WAN'] =
                      this.pillars['SD-WAN'] + 1 / categoryList.length
                    break
                  case 'wanop':
                    this.pillars.WANop =
                      this.pillars.WANop + 1 / categoryList.length
                    break
                }
                return true
              })
            }
            return true
          })

          const groupByScenario = _.countBy(this.instances_list, 'scenarioId')
          const sortedScenario = _.toPairs(groupByScenario).sort(function (
            a,
            b
          ) {
            return b[1] - a[1]
          })

          // Most launched Demo's
          sortedScenario.map((arr, index) => {
            totalScenarios.map((obj) => {
              if (obj.scenarioId === arr[0]) {
                const combine = { ...obj, count: arr[1] }
                this.scenariosOrderByLenght.push(combine)
                if (index < 10) {
                  this.scenarioList.push(obj.name)
                  this.scenarioListDataset.data.push(arr[1])
                }
              }
              return true
            })
            return true
          })

          this.scenarioListDataset.backgroundColor = randomColor({
            hue: 'orange',
            luminosity: 'light',
            count: this.scenarioList.length
          })

          // scenarios by month
          this.scenarioList.forEach((val) => {
            this.lastSixMonthScenarios.push({
              label: val,
              backgroundColor: randomColor({
                hue: 'red',
                luminosity: 'light',
                count: this.scenarioList.length
              }),
              data: []
            })
          })

          // Users Activities
          this.getUserActivity()

          this.lastSixMonth_for_demo = this.lastSixMonth
        })
      } catch (error) {
        console.log('error in dashboard Instances ' + error)
      }
    },
    groupByRole (start, end) {
      // Users Actuvuty By Department

      const startDate = moment(start).format('YYYY-MM-DD')
      const endDate = moment(end).format('YYYY-MM-DD')
      this.demo_by_role_label = []

      const dataByDepartment = this.instances_list.filter((obj) => {
        const dataFormat = moment
          .unix(obj.starting_timestamp)
          .format('YYYY-MM-DD HH:mm')
        if (startDate < dataFormat && endDate > dataFormat) {
          return true
        } else return false
      })

      const mergeData = []
      dataByDepartment.map((item, i) => {
        this.users.map((obj) => {
          if (item.username === obj.id.split('@')[0]) {
            mergeData.push(Object.assign({}, item, obj))
          }
          return true
        })
        return true
      })

      const groups = mergeData.reduce((groups, data) => {
        const date = moment.unix(data.starting_timestamp).format('YYYY-MM-DD')
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(data)
        return groups
      }, {})

      let groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date]
        }
      })

      groupArrays = groupArrays.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      this.demo_by_role_data = {}
      this.userRolesChartData.labels.map((item) => {
        this.demo_by_role_data[item] = []
        return true
      })

      groupArrays.map((obj) => {
        this.demo_by_role_label.push(moment(obj.date).format('MMM D'))

        const groupByDep = obj.data.reduce((r, a) => {
          r[a.department] = [...(r[a.department] || []), a]
          return r
        }, {})
        const groupByDepKeys = Object.keys(groupByDep)

        this.userRolesChartData.labels.forEach((key, index) => {
          let match = false
          groupByDepKeys.forEach((dept, i) => {
            if (dept === key) match = true
          })
          if (match) this.demo_by_role_data[key].push(groupByDep[key].length)
          else this.demo_by_role_data[key].push(0)
        })
        return true
      })
      if (this.demo_by_role_label === 0) this.demo_by_role_label.push(0)
    },
    groupByDemoType (start, end) {
      // Users Actuvuty By Department
      const startDate = moment(start).format('YYYY-MM-DD')
      const endDate = moment(end).format('YYYY-MM-DD')
      this.demo_by_type_label = []

      const dataByDepartment = this.instances_list.filter((obj) => {
        const dataFormat = moment
          .unix(obj.starting_timestamp)
          .format('YYYY-MM-DD HH:mm')
        if (startDate < dataFormat && endDate > dataFormat) {
          return true
        } else return false
      })

      const mergeData = []
      dataByDepartment.map((item, i) => {
        this.users.map((obj) => {
          if (item.username === obj.id.split('@')[0]) {
            mergeData.push(Object.assign({}, item, obj))
          }
          return true
        })
        return true
      })

      this.demo_by_type_data = {
        'SE Practice Demos': [],
        'SE Customer Demos': [],
        'Partners Practice Demos': [],
        'Partners Customer Demos': []
      }

      const SEDataPractice = []
      const SEDatacustomerDemos = []
      const PartnersDataPractice = []
      const PartnersDatacustomerDemos = []
      const all = []

      mergeData.forEach((obj) => {
        if (
          obj.department === 'SE' &&
          (obj.demo_purpose === 'practice' ||
            obj.demo_purpose === 'customerDemos')
        ) {
          all.push(obj)
          if (obj.demo_purpose === 'practice') {
            SEDataPractice.push(obj)
          }
          if (obj.demo_purpose === 'customerDemos') {
            SEDatacustomerDemos.push(obj)
          }
        }
        if (
          obj.department === 'Partners' &&
          (obj.demo_purpose === 'practice' ||
            obj.demo_purpose === 'customerDemos')
        ) {
          all.push(obj)
          if (obj.demo_purpose === 'practice') {
            PartnersDataPractice.push(obj)
          }
          if (obj.demo_purpose === 'customerDemos') {
            PartnersDatacustomerDemos.push(obj)
          }
        }
      })

      this.json_data = []
      all.forEach((obj) => {
        const record = {
          starting_timestamp: moment
            .unix(obj.starting_timestamp)
            .format('YYYY-MM-DD'),
          username: obj.username,
          id: obj.id,
          department: obj.department,
          demo_purpose: obj.demo_purpose,
          demo_purpose_customer_name: obj.demo_purpose_customer_name,
          demo_purpose_customer_url: obj.demo_purpose_customer_url,
          name: obj.name
        }
        this.json_data.push(record)
      })

      const groups = all.reduce((groups, data) => {
        const date = moment.unix(data.starting_timestamp).format('YYYY-MM-DD')
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(data)
        return groups
      }, {})

      const orderedGroups = {}
      Object.keys(groups)
        .sort(function (a, b) {
          return (
            moment(a, 'YYYY-MM-DD').toDate() - moment(b, 'YYYY-MM-DD').toDate()
          )
        })
        .forEach(function (key) {
          orderedGroups[key] = groups[key]
        })

      Object.keys(orderedGroups).forEach((date) => {
        const formatedDate = moment(date).format('MMM D')
        if (!this.demo_by_type_label.includes(formatedDate)) {
          this.demo_by_type_label.push(formatedDate)
        }
      })
      this.getEachRoleDemoTypeRecord(SEDataPractice, 'SE Practice Demos')
      this.getEachRoleDemoTypeRecord(SEDatacustomerDemos, 'SE Customer Demos')
      this.getEachRoleDemoTypeRecord(
        PartnersDataPractice,
        'Partners Practice Demos'
      )
      this.getEachRoleDemoTypeRecord(
        PartnersDatacustomerDemos,
        'Partners Customer Demos'
      )
    },
    getEachRoleDemoTypeRecord (record, type) {
      const groups = record.reduce((groups, data) => {
        const date = moment.unix(data.starting_timestamp).format('YYYY-MM-DD')
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(data)
        return groups
      }, {})

      const orderedGroups = {}
      Object.keys(groups)
        .sort(function (a, b) {
          return (
            moment(b, 'YYYY-MM-DD').toDate() - moment(a, 'YYYY-MM-DD').toDate()
          )
        })
        .forEach(function (key) {
          orderedGroups[key] = groups[key]
        })

      Object.keys(orderedGroups).forEach((date) => {
        const formatedDate = moment(date).format('MMM D')
        this.demo_by_type_label.forEach((allDate, index) => {
          if (typeof this.demo_by_type_data[type][index] === 'undefined') {
            if (allDate === formatedDate) {
              this.demo_by_type_data[type][index] = orderedGroups[date].length
            } else {
              this.demo_by_type_data[type][index] = 0
            }
          } else {
            if (allDate === formatedDate) {
              this.demo_by_type_data[type][index] = orderedGroups[date].length
            }
          }
        })
      })
    },
    getUserActivity () {
      const shortArray = this.instances_list.sort(function (a, b) {
        return parseInt(a.starting_timestamp) - parseInt(b.starting_timestamp)
      })
      const groupedByMonth = _.groupBy(shortArray, function (obj) {
        return moment
          .unix(obj.starting_timestamp)
          .format('YYYY-MM-DD')
          .substring(0, 7)
      })

      Object.entries(groupedByMonth).forEach(([key, value], index) => {
        this.lastSixMonth.push(moment(key).format('MMM YY'))

        const dataGroupUser = value.reduce((r, a) => {
          r[a.username] = [...(r[a.username] || []), a]
          return r
        }, {})
        const dataGroupUserKeys = Object.keys(dataGroupUser)
        this.lastSixMonthRecordByUser.push(dataGroupUserKeys.length)

        const datafilterPurposePractice = value.filter(
          (obj) => obj.demo_purpose === 'practice'
        )

        const datafilterPurposeCustomerDemos = value.filter(
          (obj) => obj.demo_purpose === 'customerDemos'
        )

        this.lastSixMonthRecordByPurposePractice.push(
          datafilterPurposePractice.length
        )
        this.lastSixMonthRecordByPurposeCustomer.push(
          datafilterPurposeCustomerDemos.length
        )
        this.lastSixMonthRecord.push(value.length)

        const dataGroup = value.reduce((r, a) => {
          r[a.name] = [...(r[a.name] || []), a]
          return r
        }, {})
        const objectKeys = Object.keys(dataGroup)
        this.lastSixMonthScenarios = this.lastSixMonthScenarios.map((obj) => {
          if (objectKeys.includes(obj.label)) {
            obj.data.push(dataGroup[obj.label].length)
          } else {
            obj.data.push(0)
          }
          return obj
        })
      })
    },
    fetchUserRecords () {
      try {
        return axios({
          method: 'GET',
          url: this.backendURL + 'dynamodb/steeldemousers',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          responseType: 'json'
        }).then((result) => {
          this.users = result.data.body.Items
          const dataGroup = this.users.reduce((r, a) => {
            r[a.department] = [...(r[a.department] || []), a]
            return r
          }, {})
          const objectEntries = Object.entries(dataGroup)

          objectEntries.map((arr) => {
            this.userRolesChartData.labels.push(arr[0])
            this.userRolesChartData.data.push(arr[1].length)
            return true
          })

          this.userRolesChartData.total = this.users.length
          this.userRolesChartData.backgroundColor = randomColor({
            hue: 'blue',
            luminosity: 'light',
            count: this.users.length
          })
        })
      } catch (error) {
        // console.log('error in dashboard Instances ' + error)
      }
    },
    fetchCommunityUserRecords () {
      try {
        return axios({
          method: 'GET',
          url: this.backendURL + 'dynamodb/communityusers',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          responseType: 'json'
        }).then((result) => {
          const resBody = result.data.body.Items
          const shortArray = resBody.sort(function (a, b) {
            return parseInt(a.issueAt) - parseInt(b.issueAt)
          })
          const groupedByMonth = _.groupBy(shortArray, function (obj) {
            return moment
              .unix(obj.issueAt)
              .format('YYYY-MM-DD')
              .substring(0, 7)
          })
          this.communityUsersRecord.data = Object.values(groupedByMonth).map(
            (arr) => arr.length
          )
          this.communityUsersRecord.label = Object.keys(
            groupedByMonth
          ).map((key) => moment(key).format('MMM YY'))
        })
      } catch (error) {
        // console.log('error in dashboard Instances ' + error)
      }
    },
    mapAzureCost (startDate, endDate) {
      this.azureCostData = [
        {
          name: null,
          labels: [],
          data: []
        },
        {
          name: null,
          labels: [],
          data: []
        },
        {
          name: null,
          labels: [],
          data: []
        }
      ]
      const reqBody = {
        timePeriod: {
          from: moment.utc(startDate).format('YYYY-MM-DDT00:00:00'),
          to: moment.utc(endDate).format('YYYY-MM-DDT23:59:59')
        }
      }

      try {
        return axios({
          method: 'POST',
          url: this.backendURL + 'azure/cost',
          data: { reqBody },
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          crossDomain: true,
          responseType: 'json'
        }).then((result) => {
          const resbody = result.data.body

          this.azureCostData[0].name = resbody[0].name
          resbody[0].data.properties.rows.map((val) => {
            this.azureCostData[0].labels.push(
              moment(val[1].toString()).format('MMM D')
            )
            this.azureCostData[0].data.push(val[0])
            return true
          })

          this.azureCostData[1].name = resbody[1].name
          resbody[1].data.properties.rows.map((val) => {
            this.azureCostData[1].labels.push(
              moment(val[1].toString()).format('MMM D')
            )
            this.azureCostData[1].data.push(val[0])
            return true
          })

          this.azureCostData[2].name = resbody[2].name
          resbody[2].data.properties.rows.map((val) => {
            this.azureCostData[2].labels.push(
              moment(val[1].toString()).format('MMM D')
            )
            this.azureCostData[2].data.push(val[0])
            return true
          })
        })
      } catch (error) {
        // console.log('error in dashboard Instances ' + error)
      }
    },
    mapAWSCost (startDate, endDate) {
      this.awsCostData = { labels: [], data: [] }

      const reqBody = {
        timePeriod: {
          Start: moment(startDate).format('YYYY-MM-DD'),
          End: moment(endDate).add(1, 'days').format('YYYY-MM-DD')
        }
      }
      try {
        return axios({
          method: 'POST',
          url: this.backendURL + 'aws/cost',
          data: { reqBody },
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          crossDomain: true,
          responseType: 'json'
        }).then((result) => {
          const resbody = JSON.parse(result.data.body)
          resbody.ResultsByTime.map((obj) => {
            this.awsCostData.labels.push(
              moment(obj.TimePeriod.Start.toString()).format('MMM')
            )
            this.awsCostData.data.push(obj.Total.NetUnblendedCost.Amount)
            return true
          })
        })
      } catch (error) {
        // console.log('error in dashboard Instances ' + error)
      }
    },
    async changeCostQuarter (val) {
      this.costQuarterDropDown.selectedOption = val
      const dateRange = this.ranges[val]
      await this.getCostQuarter(dateRange[0], dateRange[1])
    },
    async getCostQuarter (startDate, endDate) {
      this.costQuarterRecord = false
      const timeNow = new Date(moment())
      if (endDate > timeNow) {
        endDate = timeNow
      }
      this.costQuarter = {
        AWS: 0,
        AZURE: 0
      }
      const reqBody = {
        timePeriod: {
          Start: moment(startDate).format('YYYY-MM-DD'),
          End: moment(endDate).add(1, 'days').format('YYYY-MM-DD')
        }
      }
      const azureReqBody = {
        timePeriod: {
          from: moment
            .utc(startDate)
            .add(1, 'days')
            .format('YYYY-MM-DDT00:00:00'),
          to: moment.utc(endDate).format('YYYY-MM-DDT23:59:59')
        }
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('msal.idtoken')
      }
      const requestOne = axios.post(
        this.backendURL + 'aws/cost',
        { reqBody },
        { headers }
      )
      const requestTwo = axios.post(
        this.backendURL + 'azure/cost',
        { reqBody: azureReqBody },
        { headers }
      )

      await axios
        .all([requestOne, requestTwo])
        .then(
          axios.spread((...responses) => {
            const responseOne = responses[0]
            const responseTwo = responses[1]

            const resbodyOne = JSON.parse(responseOne.data.body)
            this.costQuarter.AWS = resbodyOne.ResultsByTime.reduce(function (
              sum,
              current
            ) {
              return sum + parseFloat(current.Total.NetUnblendedCost.Amount)
            },
            0)

            const resbodyTwo = responseTwo.data.body
            resbodyTwo.forEach((obj) => {
              this.costQuarter.AZURE =
                this.costQuarter.AZURE +
                obj.data.properties.rows.reduce(function (sum, current) {
                  return sum + parseFloat(current[0])
                }, 0)
            })
            this.costQuarterRecord = true
          })
        )
        .catch((errors) => {})
    }
  },
  async created () {
    const quarter1 = new Date(moment().quarter(1).startOf('quarter'))
    const quarter2 = new Date(moment().quarter(2).startOf('quarter'))
    const quarter3 = new Date(moment().quarter(3).startOf('quarter'))
    const quarter4 = new Date(moment().quarter(4).startOf('quarter'))
    const currentQuarter = moment().quarter()
    const timeNow = new Date(moment())
    this.costQuarterDropDown.selectedOption =
      'This Year Q' + (currentQuarter - 1)
    if (timeNow >= quarter1) {
      this.ranges['This Year Q1'] = [
        new Date(moment().quarter(1).startOf('quarter')),
        new Date(moment().quarter(1).endOf('quarter'))
      ]
      this.costQuarterDropDown.options.push({
        value: 'This Year Q1',
        text: 'This Year Q1'
      })
    }
    if (timeNow >= quarter2) {
      this.ranges['This Year Q2'] = [
        new Date(moment().quarter(2).startOf('quarter')),
        new Date(moment().quarter(2).endOf('quarter'))
      ]
      this.costQuarterDropDown.options.push({
        value: 'This Year Q2',
        text: 'This Year Q2'
      })
    }
    if (timeNow >= quarter3) {
      this.ranges['This Year Q3'] = [
        new Date(moment().quarter(3).startOf('quarter')),
        new Date(moment().quarter(3).endOf('quarter'))
      ]
      this.costQuarterDropDown.options.push({
        value: 'This Year Q3',
        text: 'This Year Q3'
      })
    }
    if (timeNow >= quarter4) {
      this.ranges['This Year Q4'] = [
        new Date(moment().quarter(4).startOf('quarter')),
        new Date(moment().quarter(4).endOf('quarter'))
      ]
      this.costQuarterDropDown.options.push({
        value: 'This Year Q4',
        text: 'This Year Q4'
      })
    }
    this.demos()
    await this.fetchCommunityUserRecords()
    await this.fetchUserRecords()
    await this.instance(this.dateRange)
    this.groupByRole(
      this.dateRangeByRole.startDate,
      this.dateRangeByRole.endDate
    )
    this.groupByDemoType(
      this.dateRangeByDemoType.startDate,
      this.dateRangeByDemoType.endDate
    )
    this.mapAzureCost(
      this.dateRangeByAzure.startDate,
      this.dateRangeByAzure.endDate
    )
    this.mapAWSCost(this.dateRangeByAWS.startDate, this.dateRangeByAWS.endDate)
    await this.getCostQuarter(
      new Date(
        moment()
          .quarter(currentQuarter - 1)
          .startOf('quarter')
      ),
      new Date(
        moment()
          .quarter(currentQuarter - 1)
          .endOf('quarter')
      )
    )
  }
}
</script>
<style>
.user-activity-range a.dropdown-item {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.user-activity-range li {
  width: 100% !important;
}

.user-activity-range button#dropdown-1__BV_toggle_ {
  color: #ff681d !important;
  background-color: white !important;
}
.user-activity-range button#dropdown-1__BV_toggle_:hover {
  color: white !important;
  background-color: #ff681d !important;
}
h3.graph-name {
  font-size: 1.35rem;
}
.chart-row {
  width: calc(100% - 40px);
  margin-left: 20px !important;
}

span.drp-selected {
  display: none !important;
}
.daterangepicker .ranges ul li {
  display: block;
}
/* .daterangepicker .ranges ul li:nth-child(1) {
  visibility: hidden !important;
}
.daterangepicker .ranges ul li:nth-child(2) {
  visibility: hidden !important;
} */
.daterangepicker button.applyBtn.btn.btn-sm.btn-success:hover {
  color: #218838 !important;
  background-color: white !important;
  border-color: #1e7e34;
}

.daterangepicker button.cancelBtn.btn.btn-sm.btn-secondary:hover {
  color: #5a6268 !important;
  background-color: white !important;
  border-color: #545b62;
}

.daterangepicker .calendars {
  flex-wrap: nowrap !important;
}

button.cancelBtn.btn.btn-sm.btn-secondary .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.tile_count {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  color: #73879c;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
}
.col-md-2.col-sm-4.tile_stats_count {
  padding: 0 15px 0 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-bottom: 10px;
  border-bottom: 0;
  padding-bottom: 10px;
  min-width: fit-content;
}
.tile_count .tile_stats_count:before {
  content: "";
  position: absolute;
  left: 0;
  height: 65px;
  border-left: 2px solid #adb2b5;
  margin-top: 10px;
}
.tile_count .tile_stats_count:first-child:before {
  border-left: 0;
}

.tile_count .tile_stats_count span {
  font-size: 13px;
  font-weight: 600;
}

.tile_count .tile_stats_count .count {
  font-size: 35px;
  line-height: 47px;
  font-weight: 600;
}

.tile_count .tile_stats_count .count_bottom i {
  width: 12px;
}

.green_color {
  color: #1abb9c;
  background-color: none !important;
}

.red_color {
  background-color: none !important;
}
.dashboard_graph {
  background: #fff;
  padding: 7px 10px;
}
.x_title {
  border-bottom: 2px solid #e6e9ed;
  padding: 1px 5px 6px;
  margin-bottom: 10px;
}
.dashboard_graph .x_title {
  padding: 5px 5px 7px;
}
.dashboard_graph .col-md-9,
.dashboard_graph .col-md-3 {
  padding: 0;
}
.demo-placeholder {
  height: 430px;
}
.x_panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: 0px 3px 6px #00000033;
}
.x_title {
  border-bottom: 2px solid #e6e9ed;
  padding: 1px 5px 6px;
  margin-bottom: 10px;
}
.x_content {
  padding: 0 3px 6px;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin-top: 5px;
}
.panel_toolbox {
  float: right;
  min-width: 70px;
}
.navbar-right {
  margin-right: 0;
}
canvas#line-chart {
  width: 100% !important;
}
.most-launched-name {
  margin-bottom: 0 !important;
  /* height: 31px !important; */
  min-height: 10px !important;
  font-size: 1.1rem !important;
}
.mb-3 .progress-bar {
  background-color: rgba(255, 104, 29, 0.75);
}

.demo_by_month .chartjs-render-monitor {
  min-height: 520px !important;
}

/* .demo_by_activity .chartjs-render-monitor{
  min-height: 520px !important;
} */
.user_by_geo .vue-world-map {
  padding-top: 70px !important;
  min-height: 520px !important;
}

.parent-dashboard {
  padding: 10px 0px;
  margin: 10px 0px;
  box-shadow: 10px 7px 80px -30px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 10px 7px 80px -30px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 7px 80px -30px rgba(0, 0, 0, 0.4);
}

.cost-dropdown .btn-outline-danger {
  color: #495057 !important;
  border: 1px solid #ccc;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.cost-dropdown .btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #495057 !important;
  border: 1px solid #ccc;
  background-color: snow;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
