<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      gradient: null,
      colorList: [],
      labelName: []
    }
  },
  props: ['chartLabels', 'chartData', 'options'],
  methods: {
    getGradient (color) {
      this.gradient = this.$refs.canvas
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 450)
      this.gradient.addColorStop(0, `rgba(${color}, 0.5)`)
      this.gradient.addColorStop(0.5, `rgba(${color}, 0.25)`)
      this.gradient.addColorStop(1, `rgba(${color}, 0.15)`)
      return this.gradient
    },
    getDataSet (data) {
      return this.labelName.map((val, index) => {
        const GradColor = this.getGradient(this.colorList[index])
        const obj = {
          label: val,
          borderColor: GradColor,
          pointBackgroundColor: GradColor,
          borderWidth: 1,
          pointBorderColor: GradColor,
          backgroundColor: GradColor,
          data: data[index]
        }
        return obj
      })
    }
  },
  mounted () {
    this.colorList = ['255, 104, 29', '0, 231, 255', '2, 179, 31', '14, 98, 232']
    this.labelName = ['Load', 'Start', 'Pause', 'Finished']
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: this.getDataSet(this.chartData)
      }, this.options
    )
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart({
          labels: this.chartLabels,
          datasets: this.getDataSet(newOption)
        }, this.options)
      }
    }
  }
}
</script>
