<template>
    <div class="updates">
        <div class="updates card">
             <div class="details">
                 <i class="material-icons close" v-on:click="closeUpdate()">clear</i>
                <div class="title">SteelDemo Recent Updates</div>
                <div class="content" v-for="update in data" :key="update['date']">
                    <div class="date">{{showDate(update['date'])}}</div>
                    <div class="features">
                        <p>New Features</p>
                        <ul v-for="feature in update['New Features']" :key="feature">
                            <li>{{feature}}</li>
                        </ul>
                    </div>
                    <div class="changes">
                        <p>Changes</p>
                        <ul v-for="change in update['Changes']" :key="change">
                            <li>{{change}}</li>
                        </ul>
                    </div>
                    <div class="fixes">
                        <p>Fixes</p>
                        <ul v-for="fix in update['Fixes']" :key="fix">
                            <li>{{fix}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  name: 'updates',
  props: {
    // all the updates
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    showDate (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD')
    },
    ...mapMutations([
      'UPDATE_STEELDEMO'
    ]),
    closeUpdate () {
      this.UPDATE_STEELDEMO(null)
    }
  }
}

</script>

<style>

.updates {
    text-align: left;
    width: 50vw;
    margin-left: 13vw;
}

.updates .details{
background-color:white;
height: auto;
overflow: visible;
border-radius: 10px;
position: relative;
color: #585555;
z-index: 1000;
padding: 20px;
overflow: scroll;
}

.updates .details .title{
    font-size: 1.5vw;
    color: rgb(102,100,100);
    text-align: center;
    font-weight: bold;
}

.updates .details .date{
    font-size: 1.0vw;
    font-weight: bold;
    text-decoration: underline;
}

.updates .details p{
    font-size: 0.9vw;
    font-weight: bold;
}

.updates .details li{
    font-size: 0.9vw;
    list-style-type: circle;
    margin-left: 2vw;
}

.updates .close{
    position: absolute;
    right: 5px;
    top: 16px;
    color: #aaa;
    font-size: 1.4em;
    cursor: pointer;
}

</style>
