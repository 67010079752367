<template>
  <div class="Table">
    <table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
      </thead>
      <tbody v-if="type === 'planned'">
        <tr v-for="row in data" :key="row.demoId">
          <td>
            {{ row["category"] }}
          </td>
          <td>{{ showScenarioName(row.scenarioId) }}</td>
          <td>{{ showDate(row["starting_timestamp"]) }}</td>
          <td>{{ showDuration(row["duration"]) }} hours</td>
          <td>{{ row["status"] }}</td>
          <td>
            <i
              class="fas fa-ban"
              style="color: red; cursor: pointer"
              v-on:click="cancelInstance(row['scenarioId'], row['demoId'])"
              title="Cancel demo"
            ></i>
          </td>
        </tr>
      </tbody>
      <tbody v-if="type === 'running'">
        <tr v-for="(row, index) in data" :key="row.demoId">
          <slot :row="row">
            <td>
              {{ row["category"] }}
            </td>
            <td
              style="cursor: pointer; color: blue; text-decoration: underline"
              @click="tabChange(row)"
            >
              {{ showScenarioName(row.scenarioId) }}
            </td>
            <td>{{ showDate(row["starting_timestamp"]) }}</td>
            <td>{{ showDuration(row["duration"]) }} hours</td>
            <td>{{ row["status"] }}</td>
            <td>
              <div
                v-if="row['login'] && row['login'] !== 'N/A'"
                style="display: inline-flex"
              >
                <div class="col-6" style="max-width: 135px !important">
                  {{ row["login"] }}
                </div>
                <i
                  class="material-icons"
                  style="cursor: pointer"
                  @click="copyUsername(row, index)"
                  >content_copy</i
                >
                <transition name="fade" v-on:enter="enterUserName">
                  <p
                    v-if="copyUserName"
                    style="
                      font-size: 0.8vw;
                      margin-top: 3px;
                      margin-left: 3px;
                      color: green;
                      font-weight: 500;
                    "
                  >
                    copied!
                  </p>
                </transition>
                <input
                  type="hidden"
                  id="copy-code-username"
                  :value="row['login']"
                />
              </div>
              <div v-else style="display: inline-flex">
                <div class="col-6" style="max-width: 135px !important">
                  {{ row["login"] }}
                </div>
              </div>
            </td>
            <td>
              <div
                v-if="row['password'] && row['password'] !== 'N/A'"
                style="display: inline-flex"
              >
                <div
                  class="col-6"
                  style="max-width: 135px !important; margin-top: 3px"
                >
                  ****************
                </div>
                <i
                  class="material-icons"
                  style="cursor: pointer"
                  @click="togglePassword(row, index)"
                  >content_copy</i
                >
                <transition name="fade" v-on:enter="enterPassword">
                  <p
                    v-if="copyPassword"
                    style="
                      font-size: 0.8vw;
                      margin-top: 3px;
                      margin-left: 3px;
                      color: green;
                      font-weight: 500;
                    "
                  >
                    copied!
                  </p>
                </transition>
                <input type="hidden" id="copy-code" :value="row['password']" />
              </div>
              <div v-else style="display: inline-flex">
                <div class="col-6" style="max-width: 135px !important">
                  {{ row["password"] }}
                </div>
              </div>
            </td>
            <td>{{ showSubnet(row["subnet"]) }}</td>
            <!-- <td
            class="links"
            v-if="
              row['status'] === 'User Created' ||
              row['status'] === 'Topology Created' ||
              row['status'] === 'Remote Desktop User Created' ||
              row['status'] === 'Org User Created'
            "
          >
            <i
              class="fas fa-bolt"
              v-if="!(row['url'] || row['ip_address'])"
              style="color: grey"
            ></i>
            <i
              class="fas fa-bolt"
              v-if="row['url']"
              style="color: #fbb022; cursor: pointer"
              v-on:click="openLink(row['url'])"
              title="Connect to demo"
            ></i>
            <i
              class="fas fa-bolt"
              v-if="row['ip_address']"
              style="color: #fbb022; cursor: pointer"
              v-on:click="openAlert(row['ip_address'])"
              title="Connect to demo"
            ></i>
            <i
              class="fas fa-desktop"
              v-if="row['wvd_url']"
              style="color: #fbb022; cursor: pointer"
              v-on:click="openLink(row['wvd_url'])"
              title="Connect to Virtual Desktop"
            ></i>
            <i
              class="fas fa-stop-circle"
              style="color: red; cursor: pointer"
              v-on:click="terminate_instance(row['demoId'])"
              title="Terminate demo"
            ></i>
            <i
              class="fas fa-file-alt"
              style="color: #6bb5e4; cursor: pointer"
              v-on:click="openLink(row['script'])"
              title="Script"
            ></i>
            <i
              class="fas fa-camera"
              v-if="isSnapshotCapable(row.scenarioId)"
              style="color: #575757; cursor: pointer"
              v-on:click="showSnapshot(row['demoId'])"
              title="Snapshot"
            ></i>
          </td> -->
          </slot>
        </tr>
      </tbody>
      <tbody v-if="type === 'demos' || type === 'cost'">
        <tr v-for="row in data" :key="row.demoId">
          <td>{{ row["category"] }}</td>
          <td>{{ showScenarioName(row.scenarioId) }}</td>
          <td>{{ showDate(row["starting_timestamp"]) }}</td>
          <td>{{ showDuration(row["duration"]) }} hours</td>
          <td>$ {{ row["cost"] }}</td>
        </tr>
      </tbody>
      <tbody v-if="type === 'snapshot'">
        <tr v-for="row in data" :key="row.snapshotId">
          <td>{{ row["category"] }}</td>
          <td>{{ row["name"] }}</td>
          <td>{{ showDate(row["creation_timestamp"]) }}</td>
          <td>{{ row["description"] }}</td>
          <td class="links">
            <i
              class="fas fa-play-circle"
              style="color: #6bb5e4; cursor: pointer"
              v-on:click="
                startSnapshot(row['snapshotId'], row['name'], row['category'])
              "
              title="Start snapshot"
            ></i>
            <i
              class="fas fa-trash-alt"
              style="color: #575757; cursor: pointer"
              v-on:click="deleteSnapshot(row['snapshotId'], row['name'])"
              title="Delete snapshot"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import { mapMutations, mapGetters } from 'vuex'

export default {
  data () {
    return {
      input: {
        instanceId: 0
      },
      copyUserName: false,
      copyPassword: false,
      backendURL: process.env.VUE_APP_BACKEND_URL
    }
  },
  props: {
    // headers of the table
    headers: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    // contains scenarioId<->Scenario Name match
    scenarios: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['snapshotNbr', 'terminateInstance'])
  },
  methods: {
    ...mapMutations([
      'SELECT_INSTANCE',
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_CREATE_SNAPSHOT',
      'UPDATE_SNAPSHOT_NAME',
      'UPDATE_SNAPSHOT_CATEGORY',
      'UPDATE_DELETE_SNAPSHOT',
      'UPDATE_START_SNAPSHOT',
      'UPDATE_TERMINATE_INSTANCE',
      'UPDATE_IP_ADDRESS',
      'SELECT_DEMO_PURPOSE'
    ]),
    showScenarioName (scenarioId) {
      let name = ''
      const scenario = this.scenarios.filter(function (obj) {
        return obj.id === scenarioId
      })
      // the filtering will return one and only one scenario or zero
      if (scenario.length > 0) name = scenario[0].name
      return name
    },
    togglePassword: function (row, index) {
      const codeToCopy = document.querySelector('#copy-code')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copyPassword = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    copyUsername: function () {
      const codeToCopy = document.querySelector('#copy-code-username')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copyUserName = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    enterUserName: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copyUserName = false
      }, 3000) // hide the message after 3 seconds
    },
    enterPassword: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copyPassword = false
      }, 3000) // hide the message after 3 seconds
    },
    tabChange (row) {
      if (row.snapshotId) this.$root.$emit('snapshot_nav')
      else {
        const findScenariosIndex = this.$store.scenarios.findIndex(
          (e) => e.scenarioId === row.scenarioId
        )
        const findLabIndex = this.$store.scenariosLab.findIndex(
          (e) => e.scenarioId === row.scenarioId
        )

        if (findScenariosIndex > -1) this.$root.$emit('scenario_nav')
        else if (findLabIndex > -1) this.$root.$emit('lab_nav')
      }
    },
    showDate (timestamp) {
      return moment.unix(timestamp).format('YYYY-MM-DD HH:mm')
    },
    showDuration (sDuration) {
      if (sDuration === null || sDuration === '') return 0
      const duration = Math.round(parseInt(sDuration, 10) / 3600)
      return duration
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    openAlert (ip) {
      this.UPDATE_IP_ADDRESS(ip)
    },
    showSubnet (subnet) {
      if (subnet === null) subnet = 'N/A'
      return subnet
    },
    terminate_instance (instanceId) {
      this.SELECT_INSTANCE(instanceId)
      this.UPDATE_TERMINATE_INSTANCE(true)
    },
    cancelInstance (scenarioId, instanceId) {
      this.input.instanceId = instanceId
      axios({
        method: 'POST',
        url: this.backendURL + 'topology/cancel',
        data: this.input,
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        // crossDomain: true,
        responseType: 'json'
      }).then(
        (result) => {
          this.response = result.data
        },
        (error) => {
          console.error(error)
        }
      )
    },
    showSnapshot (instanceId) {
      if (this.snapshotNbr > 2) { alert('SteelDemo does not allow more than 3 snapshots per user') } else {
        this.SELECT_INSTANCE(instanceId)
        this.$store.running_demos.map(obj => {
          if (obj.demoId === instanceId) {
            const demoPurposeRecord = {
              demo_purpose: obj.demo_purpose || '',
              demo_purpose_customer_name: obj.demo_purpose_customer_name || '',
              demo_purpose_customer_url: obj.demo_purpose_customer_url || ''
            }
            this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
          }
          return this.UPDATE_CREATE_SNAPSHOT(true)
        })
      }
    },
    startSnapshot (snapshotId, snapshotName, snapshotCategory) {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_START_SNAPSHOT(false)
      this.UPDATE_DELETE_SNAPSHOT(false)

      this.UPDATE_SNAPSHOT_ID(snapshotId)
      this.UPDATE_SNAPSHOT_NAME(snapshotName)
      this.UPDATE_SNAPSHOT_CATEGORY(snapshotCategory)
      this.UPDATE_START_SNAPSHOT(true)
    },
    deleteSnapshot (snapshotId, snapshotName) {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_START_SNAPSHOT(false)
      this.UPDATE_DELETE_SNAPSHOT(false)

      this.UPDATE_SNAPSHOT_ID(snapshotId)
      this.UPDATE_SNAPSHOT_NAME(snapshotName)
      this.UPDATE_DELETE_SNAPSHOT(true)
    }
  }
}
</script>

<style>
table th {
  text-align: center;
}

table td {
  text-align: center;
  word-wrap: break-word;
  width: 10%;
}
table i {
  font-size: 190%;
  text-align: center;
}

.table thead {
  font-size: 16px;
}

.table tbody {
  font-size: 14px;
  overflow: scroll;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
