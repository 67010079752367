var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row m-0 p-0" }, [
      _c(
        "div",
        {
          staticClass: "content-layout-area-sanbox",
          staticStyle: {
            "padding-top": "20px !important",
            "padding-right": "20px !important",
            "border-left": "1px solid #e3e3e3"
          }
        },
        [
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              "is-full-page": _vm.fullPage,
              loader: _vm.loader,
              color: _vm.color
            }
          }),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "padding-left": "20px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    color: "#ff681d",
                    "font-size": "30px",
                    padding: "20%"
                  }
                },
                [
                  _vm._v(
                    " Sandbox Labs are not supported anymore. Please choose another category "
                  )
                ]
              ),
              !_vm.scenarios.length && !_vm.isLoading
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        color: "#ff681d",
                        "font-size": "30px",
                        padding: "20%"
                      }
                    },
                    [_vm._v(" No product, please choose another scenarios ")]
                  )
                : _vm._e()
            ]
          ),
          _vm.terminateInstance
            ? _c("TerminateInstance", { attrs: { instance: _vm.instance } })
            : _vm._e(),
          _vm.ipAddress ? _c("InstanceIP") : _vm._e()
        ],
        1
      )
    ]),
    _vm.showUserCredModal
      ? _c(
          "div",
          [
            _c(
              "b-modal",
              {
                attrs: {
                  id: "modal-2",
                  centered: "",
                  "hide-header": "",
                  "content-class": "modal-content2"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modal-footer",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "btn btn-primary btn-custom btn-user-cred",
                              attrs: { size: "sm" },
                              on: { click: _vm.closeUserCredModal }
                            },
                            [_vm._v(" Close ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  435782646
                )
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-4" }, [
                      _c("strong", { staticStyle: { float: "right" } }, [
                        _vm._v("User Name: ")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-6",
                        staticStyle: { "max-width": "142px !important" }
                      },
                      [_vm._v(" " + _vm._s(_vm.userName) + " ")]
                    ),
                    _c("input", {
                      attrs: { type: "hidden", id: "copy-code-username" },
                      domProps: { value: _vm.userName }
                    }),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: {
                          cursor: "pointer",
                          "margin-left": "6px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.copyUsername()
                          }
                        }
                      },
                      [_vm._v("content_copy")]
                    ),
                    _c(
                      "transition",
                      {
                        attrs: { name: "fade" },
                        on: { enter: _vm.enterUserName }
                      },
                      [
                        _vm.copyUserName
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "0.8vw",
                                  "margin-top": "3px",
                                  "margin-left": "3px",
                                  color: "green",
                                  "font-weight": "500"
                                }
                              },
                              [_vm._v(" copied! ")]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("strong", { staticStyle: { float: "right" } }, [
                      _vm._v("Password:")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "inline-flex" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-6",
                          staticStyle: {
                            "max-width": "113px !important",
                            "margin-top": "3px"
                          }
                        },
                        [_vm._v(" ***************** ")]
                      ),
                      _c("input", {
                        attrs: { type: "hidden", id: "copy-code" },
                        domProps: { value: _vm.password }
                      }),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          staticStyle: {
                            cursor: "pointer",
                            "margin-left": "35px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.togglePassword()
                            }
                          }
                        },
                        [_vm._v("content_copy")]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: { name: "fade" },
                          on: { enter: _vm.enterPassword }
                        },
                        [
                          _vm.copyPassword
                            ? _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "0.8vw",
                                    "margin-top": "3px",
                                    "margin-left": "3px",
                                    color: "green",
                                    "font-weight": "500"
                                  }
                                },
                                [_vm._v(" copied! ")]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm.subnet
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", { staticStyle: { float: "right" } }, [
                          _vm._v("Subnet:")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticStyle: { display: "inline-flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-6",
                              staticStyle: {
                                "max-width": "113px !important",
                                "margin-top": "3px"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.subnet) + " ")]
                          ),
                          _c("input", {
                            attrs: { type: "hidden", id: "copy-code-subnet" },
                            domProps: { value: _vm.subnet }
                          }),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: {
                                cursor: "pointer",
                                "margin-left": "35px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleSubnet()
                                }
                              }
                            },
                            [_vm._v("content_copy")]
                          ),
                          _c(
                            "transition",
                            {
                              attrs: { name: "fade" },
                              on: { enter: _vm.enterSubnet }
                            },
                            [
                              _vm.copySubnet
                                ? _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "font-size": "0.8vw",
                                        "margin-top": "3px",
                                        "margin-left": "3px",
                                        color: "green",
                                        "font-weight": "500"
                                      }
                                    },
                                    [_vm._v(" copied! ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }