<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data () {
    return {
      gradient: null,
      colorList: []
    }
  },
  props: ['chartLabels', 'chartData', 'options'],
  methods: {
    getSum (data) {
      let sum = 0
      data.map((data) => {
        sum += parseInt(data)
        return sum
      })
      return sum
    },
    getGradient (color) {
      this.gradient = this.$refs.canvas
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 450)
      this.gradient.addColorStop(0, `rgba(${color}, 0.75)`)
      this.gradient.addColorStop(0.5, `rgba(${color}, 0.25)`)
      this.gradient.addColorStop(1, `rgba(${color}, 0.15)`)
      return this.gradient
    },
    getDataSet (data) {
      return Object.entries(data).map(([key, value], index) => {
        const gradColor = this.getGradient(this.colorList[index])
        const obj = {
          label: key + ' (' + this.getSum(value) + ')',
          borderColor: gradColor,
          pointBackgroundColor: gradColor,
          borderWidth: 2,
          pointBorderColor: gradColor,
          backgroundColor: gradColor,
          data: value
        }
        return obj
      })
    }
  },
  mounted () {
    this.colorList = ['255, 104, 29', '0, 231, 255', '2, 179, 31', '14, 98, 232']
    this.renderChart({
      labels: this.chartLabels,
      datasets: this.getDataSet(this.chartData)
    }, this.options)
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart({
          labels: this.chartLabels,
          datasets: this.getDataSet(newOption)
        }, this.options)
      },
      deep: true
    }
  }
}
</script>
