var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row m-0 p-0" }, [
    _c(
      "div",
      {
        staticClass: "row chart-row",
        staticStyle: { display: "inline-block", "margin-bottom": "0px" }
      },
      [
        _c("div", { staticClass: "tile_count" }, [
          _c("div", { staticClass: "col-md-2 col-sm-4 tile_stats_count" }, [
            _vm._m(0),
            _c("div", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.running_list.length))
            ])
          ]),
          _c("div", { staticClass: "col-md-2 col-sm-4 tile_stats_count" }, [
            _c("span", { staticClass: "count_top" }, [_vm._v("Active Demo's")]),
            _c("div", { staticClass: "count green_color" }, [
              _vm._v(_vm._s(_vm.running_list.length))
            ])
          ]),
          _c("div", { staticClass: "col-md-2 col-sm-4 tile_stats_count" }, [
            _c("span", { staticClass: "count_top" }, [
              _vm._v(" Your's Running Instances")
            ]),
            _c("div", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.user_running_list.length))
            ])
          ]),
          _c("div", { staticClass: "col-md-2 col-sm-4 tile_stats_count" }, [
            _c("span", { staticClass: "count_top" }, [
              _vm._v(" Your's Demo to date")
            ]),
            _c("div", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.user_instances_list.length))
            ])
          ])
        ])
      ]
    ),
    _c("div", { staticClass: "row parent-dashboard" }, [
      _c(
        "div",
        {
          staticClass: "row chart-row",
          staticStyle: { "margin-top": "10px", "margin-bottom": "10px" }
        },
        [
          _c("div", { staticClass: "col-md-12 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "pull-right",
                staticStyle: { background: "#fff", float: "right" },
                attrs: { id: "reportrange" }
              },
              [
                _c("date-range-picker", {
                  attrs: {
                    startDate: _vm.startDate,
                    endDate: _vm.endDate,
                    "locale-data": _vm.locale,
                    ranges: _vm.ranges,
                    opens: "left"
                  },
                  on: { update: _vm.showDashboard },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function(picker) {
                        return _c("div", {}, [
                          _c("i", {
                            staticClass:
                              "glyphicon glyphicon-calendar fa fa-calendar"
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("dateFormat")(picker.startDate)) +
                              " - " +
                              _vm._s(_vm._f("dateFormat")(picker.endDate)) +
                              " "
                          )
                        ])
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dateRange,
                    callback: function($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange"
                  }
                }),
                _c("b", { staticClass: "caret" })
              ],
              1
            ),
            _c(
              "small",
              {
                staticStyle: {
                  color: "grey",
                  "font-size": "0.9rem",
                  "font-weight": "700",
                  float: "right",
                  "margin-right": "20px",
                  "margin-top": "8px"
                }
              },
              [_vm._v("Please select date range for the dashboard")]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "row chart-row" }, [
        _c("div", { staticClass: "col-md-8 col-sm-8" }, [
          _c("div", { staticClass: "x_panel" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _vm.lastSixMonth.length > 0
                    ? _c("UserActivityAreaChart", {
                        attrs: {
                          "chart-labels": _vm.lastSixMonth,
                          "chart-data": [
                            _vm.lastSixMonthRecord,
                            _vm.lastSixMonthRecordByUser,
                            _vm.lastSixMonthRecordByPurposePractice,
                            _vm.lastSixMonthRecordByPurposeCustomer
                          ],
                          options: _vm.createUserActivityChartOption(),
                          styles: {
                            height: "410px !important",
                            position: "relative"
                          }
                        }
                      })
                    : _vm._e(),
                  _c("loading", {
                    attrs: {
                      active: _vm.lastSixMonth.length > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "col-md-4 col-sm-4",
            staticStyle: { display: "flex" }
          },
          [
            _c("div", { staticClass: "x_panel" }, [
              _vm._m(2),
              _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "demo-placeholder",
                    staticStyle: { padding: "0px", position: "relative" },
                    attrs: { id: "chart_plot_01" }
                  },
                  [
                    _vm._l(_vm.scenariosOrderByLenght.slice(0, 5), function(
                      item,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("h5", { staticClass: "most-launched-name" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("b-progress", {
                            staticClass: "mb-3",
                            attrs: {
                              value: item.count,
                              max: _vm.scenariosOrderByLenght[0].count + 10,
                              "show-value": ""
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c("loading", {
                      attrs: {
                        active:
                          _vm.scenariosOrderByLenght.length > 0 ? false : true,
                        "is-full-page": _vm.fullPage,
                        loader: _vm.loader,
                        color: _vm.color
                      }
                    })
                  ],
                  2
                )
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "row chart-row" }, [
        _c("div", { staticClass: "col-md-12 col-sm-12" }, [
          _c("div", { staticClass: "x_panel" }, [
            _vm._m(3),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _vm.communityUsersRecord.data.length > 0
                    ? _c("CommunityActivityAreaChart", {
                        attrs: {
                          "chart-labels": _vm.communityUsersRecord.label,
                          "chart-data": _vm.communityUsersRecord.data,
                          styles: {
                            height: "410px !important",
                            position: "relative"
                          }
                        }
                      })
                    : _vm._e(),
                  _c("loading", {
                    attrs: {
                      active:
                        _vm.communityUsersRecord.data.length > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row chart-row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-4 col-sm-4",
            staticStyle: { display: "flex" }
          },
          [
            _c("div", { staticClass: "x_panel" }, [
              _vm._m(4),
              _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "demo-placeholder",
                    staticStyle: { padding: "0px", position: "relative" },
                    attrs: { id: "chart_plot_01" }
                  },
                  [
                    _vm._l(
                      _vm.scenariosOrderByLenght
                        .slice(_vm.scenariosOrderByLenght.length - 5)
                        .reverse(),
                      function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("h5", { staticClass: "most-launched-name" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c("b-progress", {
                              staticClass: "mb-3",
                              attrs: {
                                value: item.count,
                                max:
                                  _vm.scenariosOrderByLenght[
                                    _vm.scenariosOrderByLenght.length - 5
                                  ].count + 5,
                                "show-value": ""
                              }
                            })
                          ],
                          1
                        )
                      }
                    ),
                    _c("loading", {
                      attrs: {
                        active:
                          _vm.scenariosOrderByLenght.length > 0 ? false : true,
                        "is-full-page": _vm.fullPage,
                        loader: _vm.loader,
                        color: _vm.color
                      }
                    })
                  ],
                  2
                )
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "col-md-8 col-sm-8" }, [
          _c("div", { staticClass: "x_panel" }, [
            _c("div", { staticClass: "row x_title" }, [
              _vm._m(5),
              _c(
                "div",
                {
                  staticClass: "col-md-7",
                  staticStyle: { display: "flex", "flex-direction": "row" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pull-right",
                      staticStyle: {
                        background: "#fff",
                        float: "right",
                        "margin-left": "auto"
                      },
                      attrs: { id: "reportrange" }
                    },
                    [
                      _c("date-range-picker", {
                        attrs: {
                          startDate: _vm.startDateByDemoType,
                          endDate: _vm.endDateByDemoType,
                          minDate: _vm.minStartDate,
                          maxDate: _vm.maxEndDate,
                          "locale-data": _vm.localeByDemoType,
                          opens: "left"
                        },
                        on: { update: _vm.showDemoByDemoType },
                        scopedSlots: _vm._u([
                          {
                            key: "input",
                            fn: function(picker) {
                              return _c("div", {}, [
                                _c("i", {
                                  staticClass:
                                    "glyphicon glyphicon-calendar fa fa-calendar"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(picker.startDate)
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("dateFormat")(picker.endDate)
                                    ) +
                                    " "
                                )
                              ])
                            }
                          }
                        ]),
                        model: {
                          value: _vm.dateRangeByDemoType,
                          callback: function($$v) {
                            _vm.dateRangeByDemoType = $$v
                          },
                          expression: "dateRangeByDemoType"
                        }
                      }),
                      _c("b", { staticClass: "caret" })
                    ],
                    1
                  ),
                  _c(
                    "download-excel",
                    {
                      attrs: {
                        data: _vm.json_data,
                        fields: _vm.json_fields,
                        type: "csv",
                        name: "DemoReports.xls"
                      }
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          staticStyle: {
                            "font-size": "25px",
                            "padding-left": "20px",
                            "margin-top": "6px",
                            cursor: "pointer"
                          },
                          attrs: { title: "Download Report" }
                        },
                        [_vm._v("file_download")]
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _vm.demo_by_type_label.length > 0
                    ? _c("DemoTypeChart", {
                        attrs: {
                          "chart-labels": _vm.demo_by_type_label,
                          "chart-data": _vm.demo_by_type_data,
                          options: _vm.createDemoTypeChartOption(),
                          styles: {
                            height: "410px !important",
                            position: "relative"
                          }
                        }
                      })
                    : _vm._e(),
                  _c("loading", {
                    attrs: {
                      active: _vm.demo_by_type_label.length > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row chart-row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-8 col-sm-8",
            staticStyle: { display: "flex" }
          },
          [
            _c("div", { staticClass: "x_panel" }, [
              _c("div", { staticClass: "row x_title" }, [
                _vm._m(6),
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pull-right",
                      staticStyle: { background: "#fff", float: "right" },
                      attrs: { id: "reportrange" }
                    },
                    [
                      _c("date-range-picker", {
                        attrs: {
                          startDate: _vm.startDateByRole,
                          endDate: _vm.endDateByRole,
                          minDate: _vm.minStartDate,
                          maxDate: _vm.maxEndDate,
                          "locale-data": _vm.localeByRole,
                          opens: "left"
                        },
                        on: { update: _vm.showDemoByRole },
                        scopedSlots: _vm._u([
                          {
                            key: "input",
                            fn: function(picker) {
                              return _c("div", {}, [
                                _c("i", {
                                  staticClass:
                                    "glyphicon glyphicon-calendar fa fa-calendar"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(picker.startDate)
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("dateFormat")(picker.endDate)
                                    ) +
                                    " "
                                )
                              ])
                            }
                          }
                        ]),
                        model: {
                          value: _vm.dateRangeByRole,
                          callback: function($$v) {
                            _vm.dateRangeByRole = $$v
                          },
                          expression: "dateRangeByRole"
                        }
                      }),
                      _c("b", { staticClass: "caret" })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "demo-placeholder",
                    staticStyle: { padding: "0px", position: "relative" },
                    attrs: { id: "chart_plot_01" }
                  },
                  [
                    _vm.demo_by_role_label.length > 0
                      ? _c("DemoByDepartment", {
                          attrs: {
                            "chart-data": _vm.createRoleChartData(),
                            options: _vm.createRoleChartOption(),
                            styles: {
                              height: "410px !important",
                              position: "relative"
                            }
                          }
                        })
                      : _vm._e(),
                    _c("loading", {
                      attrs: {
                        active:
                          _vm.demo_by_role_label.length > 0 ? false : true,
                        "is-full-page": _vm.fullPage,
                        loader: _vm.loader,
                        color: _vm.color
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-4 col-sm-4",
            staticStyle: { display: "flex" }
          },
          [
            _c("div", { staticClass: "x_panel" }, [
              _c("div", { staticClass: "row x_title" }, [
                _vm._m(7),
                _c(
                  "div",
                  { staticClass: "col-md-7", staticStyle: { padding: "0px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pull-right",
                        staticStyle: { background: "#fff", float: "right" },
                        attrs: { id: "reportrange" }
                      },
                      [
                        _c(
                          "b-dropdown",
                          {
                            staticClass: "m-2 cost-dropdown",
                            attrs: {
                              id: "dropdown-right",
                              right: "",
                              variant: "outline-danger",
                              "split-variant": "outline-danger",
                              offset: "25",
                              text: _vm.costQuarterDropDown.selectedOption
                            },
                            model: {
                              value: _vm.costQuarterDropDown.selectedOption,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.costQuarterDropDown,
                                  "selectedOption",
                                  $$v
                                )
                              },
                              expression: "costQuarterDropDown.selectedOption"
                            }
                          },
                          _vm._l(_vm.costQuarterDropDown.options, function(
                            option
                          ) {
                            return _c(
                              "b-dropdown-item",
                              {
                                key: option.value,
                                attrs: { value: option.value },
                                on: {
                                  click: function($event) {
                                    return _vm.changeCostQuarter(option.value)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(option.text) + " ")]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "demo-placeholder",
                    staticStyle: { padding: "0px", position: "relative" },
                    attrs: { id: "chart_plot_01" }
                  },
                  [
                    _vm.costQuarterRecord
                      ? _c("CostPieChart", {
                          attrs: {
                            "chart-labels": Object.keys(_vm.costQuarter),
                            "chart-data": Object.values(_vm.costQuarter),
                            options: _vm.createCostQuarterOption(),
                            styles: {
                              height: "410px !important",
                              position: "relative"
                            }
                          }
                        })
                      : _vm._e(),
                    _c("loading", {
                      attrs: {
                        active: !_vm.costQuarterRecord,
                        "is-full-page": _vm.fullPage,
                        loader: _vm.loader,
                        color: _vm.color
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "row chart-row" }, [
        _c("div", { staticClass: "col-md-12 col-sm-12" }, [
          _c("div", { staticClass: "x_panel" }, [
            _vm._m(8),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _c("MapChart", {
                    attrs: {
                      countryData: _vm.countryData,
                      highColor: "rgba(232, 78, 2, .75)",
                      lowColor: "rgba(249, 210, 191, .75)",
                      countryStrokeColor: "#909090",
                      defaultCountryFillColor: "#dadada",
                      legendFontColorHeader: "rgba(238, 113, 55, .75)",
                      legendBorderCss: "1px solid #fff",
                      legendBoxShadowCss: "0px 0px 10px #fff",
                      showEmptyValue: false,
                      currencyAdd: false,
                      LangUser: "en"
                    }
                  }),
                  _c("loading", {
                    attrs: {
                      active: _vm.scenarioList.length > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row chart-row" }, [
        _c("div", { staticClass: "col-md-6 col-sm-6" }, [
          _c("div", { staticClass: "x_panel" }, [
            _vm._m(9),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _vm.scenarioListDataset.data.length > 0
                    ? _c("ScenarioPieChat", {
                        attrs: {
                          "chart-labels": _vm.scenarioList,
                          "chart-data": _vm.scenarioListDataset,
                          options: _vm.createScenarioPieOption(),
                          styles: {
                            height: "410px !important",
                            position: "relative"
                          }
                        }
                      })
                    : _vm._e(),
                  _c("loading", {
                    attrs: {
                      active: _vm.scenarioList.length > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col-md-6 col-sm-6" }, [
          _c("div", { staticClass: "x_panel" }, [
            _vm._m(10),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _vm.pillars["SD-WAN"] > 0
                    ? _c("PillarsPieChart", {
                        attrs: {
                          "chart-labels": Object.keys(_vm.pillars),
                          "chart-data": Object.values(_vm.pillars),
                          options: _vm.createPillarsOption(),
                          styles: {
                            height: "410px !important",
                            position: "relative"
                          }
                        }
                      })
                    : _vm._e(),
                  _c("loading", {
                    attrs: {
                      active: _vm.pillars["SD-WAN"] > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row chart-row" }, [
        _c("div", { staticClass: "col-md-4 col-sm-4" }, [
          _c("div", { staticClass: "x_panel" }, [
            _vm._m(11),
            _c("div", { staticClass: "col-md-12 col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "demo-placeholder",
                  staticStyle: { padding: "0px", position: "relative" },
                  attrs: { id: "chart_plot_01" }
                },
                [
                  _vm.userRolesChartData.labels.length > 0
                    ? _c("UsersRolePieChart", {
                        attrs: {
                          userRolesChartData: _vm.userRolesChartData,
                          styles: {
                            height: "410px !important",
                            position: "relative"
                          }
                        }
                      })
                    : _vm._e(),
                  _c("loading", {
                    attrs: {
                      active:
                        _vm.userRolesChartData.labels.length > 0 ? false : true,
                      "is-full-page": _vm.fullPage,
                      loader: _vm.loader,
                      color: _vm.color
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "col-md-8 col-sm-8",
            staticStyle: { display: "flex" }
          },
          [
            _c("div", { staticClass: "x_panel" }, [
              _vm._m(12),
              _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "demo-placeholder",
                    staticStyle: { padding: "0px", position: "relative" },
                    attrs: { id: "chart_plot_01" }
                  },
                  [
                    _vm.lastSixMonth_for_demo.length > 0
                      ? _c("DemoBarChart", {
                          attrs: {
                            "chart-labels": _vm.lastSixMonth_for_demo,
                            "chart-data": _vm.lastSixMonthScenarios,
                            options: _vm.createDemoBarOption(),
                            styles: {
                              height: "410px !important",
                              position: "relative"
                            }
                          }
                        })
                      : _vm._e(),
                    _c("loading", {
                      attrs: {
                        active: _vm.lastSixMonth.length > 0 ? false : true,
                        "is-full-page": _vm.fullPage,
                        loader: _vm.loader,
                        color: _vm.color
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "row chart-row" }, [
      _c("div", { staticClass: "col-md-6 col-sm-6" }, [
        _c("div", { staticClass: "x_panel" }, [
          _c("div", { staticClass: "row x_title" }, [
            _vm._m(13),
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "div",
                {
                  staticClass: "pull-right",
                  staticStyle: { background: "#fff", float: "right" },
                  attrs: { id: "reportrange" }
                },
                [
                  _c("date-range-picker", {
                    attrs: {
                      startDate: _vm.startDateByAzure,
                      endDate: _vm.endDateByAzure,
                      "locale-data": _vm.localeByAzure,
                      opens: "left"
                    },
                    on: { update: _vm.showAZPrice },
                    scopedSlots: _vm._u([
                      {
                        key: "input",
                        fn: function(picker) {
                          return _c("div", {}, [
                            _c("i", {
                              staticClass:
                                "glyphicon glyphicon-calendar fa fa-calendar"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("dateFormat")(picker.startDate)) +
                                " - " +
                                _vm._s(_vm._f("dateFormat")(picker.endDate)) +
                                " "
                            )
                          ])
                        }
                      }
                    ]),
                    model: {
                      value: _vm.dateRangeByAzure,
                      callback: function($$v) {
                        _vm.dateRangeByAzure = $$v
                      },
                      expression: "dateRangeByAzure"
                    }
                  }),
                  _c("b", { staticClass: "caret" })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-md-12 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "demo-placeholder",
                staticStyle: { padding: "0px", position: "relative" },
                attrs: { id: "chart_plot_01" }
              },
              [
                _vm.azureCostData[0].labels.length > 0
                  ? _c("AzureCostAreaChart", {
                      attrs: {
                        "chart-data": _vm.createAzureCostChartData(),
                        styles: {
                          height: "410px !important",
                          position: "relative"
                        }
                      }
                    })
                  : _vm._e(),
                _c("loading", {
                  attrs: {
                    active:
                      _vm.azureCostData[0].labels.length > 0 ? false : true,
                    "is-full-page": _vm.fullPage,
                    loader: _vm.loader,
                    color: _vm.color
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-6 col-sm-6" }, [
        _c("div", { staticClass: "x_panel" }, [
          _c("div", { staticClass: "row x_title" }, [
            _vm._m(14),
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "div",
                {
                  staticClass: "pull-right",
                  staticStyle: { background: "#fff", float: "right" },
                  attrs: { id: "reportrange" }
                },
                [
                  _c("date-range-picker", {
                    attrs: {
                      startDate: _vm.startDateByAWS,
                      endDate: _vm.endDateByAWS,
                      "locale-data": _vm.localeByAWS,
                      opens: "left"
                    },
                    on: { update: _vm.showAWSPrice },
                    scopedSlots: _vm._u([
                      {
                        key: "input",
                        fn: function(picker) {
                          return _c("div", {}, [
                            _c("i", {
                              staticClass:
                                "glyphicon glyphicon-calendar fa fa-calendar"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("dateFormat")(picker.startDate)) +
                                " - " +
                                _vm._s(_vm._f("dateFormat")(picker.endDate)) +
                                " "
                            )
                          ])
                        }
                      }
                    ]),
                    model: {
                      value: _vm.dateRangeByAWS,
                      callback: function($$v) {
                        _vm.dateRangeByAWS = $$v
                      },
                      expression: "dateRangeByAWS"
                    }
                  }),
                  _c("b", { staticClass: "caret" })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-md-12 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "demo-placeholder",
                staticStyle: { padding: "0px", position: "relative" },
                attrs: { id: "chart_plot_01" }
              },
              [
                _vm.awsCostData.labels.length > 0
                  ? _c("AWSCostBarChart", {
                      attrs: {
                        "chart-labels": _vm.awsCostData.labels,
                        "chart-data": _vm.awsCostData.data,
                        styles: {
                          height: "410px !important",
                          position: "relative"
                        }
                      }
                    })
                  : _vm._e(),
                _c("loading", {
                  attrs: {
                    active: _vm.awsCostData.labels.length > 0 ? false : true,
                    "is-full-page": _vm.fullPage,
                    loader: _vm.loader,
                    color: _vm.color
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "count_top" }, [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v(" Active User")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        { staticClass: "col-md-4", staticStyle: { "font-size": "1.35rem" } },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("User Activity "),
            _c("small")
          ])
        ]
      ),
      _c("div", { staticClass: "col-md-8" }, [
        _c(
          "div",
          {
            staticClass: "pull-right",
            staticStyle: { background: "#fff", float: "right" },
            attrs: { id: "reportrange" }
          },
          [_c("b", { staticClass: "caret" })]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("Popular Demo "),
            _c("small")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" Community User Activity"),
            _c("small", {
              staticStyle: {
                padding: "5px",
                "font-size": "13px",
                color: "#969090"
              }
            })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("Least Launched Demo "),
            _c("small")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-5",
        staticStyle: { "font-size": "1.35rem", "padding-right": "0px" }
      },
      [
        _c("h3", { staticClass: "graph-name" }, [
          _vm._v(" Demo "),
          _c("small", { staticStyle: { "font-size": "70%" } }, [
            _vm._v("SE and Partners only")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-4", staticStyle: { "font-size": "1.35rem" } },
      [
        _c("h3", { staticClass: "graph-name" }, [
          _vm._v("Demo by Role "),
          _c("small")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-5",
        staticStyle: { "font-size": "1.35rem", "padding-right": "0px" }
      },
      [_c("h3", { staticClass: "graph-name" }, [_vm._v("Azure & AWS Cost")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v(" GEO Demo Activity"),
            _c(
              "small",
              {
                staticStyle: {
                  padding: "5px",
                  "font-size": "13px",
                  color: "#969090"
                }
              },
              [_vm._v(" Records from July 16, 21")]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("Demo Activity "),
            _c("small")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "1.35rem",
            "margin-left": "20px !important"
          }
        },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("Activity By Pillar "),
            _c("small")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        { staticClass: "col-md-8", staticStyle: { "font-size": "1.35rem" } },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("Users By Role"),
            _c("small")
          ])
        ]
      ),
      _c("div", { staticClass: "col-md-4" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row x_title" }, [
      _c(
        "div",
        { staticClass: "col-md-10", staticStyle: { "font-size": "1.35rem" } },
        [
          _c("h3", { staticClass: "graph-name" }, [
            _vm._v("Demo By Month"),
            _c("small")
          ])
        ]
      ),
      _c("div", { staticClass: "col-md-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-4", staticStyle: { "font-size": "1.35rem" } },
      [
        _c("h3", { staticClass: "graph-name" }, [
          _vm._v("Azure Cost "),
          _c("small")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-4", staticStyle: { "font-size": "1.35rem" } },
      [
        _c("h3", { staticClass: "graph-name" }, [
          _vm._v("AWS Cost "),
          _c("small")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }