<template>
  <div class="top-nav-bar">
    <div class="top-nav">
      <div class="container-custom">
        <ul class="top-link">
          <li>
            <a
              href="https://www.riverbed.com/"
              target="_blank"
              >Riverbed.com</a
            >
          </li>
          <li>
            <a
              href="https://www.riverbed.com/document-repository.html#!filter-page=1"
              target="_blank"
              >Resources</a
            >
          </li>
          <li>
            <a
              href="https://www.riverbed.com/about/events.html#!filter-page=1"
              target="_blank"
              >Events</a
            >
          </li>
          <li>
            <a
              href="https://education.riverbed.com/"
              target="_blank"
              >Education</a
            >
          </li>
          <li>
            <a
              href="https://community.riverbed.com/"
              target="_blank"
              >Community</a
            >
          </li>
          <li>
            <a
              href="https://www.riverbed.com/about/contact-us.html"
              target="_blank"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light nav-custom">
      <img class="nav-brand" src="@/assets/logo_riverbed_orange.svg" />
      <p class="custom-nav-link">SteelDemo Manger</p>
      <ul class="nav-item-custom clearfix">
        <li class="right-nav-items">
          <a
            class="api-nav menu-icon"
            href="https://support.riverbed.com/apis/index.html"
            target="_blank"
          >
            <span style="left: 8px">API</span></a
          >
          <a
            class="github-nav menu-icon"
            href="https://github.com/riverbed"
            target="_blank"
          >
            <span>Github</span> </a
          ><a
            class="gns-nav menu-icon"
            href="https://gns3.com/marketplace/appliances/riverbed-steelhead"
            target="_blank"
          >
            <span style="left: 4px">GNS3</span></a
          >
          <a
            class="oracle-nav menu-icon"
            href="https://cloudmarketplace.oracle.com/marketplace/en_US/listing/53564858"
            target="_blank"
          >
            <span>Oracle</span></a
          >
          <a
            class="aws-nav menu-icon"
            href="https://aws.amazon.com/marketplace/seller-profile?id=33ecb59b-d3be-4f77-a03e-1e51c0f9a4ad&ref=dtl_B06Y8QN6YM"
            target="_blank"
          >
            <span style="left: 5px">AWS</span></a
          >
          <a
            class="azure-nav menu-icon"
            href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps?search=riverbed"
            target="_blank"
          >
            <span>Azure</span></a
          >
          <a
            class="podcast-nav menu-icon"
            href="https://networkcollective.com/category/riverbed/"
            target="_blank"
          >
            <span style="left: -4px">PODCAST</span></a
          >
        </li>
      </ul>
      <ul class="btn-wrap">
        <li>
          <a
            class="primary-btn-orange free-trial"
            href="https://www.riverbed.com/in/trialdownloads.html"
            target="_blank"
            role="button"
            >free trials</a
          >
        </li>
      </ul>
      <ul>
        <li>
          <div class="notication-action">
            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none notification-bell"
              no-caret
              right
              menu-class="notication-action-dropdown"
              @show="notificationClicked()"
            >
              <template #button-content>
                <span
                  class="material-icons"
                  title="Notifications"
                  style="color: gray"
                  v-if="clicked_notifications || updates_list.length <= 0"
                >
                  notifications
                </span>
                <span class="material-icons" title="Notifications" v-else>
                  notifications_active
                </span>
              </template>
              <div v-if="!updates_list.length">
                <b-dropdown-text>No Notifications</b-dropdown-text>
              </div>
              <div v-else>
                <div v-for="(list, index) in updates_list" :key="index">
                  <div v-for="(value, name) in list" :key="name">
                    <b-card
                      class="notication-card notication-card-new"
                      body-class="notication-card-body"
                      v-if="name !== 'date' && name !== 'uuid'"
                    >
                      <b-card-title style="font-size: 18px; font-weight: 400">{{
                        name
                      }}</b-card-title>

                      <b-card-sub-title
                        class="mb-2"
                        style="
                          font-size: 10px;
                          color: #6c757dad !important;
                          margin-top: -7px;
                        "
                        >{{ getDate(list["date"]) }}</b-card-sub-title
                      >
                      <b-list-group flush>
                        <b-list-group-item
                          style="padding: 2px 2px 2px 0px"
                          v-for="(item, index_item) in value"
                          :key="index_item"
                          >{{ item }}</b-list-group-item
                        >
                      </b-list-group>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-dropdown>
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="logout-action">
            <img
              src="https://community.riverbed.com/resource/1608347446000/RiverBedCommunity/images/user-login-icon.svg"
            />
            <span @click="handleLogout()">Logout</span>
          </div>
        </li>
      </ul>
    </nav>
    <div class="navbar navbar-expand-lg navbar-light bg-blue"  :style="divscrolltop">
      <p style="display: inline-block;
    overflow-wrap: break-word; margin: 0px;">Attention:  Our Weekly General Maintenance Window is scheduled every Friday between 11:00 pm PDT to 7am PDT. During this time the system may be unstable.</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { msalMixin } from 'vue-msal'
import moment from 'moment'
import Vue from 'vue'

export default {
  name: 'Navbar',
  mixins: [msalMixin],
  data () {
    return {
      showMenu: false,
      showDropDown: false,
      nickname: this.$msal.data.user.name,
      updates_list: [],
      clicked_notifications: false,
      notificationIds: [],
      divscrolltop: { position: 'inherit' }
    }
  },
  computed: {
    ...mapState(['firestoreDB'])
  },
  methods: {
    ...mapMutations(['UPDATE_USER']),
    scrollPage () {
      if (window.scrollY >= 80) {
        this.divscrolltop = { 'margin-top': '0px !important', position: 'fixed' }
      } else {
        this.divscrolltop = { position: 'inherit' }
      }
    },
    getDate (date) {
      const dt = moment.unix(date.seconds).format('dddd, MMMM Do, YYYY')
      return dt
    },
    toggleShow: function () {
      this.showMenu = !this.showMenu
    },
    handleLogout () {
      this.$msal.signOut({
        returnTo: window.location.origin
      })
    },

    updates () {
      try {
        const refUpdates = this.firestoreDB.collection('updates')
        const dt = new Date()
        dt.setMonth(dt.getMonth() - 1)
        const queryUpdates = refUpdates.orderBy('date').startAfter(dt)
        queryUpdates.onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              this.updates_list.push(change.doc.data())
            }
            if (change.type === 'modified') {
              const index = this.updates_list.findIndex(
                (e) => e.updateId === change.doc.data().updateId
              )
              if (index === -1) {
                this.updates_list.push(change.doc.data())
              } else {
                Vue.set(this.updates_list, index, change.doc.data())
              }
            }
            if (change.type === 'removed') {
              this.updates_list.pop(change.doc.data())
            }
          })

          this.notificationIds = this.updates_list.map((obj) => {
            return obj.uuid
          })
          const localStorageNotificationIds = JSON.parse(
            localStorage.getItem('notificationIds')
          )
          if (
            localStorageNotificationIds &&
            this.equalsIgnoreOrder(
              localStorageNotificationIds,
              this.notificationIds
            )
          ) {
            this.clicked_notifications = true
          } else {
            this.clicked_notifications = false
          }
        })
      } catch (error) {
      }
    },
    notificationClicked () {
      this.clicked_notifications = true
      localStorage.setItem(
        'notificationIds',
        JSON.stringify(this.notificationIds)
      )
    },
    equalsIgnoreOrder (a, b) {
      if (a.length !== b.length) return false
      const uniqueValues = new Set([...a, ...b])
      for (const v of uniqueValues) {
        const aCount = a.filter((e) => e === v).length
        const bCount = b.filter((e) => e === v).length
        if (aCount !== bCount) return false
      }
      return true
    }
  },
  created () {
    this.UPDATE_USER(this.msal.user.name)
    this.updates()
  },
  mounted () {
    document.addEventListener('scroll', this.scrollPage)
  }
}
</script>
<style>
.logout-action {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 33px;
  cursor: pointer;
}

.logout-action img {
  width: 30px;
}

.logout-action span {
  margin-top: -22px;
  font-size: 13px;
  margin-left: -2px;
  text-decoration: none;
  color: #5b7e96;
}
.btn-wrap {
  float: right;
  display: flex;
  padding: 12px 0;
}
.primary-btn-orange {
  /* padding: 2px 12px 0 12px; */
  text-transform: uppercase;
  background: #ff681d;
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  vertical-align: top;
  min-height: 40px;
  line-height: 40px;
  display: inline-block;
  border: 1px solid #ff681d;
  cursor: pointer;
  /* padding: 2px 16px 0 16px; */
  border-radius: 50px;
  transform: perspective(1px) translateZ(0);
  margin: 10px 0px !important;
}
.menu-icon span {
  opacity: 0;
  font-size: 0.6rem;
  line-height: 15px;
  font-weight: 600;
  position: absolute;
  display: block;
  bottom: -16px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

a:hover span {
  opacity: 1;
  letter-spacing: 0px;
  color: #ff681d;
  text-transform: uppercase;
  font-size: 0.6rem;
  line-height: 15px;
  font-weight: 600;
}

.right-nav-items {
  float: right;
  margin-right: 10px !important;
  padding-top: 8px;
}

.right-nav-items > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  border-radius: 50px;
  margin-top: 45px !important;
}

.github-nav {
  background: url(../assets/github-logo-icon.svg) top no-repeat !important;
  background-size: 1.6rem !important;
  background-color: white !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.github-nav:hover {
  background: url(../assets/github-white-logo.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 1.6rem !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.api-nav {
  background: url(../assets/api.svg) top no-repeat !important;
  background-size: 1.6rem !important;
  background-color: white !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.api-nav:hover {
  background: url(../assets/api-white-logo.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 1.6rem !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.gns-nav {
  background: url(../assets/gns3-icon.svg) top no-repeat !important;
  background-size: 1.1rem !important;
  background-color: white !important;
  background-position-y: 7px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.gns-nav:hover {
  background: url(../assets/gns3-white-icon.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 1.1rem !important;
  background-position-y: 7px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.aws-nav {
  background: url(../assets/AWS-Marketplace.svg) top no-repeat !important;
  background-size: 1.6rem !important;
  background-color: white !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.aws-nav:hover {
  background: url(../assets/AWS-Marketplace-white.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 1.6rem !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.azure-nav {
  background: url(../assets/azure.svg) top no-repeat !important;
  background-size: 1.6rem !important;
  background-color: white !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.azure-nav:hover {
  background: url(../assets/azure-white.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 1.6rem !important;
  background-position-y: 4px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.podcast-nav {
  background: url(../assets/podcast-icon.svg) top no-repeat !important;
  background-size: 0.8rem !important;
  background-color: white !important;
  background-position-y: 6px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.podcast-nav:hover {
  background: url(../assets/podcast-icon-white.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 0.8rem !important;
  background-position-y: 6px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.oracle-nav {
  background: url(../assets/oracle-icon.svg) top no-repeat !important;
  background-size: 1.3rem !important;
  background-color: white !important;
  background-position-y: 9px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.oracle-nav:hover {
  background: url(../assets/oracle-icon-white.svg) top no-repeat !important;
  background-color: #ff681d !important;
  background-size: 1.3rem !important;
  background-position-y: 9px !important;
  height: 32px !important;
  width: 32px !important;
  transition: all 0.5s ease-out;
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.nav-item-custom {
  float: left;
  margin-left: auto;
  position: relative;
}

a.menu-icon {
  padding: 0px !important;
  margin: 24px 7px 14px !important;
}

.nav-item-custom > li {
  display: inline-block;
}

.top-nav {
  background-color: #ecf2f6;
  width: 100%;
  padding-right: 35px;
}
.custom-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.top-link {
  text-align: right;
}
.top-link > li {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
}
.top-link > li a {
  margin: 15px 7px 14px;
  display: block;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #415363;
  text-decoration: none;
  background-color: transparent;
}
.nav-custom {
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
  height: 58px;
  margin-top: -14px;
}

.top-link > li a:hover {
  color: #415363;
  text-decoration: none;
}

.nav-brand {
  margin-top: 0px !important;
  margin-left: 8px;
  height: 20.6562px;
  width: auto;
}

.custom-container {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-nav-link {
  position: relative;
  display: block;
  padding: 25px 19px 25px;
  letter-spacing: 0.4px;
  font-weight: 600;
  /* transition: all 0.3s ease-in-out; */
  margin-top: 20.5px;
  color: #95a5a6;
  margin-left: -12px;
  font-size: 20px;
  font-weight: 340;
}

.right-side {
  margin-left: auto;
  margin-right: 20px;
}

.dropdown-item {
  padding-right: 20px !important;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: #ffffff !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: white;
}

.user-login {
  display: flex !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: -20px !important;
}
.username {
  color: black;
  margin-top: 13px;
  margin-left: 10px;
}

.navbar-mobile {
  display: none;
}

.notication-action {
  /* margin-left: 4px; */
  margin-top: -11px;
  cursor: pointer;
}

.notification-bell {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.notication-action-dropdown {
  width: 30rem !important;
}

.notication-card {
  line-height: 1.2rem;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}

.notication-card-new {
  box-shadow: 0px 3px 6px 0px rgba(255, 104, 29, 0.8588235294117647) !important;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(255, 104, 29, 0.8588235294117647) !important;
}

.notication-card-body {
  padding: 0.7rem !important;
}
.free-trial:hover{
  background-color: #f8f9fa !important;
  color: #ff681d !important;
  text-decoration: none;
}

.bg-blue{
  min-height: 42px;
  background-color: #5fb4e4;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 5px 100px;
  font-size: 20px;
  font-family: "source-sans-pro",Arial,Helvetica,sans-serif;
  color: #ffffff!important;
  line-height: 1.5;
  font-weight: 500!important;
  display: block;
  top: 0px;
  z-index: 100;
}
@media screen and (max-width: 1000px) {
  .navbar {
    display: none !important;
  }

  .navbar-mobile {
    display: block !important;
  }

  .bg-blue{
    display: block !important;
    margin-top: -14px !important;

  }
}
</style>
