<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['chartLabels', 'chartData', 'options'],
  mounted () {
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: [
          {
            backgroundColor: this.chartData.backgroundColor,
            data: this.chartData.data
          }
        ]
      }, this.options
    )
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart({
          labels: this.chartLabels,
          datasets: [
            {
              backgroundColor: newOption.backgroundColor,
              data: newOption.data
            }
          ]
        }, this.options)
      },
      deep: true
    }
  }
}
</script>
