var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "callback" }, [
    _vm.displayError
      ? _c("div", { staticClass: "validEmail" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/logo-public.svg") }
          }),
          _c("div", { staticClass: "appName" }, [_vm._v("SteelDemo")]),
          _c("div", { staticClass: "message" }, [
            _vm._v(_vm._s(_vm.errorDescription))
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }