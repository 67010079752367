<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['chartLabels', 'chartData', 'options'],
  data () {
    return {
      gradient: null,
      colorList: []
    }
  },
  methods: {
    getGradient (color) {
      this.gradient = this.$refs.canvas
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 450)
      this.gradient.addColorStop(0, `rgba(${color}, 0.5)`)
      this.gradient.addColorStop(0.5, `rgba(${color}, 0.25)`)
      this.gradient.addColorStop(1, `rgba(${color}, 0)`)
      return this.gradient
    }
  },
  mounted () {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
    const totalLabels = this.chartLabels.map((data, index) => {
      return data + ' (' +
        formatter.format(this.chartData[index]) +
        ')'
    })
    this.colorList = [this.getGradient('255, 104, 29'), this.getGradient('0, 231, 255')]

    this.renderChart(
      {
        labels: totalLabels,
        datasets: [
          {
            backgroundColor: this.colorList,
            pointBackgroundColor: this.colorList,
            borderWidth: 3,
            pointBorderColor: this.colorList,
            data: this.chartData
          }
        ]
      }, this.options
    )
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart(
          {
            labels: this.chartLabels,
            datasets: [
              {
                backgroundColor: this.colorList,
                pointBackgroundColor: this.colorList,
                borderWidth: 3,
                pointBorderColor: this.colorList,
                data: newOption
              }
            ]
          }, this.options
        )
      },
      deep: true
    }
  }
}
</script>
