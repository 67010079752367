<template>
  <div class="dashboard">
    <div class="summary" v-bind:style="{ opacity: opacityDashboard }">
      <div
        class="dashboard_box"
        v-for="info in info_data"
        :key="info.id"
        v-on:click="UPDATE_FOCUS(info.name)"
      >
        <div :class="info.name">
          <i class="material-icons icon">{{ info.icon }}</i>
        </div>
        <div class="info">
          <p class="item">{{ info.label }}</p>
          <p class="value">{{ info.value }}</p>
        </div>
      </div>
    </div>
    <div class="table" v-bind:style="{ opacity: opacityDashboard }">
      <Tables
        :headers="headerFields_planned"
        :data="planned_list"
        :type="focus"
        :scenarios="scenarioNames"
        v-if="focus === 'planned'"
      />
      <!-- <Tables
        :headers="headerFields_snapshot"
        :data="snapshot_list"
        :type="focus"
        :scenarios="scenarioNames"
        v-if="focus === 'snapshot'"
      /> -->
      <Tables
        :headers="headerFields_running"
        :data="running_list"
        :type="focus"
        :scenarios="scenarioNames"
        v-if="focus === 'running'"
      />
      <Tables
        :headers="headerFields_demos"
        :data="demos_list"
        :type="focus"
        :scenarios="scenarioNames"
        v-if="focus === 'demos' || focus === 'cost'"
      />
    </div>
    <!-- <CreateSnapshot :instance="instance" v-if="createSnapshot" /> -->
    <!-- <StartSnapshot :snapshot="snapshotId" v-if="startSnapshot" />
    <DeleteSnapshot :snapshot="snapshotId" v-if="deleteSnapshot" /> -->
    <TerminateInstance :instance="instance" v-if="terminateInstance" />
    <InstanceIP v-if="ipAddress" />
    <Updates :data="updates_list" v-if="steeldemo_updates" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import Vue from 'vue'
import Tables from '@/components/Tables'
import InstanceIP from '@/components/InstanceIP'
// import CreateSnapshot from '@/components/CreateSnapshot'
// import StartSnapshot from "@/components/StartSnapshot";
// import DeleteSnapshot from "@/components/DeleteSnapshot";
import TerminateInstance from '@/components/TerminateInstance'
import Updates from '@/components/Updates'

export default {
  name: 'dashboard',
  components: {
    Tables,
    // CreateSnapshot,
    // StartSnapshot,
    // DeleteSnapshot,
    TerminateInstance,
    InstanceIP,
    Updates
  },
  data () {
    return {
      focus: 'running',
      cost: 0,
      opacityDashboard: 1,
      planned_list: [],
      running_list: [],
      demos_list: [],
      snapshot_list: [],
      updates_list: [],
      info_data: [
        {
          id: 0,
          name: 'running',
          icon: 'cached',
          label: 'Running Instances',
          value: ''
        },
        // {
        //   id: 1,
        //   name: "snapshot",
        //   icon: "photo_camera",
        //   label: "Snapshots",
        //   value: "",
        // },
        {
          id: 1,
          name: 'planned',
          icon: 'alarm',
          label: 'Planned Instances',
          value: ''
        },
        {
          id: 2,
          name: 'demos',
          icon: 'group',
          label: 'Demos to date',
          value: ''
        }
      ],

      headerFields_planned: [
        'Category',
        'Scenario',
        'Starting Date',
        'Duration',
        'Status'
      ],
      headerFields_running: [
        'Category',
        'Scenario',
        'Starting Date',
        'Duration',
        'Status',
        'Username',
        'Password',
        'Subnet'
      ],
      headerFields_demos: [
        'Category',
        'Scenario',
        'Starting Date',
        'Duration',
        'Cost'
      ],
      headerFields_snapshot: ['Category', 'Name', 'Date', 'Description'],
      scenarioNames: []
    }
  },
  computed: {
    ...mapGetters(['GET_USER', 'mailUserName']),
    ...mapState([
      'firestoreDB',
      'category',
      'instance',
      'snapshotId',
      'deleteSnapshot',
      'startSnapshot',
      'terminateInstance',
      'ipAddress',
      'lastLogin',
      'steeldemo_updates'
    ])
  },
  methods: {
    ...mapMutations(['UPDATE_SNAPSHOTNBR', 'UPDATE_FOCUS', 'UPDATE_STEELDEMO'])
  },
  created () {
    // fetch data from the firestore
    // we retrieve the firestoreDB object from the store
    // let's pull data from the collection "instances"
    // and track all changes with 'onSnapShot'

    try {
      const ref = this.firestoreDB.collection('instances')
      const query = ref.where('username', '==', this.mailUserName)
      query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const instance = change.doc.data()

          if (change.type === 'added') {
            if (instance.status === 'planned') {
              this.planned_list.push(instance)
            }
            if (
              instance.status === 'terminated' ||
              instance.status === 'canceled'
            ) {
              this.demos_list.push(instance)
              const cost = Math.round(instance.cost)
              if (cost > 0) this.cost = this.cost + cost
            }
          }
          if (change.type === 'modified') {
            const index = this.planned_list.findIndex(
              (e) => e.demoId === instance.demoId
            )
            if (index === -1) {
              this.planned_list.push(instance)
            } else {
              Vue.set(this.planned_list, index, instance)
            }
          }
          if (change.type === 'removed') {
            this.planned_list.pop(change.doc.data())
          }
        })

        this.$store.planned_demos = this.planned_list

        Vue.set(this.info_data, 1, {
          id: 1,
          name: 'planned',
          icon: 'alarm',
          label: 'Planned Instances',
          value: this.planned_list.length
        })
        Vue.set(this.info_data, 2, {
          id: 2,
          name: 'demos',
          icon: 'group',
          label: 'Demos to date',
          value: this.demos_list.length
        })
      })
    } catch (error) {
    //   console.log('error in dashboard Instances ' + error)
      // this.$router.push({ name: 'callback'})
    }
    try {
      const refDemos = this.firestoreDB.collection('demos')
      const queryDemos = refDemos.where('username', '==', this.mailUserName)
      queryDemos.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.running_list.push(change.doc.data())
          }
          if (change.type === 'modified') {
            const index = this.running_list.findIndex(
              (e) => e.demoId === change.doc.data().demoId
            )
            if (index === -1) {
              this.running_list.push(change.doc.data())
            } else {
              Vue.set(this.running_list, index, change.doc.data())
            }
          }
          if (change.type === 'removed') {
            this.running_list.pop(change.doc.data())
          }
        })

        this.$store.running_demos = this.running_list
        Vue.set(this.info_data, 0, {
          id: 0,
          name: 'running',
          icon: 'cached',
          label: 'Running Instances',
          value: this.running_list.length
        })
      })
    } catch (error) {
    }

    try {
      this.$store.scenarios.map((obj) => {
        const scenarioEntry = obj
        const scenario = {}
        scenario.id = scenarioEntry.scenarioId
        scenario.name = scenarioEntry.name
        scenario.snapshot = scenarioEntry.snapshot
        if (!this.scenarioNames[scenarioEntry.scenarioId]) { this.scenarioNames.push(scenario) }
        return true
      })

      this.$store.scenariosLab.map((obj) => {
        const scenarioEntry = obj
        const scenario = {}
        scenario.id = scenarioEntry.scenarioId
        scenario.name = scenarioEntry.name
        scenario.snapshot = scenarioEntry.snapshot
        if (!this.scenarioNames[scenarioEntry.scenarioId]) { this.scenarioNames.push(scenario) }
        return true
      })

      Vue.set(this.info_data, 0, {
        id: 0,
        name: 'running',
        icon: 'cached',
        label: 'Running Instances',
        value: this.running_list.length
      })
    } catch (error) {
      // this.$router.push({ name: 'callback'})
    }
    // let's retrieve now the updates from SteelDemo
  },
  mounted () {
    // tracking that category parameter state change in the store
    this.$store.watch(
      (state, getters) => getters.category,
      (newValue, oldValue) => {
        if (newValue.length > 0) this.opacityDashboard = 0.1
        else this.opacityDashboard = 1
      }
    )
    // tracking that focus parameter state change in the store
    this.$store.watch(
      (state, getters) => getters.focus,
      (newValue, oldValue) => {
        this.focus = newValue
      }
    )
    // tracking that steeldemo_updates parameter state change in the store
    this.$store.watch(
      (state, getters) => getters.steeldemo_updates,
      (newValue, oldValue) => {
        this.steeldemo_updates = newValue
      }
    )
  }
}
</script>

<style>
.dashboard {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  z-index: 0;
  width: 100%;
  margin-top: 60px;
}

.dashboard_box {
  margin: 0 auto;
  padding-top: 0.1vw;
  top: 0vw;
  cursor: pointer;
  display: inline-table;
  justify-content: center;
  border-radius: 10px;
  border: 0.5px solid;
  border-color: lightgrey;
  width: 60px;
  height: 30px;
  margin-left: 1rem;
}

.dashboard .dashboard_box {
  background-color: white;
  overflow: visible;
  box-shadow: 5px 10px 8px #888888;
  display: inline-flex;
  flex-direction: row;
  padding-left: 0%;
  margin-top: 30px;
}

.dashboard .icon {
  position: relative;
  font-size: 25px;
  left: 0%;
  top: 10%;
}

.dashboard .running {
  color: #fbb022;
}
.dashboard .snapshot {
  color: #575757;
}

.dashboard .planned {
  color: #6bb5e4;
}
.dashboard .demos {
  color: #5b7f95;
}
.dashboard .cost {
  color: #ff6900;
}

.dashboard .dashboard_box .info {
  display: inline-flex;
  flex-direction: column;
  right: 0%;
  text-align: center;
  margin-left: 1vw;
}

.dashboard .dashboard_box .item {
  display: none;
}
.dashboard .dashboard_box .value {
  font-size: 10px;
  font-weight: bold;
  margin-top: 0;
  padding-top: 0;
}

.dashboard .table {
  position: fixed;
  margin-top: 15px;
  background-color: white;
  box-shadow: 5px 10px 8px #888888;
  width: 78%;
  text-align: center;
  table-layout: fixed;
  overflow: auto;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (min-width: 1000px) {
  .dashboard {
    margin-top: 0px !important;
  }

  .dashboard_box {
    margin: 0 auto !important;
    padding-top: 0.1vw !important;
    top: 0vw !important;
    cursor: pointer !important;
    display: inline-table !important;
    justify-content: center !important;
    border-radius: 10px !important;
    border: 0.5px solid !important;
    border-color: lightgrey !important;
    width: 25.5% !important;
    height: 100px !important;
    margin-left: 1rem !important;
  }

  .dashboard .dashboard_box {
    background-color: white !important;
    overflow: visible !important;
    box-shadow: 5px 10px 8px #888888 !important;
    display: inline-flex !important;
    flex-direction: row !important;
    padding-left: 0% !important;
    margin-top: 30px !important;
  }

  .dashboard .icon {
    position: relative !important;
    font-size: 60px !important;
    left: 0% !important;
    top: 10% !important;
  }

  .dashboard .running {
    color: #fbb022;
  }
  .dashboard .snapshot {
    color: #575757;
  }

  .dashboard .planned {
    color: #6bb5e4;
  }
  .dashboard .demos {
    color: #5b7f95;
  }
  .dashboard .cost {
    color: #ff6900;
  }

  .dashboard .dashboard_box .info {
    display: inline-flex;
    flex-direction: column;
    right: 0%;
    text-align: center;
    margin-left: 1vw;
  }

  .dashboard .dashboard_box .item {
    display: initial;
    margin-top: 0.3vw;
    margin-bottom: 0;
    font-size: 15px;
    padding-top: 0.1vw;
  }
  .dashboard .dashboard_box .value {
    font-size: 2vw;
    font-weight: bold;
    margin-top: 0;
    padding-top: 0;
  }

  .dashboard .table {
    position: fixed;
    margin-top: 1.5vw;
    background-color: white;
    box-shadow: 5px 10px 8px #888888;
    width: 78%;
    text-align: center;
    table-layout: fixed;
    overflow: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
