var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row m-0 p-0" }, [
    _c("div", { staticClass: "row chart-row parent-dashboard" }, [
      _c("div", { staticClass: "col-md-5", staticStyle: { padding: "0px" } }, [
        _c(
          "div",
          {
            staticClass: "col-md-12 col-sm-12",
            staticStyle: { display: "flex" }
          },
          [
            _c(
              "div",
              { staticClass: "x_panel", staticStyle: { "box-shadow": "none" } },
              [
                _c("div", { staticClass: "row x_title" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-4",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pull-right",
                          staticStyle: { background: "#fff", float: "right" },
                          attrs: { id: "reportrange" }
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "m-2 cost-dropdown",
                              attrs: {
                                id: "dropdown-right",
                                right: "",
                                variant: "outline-danger",
                                "split-variant": "outline-danger",
                                offset: "25",
                                text: _vm.viewersEngageDropDown.selectedOption
                              },
                              model: {
                                value: _vm.viewersEngageDropDown.selectedOption,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.viewersEngageDropDown,
                                    "selectedOption",
                                    $$v
                                  )
                                },
                                expression:
                                  "viewersEngageDropDown.selectedOption"
                              }
                            },
                            _vm._l(_vm.viewersEngageDropDown.options, function(
                              option
                            ) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: option.value,
                                  attrs: { value: option.value }
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "demo-placeholder",
                      staticStyle: {
                        padding: "0px",
                        position: "relative",
                        "max-height": "350px"
                      },
                      attrs: { id: "chart_plot_01" }
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            "font-size": "18px !important",
                            color: "#333"
                          }
                        },
                        [_vm._v(" SaaS and Security ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "count",
                          staticStyle: {
                            color: "#948c8c",
                            "text-transform": "uppercase",
                            "font-size": "12px",
                            display: "flex",
                            "flex-direction": "row"
                          },
                          on: { click: _vm.clickViewerVideo }
                        },
                        [
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              staticStyle: {
                                "margin-top": "-12px",
                                "margin-right": "15px"
                              },
                              attrs: { max: 100 }
                            },
                            [
                              _c("b-progress-bar", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  value: 84.4,
                                  label: ((84.4 / 100) * 100).toFixed(2) + "%"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: {
                                "font-size": "25px",
                                "margin-top": "-16px",
                                "margin-right": "4px"
                              }
                            },
                            [_vm._v("visibility")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "-12px" } },
                            [_vm._v("4")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            "font-size": "18px !important",
                            color: "#333"
                          }
                        },
                        [_vm._v(" Traffic conditioning ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "count",
                          staticStyle: {
                            color: "#948c8c",
                            "text-transform": "uppercase",
                            "font-size": "12px",
                            display: "flex",
                            "flex-direction": "row"
                          },
                          on: { click: _vm.clickViewerVideo }
                        },
                        [
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              staticStyle: {
                                "margin-top": "-12px",
                                "margin-right": "15px"
                              },
                              attrs: { max: 100 }
                            },
                            [
                              _c("b-progress-bar", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  value: 67.2,
                                  label: ((67.2 / 100) * 100).toFixed(2) + "%"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: {
                                "font-size": "25px",
                                "margin-top": "-16px",
                                "margin-right": "4px"
                              }
                            },
                            [_vm._v("visibility")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "-12px" } },
                            [_vm._v("3")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            "font-size": "18px !important",
                            color: "#333"
                          }
                        },
                        [_vm._v(" MS Stream ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "count",
                          staticStyle: {
                            color: "#948c8c",
                            "text-transform": "uppercase",
                            "font-size": "12px",
                            display: "flex",
                            "flex-direction": "row"
                          },
                          on: { click: _vm.clickViewerVideo }
                        },
                        [
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              staticStyle: {
                                "margin-top": "-12px",
                                "margin-right": "15px"
                              },
                              attrs: { max: 100 }
                            },
                            [
                              _c("b-progress-bar", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  value: 50,
                                  label: ((50 / 100) * 100).toFixed(2) + "%"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: {
                                "font-size": "25px",
                                "margin-top": "-16px",
                                "margin-right": "4px"
                              }
                            },
                            [_vm._v("visibility")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "-12px" } },
                            [_vm._v("2")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            "font-size": "18px !important",
                            color: "#333"
                          }
                        },
                        [_vm._v(" SaaS and Security 2 ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "count",
                          staticStyle: {
                            color: "#948c8c",
                            "text-transform": "uppercase",
                            "font-size": "12px",
                            display: "flex",
                            "flex-direction": "row"
                          },
                          on: { click: _vm.clickViewerVideo }
                        },
                        [
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              staticStyle: {
                                "margin-top": "-12px",
                                "margin-right": "15px"
                              },
                              attrs: { max: 100 }
                            },
                            [
                              _c("b-progress-bar", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  value: 30.8,
                                  label: ((30.8 / 100) * 100).toFixed(2) + "%"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: {
                                "font-size": "25px",
                                "margin-top": "-16px",
                                "margin-right": "4px"
                              }
                            },
                            [_vm._v("visibility")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "-12px" } },
                            [_vm._v("1")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "col-md-7",
          staticStyle: { padding: "0px", display: "flex" }
        },
        [
          _c(
            "div",
            {
              staticClass: "col-md-12 col-sm-12",
              staticStyle: { display: "flex" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "x_panel",
                  staticStyle: { "box-shadow": "none" }
                },
                [
                  _c("div", { staticClass: "row x_title" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "demo-placeholder",
                          staticStyle: {
                            padding: "0px",
                            position: "relative",
                            "max-height": "350px"
                          },
                          attrs: { id: "chart_plot_01" }
                        },
                        [
                          _c("ViewerEngagementBarChart", {
                            attrs: {
                              "chart-labels": _vm.viewerEngagementChartLabel,
                              "chart-data": _vm.viewerEngagementChartData,
                              options: _vm.createviewerEngagementChartOption(),
                              styles: {
                                height: "320px !important",
                                position: "relative"
                              }
                            }
                          }),
                          _c("loading", {
                            attrs: {
                              "is-full-page": _vm.fullPage,
                              loader: _vm.loader,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ]
      ),
      _c("div", { staticClass: "col-md-12", staticStyle: { padding: "0px" } }, [
        _c(
          "div",
          {
            staticClass: "col-md-12 col-sm-12",
            staticStyle: { display: "flex" }
          },
          [
            _c(
              "div",
              { staticClass: "x_panel", staticStyle: { "box-shadow": "none" } },
              [
                _c("div", { staticClass: "row x_title" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "demo-placeholder",
                        staticStyle: {
                          padding: "0px",
                          position: "relative",
                          "max-height": "350px"
                        },
                        attrs: { id: "chart_plot_01" }
                      },
                      [
                        _c("ViewerEngagementAreaChart", {
                          attrs: {
                            "chart-labels": _vm.viewerEngagementEventChartLabel,
                            "chart-data": _vm.viewerEngagementEventChartData,
                            options: _vm.createviewerEngagementEventChartOption(),
                            styles: {
                              height: "320px !important",
                              position: "relative"
                            }
                          }
                        }),
                        _c("loading", {
                          attrs: {
                            "is-full-page": _vm.fullPage,
                            loader: _vm.loader,
                            color: _vm.color
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-8",
        staticStyle: { "font-size": "1.35rem", "padding-right": "0px" }
      },
      [_c("h3", { staticClass: "graph-name" }, [_vm._v("Viewers Engagement")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "1.35rem",
          "margin-left": "20px !important",
          "text-transform": "uppercase"
        }
      },
      [
        _c("h3", { staticClass: "graph-name" }, [
          _vm._v("Viewers Acitivity"),
          _c("small")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "1.35rem",
          "margin-left": "20px !important",
          "text-transform": "uppercase"
        }
      },
      [
        _c("h3", { staticClass: "graph-name" }, [
          _vm._v("Viewers Events"),
          _c("small")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }