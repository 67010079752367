<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['chartLabels', 'chartData', 'options'],
  data () {
    return {
      gradient: null,
      colorList: []
    }
  },
  methods: {
    getGradient (rgbcolorArray) {
      const colorArray = []
      rgbcolorArray.forEach(element => {
        const color = element.split(/(?:\(|\))+/)[1]
        this.gradient = this.$refs.canvas
          .getContext('2d')
          .createLinearGradient(0, 0, 0, 450)
        this.gradient.addColorStop(0, `rgba(${color}, 0.75)`)
        this.gradient.addColorStop(0.5, `rgba(${color}, 0.25)`)
        this.gradient.addColorStop(1, `rgba(${color}, 0.15)`)
        colorArray.push(this.gradient)
      })

      return colorArray
    },
    getDataSet (data) {
      return data.map((value, index) => {
        const gradColor = this.getGradient(value.backgroundColor)
        const obj = {
          backgroundColor: gradColor,
          pointBackgroundColor: gradColor,
          borderWidth: 3,
          pointBorderColor: gradColor,
          data: value.data
        }
        return obj
      })
    }
  },
  mounted () {
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: this.getDataSet(this.chartData)
      }, this.options
    )
  },
  watch: {
    chartData: {
      handler (newOption, oldOption) {
        this.renderChart(
          {
            labels: this.chartLabels,
            datasets: this.getDataSet(this.chartData)
          }, this.options
        )
      },
      deep: true
    }
  }
}
</script>
