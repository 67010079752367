var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row m-0 p-0" }, [
      _c(
        "div",
        {
          staticClass: "content-layout-area",
          staticStyle: {
            "padding-top": "20px !important",
            "padding-right": "20px !important",
            "border-right": "1px solid #e3e3e3",
            "border-left": "1px solid #e3e3e3"
          }
        },
        [
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              "is-full-page": _vm.fullPage,
              loader: _vm.loader,
              color: _vm.color
            }
          }),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "padding-left": "20px" } },
            [
              !_vm.snapshots.length && !_vm.isLoading
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        color: "#ff681d",
                        "font-size": "30px",
                        padding: "20%"
                      }
                    },
                    [_vm._v(" No snapshot to show ")]
                  )
                : _vm._e(),
              _vm._l(_vm.snapshots, function(snapshot) {
                return _c(
                  "div",
                  {
                    key: snapshot.snapshotId,
                    staticClass: "col-custom",
                    staticStyle: { "padding-bottom": "20px" }
                  },
                  [
                    _c("div", { staticClass: "col-4-custom" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "0.7vw",
                            color: "rgba(128, 128, 128, 0.82)",
                            position: "absolute",
                            right: "24px",
                            top: "7px",
                            "font-weight": "500"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.showDate(snapshot["creation_timestamp"]))
                          )
                        ]
                      ),
                      _c("div", { staticClass: "content-wrap-custom" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(snapshot.category))
                        ]),
                        _c("h5", { staticClass: "custom-class-h5" }, [
                          _vm._v(_vm._s(snapshot.name))
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "custom-class-p",
                            staticStyle: {
                              "font-size": "13px",
                              "font-weight": "600",
                              "margin-bottom": "0.5rem",
                              bottom: "87px",
                              position: "absolute",
                              "padding-right": "30px",
                              "padding-bottom": "10px",
                              "overflow-wrap": "anywhere"
                            }
                          },
                          [_vm._v(" " + _vm._s(snapshot.description) + " ")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "status-custom justify-content-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "links snapshot-list-links" },
                            [
                              !snapshot.inProcess
                                ? _c(
                                    "p",
                                    { staticClass: "scenario-status-label" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-play-circle fas-custom",
                                        staticStyle: {
                                          color: "#6bb5e4",
                                          cursor: "pointer"
                                        },
                                        attrs: { title: "Start snapshot" },
                                        on: {
                                          click: function($event) {
                                            return _vm.startSnapshot(snapshot)
                                          }
                                        }
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "fas fa-trash-alt fas-custom",
                                        staticStyle: {
                                          color: "#575757",
                                          cursor: "pointer"
                                        },
                                        attrs: { title: "Delete snapshot" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSnapshot(
                                              snapshot["snapshotId"],
                                              snapshot["name"]
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "auto",
                                        "margin-right": "20px",
                                        "text-align": "center",
                                        "margin-top": "-3px"
                                      }
                                    },
                                    [
                                      snapshot["url"]
                                        ? _c("i", {
                                            staticClass:
                                              "fas fa-bolt fas-custom",
                                            staticStyle: {
                                              color: "#fbb022",
                                              cursor: "pointer"
                                            },
                                            attrs: { title: "Connect to demo" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openLink(
                                                  snapshot["url"]
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      snapshot["ip_address"]
                                        ? _c("i", {
                                            staticClass:
                                              "fas fa-bolt fas-custom",
                                            staticStyle: {
                                              color: "#fbb022",
                                              cursor: "pointer"
                                            },
                                            attrs: { title: "snapshot" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openAlert(
                                                  snapshot["ip_address"]
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      snapshot["wvd_url"]
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: snapshot["wvd_url"],
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-desktop fas-custom",
                                                staticStyle: {
                                                  color: "#fbb022",
                                                  cursor: "pointer"
                                                },
                                                attrs: { title: "Connect Demo" }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      snapshot.status === "User Created" &&
                                      snapshot.user &&
                                      snapshot.password
                                        ? _c("i", {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal.modal-2",
                                                modifiers: { "modal-2": true }
                                              }
                                            ],
                                            staticClass:
                                              "fas fa-user-secret fas-custom",
                                            staticStyle: {
                                              color: "#fbb022",
                                              cursor: "pointer"
                                            },
                                            attrs: { title: "Demo details" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openUserCred(
                                                  snapshot["user"],
                                                  snapshot["password"],
                                                  snapshot["subnet"] || ""
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      snapshot["demoId"]
                                        ? _c("i", {
                                            staticClass:
                                              "fas fa-stop-circle fas-custom",
                                            staticStyle: {
                                              color: "red",
                                              cursor: "pointer"
                                            },
                                            attrs: { title: "Terminate demo" },
                                            on: {
                                              click: function($event) {
                                                return _vm.terminate_instance(
                                                  snapshot["demoId"]
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      snapshot["script"]
                                        ? _c("i", {
                                            staticClass:
                                              "fas fa-file-alt fas-custom",
                                            staticStyle: {
                                              color: "#6bb5e4",
                                              cursor: "pointer"
                                            },
                                            attrs: { title: "Script" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openLink(
                                                  snapshot["script"]
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("i", {
                                        staticClass: "fas fa-camera fas-custom",
                                        staticStyle: {
                                          color: "#575757",
                                          cursor: "pointer"
                                        },
                                        attrs: { title: "Snapshot" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showSnapshot(
                                              snapshot["demoId"]
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                            ]
                          ),
                          snapshot.status && snapshot.inProcess
                            ? _c(
                                "p",
                                { staticClass: "scenario-status-running" },
                                [_vm._v(" " + _vm._s(snapshot.status) + " ")]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm.terminateInstance
            ? _c("TerminateInstance", { attrs: { instance: _vm.instance } })
            : _vm._e(),
          _vm.ipAddress ? _c("InstanceIP") : _vm._e()
        ],
        1
      )
    ]),
    _vm.showUserCredModal
      ? _c(
          "div",
          [
            _c(
              "b-modal",
              {
                attrs: {
                  id: "modal-2",
                  centered: "",
                  "hide-header": "",
                  "content-class": "modal-content2"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modal-footer",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "btn btn-primary btn-custom btn-user-cred",
                              attrs: { size: "sm" },
                              on: { click: _vm.closeUserCredModal }
                            },
                            [_vm._v(" Close ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  435782646
                )
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("strong", { staticStyle: { float: "right" } }, [
                      _vm._v("User Name: ")
                    ])
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _vm._v(_vm._s(_vm.userName))
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("strong", { staticStyle: { float: "right" } }, [
                      _vm._v("Password:")
                    ])
                  ]),
                  _c("div", { staticStyle: { display: "inline-flex" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-6",
                        staticStyle: {
                          "max-width": "113px !important",
                          "margin-top": "3px"
                        }
                      },
                      [_vm._v(" ***************** ")]
                    ),
                    _c("input", {
                      attrs: { type: "hidden", id: "copy-code" },
                      domProps: { value: _vm.password }
                    }),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: {
                          cursor: "pointer",
                          "margin-left": "35px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.togglePassword()
                          }
                        }
                      },
                      [_vm._v("content_copy")]
                    )
                  ])
                ]),
                _vm.subnet
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("strong", { staticStyle: { float: "right" } }, [
                          _vm._v("Subnet:")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticStyle: { display: "inline-flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-6",
                              staticStyle: {
                                "max-width": "113px !important",
                                "margin-top": "3px"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.subnet) + " ")]
                          ),
                          _c("input", {
                            attrs: { type: "hidden", id: "copy-code-subnet" },
                            domProps: { value: _vm.subnet }
                          }),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              staticStyle: {
                                cursor: "pointer",
                                "margin-left": "35px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleSubnet()
                                }
                              }
                            },
                            [_vm._v("content_copy")]
                          ),
                          _c(
                            "transition",
                            {
                              attrs: { name: "fade" },
                              on: { enter: _vm.enterSubnet }
                            },
                            [
                              _vm.copySubnet
                                ? _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "font-size": "0.8vw",
                                        "margin-top": "3px",
                                        "margin-left": "3px",
                                        color: "green",
                                        "font-weight": "500"
                                      }
                                    },
                                    [_vm._v(" copied! ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }