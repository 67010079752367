<template>
  <div>
    <div class="row m-0 p-0">
      <div
        class="content-layout-area"
        style="
          padding-top: 20px !important;
          padding-right: 20px !important;
          border-right: 1px solid #e3e3e3;
          border-left: 1px solid #e3e3e3;
        "
      >
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :loader="loader"
          :color="color"
        />
        <div class="row" style="padding-left: 20px">
          <div
            v-if="!snapshots.length && !isLoading"
            style="
              text-align: center;
              color: #ff681d;
              font-size: 30px;
              padding: 20%;
            "
          >
            No snapshot to show
          </div>
          <div
            class="col-custom"
            style="padding-bottom: 20px"
            v-for="snapshot in snapshots"
            :key="snapshot.snapshotId"
          >
            <div class="col-4-custom">
              <span
                style="
                  font-size: 0.7vw;
                  color: rgba(128, 128, 128, 0.82);
                  position: absolute;
                  right: 24px;
                  top: 7px;
                  font-weight: 500;
                "
                >{{ showDate(snapshot["creation_timestamp"]) }}</span
              >
              <div class="content-wrap-custom">
                <span class="title">{{ snapshot.category }}</span>
                <h5 class="custom-class-h5">{{ snapshot.name }}</h5>
                <p
                  class="custom-class-p"
                  style="
                    font-size: 13px;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                    bottom: 87px;
                    position: absolute;
                    padding-right: 30px;
                    padding-bottom: 10px;
                    overflow-wrap: anywhere;
                  "
                >
                  {{ snapshot.description }}
                </p>
              </div>
              <div class="status-custom justify-content-center">
                <div class="links snapshot-list-links">
                  <p class="scenario-status-label" v-if="!snapshot.inProcess">
                    <i
                      class="fas fa-play-circle fas-custom"
                      style="color: #6bb5e4; cursor: pointer"
                      v-on:click="
                        startSnapshot(snapshot)
                      "
                      title="Start snapshot"
                    ></i>
                    <i
                      class="fas fa-trash-alt fas-custom"
                      style="color: #575757; cursor: pointer"
                      v-on:click="
                        deleteSnapshot(snapshot['snapshotId'], snapshot['name'])
                      "
                      title="Delete snapshot"
                    ></i>
                  </p>
                  <div
                    v-else
                    style="
                      margin-left: auto;
                      margin-right: 20px;
                      text-align: center;
                      margin-top: -3px;
                    "
                  >
                    <i
                      class="fas fa-bolt fas-custom"
                      v-if="snapshot['url']"
                      style="color: #fbb022; cursor: pointer"
                      v-on:click="openLink(snapshot['url'])"
                      title="Connect to demo"
                    ></i>
                    <i
                      class="fas fa-bolt fas-custom"
                      v-if="snapshot['ip_address']"
                      style="color: #fbb022; cursor: pointer"
                      v-on:click="openAlert(snapshot['ip_address'])"
                      title="snapshot"
                    ></i>
                    <a :href="snapshot['wvd_url']" v-if="snapshot['wvd_url']" target="_blank">
                      <i
                        class="fas fa-desktop fas-custom"
                        style="color: #fbb022; cursor: pointer"
                        title="Connect Demo"
                      ></i>
                    </a>
                    <i
                      class="fas fa-user-secret fas-custom"
                      v-if="
                        snapshot.status === 'User Created' &&
                        snapshot.user &&
                        snapshot.password
                      "
                      v-b-modal.modal-2
                      v-on:click="
                        openUserCred(snapshot['user'], snapshot['password'], snapshot['subnet'] || '')
                      "
                      style="color: #fbb022; cursor: pointer"
                      title="Demo details"
                    ></i>
                    <i
                      class="fas fa-stop-circle fas-custom"
                      v-if="snapshot['demoId']"
                      style="color: red; cursor: pointer"
                      v-on:click="terminate_instance(snapshot['demoId'])"
                      title="Terminate demo"
                    ></i>
                    <i
                      class="fas fa-file-alt fas-custom"
                      v-if="snapshot['script']"
                      style="color: #6bb5e4; cursor: pointer"
                      v-on:click="openLink(snapshot['script'])"
                      title="Script"
                    ></i>
                    <i
                      class="fas fa-camera fas-custom"
                      style="color: #575757; cursor: pointer"
                      v-on:click="showSnapshot(snapshot['demoId'])"
                      title="Snapshot"
                    ></i>
                  </div>
                </div>
                <p
                  v-if="snapshot.status && snapshot.inProcess"
                  class="scenario-status-running"
                >
                  {{ snapshot.status }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <TerminateInstance :instance="instance" v-if="terminateInstance" />
        <InstanceIP v-if="ipAddress" />
      </div>
    </div>
    <div v-if="showUserCredModal">
      <b-modal id="modal-2" centered hide-header content-class="modal-content2">
        <div class="row">
          <div class="col-4">
            <strong style="float: right">User Name: </strong>
          </div>
          <div class="col-6">{{ userName }}</div>
        </div>
        <div class="row">
          <div class="col-4">
            <strong style="float: right">Password:</strong>
          </div>
          <div style="display: inline-flex">
            <div
              class="col-6"
              style="max-width: 113px !important; margin-top: 3px"
            >
              *****************
            </div>
            <input type="hidden" id="copy-code" :value="password" />
            <i
              class="material-icons"
              @click="togglePassword()"
              style="cursor: pointer; margin-left: 35px"
              >content_copy</i
            >
          </div>
        </div>
        <div class="row" v-if="subnet">
          <div class="col-4">
            <strong style="float: right">Subnet:</strong>
          </div>
          <div style="display: inline-flex">
            <div
              class="col-6"
              style="max-width: 113px !important; margin-top: 3px"
            >
              {{subnet}}
            </div>
            <input type="hidden" id="copy-code-subnet" :value="subnet" />
            <i
              class="material-icons"
              @click="toggleSubnet()"
              style="cursor: pointer; margin-left: 35px"
              >content_copy</i
            >
            <transition name="fade" v-on:enter="enterSubnet">
              <p
                v-if="copySubnet"
                style="
                  font-size: 0.8vw;
                  margin-top: 3px;
                  margin-left: 3px;
                  color: green;
                  font-weight: 500;
                "
              >
                copied!
              </p>
            </transition>
          </div>
        </div>
        <template #modal-footer content-class="modal-footer1">
          <b-button
            class="btn btn-primary btn-custom btn-user-cred"
            size="sm"
            v-on:click="closeUserCredModal"
          >
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
    <div></div>
  </div>
</template>

<script>
import TerminateInstance from '@/components/TerminateInstance'
import InstanceIP from '@/components/InstanceIP'
import { mapMutations, mapGetters, mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import moment from 'moment'

export default {
  name: 'scenarioList',
  components: {
    TerminateInstance,
    InstanceIP,
    Loading
  },
  data () {
    return {
      snapshots: [],
      scenarioId: null,
      showUserCredModal: false,
      menuCount: 5,
      userName: '',
      password: '',
      isLoading: false,
      fullPage: false,
      loader: 'bars',
      color: '#ff681d',
      running_list: [],
      copySubnet: false,
      subnet: ''
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['GET_USER', 'mailUserName', 'snapshotNbr']),
    ...mapState([
      'firestoreDB',
      'terminateInstance',
      'instance',
      'ipAddress',
      'snapshotId'
    ])
  },
  methods: {
    startSnapshot (snapshot) {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_START_SNAPSHOT(false)
      this.UPDATE_DELETE_SNAPSHOT(false)

      this.UPDATE_SNAPSHOT_ID(snapshot.snapshotId)
      this.UPDATE_SNAPSHOT_NAME(snapshot.name)
      this.UPDATE_SNAPSHOT_CATEGORY(snapshot.category)
      this.UPDATE_START_SNAPSHOT(true)
      const demoPurposeRecord = {
        demo_purpose: snapshot.demo_purpose || '',
        demo_purpose_customer_name: snapshot.demo_purpose_customer_name || '',
        demo_purpose_customer_url: snapshot.demo_purpose_customer_url || ''
      }
      this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
    },
    deleteSnapshot (snapshotId, snapshotName) {
      this.UPDATE_SNAPSHOT_ID('')
      this.UPDATE_SNAPSHOT_NAME(null)
      this.UPDATE_START_SNAPSHOT(false)
      this.UPDATE_DELETE_SNAPSHOT(false)

      this.UPDATE_SNAPSHOT_ID(snapshotId)
      this.UPDATE_SNAPSHOT_NAME(snapshotName)
      this.UPDATE_DELETE_SNAPSHOT(true)

      const demoPurposeRecord = {
        demo_purpose: '',
        demo_purpose_customer_name: '',
        demo_purpose_customer_url: ''
      }
      this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
    },
    showDate (timestamp) {
      const date = moment.unix(timestamp).format('YYYY-MM-DD HH:mm')
      return date || ''
    },
    openUserCred (user, password, subnet) {
      this.showUserCredModal = true
      this.userName = user
      this.password = password
      this.subnet = subnet
    },
    closeUserCredModal () {
      this.showUserCredModal = false
      this.userName = ''
      this.password = ''
      this.subnet = ''
    },
    ...mapMutations([
      'SELECT_INSTANCE',
      'UPDATE_SNAPSHOT_ID',
      'UPDATE_CREATE_SNAPSHOT',
      'UPDATE_TERMINATE_INSTANCE',
      'UPDATE_IP_ADDRESS',
      'SELECT_CATEGORY',
      'UPDATE_SNAPSHOT_NAME',
      'UPDATE_SNAPSHOT_CATEGORY',
      'UPDATE_DELETE_SNAPSHOT',
      'UPDATE_START_SNAPSHOT',
      'UPDATE_SNAPSHOTNBR',
      'SELECT_DEMO_PURPOSE'
    ]),
    togglePassword: function () {
      const codeToCopy = document.querySelector('#copy-code')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    toggleSubnet: function () {
      const codeToCopy = document.querySelector('#copy-code-subnet')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      this.copySubnet = document.execCommand('copy')
      codeToCopy.setAttribute('type', 'hidden')
    },
    enterSubnet: function (el, done) {
      const that = this
      setTimeout(function () {
        that.copySubnet = false
      }, 3000) // hide the message after 3 seconds
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    openAlert (ip) {
      this.UPDATE_IP_ADDRESS(ip)
    },
    terminate_instance (instanceId) {
      this.SELECT_INSTANCE(instanceId)
      this.UPDATE_TERMINATE_INSTANCE(true)
    },
    showSnapshot (instanceId) {
      if (this.snapshotNbr > 2) {
        alert('SteelDemo does not allow more than 3 snapshots per user')
      } else {
        this.SELECT_INSTANCE(instanceId)
        this.$store.running_demos.map(obj => {
          if (obj.demoId === instanceId) {
            const demoPurposeRecord = {
              demo_purpose: obj.demo_purpose || '',
              demo_purpose_customer_name: obj.demo_purpose_customer_name || '',
              demo_purpose_customer_url: obj.demo_purpose_customer_url || ''
            }
            this.SELECT_DEMO_PURPOSE(demoPurposeRecord)
          }
          return this.UPDATE_CREATE_SNAPSHOT(true)
        })
      }
    },
    updateStatus () {
      const refDemos = this.firestoreDB.collection('demos')
      const queryDemos = refDemos.where('username', '==', this.mailUserName)
      queryDemos.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.running_list.push(change.doc.data())
          }
          if (change.type === 'modified') {
            const index = this.running_list.findIndex(
              (e) => e.demoId === change.doc.data().demoId
            )
            if (index === -1) {
              this.running_list.push(change.doc.data())
            } else {
              this.running_list[index] = change.doc.data()
            }
          }
          if (change.type === 'removed') {
            this.running_list.pop(change.doc.data())
            if (change.doc.data().snapshotId) {
              const findSnapshotIndex = this.snapshots.findIndex(
                (e) => e.snapshotId === change.doc.data().snapshotId
              )
              if (findSnapshotIndex > -1) {
                this.snapshots[findSnapshotIndex].inProcess = false
              }
            }
          }
        })
        this.$store.running_demos = this.running_list
        this.$store.running_demos.map((record) => {
          if (record.snapshotId) {
            const findSnapshotIndex = this.snapshots.findIndex(
              (e) => e.snapshotId === record.snapshotId
            )
            if (findSnapshotIndex > -1) {
              const snapshot = this.snapshots[findSnapshotIndex]
              snapshot.status = record.status
              snapshot.url = record.url
              snapshot.ip_address = record.ip_address
              snapshot.wvd_url = record.wvd_url
              snapshot.demoId = record.demoId
              snapshot.script = record.script
              snapshot.duration = record.duration
              if (
                (record.login && record.password) ||
                (record.login !== 'N/A' && record.password !== 'N/A')
              ) {
                snapshot.user = record.login
                snapshot.password = record.password
              }
              if (record.subnet && record.subnet !== 'N/A' && record.subnet !== null) {
                snapshot.subnet = record.subnet
              }
              snapshot.inProcess = true
              this.snapshots[findSnapshotIndex] = snapshot
            }
          }
          return true
        })
        this.snapshots = [...this.snapshots]
      })
    },
    getSnapshotData () {
      try {
        const ref = this.firestoreDB.collection('snapshots')

        const query = ref.where('username', '==', this.mailUserName)
        query.onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const instance = change.doc.data()
            if (change.type === 'added') {
              const indexSnapshot = this.snapshots.findIndex(
                (e) => e.snapshotId === instance.snapshotId
              )
              if (indexSnapshot === -1) {
                this.snapshots.push(instance)
              }
            }

            if (change.type === 'removed') {
              const indexSnapshot = this.snapshots.findIndex(
                (e) => e.snapshotId === instance.snapshotId
              )
              if (indexSnapshot > -1) {
                this.snapshots.splice(indexSnapshot, 1)
              }
            }
          })
          this.UPDATE_SNAPSHOTNBR(this.snapshots.length)
        })
      } catch (error) {
        // console.log('error in dashboard Instances ' + error)
      }
    }
  },
  created () {
    this.snapshots = [...this.data]
    this.UPDATE_SNAPSHOTNBR(this.snapshots.length)
    this.getSnapshotData()
    this.updateStatus()
  }
}
</script>
<style>
@media screen and (max-width: 1395px) and (min-width: 1104px) {
  .col-custom {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media screen and (max-width: 1103px) {
  .col-custom {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.col-custom {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0px 20px;
  display: block;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.content-layout-area {
  width: calc(100% - 250px);
}

@media screen and (min-width: 768px) {
  .col-outer.center-align-items {
    display: flex !important;
    justify-content: center !important;
  }
}

.col-outer {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 46px;
}

.col-4-custom-lab:nth-child(1) {
  border-top: 4px solid #5fb4e4;
}

.col-4-custom-lab:nth-child(2) {
  border-top: 4px solid #5b7d95;
}

.col-4-custom-lab {
  height: 240px;
  box-shadow: 0px 3px 6px #00000033;
  border: 1px solid #f0f5f8;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.col-4-custom-lab:focus {
  outline: none !important;
}

.col-4-custom-lab:nth-child(1) {
  border-top: 4px solid #5fb4e4;
}
.col-4-custom-lab > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.icon {
  position: absolute;
  right: 15px;
  top: 12px;
  min-width: 45px;
  min-height: 45px;
}

.modal-footer1 {
  background-color: white;
  display: block;
}

img {
  border-style: none;
  max-width: 100%;
  padding: 0;
  margin: 0;
  height: auto;
}

.content-wrap-custom {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 60px;
  padding: 0 23px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  height: 90px;
}
.content-wrap-custom:focus {
  outline: none !important;
}

.content-wrap-custom .title {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  color: #5fb4e4;
  text-transform: uppercase;
  margin-top: -45px !important;
}

h5 {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 1.33 !important;
  letter-spacing: 0.21px;
  text-align: left;
  color: #2c3843;
  padding: 8px 0 4px;
  min-height: 50px;
}

.content-wrap-custom .btn-orange-link {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  color: #ff681d;
  padding: 0 40px 0 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  position: relative;
}

.material-icons {
  color: #ff681d;
}

.col-4-custom-lab:nth-child(3) {
  border-top: 4px solid #ff681d;
}

.scenario-status {
  text-transform: uppercase;
  padding: 5px;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 5px;
  color: #fff;
  background-color: red;
}

.scenario-status-label {
  text-transform: uppercase;
  padding: 5px;
  margin-top: 4px;
  border-radius: 5px;
  color: #ff681d;
  font-size: 0.8vw;
  text-align: center;
  font-weight: 700;
}

.scenario-status-running {
  text-transform: uppercase;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: green;
  font-size: 0.8vw;
  text-align: center;
  margin-top: 4px;
  margin-bottom: -2px;
}
.snapshot-list-links {
  margin-top: 0px;
  border-top: 1px solid #e3e3e3 !important;
}

.fas-custom {
  padding-left: 7px;
  font-weight: 900;
  font-size: 1.5vw;
  padding-top: 12px;
}

.modal-content2 {
  width: 500px !important;
  margin-left: 70px !important;
}

#modal-footer > .btn-custom {
  background-color: #ff681d;
  color: white;
  border-color: #ff681d;
}

#modal-footer > .btn-custom:hover {
  background-color: white;
  color: #ff681d;
  border-color: #ff681d;
}
.btn-user-cred {
  margin-top: 10px !important;
  margin-right: 36% !important;
  width: 30% !important;
  background-color: #ff681d !important;
}

.btn-user-cred:hover {
  background-color: #fff !important;
  color: #ff681d !important;
  border-color: #ff681d !important;
}
.staging {
  background-color: #e2dede !important;
  border-top: 4px solid #212529 !important;
}

.badge-custom {
  width: 48px !important;
  color: #fff !important;
  background-color: #5fb4e4 !important;
  margin-left: -3px !important;
  border-radius: 0 !important;
  font-size: 10px !important;
  height: 20px !important;
  margin-top: -2px;
  font-weight: 400 !important;
}

.featured .badge-custom {
  background-color: #ff681d !important;
}

.status-custom {
  padding: 5px;
  padding-top: 8px;
  margin-bottom: -10px;
}
@media screen and (max-width: 1200px) {
  h5 {
    font-size: 18px !important;
  }

  .scenario-status-label {
    margin-top: -3.5px;
    color: #ff6900;
    font-size: 0.9vw;
    text-align: center;
    font-weight: 700;
  }
}
@media screen and (max-width: 1536px) {
  .scenario-status-running {
    font-size: 0.9vw !important;
  }

  .scenario-status-label {
    font-size: 0.9vw !important;
  }
  .content-wrap-custom .title {
    font-size: 10px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #5fb4e4 !important;
    text-transform: uppercase !important;
    margin-top: -45px !important;
  }
  .content-wrap-custom .custom-class-h5 {
    font-size: 18px !important;
  }
  .content-wrap-custom .custom-class-p {
    font-size: 12px !important;
  }
  .card-filter,
  .card-filter:hover {
    overflow-anchor: none !important;
    background-color: #ff681d !important;
    text-align: start !important;
    width: 192px !important;
    padding-left: 13px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-left: -8px !important;
    color: white !important;
  }

  .custom-responsive-filter,
  .custom-responsive-filter .focus {
    padding-top: 7.5px !important;
    padding-left: 10px !important;
    cursor: pointer !important;
    margin-top: 5px !important;
    border-radius: 10px !important;
    padding-bottom: 7.5px !important;
    margin-left: -17px !important;
  }
}
</style>
