<template>
  <div class="row m-0 p-0">
    <div class="row parent-dashboard" style="box-shadow: none">
      <div class="row chart-row" style="margin-top: 5px; margin-bottom: 5px">
        <div class="col-md-12 col-sm-12">
          <div
            style="float: left"
            v-if="memberOfDashboardViewers"
          >
            <b-dropdown
              variant="outline-danger"
              split-variant="outline-danger"
              offset="25"
              class="m-2 manager-dropdown"
              v-model="managerListDropDown.selectedOption"
              :text="managerListDropDown.selectedOption"
            >
              <b-dropdown-item
                v-for="option in managerListDropDown.options"
                :key="option.value"
                :value="option.value"
                @click="changeMangerDropValue(option.value)"
              >
                {{ option.text }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            id="reportrange"
            class="pull-right"
            style="background: #fff; float: right"
          >
            <date-range-picker
              v-model="dateRange"
              :startDate="startDate"
              :endDate="endDate"
              :locale-data="locale"
              :ranges="ranges"
              :maxDate="maxEndDate"
              @update="showDashboard"
              opens="left"
            >
              <div slot="input" slot-scope="picker">
                <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                {{ picker.startDate | dateFormat }} -
                {{ picker.endDate | dateFormat }}
              </div>
            </date-range-picker>
            <b class="caret"></b>
          </div>
          <small
            style="
              color: grey;
              font-size: 0.9rem;
              font-weight: 700;
              float: right;
              margin-right: 20px;
              margin-top: 8px;
            "
            >Please select date range for the dashboard</small
          >
        </div>
      </div>
      <div class="row chart-row parent-dashboard">
        <b-container class="bv-example-row">
          <b-row class="justify-content-md-center">
            <b-col col lg="4" style="background-color: #ff681d;margin-top: 9px;margin-bottom: 7px;"></b-col>
            <b-col cols="12" md="auto" style="font-weight: bold;">Individual Activity</b-col>
            <b-col col lg="4"  style="background-color: #ff681d;margin-top: 9px;margin-bottom: 7px;"></b-col>
          </b-row>
        </b-container>
        <div
          class="col-custom-70"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div class="col-md-10" style="font-size: 1.35rem">
                <h3 class="graph-name">
                  Reportee Activity<small
                    style="
                      padding: 5px;
                      font-size: 13px;
                      color: rgb(150, 144, 144);
                    "
                    >Select a user for detailed records</small
                  >
                </h3>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 20px; position: relative; max-height: 445px"
              >
                <ReporteeBarChart
                  v-if="reporteesName.length > 0"
                  :chart-labels="reporteesName"
                  :chart-data="reporteesRecord"
                  :options="createReporteesBarOption()"
                  :styles="{ height: '415px !important', position: 'relative' }"
                />
                <loading
                  :active="reporteesRecord.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-custom-30"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">
                  Reportee Demo Activity
                  <small> ({{ reporteeNameDetails }})</small>
                </h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative; max-height: 445px"
              >
                <ReporteePieChart
                  v-if="reporteePieDataSet.length > 0"
                  :chart-labels="Object.keys(reporteePieData)"
                  :chart-data="reporteePieDataSet"
                  :options="createReporteePieOption()"
                  :styles="{ height: '415px !important', position: 'relative' }"
                />
                <loading
                  :active="reporteePieDataSet.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-custom-100"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none">
            <div class="row x_title">
              <div class="col-md-12" style="font-size: 1.35rem; display: flex">
                <h3 class="graph-name" style="text-transform: uppercase">
                  {{ reporteeNameDetails }}
                </h3>
                <small></small>
              </div>
            </div>
            <div class="col-md-12 col-sm-12" style="min-height: 445px">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <b-table
                  striped
                  hover
                  responsive
                  sticky-header="400px"
                  :no-border-collapse="true"
                  :items="reporteeDetails"
                ></b-table>
                <loading
                  :active="reporteesRecord.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row chart-row parent-dashboard">
        <b-container class="bv-example-row">
          <b-row class="justify-content-md-center">
            <b-col col lg="4" style="background-color: #ff681d;margin-top: 9px;margin-bottom: 7px;"></b-col>
            <b-col cols="12" md="auto" style="font-weight: bold;">Team Activity</b-col>
            <b-col col lg="4"  style="background-color: #ff681d;margin-top: 9px;margin-bottom: 7px;"></b-col>
          </b-row>
        </b-container>
        <div class="col-custom-70" style="display: flex;">
          <div class="x_panel" style="box-shadow: none;">
            <div class="row x_title">
              <div class="col-md-12" style="font-size: 1.35rem">
                <h3 class="graph-name">
                  Reportees Activity
                  <small
                    style="
                      padding: 5px;
                      font-size: 13px;
                      color: rgb(150, 144, 144);
                    "
                    >(Direct Reports Only)</small
                  >
                </h3>
              </div>
              <div class="col-md-8">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right"
                >
                  <b class="caret"></b>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <UserActivityAreaChart
                  v-if="lastSixMonth.length > 0"
                  :chart-labels="lastSixMonth"
                  :chart-data="[
                    lastSixMonthRecord,
                    lastSixMonthRecordByUser,
                    lastSixMonthRecordByPurposePractice,
                    lastSixMonthRecordByPurposeCustomer,
                  ]"
                  :options="createUserActivityChartOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="lastSixMonth.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-custom-30" style="display: flex;">
          <div class="x_panel" style="box-shadow: none;">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">
                  Activity By Pillar
                  <small
                    style="
                      padding: 5px;
                      font-size: 13px;
                      color: rgb(150, 144, 144);
                    "
                    >(Direct Reports Only)</small
                  >
                </h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <PillarsPieChart
                  v-if="pillars['SD-WAN'] > 0"
                  :chart-labels="Object.keys(pillars)"
                  :chart-data="Object.values(pillars)"
                  :options="createPillarsOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="pillars['SD-WAN'] > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-custom-30" style="display: flex;">
          <div class="x_panel" style="box-shadow: none;">
            <div class="row x_title">
              <div style="font-size: 1.35rem; margin-left: 20px !important">
                <h3 class="graph-name">
                  Demo Activity
                  <small
                    style="
                      padding: 5px;
                      font-size: 13px;
                      color: rgb(150, 144, 144);
                    "
                    >(Direct Reports Only)</small
                  >
                </h3>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <ScenarioPieChat
                  v-if="scenarioListDataset.data.length > 0"
                  :chart-labels="scenarioList"
                  :chart-data="scenarioListDataset"
                  :options="createScenarioPieOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="scenarioList.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-custom-70"
          style="display: -webkit-flex; display: flex"
        >
          <div class="x_panel" style="box-shadow: none;">
            <div class="row x_title">
              <div class="col-md-10" style="font-size: 1.35rem">
                <h3 class="graph-name">
                  Demo’s By Month
                  <small
                    style="
                      padding: 5px;
                      font-size: 13px;
                      color: rgb(150, 144, 144);
                    "
                    >(Direct Reports Only)</small
                  >
                </h3>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <DemoBarChart
                  v-if="lastSixMonth_for_demo.length > 0"
                  :chart-labels="lastSixMonth_for_demo"
                  :chart-data="lastSixMonthScenarios"
                  :options="createDemoBarOption()"
                  :styles="{ height: '410px !important', position: 'relative' }"
                />
                <loading
                  :active="lastSixMonth.length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row chart-row parent-dashboard">
        <b-container class="bv-example-row">
          <b-row class="justify-content-md-center">
            <b-col col lg="4" style="background-color: #ff681d;margin-top: 9px;margin-bottom: 7px;"></b-col>
            <b-col cols="12" md="auto" style="font-weight: bold;">Geo Activity</b-col>
            <b-col col lg="4"  style="background-color: #ff681d;margin-top: 9px;margin-bottom: 7px;"></b-col>
          </b-row>
        </b-container>
        <div class="col-md-12 col-sm-12">
          <div class="x_panel">
            <div class="row x_title">
              <div
                class="col-md-8"
                style="font-size: 1.35rem; padding-right: 0px"
              >
                <h3 class="graph-name">
                  GEO Demos Activity<small
                    style="padding: 5px; font-size: 13px; color: #969090"
                    >Records from July 16, 21 (Total {{countryDataTotal}})</small
                  >
                </h3>
              </div>
              <div class="col-md-4" style="padding: 0px">
                <div
                  id="reportrange"
                  class="pull-right"
                  style="background: #fff; float: right"
                >
                  <b-dropdown
                    id="dropdown-right"
                    right
                    variant="outline-danger"
                    split-variant="outline-danger"
                    offset="25"
                    class="m-2 cost-dropdown"
                    v-model="reporteeGeoDropDown.selectedOption"
                    :text="reporteeGeoDropDown.selectedOption"
                  >
                    <b-dropdown-item
                      v-for="option in reporteeGeoDropDown.options"
                      :key="option.value"
                      :value="option.value"
                      @click="changeReporteeGeoData(option.value)"
                    >
                      {{ option.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div
                id="chart_plot_01"
                class="demo-placeholder"
                style="padding: 0px; position: relative"
              >
                <MapChart
                  :countryData="countryData"
                  highColor="rgba(232, 78, 2, .75)"
                  lowColor="rgba(249, 210, 191, .75)"
                  countryStrokeColor="#909090"
                  defaultCountryFillColor="#dadada"
                  legendFontColorHeader="rgba(238, 113, 55, .75)"
                  legendBorderCss="1px solid #fff"
                  legendBoxShadowCss="0px 0px 10px #fff"
                  :showEmptyValue="false"
                  :currencyAdd="false"
                  LangUser="en"
                />
                <loading
                  :active="Object.keys(countryData).length > 0 ? false : true"
                  :is-full-page="fullPage"
                  :loader="loader"
                  :color="color"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import UserActivityAreaChart from '@/charts/UserActivityAreaChart.vue'
import ScenarioPieChat from '@/charts/ScenarioPieChat.vue'
import DemoBarChart from '@/charts/DemoBarChart.vue'
import ReporteeBarChart from '@/charts/ReporteeBarChart.vue'
import ReporteePieChart from '@/charts/ReporteePieChart.vue'
import PillarsPieChart from '@/charts/PillarsPieChart.vue'
import MapChart from 'vue-chart-map'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import randomColor from 'randomcolor'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Loading from 'vue-loading-overlay'
import _ from 'lodash'

export default {
  name: 'ManegerDashboard',
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      running_list: [],
      instances_list: [],
      user_instances_list: [],
      chartData: null,
      appendToBody: true,
      monthNames: moment.months(),
      lastSixMonth: [],
      lastSixMonthRecord: [],
      lastSixMonthRecordByUser: [],
      lastSixMonthRecordByPurposePractice: [],
      lastSixMonthRecordByPurposeCustomer: [],
      lastSixMonth_for_demo: [],
      scenarioList: [],
      scenarioListDataset: {
        data: [],
        backgroundColor: []
      },
      lastSixMonthScenarios: [],
      demo_by_type_label: [],
      demo_by_type_data: {
        'SE Practice Demos': [],
        'SE Customer Demos': [],
        'Partners Practice Demos': [],
        'Partners Customer Demos': []
      },
      startDate: null,
      endDate: null,
      dateRange: {
        startDate: moment.utc().subtract(5, 'months').startOf('month'),
        endDate: moment.utc()
      },
      locale: {
        direction: 'ltr',
        format: 'DD-MM-YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      ranges: {
        'This Financial Year': [
          new Date(moment.utc().startOf('year')),
          new Date(moment.utc())
        ],
        'Last Financial Year': [
          new Date(moment.utc().startOf('year').subtract(1, 'year')),
          new Date(
            moment.utc().subtract(1, 'year').month('December').endOf('month')
          )
        ]
      },
      maxEndDate: moment.utc().format('YYYY-MM-DD'),
      pillars: {
        'App Acceleration': 0,
        NPM: 0,
        'SD-WAN': 0,
        WANop: 0
      },
      fullPage: false,
      loader: 'bars',
      color: '#ff681d',
      scenariosOrderByLenght: [],
      countryData: {},
      reporteesName: [],
      reporteesRecord: [],
      reporteeDetailsAll: [],
      reporteeDetails: [],
      reporteeNameDetails: '',
      reporteePieData: [],
      reporteePieDataSet: [],
      reporteeGeoDropDown: {
        selectedOption: '',
        options: [
          {
            value: 'Reportees',
            text: 'Reportees'
          },
          {
            value: 'EMEA',
            text: 'EMEA'
          },
          {
            value: 'APAC',
            text: 'APAC'
          },
          {
            value: 'AMER',
            text: 'AMER'
          }
        ]
      },
      managerListDropDown: {
        selectedOption: '',
        options: []
      },
      memberOfDashboardViewers: false,
      countryDataTotal: 0
    }
  },
  computed: {
    ...mapGetters(['mailUserName', 'reportees']),
    ...mapState(['firestoreDB'])
  },
  components: {
    UserActivityAreaChart,
    DemoBarChart,
    ScenarioPieChat,
    DateRangePicker,
    Loading,
    PillarsPieChart,
    MapChart,
    ReporteeBarChart,
    ReporteePieChart
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format('MMMM Do YYYY')
    }
  },
  methods: {
    getSum (data) {
      let sum = 0
      data.map((data) => {
        sum += parseInt(data)
        return sum
      })
      return sum
    },
    getSumFloat (data) {
      let sum = 0
      data.map((data) => {
        sum += parseFloat(data)
        return sum
      })
      return sum
    },
    createDemoBarOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.yLabel > 0) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                  ': ' +
                  tooltipItem.yLabel
                )
              }
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.5,
              barPercentage: 1
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        },
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    },
    createReporteesBarOption () {
      const total =
        this.reporteesRecord.length > 0
          ? this.reporteesRecord[0].data.reduce((a, b) => a + b, 0)
          : 0
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Total: ' + total + ' Demos'
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.yLabel > 0) {
                return tooltipItem.xLabel + ': ' + tooltipItem.yLabel
              }
            }
          }
        },
        onClick: this.handleClick,
        legend: {
          display: false
        }
      }
    },
    handleClick (evt, element) {
      if (element.length > 0) {
        this.reporteeNameDetails = this.reporteesRecord[0].label[
          element[0]._index
        ]
        this.reporteeDetails = this.reporteeDetailsAll
          .filter(
            (obj) =>
              obj.name === this.reporteesRecord[0].label[element[0]._index]
          )
          .map(({ name, index, ...rest }) => ({
            ...rest
          }))
        this.getReporteePieChartData()
      }
    },
    getReporteePieChartData () {
      this.reporteePieData = {}
      this.reporteePieDataSet = []
      const scenarioFilter = this.instances_list.filter(
        (obj) => obj.username === this.reporteeNameDetails
      )
      const scenarioFilterCount = _.countBy(scenarioFilter, 'scenarioId')
      Object.keys(scenarioFilterCount).forEach((id) => {
        scenarioFilter.forEach((obj) => {
          if (obj.scenarioId === id && !(obj.name in this.reporteePieData)) {
            this.reporteePieData[obj.name] = scenarioFilterCount[id]
          }
        })
      })
      const obj = {
        backgroundColor: randomColor({
          hue: '#00e7ff',
          luminosity: 'bright',
          count: Object.keys(scenarioFilterCount).length,
          format: 'rgb'
        }),
        data: Object.values(scenarioFilterCount)
      }
      this.reporteePieDataSet.push(obj)
    },
    async changeReporteeGeoData (val) {
      this.reporteeGeoDropDown.selectedOption = val
      if (val === 'Reportees') {
        this.countryData = _.countBy(this.instances_list, 'country')
        this.countryDataTotal = this.instances_list.length
      } else {
        this.countryData = {}
        await this.getRegionRecord(val)
      }
    },
    async changeMangerDropValue (val) {
      this.managerListDropDown.selectedOption = val
      this.$store.reportees = this.$store.managerList[val]
      this.resetRecord()
      this.demos()
      await this.instance(this.dateRange)
    },
    async getRegionRecord (val) {
      const params = {
        start: moment
          .utc(this.dateRange.startDate, 'YYYY-MM-DD')
          .valueOf()
          .toString(),
        end: moment
          .utc(this.dateRange.endDate, 'YYYY-MM-DD')
          .valueOf()
          .toString(),
        region: val
      }
      await axios({
        method: 'POST',
        data: { ...params },
        url: this.backendURL + 'dynamodb/instanceslogs',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('msal.idtoken')
        },
        crossDomain: true,
        responseType: 'json'
      }).then((result) => {
        this.countryData = _.countBy(result.data.body, 'country')
        this.countryDataTotal = result.data.body.length
      })
    },
    createScenarioPieOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 20,
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                data.labels[tooltipItem.index] +
                ': ' +
                (
                  (data.datasets[0].data[tooltipItem.index] * 100) /
                  this.getSum(data.datasets[0].data)
                ).toFixed(2) +
                '%'
              )
            }
          }
        }
      }
    },
    createPillarsOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 10,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                data.labels[tooltipItem.index] +
                ': ' +
                (
                  (data.datasets[0].data[tooltipItem.index] * 100) /
                  this.getSum(data.datasets[0].data)
                ).toFixed(2) +
                '%'
              )
            }
          }
        }
      }
    },
    createReporteePieOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 40,
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    },
    createUserActivityChartOption () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label.split('(')[0] +
                ': ' +
                tooltipItem.yLabel
              )
            }
          }
        }
      }
    },
    resetRecord () {
      this.scenarioList = []
      this.scenarioListDataset = {
        data: [],
        backgroundColor: []
      }
      this.scenariosOrderByLenght = []
      this.pillars = {
        'App Acceleration': 0,
        NPM: 0,
        'SD-WAN': 0,
        WANop: 0
      }
      this.lastSixMonthScenarios = []
      this.lastSixMonth = []
      this.lastSixMonthRecordByUser = []
      this.lastSixMonthRecordByPurposePractice = []
      this.lastSixMonthRecordByPurposeCustomer = []
      this.lastSixMonthRecord = []
      this.reporteesRecord = []
      this.reporteeNameDetails = ''
      this.reporteeDetails = []
      this.reporteeDetailsAll = []
      this.reporteePieData = {}
      this.reporteePieDataSet = []
      this.reporteeGeoDropDown.selectedOption = 'Reportees'
      this.countryData = {}
      this.countryDataTotal = 0
    },
    async showDashboard (val) {
      this.resetRecord()
      await this.instance(val)
    },
    demos () {
      const refDemos = this.firestoreDB.collection('demos')
      this.reporteesName = this.$store.reportees.map((id) => {
        return id.split('@')[0]
      })
      const queryDemos = refDemos
      queryDemos.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.running_list.push(change.doc.data())
          }
          if (change.type === 'modified') {
            const index = this.running_list.findIndex(
              (e) => e.demoId === change.doc.data().demoId
            )
            if (index === -1) {
              this.running_list.push(change.doc.data())
            } else {
              this.running_list[index] = change.doc.data()
            }
          }
          if (change.type === 'removed') {
            this.running_list.pop(change.doc.data())
          }
        })
        this.running_list = this.running_list.filter((obj) =>
          this.reporteesName.includes(obj.username)
        )
      })
    },
    instance (dateRange) {
      const params = {
        start: moment
          .utc(dateRange.startDate, 'YYYY-MM-DD')
          .valueOf()
          .toString(),
        end: moment.utc(dateRange.endDate, 'YYYY-MM-DD').valueOf().toString(),
        reportees: this.reporteesName
      }
      try {
        return axios({
          method: 'POST',
          data: { ...params },
          url: this.backendURL + 'dynamodb/instanceslogs',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('msal.idtoken')
          },
          crossDomain: true,
          responseType: 'json'
        }).then((result) => {
          const totalScenarios = this.$store.scenarios.concat(
            this.$store.scenariosLab
          )

          this.instances_list = result.data.body.filter(
            (obj) => obj.scenarioId !== 'scenario999'
          )

          this.instances_list = _.map(this.instances_list, (item) => {
            return _.merge(
              item,
              _.find(totalScenarios, { id: item.scenarioId })
            )
          }).filter((obj) => obj.visible)
          const reporteesCount = _.countBy(this.instances_list, 'username')
          const reporteesobj = {
            backgroundColor: randomColor({
              hue: '#ff681d',
              luminosity: 'bright',
              count: this.reporteesName.length,
              format: 'rgb'
            }),
            data: [],
            label: []
          }

          this.reporteesName.forEach((record) => {
            const count = reporteesCount[record] ? reporteesCount[record] : 0
            reporteesobj.data.push(count)
            reporteesobj.label.push(record)
          })
          this.reporteesRecord.push(reporteesobj)

          this.countryData = _.countBy(this.instances_list, 'country')
          this.countryDataTotal = this.instances_list.length
          // Users Demos to date
          this.user_instances_list = this.instances_list.filter(
            (obj) => obj.username === this.mailUserName
          )
          // activity by pillars
          this.instances_list.map((obj) => {
            if (obj.category) {
              const categoryList = obj.category.split(',')
              categoryList.map((category) => {
                const trimcategory = category
                  .trim()
                  .toLowerCase()
                  .replace('-', '')
                switch (trimcategory) {
                  case 'all':
                    this.pillars['App Acceleration'] =
                      this.pillars['App Acceleration'] + 1 / 4
                    this.pillars.NPM = this.pillars.NPM + 1 / 4
                    this.pillars['SD-WAN'] = this.pillars['SD-WAN'] + 1 / 4
                    this.pillars.WANop = this.pillars.WANop + 1 / 4
                    break
                  case 'acceleration':
                  case 'app acceleration':
                    this.pillars['App Acceleration'] =
                      this.pillars['App Acceleration'] +
                      1 / categoryList.length
                    break
                  case 'visibility':
                  case 'npm':
                    this.pillars.NPM =
                      this.pillars.NPM + 1 / categoryList.length
                    break
                  case 'sdwan':
                    this.pillars['SD-WAN'] =
                      this.pillars['SD-WAN'] + 1 / categoryList.length
                    break
                  case 'wanop':
                    this.pillars.WANop =
                      this.pillars.WANop + 1 / categoryList.length
                    break
                }
                return true
              })
            }
            return true
          })

          const groupByScenario = _.countBy(this.instances_list, 'scenarioId')
          const sortedScenario = _.toPairs(groupByScenario).sort(function (
            a,
            b
          ) {
            return b[1] - a[1]
          })

          // Most launched Demo's
          sortedScenario.map((arr, index) => {
            totalScenarios.map((obj) => {
              if (obj.scenarioId === arr[0]) {
                const combine = { ...obj, count: arr[1] }
                this.scenariosOrderByLenght.push(combine)
                if (index < 10) {
                  this.scenarioList.push(obj.name)
                  this.scenarioListDataset.data.push(arr[1])
                }
              }
              return true
            })
            return true
          })

          this.scenarioListDataset.backgroundColor = randomColor({
            hue: 'orange',
            luminosity: 'light',
            count: this.scenarioList.length,
            format: 'rgba',
            alpha: 0.75
          })

          // scenarios by month
          this.scenarioList.forEach((val) => {
            this.lastSixMonthScenarios.push({
              label: val,
              backgroundColor: randomColor({
                hue: 'red',
                luminosity: 'light',
                count: this.scenarioList.length,
                format: 'rgba',
                alpha: 0.75
              }),
              data: []
            })
          })

          // Users Activities
          this.getUserActivity()

          this.lastSixMonth_for_demo = this.lastSixMonth
        })
      } catch (error) {
        console.log('error in dashboard Instances ' + error)
      }
    },
    getUserActivity () {
      const shortArray = this.instances_list.sort(function (a, b) {
        return parseInt(a.starting_timestamp) - parseInt(b.starting_timestamp)
      })
      const groupedByMonth = _.groupBy(shortArray, function (obj) {
        return moment
          .unix(obj.starting_timestamp)
          .format('YYYY-MM-DD')
          .substring(0, 7)
      })
      Object.entries(groupedByMonth).forEach(([key, value], index) => {
        this.lastSixMonth.push(moment(key).format('MMM YY'))

        const dataGroupUser = value.reduce((r, a) => {
          r[a.username] = [...(r[a.username] || []), a]
          return r
        }, {})
        const dataGroupUserKeys = Object.keys(dataGroupUser)
        this.lastSixMonthRecordByUser.push(dataGroupUserKeys.length)

        const datafilterPurposePractice = value.filter(
          (obj) => obj.demo_purpose === 'practice'
        )

        const datafilterPurposeCustomerDemos = value.filter(
          (obj) => obj.demo_purpose === 'customerDemos'
        )

        this.lastSixMonthRecordByPurposePractice.push(
          datafilterPurposePractice.length
        )
        this.lastSixMonthRecordByPurposeCustomer.push(
          datafilterPurposeCustomerDemos.length
        )
        this.lastSixMonthRecord.push(value.length)
        this.reporteesName.forEach((name) => {
          const detailsObj = {
            name: name,
            month: moment(key).format('MMM YY')
          }
          let record = value.filter((obj) => obj.username === name).length
          detailsObj['Overall activity'] = record

          record = datafilterPurposeCustomerDemos.filter(
            (obj) => obj.username === name
          )
          let customerName = ''
          if (record.length > 0) {
            const unique = [
              ...new Set(
                record.map((item) =>
                  item.demo_purpose_customer_name
                    .trim()
                    .toLowerCase()
                    .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
                )
              )
            ]
            customerName = unique.join()
          }
          detailsObj['Customer Demos'] = record.length
          detailsObj['Customer Names'] = customerName

          record = datafilterPurposePractice.filter(
            (obj) => obj.username === name
          ).length
          detailsObj['Practice Demos'] = record
          this.reporteeDetailsAll.push(detailsObj)
        })

        const dataGroup = value.reduce((r, a) => {
          r[a.name] = [...(r[a.name] || []), a]
          return r
        }, {})
        const objectKeys = Object.keys(dataGroup)
        this.lastSixMonthScenarios = this.lastSixMonthScenarios.map((obj) => {
          if (objectKeys.includes(obj.label)) {
            obj.data.push(dataGroup[obj.label].length)
          } else {
            obj.data.push(0)
          }
          return obj
        })
      })
      this.reporteeNameDetails = this.reporteesRecord[0].label[0]
      this.reporteeDetails = this.reporteeDetailsAll
        .filter((obj) => obj.name === this.reporteesRecord[0].label[0])
        .map(({ name, index, ...rest }) => ({
          ...rest
        }))
      this.getReporteePieChartData()
    }
  },
  async created () {
    this.memberOfDashboardViewers = this.$store.memberOfDashboardViewers
    if (this.memberOfDashboardViewers) {
      const mangerlistKeys = Object.keys(this.$store.managerList)
      mangerlistKeys.forEach(arr => {
        this.managerListDropDown.options.push({
          value: arr,
          text: arr
        })
      })
      this.managerListDropDown.selectedOption = mangerlistKeys[0]
      this.$store.reportees = this.$store.managerList[mangerlistKeys[0]]
    }
    this.reporteeGeoDropDown.selectedOption = 'Reportees'
    const quarter1 = new Date(moment.utc().quarter(1).startOf('quarter'))
    const quarter2 = new Date(moment.utc().quarter(2).startOf('quarter'))
    const quarter3 = new Date(moment.utc().quarter(3).startOf('quarter'))
    const quarter4 = new Date(moment.utc().quarter(4).startOf('quarter'))
    const timeNow = new Date(moment.utc())
    if (timeNow >= quarter1) {
      this.ranges['This Year Q1'] = [
        new Date(moment.utc().quarter(1).startOf('quarter')),
        new Date(moment.utc().quarter(1).endOf('quarter'))
      ]
    }
    if (timeNow >= quarter2) {
      this.ranges['This Year Q2'] = [
        new Date(moment.utc().quarter(2).startOf('quarter')),
        new Date(moment.utc().quarter(2).endOf('quarter'))
      ]
    }
    if (timeNow >= quarter3) {
      this.ranges['This Year Q3'] = [
        new Date(moment.utc().quarter(3).startOf('quarter')),
        new Date(moment.utc().quarter(3).endOf('quarter'))
      ]
    }
    if (timeNow >= quarter4) {
      this.ranges['This Year Q4'] = [
        new Date(moment.utc().quarter(4).startOf('quarter')),
        new Date(moment.utc().quarter(4).endOf('quarter'))
      ]
    }
    this.demos()
    await this.instance(this.dateRange)
  }
}
</script>
<style>
@media screen and (max-width: 1395px) and (min-width: 1200px) {
  .col-custom-70 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
    .col-custom-30 {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
    .col-custom-100 {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media screen and (max-width: 1201px) {
  .col-custom-70 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .col-custom-30 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .col-custom-100 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.col-custom-70 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
  padding: 0px 20px;
  display: block;
  position: relative;
  width: 100%;
}
.col-custom-30 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 30%;
  max-width: 30%;
  padding: 0px 20px;
  display: block;
  position: relative;
  width: 100%;
}
.col-custom-100 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0px 20px;
  display: block;
  position: relative;
  width: 100%;
}
.manager-dropdown button#dropdown-right__BV_toggle_ {
  color:  #ff681d !important;
}
.manager-dropdown .btn-outline-danger:hover {
  color:  white !important;
  background-color: #ff681d !important;
}
</style>
